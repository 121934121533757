import { mobileConstants } from "_constants";

const login = (payload) => {
  return {
    type: mobileConstants.LOGIN_REQUEST,
    payload,
  };
};

const getInitial = () => {
    return {
      type: mobileConstants.GET_INITIAL_MOBILE_REQUEST,
    };
  };

  const getPreClaims = (payload) => {
    return {
      type: mobileConstants.GET_PRECLAIMS_MOBILE_REQUEST,
      payload,
    };
  };

  const getAllMobileClaims = (payload) => {
    return {
      type: mobileConstants.GET_ALL_MOBILE_CLAIMS_REQUEST,
      payload,
    };
  };

  const getSchemaRdaOptions = (entityid) => {
    return {
      type: mobileConstants.GET_RDA_MOBILE_REQUEST,
      entityid
    };
  };

  const createInitial = (payload,entityID) => {
    return {
      type: mobileConstants.CREATE_INITIAL_MOBILE_REQUEST,
      payload,
      entityID
    };
  };

  const getFull = (mobileclaimid, internal) => {
    return {
      type: mobileConstants.GET_FULL_MOBILE_REQUEST,
      mobileclaimid,
      internal
    };
  };

  const updateFull = (payload,mobileclaimid, internal) => {
    return {
      type: mobileConstants.UPDATE_FULL_MOBILE_REQUEST,
      payload,
      mobileclaimid,
      internal
    };
  };

  const createMoveToPrg = (mobileclaimid, lobid) => {
    return {
      type: mobileConstants.CREATE_MOVE_TO_PRG_MOBILE_REQUEST,
      mobileclaimid, 
      lobid
    };
  };

  const getFiles = (mobileclaimid, internal) => {
    return {
      type: mobileConstants.GET_MOBILE_ATTACHMENTS_REQUEST,
      mobileclaimid,
      internal
    };
  };

    const uploadFile = (payload) => {
    return {
      type: mobileConstants.UPLOAD_CLAIM_ATTACHMENT_MOBILE_REQUEST,
      payload
    };
  };

  const deleteMobileClaim = (payload) => {
    return {
      type: mobileConstants.DELETE_MOBILE_CLAIM_REQUEST,
      payload
    };
  };

  // const getClaimRateCardContractor = (claimId) => {
  //   return {
  //     type:
  //       mobileConstants.GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_MOBILE_REQUEST,
  //     claimId,
  //   };
  // };
  
  // const getClaimRateCardMaterial = (claimId) => {
  //   return {
  //     type:
  //     mobileConstants.GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_MOBILE_REQUEST,
  //     claimId,
  //   };
  // };
  
  // const getClaimRateCardLabor = (claimId) => {
  //   return {
  //     type:
  //     mobileConstants.GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_MOBILE_REQUEST,
  //     claimId,
  //   };
  // };

  const logout = () => {
    return {
      type: mobileConstants.MOBILE_CLEAR,
    };
  };
export const mobileActions = {
  login,
  getInitial,
  getPreClaims,
  getAllMobileClaims,
  getSchemaRdaOptions,
  createInitial,
  getFull,
  updateFull,
  createMoveToPrg,
  getFiles,
  uploadFile,
  deleteMobileClaim,
  // getClaimRateCardContractor,
  // getClaimRateCardMaterial,
  // getClaimRateCardLabor,
  logout
};
import { getApiBase } from "_helpers";

import { lobOptions } from "_helpers";
import { toSqlDateString } from "_helpers";
import { cookieService } from "_services";
import _uniqueId from "lodash/uniqueId";

const getClientInformation = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/general?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured general information");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getDispatchEntities = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/schema/dispatchEntities${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured fetching Dispatch Entities");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};


const updateClientInformation = (  files,payload,
   id,lob ) => {
  let entries = Object.entries(payload)
  const dataFile = new FormData();

  dataFile.append("lob", lob);

if(files !== null){
  const fileNameWithType = files[0].name;
  const originalFileName = files[0].name.substr(0, files[0].name.lastIndexOf("."));

  const fileTypeNative = files[0].name.substr(files[0].name.lastIndexOf("."));

  dataFile.append("file", files[0], fileNameWithType);
  dataFile.append("fileName", `${id} - ${_uniqueId()}`);
  dataFile.append("originalFileName", originalFileName);
  dataFile.append("fileNameWithType", fileNameWithType);
  dataFile.append("fileTypeNative", fileTypeNative);
}
  entries.map( ([key, val] = entries) => dataFile.append(`${key}`, `${val}`))
  if (process.env.NODE_ENV !== "production") {
    dataFile.append("jwtCookie", cookieService.get("token"));
  }

  const parameters = {
    method: "PUT",
    credentials: "include",
    body: dataFile,
  };
  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/general?entityid=${id}`,
  parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating client information");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};



// depreciated
const getSowContracts = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/sowcontracts?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Sow Contracts");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

// deprecaited
const addSowContract = (
  { rdaId, startDate, endDate, sowName, billingType },
  entityId,
  lob
) => {
  let body = {
    rdaId,
    startDate: toSqlDateString(startDate),
    endDate: toSqlDateString(endDate),
    sowName,
    billingType,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  return fetch(
    `${apiBase}/admin/entity/sowcontracts?entityid=${entityId}&lob=${lobOptions[lob]}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating sow contract");
      }
      return response.json();
    })
    .catch((err) => {
      console.log("err", err);
      throw err;
    });
};

// depreciated
const updateSowContract = (entityId, lob, { id, ...payload }) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/sowcontracts?entityid=${entityId}&lob=${lobOptions[lob]}&sowid=${id}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id };
    })
    .catch((err) => {
      throw err;
    });
};


const getPaymentForm = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/payment?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Rda Control");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updatePaymentForm = (payload, entityId, lob) => {
  let body = {};

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/payment?entityid=${entityId}&lob=${lobOptions[lob]}&ccfee=${payload["ccfee"]}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating payment form");
      }

      return response;
    })
    .catch((err) => {
      throw err;
    });
};


const getPaymentFormFees = (entityId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/fees?entityId=${entityId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Fees");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updatePaymentFormFees = (payload,entityId) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }
  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/fees?entityId=${entityId}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating Fees ");
      }

      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const adminEntityService = {
  getClientInformation,
  getDispatchEntities,
  updateClientInformation,
  getSowContracts,
  addSowContract,
  updateSowContract,
  getPaymentForm,
  updatePaymentForm,
  getPaymentFormFees,
  updatePaymentFormFees
};

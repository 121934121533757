import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const getAllProjects = (page, rowsPerPage, filterParams, search) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/frelo/claims?rows=${rowsPerPage}&page=${page}${filterParams}${
      search ? `&search=${encodeURI(search)}` : ""
    }${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching claims");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getMyProjects = (page, rowsPerPage, filterParams, search) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/frelo/myclaims?rows=${rowsPerPage}&page=${page}${filterParams}${
      search ? `&search=${encodeURI(search)}` : ""
    }${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching claims");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getProjectById = (projectId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/frelo/claim?claimid=${projectId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching claim ${projectId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateFreloProject = (projectId, payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  return fetch(`${apiBase}/frelo/updateclaim?claimid=${projectId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, projectId };
    })
    .catch((err) => {
      throw err;
    });
};

const createFreloProject = (payload) => {
  let body = { ...payload };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  return fetch(`${apiBase}/frelo/create`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating claim");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getSchemaEntityOptions = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/frelo/schema/entityoptions${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching entity options}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getCreateProjectFieldRules = (entityId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/frelo/schema/create?entityid=${entityId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching field meta data");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getSchemaRdaOptions = (entityId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/frelo/schema/rda?entityid=${entityId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching rda options}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getSchemaFilterConditions = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/schema/claimsearchconditions${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching field meta data");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getFilterSets = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/frelo/filters/save${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching saved filter sets");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};
const checkFilterSetName = (base, name) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/frelo/filters/save/name?base=${base}&name=${name}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error checking filter set name");
      }
      return { ...response.json(), status: response.status };
    })
    .catch((err) => {
      throw err;
    });
};

const updateFilterSet = (filterSetId, payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/frelo/filters/save?filterid=${filterSetId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating filter set information");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const removeFilterSet = (id) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/frelo/filters/save?filterid=${id}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error deleting collection");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const createFilterSet = (payload) => {
  let body = { ...payload };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/frelo/filters/save`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating filter set");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};
const duplicateFilterSet = (id, name) => {
  let body = { name };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/frelo/filters/save/duplicate?filterid=${id}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error duplicating filter set");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const uploadFile = (image, { claimId, category, type, privateFile }) => {
  const dataFile = new FormData();

  // make image name better without using name of file...

  // put assets into claimId folder within gcp.
  // unique name, url.

  // from file upload
  const fileNameWithType = image.name;
  const originalFileName = image.name.substr(0, image.name.lastIndexOf("."));

  const fileTypeNative = image.name.substr(image.name.lastIndexOf("."));

  dataFile.append("file", image, `${claimId} - ${category}`);
  dataFile.append("fileName", `${claimId} - ${category}`);
  dataFile.append("claimId", claimId);
  dataFile.append("category", category);
  dataFile.append("type", type);
  dataFile.append("originalFileName", originalFileName);
  dataFile.append("fileNameWithType", fileNameWithType);
  dataFile.append("fileTypeNative", fileTypeNative);
  dataFile.append("lob", "FRELO"); // TEL , POWGAS , FRELO
  dataFile.append("privateFile", privateFile);

  if (process.env.NODE_ENV !== "production") {
    dataFile.append("jwtCookie", cookieService.get("token"));
  }

  const parameters = {
    method: "POST",
    credentials: "include",
    body: dataFile,
  };

  let apiBase = getApiBase();
  return fetch(`${apiBase}/claim/upload-frelo`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id: originalFileName };
    })
    .catch((err) => {
      throw err;
    });
};

const getFiles = (startOffset) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  let apiBase = getApiBase();
  return fetch(`${apiBase}/claim/files?offset=${startOffset}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching attachments");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getSignedUrl = (path, expire) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  let apiBase = getApiBase();
  return fetch(`${apiBase}/claim/file?path=${path}&expire=${expire}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching attachments");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const postPayment = (payload, claimId) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/frelo/payment/save?claimid=${claimId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      if (response.status !== 200) {
        throw new Error("Error posting payment");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

let getProjectHistory = (projectId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/frelo/claimhistory?claimid=${projectId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching rda options}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const deleteLedger = (id) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/joint/ledger/delete?ledgerid=${id}&lobid=3${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error removing ledger");
      }
      return { id: id, status: response.status };
    })
    .catch((err) => {
      throw err;
    });
};

const deleteStatusNote = (watchId) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/joint/statusnotes/delete?id=${watchId}&lobid=3${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error removing Status Note");
      }
      return { id: watchId, status: response.status };
    })
    .catch((err) => {
      throw err;
    });
};

export const freloProjectService = {
  getAllProjects,
  getMyProjects,
  getProjectById,
  updateFreloProject,
  createFreloProject,
  getSchemaEntityOptions,
  getSchemaRdaOptions,
  getSchemaFilterConditions,
  getCreateProjectFieldRules,
  getFilterSets,
  checkFilterSetName,
  updateFilterSet,
  removeFilterSet,
  createFilterSet,
  duplicateFilterSet,
  uploadFile,
  getFiles,
  getSignedUrl,
  postPayment,
  getProjectHistory,
  deleteLedger,
  deleteStatusNote,
};

import { telecomClaimConstants } from "_constants";

const getAll = (page, rowsPerPage, filterModal, search, searchField, searchFields, isDispatchAdmin, allClaimsCount, restrictedStatusIds) => {
  return {
    type: telecomClaimConstants.GET_ALL_CLAIMS_TELECOM_REQUEST,
    page,
    rowsPerPage,
    filterModal,
    search,
    searchField,
    searchFields,
    isDispatchAdmin,
    allClaimsCount,
    restrictedStatusIds,
  };
};

const getMyMobile = (page, rowsPerPage, filterModal, search, searchField, searchFields, restrictedStatusIds) => {
  return {
    type: telecomClaimConstants.GET_MY_MOBILE_CLAIMS_TELECOM_REQUEST,
    page,
    rowsPerPage,
    filterModal,
    search,
    searchField,
    searchFields,
    restrictedStatusIds,
  };
};

const getMy = (page, rowsPerPage, filterModal, search, searchField, searchFields, isDispatchAdmin, allClaimsCount, userLogginIn) => {
  return {
    type: telecomClaimConstants.GET_MY_CLAIMS_TELECOM_REQUEST,
    page,
    rowsPerPage,
    filterModal,
    search,
    searchField,
    searchFields,
    isDispatchAdmin,
    allClaimsCount,
    userLogginIn,
  };
};
const getMyTeams = (page, rowsPerPage, filterModal, search, searchField, searchFields, isDispatchAdmin, allClaimsCount, userLogginIn) => {
  return {
    type: telecomClaimConstants.GET_MY_TEAMS_CLAIMS_TELECOM_REQUEST,
    page,
    rowsPerPage,
    filterModal,
    search,
    searchField,
    searchFields,
    isDispatchAdmin,
    allClaimsCount,
    userLogginIn,
  };
};

const getById = (claimId, restrictedStatusIds) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_REQUEST,
    claimId,
    restrictedStatusIds,
  };
};

const update = (claimId, payload, restrictedStatusIds) => {
  return {
    type: telecomClaimConstants.UPDATE_TELECOM_CLAIM_REQUEST,
    claimId,
    payload,
    restrictedStatusIds,
  };
};

const deleteClaim = (
  claimId,
  currentQuery,
  page,
  rowsPerPage,
  filterModal,
  search,
  isDispatchAdmin,
  allClaimsCount,
  userLogginIn,
  restrictedStatusIds,
  trackChanges,
  data,
  callback,
  searchField,
  searchFields,
) => {
  return {
    type: telecomClaimConstants.DELETE_TELECOM_CLAIM_REQUEST,
    claimId,
    currentQuery,
    page,
    rowsPerPage,
    filterModal,
    search,
    isDispatchAdmin,
    allClaimsCount,
    userLogginIn,
    restrictedStatusIds,
    trackChanges,
    data,
    callback,
    searchField,
    searchFields,
  };
};

const getSchemaEntityOptions = (lob) => {
  return {
    type: telecomClaimConstants.GET_SCHEMA_ENTITY_OPTIONS_TELECOM_REQUEST,
    lob,
  };
};

const getCreateClaimFieldRules = (entityId) => {
  return {
    type: telecomClaimConstants.GET_CREATE_CLAIM_FIELD_RULES_TELECOM_REQUEST,
    entityId,
  };
};

const getSchemaRdaOptions = (entityId, getAll = false) => {
  return {
    type: telecomClaimConstants.GET_SCHEMA_RDA_OPTIONS_TELECOM_REQUEST,
    entityId,
    getAll
  };
};

const getSchemaFilterConditions = () => {
  return {
    type: telecomClaimConstants.GET_SCHEMA_FILTER_OPTIONS_TELECOM_REQUEST,
  };
};

const getFilterSets = () => {
  return {
    type: telecomClaimConstants.GET_FILTER_SETS_TELECOM_REQUEST,
  };
};

const createFilterSet = (payload) => {
  return {
    type: telecomClaimConstants.CREATE_FILTER_SET_TELECOM_REQUEST,
    payload,
  };
};

const removeFilterSet = (id) => {
  return {
    type: telecomClaimConstants.REMOVE_FILTER_SET_TELECOM_REQUEST,
    id,
  };
};

const setFilterSetActive = (id) => {
  return {
    type: telecomClaimConstants.SET_FILTER_SET_ACTIVE_TELECOM_REQUEST,
    id,
  };
};

// @TODO
// move to update action
const updateFilterSetName = (name, id) => {
  return {
    type: telecomClaimConstants.UPDATE_FILTER_SET_NAME_TELECOM_REQUEST,
    name,
    id,
  };
};

const duplicateFilterSet = (name, id) => {
  return {
    type: telecomClaimConstants.DUPLICATE_FILTER_SET_TELECOM_REQUEST,
    name,
    id,
  };
};

const updateFilterSet = (id, payload) => {
  return {
    type: telecomClaimConstants.UPDATE_FILTER_SET_TELECOM_REQUEST,
    id,
    payload,
  };
};

const uploadFile = (files, fieldValues, claimId) => {
  return {
    type: telecomClaimConstants.UPLOAD_CLAIM_ATTACHMENT_TELECOM_REQUEST,
    payload: { ...fieldValues, files },
    claimId,
  };
};

const handleSaveRotation = (file, GCSPath, claimId, fileID, fk_categoryID) => {
  return {
    type: telecomClaimConstants.SAVE_ROTATION_TELECOM_REQUEST,
    payload: { file, GCSPath, claimId, fileID, fk_categoryID },
  };
};

const getFiles = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_ATTACHMENTS_TELECOM_REQUEST,
    claimId,
  };
};

const sortOrder = (payload, claimId) => {
  return {
    type: telecomClaimConstants.SORT_ATTACHMENTS_TELECOM_CLAIM_REQUEST,
    payload,
    claimId,
  };
};

const updateCategory = (payload, claimId) => {
  return {
    type: telecomClaimConstants.UPDATE_CATEGORY_ATTACHMENTS_TELECOM_CLAIM_REQUEST,
    payload,
    claimId,
  };
};

const getSignedUrl = (path, expire) => {
  return {
    type: telecomClaimConstants.GET_SIGNED_ATTACHMENT_LINK_TELECOM_REQUEST,
    path,
    expire,
  };
};

const postPayment = (claimId, data) => {
  return {
    type: telecomClaimConstants.POST_PAYMENT_TELECOM_REQUEST,
    payload: { ...data },
    claimId,
  };
};

const getClaimHistory = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_HISTORY_TELECOM_REQUEST,
    claimId,
  };
};

const getClaimJobCostingInvoice = (claimId, ccma) => {
  return {
    type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_REQUEST,
    claimId,
    ccma,
  };
};

const getClaimRateCardContractor = (claimId) => {
  return {
    type: telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_TELECOM_REQUEST,
    claimId,
  };
};

const getClaimRateCardMaterial = (claimId) => {
  return {
    type: telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_TELECOM_REQUEST,
    claimId,
  };
};

const getClaimRateCardLabor = (claimId, ccma) => {
  return {
    type: telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_TELECOM_REQUEST,
    claimId,
    ccma,
  };
};

const getClaimRateCardUsic = (claimId) => {
  return {
    type: telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_USIC_TELECOM_REQUEST,
    claimId,
  };
};

const claimInvoiceTimeStamp = (claimId) => {
  return {
    type: telecomClaimConstants.CLAIM_INVOICE_TIMESTAMP_TELECOM_REQUEST,
    claimId,
  };
};

const claimPostInHouseLabor = (payload, claimId, invoiceId, ccma, entityid) => {
  return {
    type: telecomClaimConstants.POST_CLAIM_IN_HOUSE_LABOR_ITEM_TELECOM_REQUEST,
    payload,
    claimId,
    invoiceId,
    ccma,
    entityid,
  };
};

const claimPostMaterialItem = (payload, claimId, invoiceId, ccma, entityid) => {
  return {
    type: telecomClaimConstants.POST_CLAIM_MATERIAL_ITEM_TELECOM_REQUEST,
    payload,
    claimId,
    invoiceId,
    ccma,
    entityid,
  };
};

const claimPostOtherItem = (payload, claimId, invoiceId) => {
  return {
    type: telecomClaimConstants.POST_CLAIM_OTHER_ITEM_TELECOM_REQUEST,
    payload,
    claimId,
    invoiceId,
  };
};

const claimPostUnlistedItem = (payload, claimId, entityId, invoiceId) => {
  return {
    type: telecomClaimConstants.POST_CLAIM_UNLISTED_ITEM_TELECOM_REQUEST,
    payload,
    claimId,
    entityId,
    invoiceId,
  };
};

const claimPostContractorItem = (payload, claimId, invoiceId, ccma, entityid) => {
  return {
    type: telecomClaimConstants.POST_CLAIM_CONTRACTOR_ITEM_TELECOM_REQUEST,
    payload,
    claimId,
    invoiceId,
    ccma,
    entityid,
  };
};

const claimPostLOItem = (payload, claimId, invoiceId, entityId) => {
  return {
    type: telecomClaimConstants.POST_CLAIM_LO_ITEM_TELECOM_REQUEST,
    payload,
    claimId,
    invoiceId,
    entityId,
  };
};

const claimJobCostingDeleteOtherItem = (invoiceOtherItemIds, invoiceId, claimId) => {
  return {
    type: telecomClaimConstants.DELETE_CLAIM_JOB_COSTING_OTHER_ITEM_REQUEST,
    invoiceOtherItemIds,
    invoiceId,
    claimId,
  };
};

const claimJobCostingDeleteLaborItem = (invoiceLaborItemIds, invoiceId, claimId, ccma) => {
  return {
    type: telecomClaimConstants.DELETE_CLAIM_JOB_COSTING_LABOR_ITEM_REQUEST,
    invoiceLaborItemIds,
    invoiceId,
    claimId,
    ccma,
  };
};

const claimJobCostingDeleteMaterialItem = (invoiceMaterialItemIds, invoiceId, claimId, ccma) => {
  return {
    type: telecomClaimConstants.DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_REQUEST,
    invoiceMaterialItemIds,
    invoiceId,
    claimId,
    ccma,
  };
};

const claimJobCostingDeleteContractorItem = (invoiceContractorItemIds, invoiceId, claimId, ccma) => {
  return {
    type: telecomClaimConstants.DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_REQUEST,
    invoiceContractorItemIds,
    invoiceId,
    claimId,
    ccma,
  };
};

const claimJobCostingDeleteLOItem = (invoiceLOItemIds, invoiceId, claimId, entityId) => {
  return {
    type: telecomClaimConstants.DELETE_CLAIM_JOB_COSTING_LO_ITEM_REQUEST,
    invoiceLOItemIds,
    invoiceId,
    claimId,
    entityId,
  };
};

const claimUploadJobCosting = (file, name, claimId, userId, username) => {
  return {
    type: telecomClaimConstants.UPLOAD_CLAIM_JOB_COSTING_SHEET_TELECOM_REQUEST,
    file,
    name,
    claimId,
    userId,
    username,
  };
};

const claimUploadRotatedImage = (file, GCSPath, claimId) => {
  return {
    type: telecomClaimConstants.UPLOAD_CLAIM_ROTATED_IMAGE_TELECOM_REQUEST,
    file,
    GCSPath,
    claimId,
  };
};

const getClaimInvoiceDocument = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_INVOICE_DOCUMENT_TELECOM_REQUEST,
    claimId,
  };
};

const claimUploadDocument = (file, name, claimId, userId, username, parent, category, fileType) => {
  return {
    type: telecomClaimConstants.UPLOAD_CLAIM_DOCUMENT_TELECOM_REQUEST,
    file,
    name,
    claimId,
    userId,
    username,
    parent,
    category,
    fileType
  };
};

const getClaimDocument = (claimId, letterType, contacts, reason) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_DOCUMENT_TELECOM_REQUEST,
    claimId,
    letterType,
    contacts,
    reason,
  };
};

const getClaimDocumentSchemaReason = () => {
  return {
    type: telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST,
  };
};

const addClaimDocumentSchemaReason = (LetterReason) => {
  return {
    type: telecomClaimConstants.ADD_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST,
    LetterReason,
  };
};

const editClaimDocumentSchemaReason = (LRID, LetterReason) => {
  return {
    type: telecomClaimConstants.EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST,
    LRID,
    LetterReason,
  };
};

const removeClaimDocumentSchemaReason = (LRID) => {
  return {
    type: telecomClaimConstants.REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST,
    LRID,
  };
};

const getClaimDocumentSchemaContacts = () => {
  return {
    type: telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_TELECOM_REQUEST,
  };
};

const getClaimDocumentSchemaLastSentence = (entityId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_TELECOM_REQUEST,
    entityId,
  };
};

const getAttachmentImageCategoriesSchema = () => {
  return {
    type: telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_TELECOM_REQUEST,
  };
};

const getAttachmentVideoCategoriesSchema = () => {
  return {
    type: telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_TELECOM_REQUEST,
  };
};

const getAttachmentDocumentCategoriesSchema = (parentType) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_TELECOM_REQUEST,
    parentType,
  };
};

const getClaimDocumentSchemaDigLaws = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_DIG_LAWS_TELECOM_REQUEST,
    claimId,
  };
};

const getClaimNarrativeCreatorDocument = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_TELECOM_REQUEST,
    claimId,
  };
};

const getClaimIntentToBillDocument = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_INTENT_TO_BILL_DOCUMENT_TELECOM_REQUEST,
    claimId,
  };
};

const getClaimPromissoryNoteDocument = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_PROMISSORY_DOCUMENT_TELECOM_REQUEST,
    claimId,
  };
};

const getClaimLegalFinalNoticeDocument = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_TELECOM_REQUEST,
    claimId,
  };
};

const getClaimFinalNoticeDocument = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_FINAL_NOTICE_DOCUMENT_TELECOM_REQUEST,
    claimId,
  };
};

const getClaimSecondNoticeDocument = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_SECOND_NOTICE_DOCUMENT_TELECOM_REQUEST,
    claimId,
  };
};

const getClaimReleaseLetterDocument = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_RELEASE_LETTER_DOCUMENT_TELECOM_REQUEST,
    claimId,
  };
};

const getClaimPaymentReceiptDocument = (claimId, ledgerId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_TELECOM_REQUEST,
    claimId,
    ledgerId,
  };
};

const getClaimBalanceOwedDocument = (claimId) => {
  return {
    type: telecomClaimConstants.GET_CLAIM_BALANCE_OWED_DOCUMENT_TELECOM_REQUEST,
    claimId,
  };
};

const getLogo = (entityLogoName) => {
  return {
    type: telecomClaimConstants.GET_LOGO_TELECOM_REQUEST,
    entityLogoName,
  };
};

const deleteLedger = (ledgerId, claimId) => {
  return {
    type: telecomClaimConstants.REMOVE_LEDGER_TELECOM_REQUEST,
    ledgerId,
    claimId,
  };
};
const updateLedger = (payload, watchId, cb = null) => {
  return {
    type: telecomClaimConstants.UPDATE_LEDGER_TELECOM_REQUEST,
    payload,
    watchId,
    cb,
  };
};
const deleteStatusNote = (id, watchId) => {
  return {
    type: telecomClaimConstants.REMOVE_STATUS_NOTE_TELECOM_REQUEST,
    id,
    watchId,
  };
};
const deleteActionNote = (actionId, claimId) => {
  return {
    type: telecomClaimConstants.DELETE_ACTION_NOTE_TELECOM_REQUEST,
    actionId,
    claimId,
  };
};
const saveColumnFilter = (filters) => {
  return {
    type: telecomClaimConstants.SAVE_TELECOM_COLUMN_FILTER_REQUEST,
    filters,
  };
};
export const telecomClaimActions = {
  getAll,
  getMy,
  getMyTeams,
  getMyMobile,
  getById,
  update,
  deleteClaim,
  getSchemaEntityOptions,
  getSchemaRdaOptions,
  getSchemaFilterConditions,
  getCreateClaimFieldRules,
  getFilterSets,
  createFilterSet,
  removeFilterSet,
  setFilterSetActive,
  updateFilterSetName,
  duplicateFilterSet,
  updateFilterSet,
  uploadFile,
  handleSaveRotation,
  getFiles,
  sortOrder,
  updateCategory,
  getSignedUrl,
  postPayment,
  getClaimHistory,
  getClaimJobCostingInvoice,
  getClaimRateCardContractor,
  getClaimRateCardMaterial,
  getClaimRateCardLabor,
  getClaimRateCardUsic,
  claimInvoiceTimeStamp,
  claimPostInHouseLabor,
  claimPostMaterialItem,
  claimPostOtherItem,
  claimPostUnlistedItem,
  claimPostLOItem,
  claimPostContractorItem,
  claimJobCostingDeleteOtherItem,
  claimJobCostingDeleteLaborItem,
  claimJobCostingDeleteMaterialItem,
  claimJobCostingDeleteContractorItem,
  claimJobCostingDeleteLOItem,
  claimUploadJobCosting,
  claimUploadRotatedImage,
  getClaimInvoiceDocument,
  claimUploadDocument,
  getClaimDocument,
  getClaimDocumentSchemaReason,
  getClaimDocumentSchemaContacts,
  getClaimDocumentSchemaLastSentence,
  getAttachmentImageCategoriesSchema,
  getAttachmentVideoCategoriesSchema,
  getAttachmentDocumentCategoriesSchema,
  getClaimDocumentSchemaDigLaws,
  getClaimNarrativeCreatorDocument,
  getClaimIntentToBillDocument,
  getClaimPromissoryNoteDocument,
  getClaimLegalFinalNoticeDocument,
  getClaimFinalNoticeDocument,
  getClaimReleaseLetterDocument,
  getClaimSecondNoticeDocument,
  getClaimPaymentReceiptDocument,
  getClaimBalanceOwedDocument,
  getLogo,
  deleteLedger,
  updateLedger,
  deleteStatusNote,
  deleteActionNote,
  addClaimDocumentSchemaReason,
  editClaimDocumentSchemaReason,
  removeClaimDocumentSchemaReason,
  saveColumnFilter,
};

export const freloProjectConstants = {
  GET_ALL_PROJECTS_FRELO_REQUEST: "GET_ALL_PROJECTS_FRELO_REQUEST",
  GET_ALL_PROJECTS_FRELO_SUCCESS: "GET_ALL_PROJECTS_FRELO_SUCCESS",
  GET_MY_PROJECTS_FRELO_REQUEST: "GET_MY_PROJECTS_FRELO_REQUEST",
  GET_MY_PROJECTS_FRELO_SUCCESS: "GET_MY_PROJECTS_FRELO_SUCCESS",
  GET_PROJECT_BY_ID_FRELO_REQUEST: "GET_PROJECT_BY_ID_FRELO_REQUEST",
  GET_PROJECT_BY_ID_FRELO_SUCCESS: "GET_PROJECT_BY_ID_FRELO_SUCCESS",
  UPDATE_FRELO_PROJECT_REQUEST: "UPDATE_FRELO_PROJECT_REQUEST",
  UPDATE_FRELO_PROJECT_SUCCESS: "UPDATE_FRELO_PROJECT_SUCCESS",
  GET_CLAIM_BY_ID_FRELO_LOADING_TRUE:"GET_CLAIM_BY_ID_FRELO_LOADING_TRUE",
  GET_SCHEMA_ENTITY_OPTIONS_FRELO_REQUEST:
    "GET_SCHEMA_ENTITY_OPTIONS_FRELO_REQUEST",
  GET_SCHEMA_ENTITY_OPTIONS_FRELO_SUCCESS:
    "GET_SCHEMA_ENTITY_OPTIONS_FRELO_SUCCESS",
  GET_CREATE_PROJECT_FIELD_RULES_FRELO_REQUEST:
    "GET_CREATE_PROJECT_FIELD_RULES_FRELO_REQUEST",
  GET_CREATE_PROJECT_FIELD_RULES_FRELO_SUCCESS:
    "GET_CREATE_PROJECT_FIELD_RULES_FRELO_SUCCESS",
  GET_SCHEMA_RDA_OPTIONS_FRELO_REQUEST: "GET_SCHEMA_RDA_OPTIONS_FRELO_REQUEST",
  GET_SCHEMA_RDA_OPTIONS_FRELO_SUCCESS: "GET_SCHEMA_RDA_OPTIONS_FRELO_SUCCESS",
  GET_SCHEMA_FILTER_OPTIONS_FRELO_REQUEST:
    "GET_SCHEMA_FILTER_OPTIONS_FRELO_REQUEST",
  GET_SCHEMA_FILTER_OPTIONS_FRELO_SUCCESS:
    "GET_SCHEMA_FILTER_OPTIONS_FRELO_SUCCESS",
  GET_FILTER_SETS_FRELO_REQUEST: "GET_FILTER_SETS_FRELO_REQUEST",
  GET_FILTER_SETS_FRELO_SUCCESS: "GET_FILTER_SETS_FRELO_SUCCESS",
  CREATE_FILTER_SET_FRELO_REQUEST: "CREATE_FILTER_SET_FRELO_REQUEST",
  CREATE_FILTER_SET_FRELO_SUCCESS: "CREATE_FILTER_SET_FRELO_SUCCESS",
  UPDATE_FILTER_SET_FRELO_REQUEST: "UPDATE_FILTER_SET_FRELO_REQUEST",
  UPDATE_FILTER_SET_FRELO_SUCCESS: "UPDATE_FILTER_SET_FRELO_SUCCESS",
  REMOVE_FILTER_SET_FRELO_REQUEST: "REMOVE_FILTER_SET_FRELO_REQUEST",
  REMOVE_FILTER_SET_FRELO_SUCCESS: "REMOVE_FILTER_SET_FRELO_SUCCESS",
  DUPLICATE_FILTER_SET_FRELO_REQUEST: "DUPLICATE_FILTER_SET_FRELO_REQUEST",
  DUPLICATE_FILTER_SET_FRELO_SUCCESS: "DUPLICATE_FILTER_SET_FRELO_SUCCESS",
  UPLOAD_CLAIM_ATTACHMENT_FRELO_REQUEST:
    "UPLOAD_CLAIM_ATTACHMENT_FRELO_REQUEST",
  UPLOAD_CLAIM_ATTACHMENT_FRELO_SUCCESS:
    "UPLOAD_CLAIM_ATTACHMENT_FRELO_SUCCESS",
    REMOVE_LEDGER_FRELO_REQUEST:"REMOVE_LEDGER_FRELO_REQUEST",
    REMOVE_LEDGER_FRELO_SUCCESS:"REMOVE_LEDGER_FRELO_SUCCESS",
    REMOVE_STATUS_NOTE_FRELO_REQUEST:"REMOVE_STATUS_NOTE_FRELO_REQUEST",
    REMOVE_STATUS_NOTE_FRELO_SUCCESS:"REMOVE_STATUS_NOTE_FRELO_SUCCESS",
  GET_CLAIM_ATTACHMENTS_FRELO_REQUEST: "GET_CLAIM_ATTACHMENTS_FRELO_REQUEST",
  GET_CLAIM_ATTACHMENTS_FRELO_SUCCESS: "GET_CLAIM_ATTACHMENTS_FRELO_SUCCESS",
  POST_PAYMENT_FRELO_REQUEST: "POST_PAYMENT_FRELO_REQUEST",
  POST_PAYMENT_FRELO_SUCCESS: "POST_PAYMENT_FRELO_SUCCESS",
  GET_PROJECT_HISTORY_FRELO_REQUEST: "GET_PROJECT_HISTORY_FRELO_REQUEST",
  GET_PROJECT_HISTORY_FRELO_SUCCESS: "GET_PROJECT_HISTORY_FRELO_SUCCESS",
  FRELO_PROJECT_CLEAR: "FRELO_PROJECT_CLEAR",
};

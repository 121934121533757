import { put, call } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  powerGasReportConstants,
} from "_constants";

import { powerGasReportsService } from "_services";
import { formatDate } from "_helpers";
export function* getAllReportsPowerGasSaga() {
  try {
    const response = yield call(powerGasReportsService.getAllReports);
    yield put({
      type: powerGasReportConstants.GET_ALL_REPORTS_POWER_GAS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getOneReportPowerGasSaga({ reportId }) {
  try {
    const response = yield call(powerGasReportsService.getOneReport, reportId);

    yield put({
      type: powerGasReportConstants.GET_ONE_REPORT_POWER_GAS_SUCCESS,
      payload: {
        reportId,
        data: response,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* runOneReportPowerGasSaga({ reportId, payload, fields }) {
  try {
    let data = Object.keys(payload).reduce((prev, el) => {
      return [...prev, { ReportParamID: el, value: payload[el] }];
    }, []);

    const result = yield call(powerGasReportsService.runReport, reportId, {
      params: data,
    });
    let { response } = result;

    yield put({
      type: powerGasReportConstants.POST_RUN_REPORT_POWER_GAS_SUCCESS,
      payload: {
        reportId,
        data: response,
        fields,
      },
    });
    
    if (response.length === 0)
      yield put({type: alertConstants.SUCCESS, payload: {message: "No results found for given parameters"}});
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: powerGasReportConstants.POST_RUN_REPORT_POWER_GAS_ERROR,
    });
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

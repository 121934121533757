import React from "react";
import { Controller } from "react-hook-form";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import theme from "_theme/theme";
import MomentUtils from "@date-io/moment";

export default function Index({
  control,
  name,
  customViews,
  placeholder,
  label,
  disabled,
  customStyle,
  defaultValue,
  shouldDisableDate,
  disableFuture,
  disablePast,
  darkMode,
  testId,
  error,
  helperText,
  KeyboardButtonProps,
  size,
  maxDate,
  minDate,
  customValidationError,
  required
}) {
  return (
    <Controller
      rules={{
        validate: (value) => {
          if (required && !value) {
            return customValidationError ?? "Required"
          }
          if (maxDate && value) {
            if (isNaN(new Date(value))) return "Invalid date format. Please enter a valid date in MM/DD/YYYY format.";
            // catch case where user manually entered date via keyboard (value entered exceeds maxDate value)
            if (new Date(value).toISOString().split("T")[0] > new Date(maxDate).toISOString().split("T")[0]) {
              return customValidationError ?? "Value exceeds maximum date allowed";
            }
          }
          if (minDate && value) {
            if (isNaN(new Date(value))) return "Invalid date format. Please enter a valid date in MM/DD/YYYY format.";
            // catch case where user manually entered date via keyboard (value entered exceeds maxDate value)
            if (new Date(value).toISOString().split("T")[0] < new Date(minDate).toISOString().split("T")[0]) {
              return customValidationError ?? "Value is less than minimum date allowed";
            }
          }
          return true;
        },
      }}
      render={({ ref, ...props }) => {
        return (
          <MuiPickersUtilsProvider utils={MomentUtils} locale="en" utc={true}>
            <KeyboardDatePicker
              {...props}
              value={props.value || null}
              error={error ? error : false}
              helperText={helperText ? helperText : null}
              views={customViews}
              fullWidth
              inputVariant="outlined"
              variant="inline"
              label={label}
              size={size || "small"}
              disableToolbar
              autoOk
              disableFuture={disableFuture ? disableFuture : false}
              disablePast={disablePast ? disablePast : false}
              shouldDisableDate={shouldDisableDate ? shouldDisableDate : null}
              format="MM/DD/YYYY" // Specify the desired date format here
              disabled={disabled ? disabled : false}
              maxDate={maxDate ? maxDate : undefined}
              minDate={minDate ? minDate : undefined}
              InputLabelProps={{ shrink: true }}
              style={{
                backgroundColor: darkMode ? theme.palette.HeaderGrayPrg.main : theme.palette.WhitePrg.main,
              }}
              InputProps={{
                style: customStyle
                  ? {
                      ...customStyle,
                    }
                  : {},
              }}
              KeyboardButtonProps={{
                ...KeyboardButtonProps,
                "data-testid": `${testId ? testId : ""}`,
              }}
              inputRef={ref}
            />
          </MuiPickersUtilsProvider>
        );
      }}
      control={control}
      name={name}
      placeholder={placeholder ? placeholder : ""}
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
}

import React from "react";
import clsx from "clsx";
import { userActions } from "_actions";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import LobSelect from "./components/LobSelect";
import StartClaim from "./components/StartClaim";
// import Search from "./components/Search";
import Profile from "./components/Profile";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ObjectTools } from "_utility";
import _getProperty from "lodash/get";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { lobUrlMaskInteger } from '_helpers';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme["palette"]["OffWhitePrg"]["main"],
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 13,
    color: theme["palette"]["DarkGrayPrg"]["main"],
  },
  hide: {
    display: "none",
  },
  searchWrap: {
    top: "13px",
    left: "350px",
    margin: "auto",
    position: "absolute",
    maxWidth: "400px",
    [theme.breakpoints.down("sm")]: {
      left: "77px",
    },
  },
  assume: {
    display: 'flex',
    alignItems: 'center'
  },
  btn: {
    fontSize: `9px !important`,
    backgroundColor: `${theme.palette.GreenPrg.main} !important`
  }
}));

export const NavBar = ({ openDrawer, toggleDrawer, open }) => {
  const classes = useStyles();
  const { getNestedPropertySafe } = ObjectTools;
  const dispatch = useDispatch();

  const selectedLob = useSelector((state) =>
    getNestedPropertySafe(state, ["lob", "current"])
  );

  const currentUser = useSelector((state) =>
    _getProperty(state, [
      "auth"
    ])
  );

  const hasStartClaimAccess = useSelector((state) =>
    getNestedPropertySafe(state, ["permission", "actionAccess", lobUrlMaskInteger[selectedLob], "startClaim"])
  )

  let currentRole = currentUser && currentUser['defaultRoleId']
  let assumerole = false
  let username = currentUser && currentUser['username']
  let user = currentUser && currentUser['userId']


  const endUserImpersonation = () => {
    dispatch(userActions.assumeUser({ username: currentUser['currentUser'] }))
    dispatch(userActions.endImpersonation())
  }

  const endRoleImpersonation = () => {
    dispatch(userActions.assumeRole({ roleId: currentRole }, assumerole, currentRole, username, user))
    dispatch(userActions.endImpersonation())
  }

  return (
    <AppBarWrapper>
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>
          <MenuWrapper>
            <LobSelect openDrawer={openDrawer} />
            <Group className={`${selectedLob !== "Admin" && selectedLob !== "POWGAS" ? "mobileTopNav" : ""} ${!open && "showDrop"}`}>
              {hasStartClaimAccess && selectedLob === "TEL" ? <StartClaim toggleDrawer={toggleDrawer} /> : "" }
            </Group>
            {currentUser && currentUser['assumeUser'] ?
              <div className={classes.assume}>
                <Alert
                  severity="success"
                  color='success'
                  action={
                    <Button className={classes.btn} size="small" onClick={endUserImpersonation}>
                      End Session
                    </Button>
                  }
                >
                  <Typography variant="caption" >
                    Assuming User: {currentUser['username']}
                  </Typography>
                </Alert>
              </div>
              : currentUser && currentUser['assumeRole'] ?
                <div className={classes.assume}>
                  <Alert
                    severity="success"
                    color='success'
                    action={
                      <Button className={classes.btn} size="small" onClick={endRoleImpersonation}>
                        End Session
                      </Button>
                    }
                  >
                    <Typography variant="caption" >
                      Assuming Role: {currentUser['role']['roleName']}
                    </Typography>
                  </Alert>
                </div>
                : ""

            }
            {/* <div className={classes.searchWrap}>
              <Search />
            </div> */}
            <Group>
              <Profile />
            </Group>
          </MenuWrapper>
        </Toolbar>
      </AppBar>
    </AppBarWrapper>
  );
};

const AppBarWrapper = styled.div`
@media (max-width: 600px) {
  .mobileTopNav {
    position: absolute;
    top: 67px;
    left: 5px;
    text-align: left;
    padding: 10px !important;
    border-radius: 0px;
    flex-direction: column;
   & > div {
      min-width: 100%;
      flex-basis: 100%;
      margin-bottom: 20px;
    }
    &.showDrop {
      display: none !important;
    }
}
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Group = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

import { activeLinksConstants } from "../_constants";

 function setActiveLinks(payload) {
    return {
      type: activeLinksConstants.ACTIVE_LINKS_SET,
      payload
    };
  }

  export const activeLinksActions = {
    setActiveLinks
  };
const serializeKey = (key) => {
  let keyPair = key.split("-");
  if (keyPair.length > 1) {
    return {
      id: keyPair[0],
      key: keyPair[1],
    };
  } else {
    return null;
  }
};

export const serializeDefaultValues = (uniqueId, columnIds_inner, array) => {
  let response_immutable = array.reduce((prev, item) => {
    let buildRes = Object.keys(item).reduce((prev_inner, property) => {
      if (columnIds_inner.includes(property)) {
        return {
          ...prev_inner,
          [`${item[uniqueId]}-${property}`]: item[property],
        };
      }
      return { ...prev_inner };
    }, {});

    return { ...prev, ...buildRes };
  }, {});

  return { ...response_immutable };
};

export const getUniqueObjValues = (data, defaultValues) => {
  let result = Object.keys(data).reduce((prev, key) => {
    if (defaultValues[key] === null || defaultValues[key] === "") {
      // Autocomplete keeps values null
      // Textfield converts null to empty string
      // we need to treat them as equal, (null = "") basically
      if (data[key] !== null && data[key] !== "") {
        return { ...prev, [key]: data[key] };
      }
      return { ...prev };
    }

    if (typeof defaultValues[key] === "number") {
      // Inputs with type number will still submit a string
      if (defaultValues[key] !== parseFloat(data[key])) {
        return { ...prev, [key]: data[key] };
      }
      return { ...prev };
    }

    if (defaultValues[key] !== data[key]) {
      return { ...prev, [key]: data[key] };
    }

    return { ...prev };
  }, {});

  return { ...result };
};

export const buildPayloadArray = (data) => {
  let res = {};

  Object.keys(data).forEach((key) => {
    let keyPair = serializeKey(key);

    if (keyPair) {
      res[keyPair["id"]] = {
        ...res[keyPair["id"]],
        [keyPair["key"]]: data[key],
      };
    }
  });

  let response = Object.keys(res).map((elm) => {
    return {
      id: elm,
      ...res[elm],
    };
  });

  return [...response];
};

export const buildAlertMessage = (subject, response_array = [] ) => {
  let success = response_array.filter((el) => el["status"] === 200);
  let fail = response_array.filter((el) => el["status"] !== 200);

  let success_ids = success.map((el) => el["id"]);
  let fail_ids = fail.map((el) => el["id"]);

  let message = `${subject}`;

  if (success_ids.length > 0) {
    let successMsg = `success: ${success_ids.join(", ")}`;
    message = `${message} ${successMsg}`;
  }

  if (fail_ids.length > 0) {
    let failMsg = `failed: ${fail_ids.join(", ")}`;
    message = `${message} ${failMsg}`;
  }

  return message;
};

export const removeEmptyValues = (data) => {
  let trimmedData = Object.keys(data).reduce((prev, el) => {
    if (data[el] === "" || data[el] === null || data[el] === undefined) {
      return { ...prev };
    }
    return { ...prev, [el]: data[el] };
  }, {});
  return trimmedData;
};

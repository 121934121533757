import { adminNotificationsConstants } from "_constants";

const getAll = () => {
  return {
    type: adminNotificationsConstants.ADMIN_NOTIFICATIONS_GET_ALL_REQUEST,
  };
};

const updateNotifications = ({notifications}) => {
  return {
    type: adminNotificationsConstants.ADMIN_NOTIFICATIONS_UPDATE_REQUEST,
    notifications
  };
};

export const adminNotificationsActions = {
  getAll,
  updateNotifications,
};

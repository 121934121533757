import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mobileActions } from "_actions";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { ObjectTools } from "_utility";
import { formatDateNoTime, formatTime } from "_helpers";
import _getProperty from "lodash/get";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Documents from "../Documents";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    padding: 20,
    [theme.breakpoints.up("md")]: {
      padding: 40,
      height: "100vh",
    },
    backgroundColor: theme.palette.HeaderGrayPrg.main,
    "& .MuiDataGrid-root": {
      color: theme.palette.WhitePrg.main,
      "& .MuiDataGrid-cell": {
        "& .MuiDataGrid-cell--editing": {
          backgroundColor: theme.palette.HeaderGrayPrg.main,
        },
      },
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "#3535353b",
    },
  },
  grid: {
    width: "100%",
    padding: 20,
    backgroundColor: theme.palette.HeaderGrayPrg.main,
    "& .MuiDataGrid-root": {
      color: theme.palette.WhitePrg.main,
      "& .MuiDataGrid-cell": {
        "& .MuiDataGrid-cell--editing": {
          backgroundColor: theme.palette.HeaderGrayPrg.main,
        },
      },
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "#3535353b",
    },
  },
  menuLogo: {
    maxWidth: 100,
  },
}));
export default function MyInvoices() {
  const classes = useStyles();
  const history = useHistory();
  const { getNestedPropertySafe } = ObjectTools;
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  const mobileLogin = useSelector((state) => getNestedPropertySafe(state, ["mobile"]));

  const mobileData = useSelector((state) =>
    getNestedPropertySafe(state, ["mobile", "myClaims", mobileLogin["userId"]])
  );

  const rows = mobileData ? Object.keys(mobileData).map((key) => mobileData[key]) : [];
  const email = useSelector((state) => _getProperty(state, ["mobile", "userId"]));
  const entityLogo = useSelector((state) => _getProperty(state, ["mobile", "entityLogo"]));

  useEffect(() => {
    dispatch(mobileActions.getPreClaims(email));
  }, []);

  const handleEdit = (mobileClaimID, entityID) => {
    history.push(`/mobile-claim/my-list/${entityID}/${mobileClaimID}`);
    dispatch(mobileActions.getFull(mobileClaimID));
  };

  const columns = [
    {
      field: "claimID",
      headerName: "Claim ID",
      width: 100,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.claimID ? (
          <Typography sx={{ fontWeight: "bold", fontSize: ".9rem" }}>{params.row.claimID}</Typography>
        ) : (
          <Button
            size="small"
            variant="contained"
            sx={{ color: "white" }}
            onClick={(e) => {
              handleEdit(params.row.mobileClaimID, params.row.entityID);
            }}
            disabled={params.row.status === "A0__PreClaim" ? true : false}
          >
            Continue
          </Button>
        );
      },
      editable: false,
      filterable: false,
    },
    {
      field: "files",
      headerName: "Files",
      width: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            sx={{ color: "white" }}
            onClick={(e) => {
              openFileModal(params.row.mobileClaimID, params.row.claimID);
            }}
            disabled={params.row.status === "A0__PreClaim" ? true : false}
          >
            View/Upload Files
          </Button>
        );
      },
      editable: false,
      filterable: false,
    },
    {
      field: "created",
      headerName: "Entered Date",
      width: 120,
      editable: false,
      renderCell: (params) => {
        return formatDateNoTime(params.row.created);
      },
    },
    {
      field: "OutDate",
      headerName: "Damaged Date",
      width: 120,
      editable: false,
      renderCell: (params) => {
        return formatDateNoTime(params.row.OutDate);
      },
    },
    {
      field: "OutTime",
      headerName: "Damaged Time",
      width: 120,
      editable: false,
      renderCell: (params) => {
        return formatTime(params.row.OutTime);
      },
    },
    {
      field: "LocAddress",
      headerName: "Address",
      width: 200,
      editable: false,
    },
    {
      field: "LocCity",
      headerName: "City",
      width: 100,
      editable: false,
    },
    {
      field: "LocState",
      headerName: "State",
      width: 75,
      editable: false,
    },
    {
      field: "LocZip",
      headerName: "Zip",
      width: 100,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: ["A0__Quick Start", "A0__PreClaim"],
      renderCell: (params) => {
        if (params.value == null) {
          return "A0__Quick Start";
        }
      },
    },
  ];

  const handleStartClaim = () => {
    history.push(`/mobile-claim/start-claim/`);
  };
  const handleLogOut = () => {
    history.push(`/mobile-claim/`);
    dispatch(mobileActions.logout());
  };

  const [open, setOpen] = useState(false);
  const [selectedMobileClaimID, setSelectedMobileClaimID] = useState();
  const [selectedClaimId, setSelectedClaimID] = useState();
  const openFileModal = (mobileClaimID, claimID) => {
    setSelectedMobileClaimID(mobileClaimID);
    setSelectedClaimID(claimID);
    setOpen(true);
  };
  const closeFileModal = () => {
    setSelectedMobileClaimID();
    setOpen(false);
  };

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3} sx={{ marginBottom: "30px" }}>
        <Grid item xs={12} md={6}>
          {entityLogo && entityLogo && <img alt="logo" className={classes.menuLogo} src={entityLogo} />}
          <Typography variant="body1" component="div">
            My Claims - <span style={{ color: "#c7c2c2" }}>{mobileLogin["userId"]}</span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-end" } }}>
            <Button variant="contained" color="primary" sx={{ color: "white" }} onClick={handleStartClaim}>
              Start Claim
            </Button>
            <Button variant="outlined" sx={{ marginLeft: "10px" }} onClick={handleLogOut}>
              Log Out
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ height: { xs: "100%", md: "calc(100vh - 180px)" }, width: "100%" }}>
        <Typography variant="subtitle2">*Claim ID is generated when claim has been completed</Typography>
        <DataGridPro
          className={classes.grid}
          rows={rows}
          columns={columns ? columns : []}
          pagination
          pageSize={100}
          rowCount={rows.length}
          rowsPerPageOptions={[100]}
          getRowId={(row) => `${row.mobileClaimID}`}
          autoHeight={isMobile}
        />
      </Box>

      <Dialog onClose={closeFileModal} open={open} fullWidth={true} maxWidth="lg" fullScreen={isMobile}>
        <IconButton
          aria-label="close"
          onClick={closeFileModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Documents mobileClaimID={selectedMobileClaimID} claimID={selectedClaimId} />
        </DialogContent>
      </Dialog>
    </Paper>
  );
}

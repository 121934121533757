import { adminPowerBiRoleConstants } from "_constants";

const getPowerBiRoles = () => {
  return {
    type: adminPowerBiRoleConstants.GET_ALL_POWERBI_ROLES_REQUEST,
  };
};

const createPowerBiRole = (payload) => {
  return {
    type: adminPowerBiRoleConstants.CREATE_POWERBI_ROLE_REQUEST,
    payload,
  };
};

const updatePowerBiRole = (payload) => {
  return {
    type: adminPowerBiRoleConstants.UPDATE_POWERBI_ROLE_REQUEST,
    payload
  };
};

export const adminPowerBiRoleActions = {
  getPowerBiRoles,
  createPowerBiRole,
  updatePowerBiRole
};

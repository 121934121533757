import { settingsConstants } from "../_constants";

 function changeTheme() {
    return { type: settingsConstants.SETTINGS_CHANGE_THEME_SUCCESS };
  }

  function clearSettings() {
    return { type: settingsConstants.SETTINGS_CLEAR }
  }
  
  export const settingsActions = {
    changeTheme,
    clearSettings
  };
  
import { adminEntityConstants_ } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";
import { integerToLOB } from "_helpers";
import { normalize } from "normalizr";
import { adminEntityManagementSchema } from "_schema";
import _getProperty from "lodash/get";
let { statusSchema, sowContractSchema } = adminEntityManagementSchema;

let initState = {
  entities: [],
  lob: [],
  contacts: {
    loading: false,
    telecom: {},
    frelo: {},
    "power-gas": {},
  },
  status: {
    loading: false,
    telecom: {},
    frelo: {},
    "power-gas": {},
  },
  sowContracts: {
    loading: false,
    telecom: {},
    frelo: {},
    "power-gas": {},
  },
  rdaControl: {
    loading: false,
    telecom: {},
    frelo: {},
    "power-gas": {},
  },
  billingTypes: {
    loading: false,
    telecom: {},
    frelo: {},
    "power-gas": {},
  },
  subAccounts: {
    loading: false,
    telecom: {},
    frelo: {},
    "power-gas": {},
  },
  docManagement: {
    loading: false,
    telecom: {},
    frelo: {},
    "power-gas": {},
  },
  schema: {
    billingTypes: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
    rda: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
    rdaOptions: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
    rdaOptionsRegions: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
    rdaOptionsDivisions: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
    rdaOptionsAreas: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
    subAccounts: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
    subAccountsRda: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
    docFieldMentions: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
    docClaimReasons: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
    docClaimLastSentences: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
    docClaimContacts: {
      telecom: {},
      frelo: {},
      "power-gas": {},
    },
  },
  invoicing: {
    loading: false,
    telecom: {},
    frelo: {},
    "power-gas": {},
  },
};

export function entity(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminEntityConstants_.GET_ALL_ADMIN_ENTITY_SUCCESS:
      return produce(state, (draft) => {
        draft["entities"] = payload;
      });
    case adminEntityConstants_.GET_ALL_ADMIN_SCHEMA_LOB_SUCCESS:
      return produce(state, (draft) => {
        draft["lob"] = payload;
      });
    case adminEntityConstants_.SET_ADMIN_ENTITY_STATUS_LOADING_TRUE: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
        },
      };
    }
    case adminEntityConstants_.GET_ALL_ADMIN_ENTITY_STATUS_SUCCESS:
      return produce(state, (draft) => {
        let { entityId, status, lob } = payload;

        if (_getProperty(state, ["status", lob])) {
          const normalizedData = normalize(status, statusSchema);
          draft["status"]["loading"] = false;
          draft["status"][lob] = { [entityId]: normalizedData }; // just stash one entityId at a time, to save storage
        }
      });
    case adminEntityConstants_.SET_ADMIN_ENTITY_SOW_CONTRACTS_LOADING_TRUE: {
      return {
        ...state,
        sowContracts: {
          ...state.sowContracts,
          loading: true,
        },
      };
    }
    case adminEntityConstants_.GET_ALL_ADMIN_ENTITY_SOW_CONTRACTS_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, sowContracts } = payload;

        if (_getProperty(state, ["sowContracts", lob])) {
          const normalizedData = normalize(sowContracts, sowContractSchema);
          draft["sowContracts"]["loading"] = false;
          draft["sowContracts"][lob] = { [entityId]: normalizedData }; // just stash one entityId at a time, to save storage
        }
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_BILLING_TYPES_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, billingTypes } = payload;
        if (_getProperty(state, ["schema", "billingTypes", lob])) {
          draft["schema"]["billingTypes"][lob] = { [entityId]: billingTypes };
        }
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_RDA_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, rdaId } = payload;
        if (_getProperty(state, ["schema", "rda", lob])) {
          draft["schema"]["rda"][lob] = { [entityId]: rdaId };
        }
      });
    case adminEntityConstants_.ADMIN_ENTITY_SCHEMA_SUB_ACCOUNTS_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, subaccountOptions } = payload;

        if (_getProperty(state, ["schema", "subAccounts", lob])) {
          draft["schema"]["subAccounts"][lob] = {
            [entityId]: subaccountOptions,
          };
        }
      });
    case adminEntityConstants_.GET_ALL_ADMIN_ENTITY_RDA_CONTROL_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, rdaControl } = payload;

        if (_getProperty(state, ["rdaControl", lob])) {
          draft["rdaControl"]["loading"] = false;
          draft["rdaControl"][lob] = {
            [entityId]: rdaControl,
          };
        }
      });
    case adminEntityConstants_.SET_ADMIN_ENTITY_RDA_CONTROL_LOADING_TRUE: {
      return {
        ...state,
        rdaControl: {
          ...state.rdaControl,
          loading: true,
        },
      };
    }
    case adminEntityConstants_.GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_SUCCESS: {
      return produce(state, (draft) => {
        let { lob, entityId, rdaOptions } = payload;

        if (_getProperty(state, ["schema", "rdaOptions", lob])) {
          draft["schema"]["rdaOptions"][lob] = { [entityId]: rdaOptions };
        }
      });
    }
    case adminEntityConstants_.SET_ADMIN_ENTITY_BILLING_TYPES_LOADING_TRUE: {
      return {
        ...state,
        billingTypes: {
          ...state.billingTypes,
          loading: true,
        },
      };
    }
    case adminEntityConstants_.GET_ADMIN_ENTITY_BILLING_TYPES_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, billingTypes } = payload;

        if (_getProperty(state, ["billingTypes", lob])) {
          draft["billingTypes"]["loading"] = false;
          draft["billingTypes"][lob] = {
            [entityId]: billingTypes,
          };
        }
      });
    case adminEntityConstants_.SET_ADMIN_ENTITY_SUB_ACCOUNTS_LOADING_TRUE: {
      return {
        ...state,
        subAccounts: {
          ...state.subAccounts,
          loading: true,
        },
      };
    }
    case adminEntityConstants_.GET_ADMIN_ENTITY_SUB_ACCOUNTS_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, subAccounts } = payload;

        if (_getProperty(state, ["subAccounts", lob])) {
          draft["subAccounts"]["loading"] = false;
          draft["subAccounts"][lob] = {
            [entityId]: subAccounts,
          };
        }
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_SUBACCOUNTS_RDA_SCHEMA_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, subaccountRda } = payload;

        if (_getProperty(state, ["schema", "subAccountsRda", lob])) {
          draft["schema"]["subAccountsRda"][lob] = {
            [entityId]: subaccountRda,
          };
        }
      });
    case adminEntityConstants_.SET_ADMIN_ENTITY_CONTACTS_LOADING_TRUE: {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: true,
        },
      };
    }
    case adminEntityConstants_.GET_ADMIN_ENTITY_CONTACTS_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, contacts } = payload;

        if (_getProperty(state, ["contacts", lob])) {
          draft["contacts"]["loading"] = false;
          draft["contacts"][lob] = {
            [entityId]: contacts,
          };
        }
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_FIELD_MENTIONS_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, data } = payload;

        draft["schema"]["docFieldMentions"][lob] = {
          [entityId]: data,
        };
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_REASONS_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, data } = payload;

        draft["schema"]["docClaimReasons"][lob] = {
          [entityId]: data,
        };
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_LAST_SENTENCE_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, data } = payload;

        draft["schema"]["docClaimLastSentences"][lob] = {
          [entityId]: data,
        };
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_SUCCESS:
      return produce(state, (draft) => {
        let { isMultiple, lobName, data } = payload;
        if (isMultiple) {
          draft["schema"]["docClaimContacts"]["all"] = data.all;
          draft["schema"]["docClaimContacts"]["telecom"] = data["telecom"] ? data["telecom"] : {};
          draft["schema"]["docClaimContacts"]["power-gas"] = data["power-gas"] ? data["power-gas"] : {};
          draft["schema"]["docClaimContacts"]["frelo"] = data["frelo"] ? data["frelo"] : {};
        } else {
          draft["schema"]["docClaimContacts"][lobName] = data;
        }
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, data } = payload;

        draft["docManagement"]["loading"] = false;
        draft["docManagement"][lob][entityId] = { invoice: data };
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_DOC_CLAIM_TEMPLATE_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, data } = payload;

        draft["docManagement"]["loading"] = false;
        draft["docManagement"][lob][entityId] = { claim: data };
      });
    case adminEntityConstants_.SET_ADMIN_ENTITY_DOC_MANAGEMENT_LOADING_TRUE:
      return produce(state, (draft) => {
        draft["docManagement"]["loading"] = true;
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_REGIONS_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, data } = payload;

        draft["schema"]["rdaOptionsRegions"][lob] = {
          [entityId]: data,
        };
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_DIVISIONS_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, data } = payload;

        draft["schema"]["rdaOptionsDivisions"][lob] = {
          [entityId]: data,
        };
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_AREAS_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, data } = payload;

        draft["schema"]["rdaOptionsAreas"][lob] = {
          [entityId]: data,
        };
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_REQUEST:
    case adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_LINE_ITEMS_REQUEST:
    case adminEntityConstants_.UPDATE_ADMIN_ENTITY_INVOICING_REQUEST:
      return produce(state, (draft) => {
        draft["invoicing"]["loading"] = true;
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_SUCCESS:
      return produce(state, (draft) => {
        let { lobId, entityId, data, continueLoading } = payload;
        draft["invoicing"][integerToLOB[lobId]][entityId] = {
          ...state["invoicing"][integerToLOB[lobId]][entityId],
          addLineItemButtons: data.addLineItemButtons,
        };
        if (!continueLoading) draft["invoicing"]["loading"] = false;
      });
    case adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_LINE_ITEMS_SUCCESS:
      return produce(state, (draft) => {
        let { lobId, entityId, data } = payload;
        console.log({ data });
        draft["invoicing"][integerToLOB[lobId]][entityId] = {
          ...state["invoicing"][integerToLOB[lobId]][entityId],
          lineItems: data.lineItems,
        };
        draft["invoicing"]["loading"] = false;
      });

    case REHYDRATE: {
      if (payload && payload.admin && payload.admin.entity) {
        return { ...payload.admin.entity };
      } else {
        return { ...initState };
      }
    }
    case adminEntityConstants_.ADMIN_ENTITY_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const newContact = async (payload) => {
    let body = { ...payload };

    if (process.env.NODE_ENV !== "production") {
        body = {
            ...body,
            jwtCookie: cookieService.get("token"),
        };
    }

    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body),
    };

    // let apiBase = "http://localhost:3000/api"; // local host testing
    let apiBase = getApiBase();

    try {
        const response = await fetch(`${apiBase}/joint/contacts/newContact`, parameters);
        if (response.status === 403) {
            throw new Error("Invalid Auth Token");
        }
        return await response.json();
    } catch (err) {
        throw err;
    }
};




const updateContact = async (payload) => {
    let body = { ...payload };
    if (process.env.NODE_ENV !== "production") {
        body = {
            ...body,
            jwtCookie: cookieService.get("token"),
        };
    }

    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body),
    };

    // let apiBase = "http://localhost:4000/api"; // local host testing
    let apiBase = getApiBase();
    
    try {
        const response = await fetch(`${apiBase}/joint/contacts/updateContact`, parameters);
        if (response.status === 403) {
            throw new Error("Invalid Auth Token");
        }
        return {...await response.json(), status: response.status};
    } catch (err) {
        throw err;
    }
};


export const jointContactsService = {
    newContact,
    updateContact
};

import { jointReportConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";
let initState = {
  current: {},
  my: [],
  shared: [],
  procs: [],
  data: {
    all: {},
    count: 0,
    loading: false
  },
  scoreboard: {},
  aList: {
    loading: false,
  },
  remittance: {
    loading: false,
    all: {}
  }
};
export function reports(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case jointReportConstants.GET_USER_REPORTS_SUCCESS:
      return produce(state, (draft) => {
        draft["my"] = [...payload.userReports];
        draft["shared"] = [...payload.sharedReports];
      })
    case jointReportConstants.GET_REPORT_SUCCESS:
      return produce(state, (draft) => {
        const { data } = payload;
        draft["current"] = {...data};
      })
    case jointReportConstants.GET_REPORT_PROCS_SUCCESS:
      return produce(state, (draft) => {
        const { procs } = payload;
        draft["procs"] = [...procs];
      })
    case jointReportConstants.QUERY_REPORT_PROC_SUCCESS:
      return produce(state, (draft) => {
        let { data } = payload;
          draft["data"]["all"] = [...data.data];
          draft["data"]["count"] = data.count;
          draft["data"]["loading"] = false;
      });
    case jointReportConstants.QUERY_REPORT_PROC_LOADING:
      return produce(state, (draft) => {
        draft["data"]["loading"] = true;
      });
    case jointReportConstants.GET_SCORE_BOARD_SUCCESS:
      return produce(state, (draft) => {
        draft["scoreboard"] = payload;
      });
    case jointReportConstants.GET_A_LIST_REPORT_SUCCESS:
      return produce(state, (draft) => {
        let { data, marketId, regionId } = payload;

        let key = `${regionId}-${marketId}`;

        draft["aList"] = { [key]: data, loading: false };
      });
    case jointReportConstants.GET_A_LIST_REPORT_LOADING_TRUE:
      return produce(state, (draft) => {
        draft["aList"] = { loading: true };
      });
    
      case jointReportConstants.GET_REMITTANCE_REQUEST:
        return produce(state, (draft) => {
          draft.remittance.loading = true;
        })
      case jointReportConstants.GET_REMITTANCE_SUCCESS:
        return produce(state, (draft) => {
          const { entityIDs, data } = payload;
          entityIDs.forEach(entityID => {
            draft.remittance.all[entityID] = data[entityID]
          })
          draft.remittance.loading = false;
        })
      case jointReportConstants.GET_REMITTANCE_CLEAR:
        return produce(state, (draft) => {
          draft.remittance.loading = false;
          draft.remittance.all = {};
        })

    case REHYDRATE: {
      if (payload && payload.joint && payload.joint.reports) {
        return { ...payload.joint.reports };
      } else {
        return { ...initState };
      }
    }
    case jointReportConstants.JOINT_REPORTS_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

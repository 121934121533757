import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { FormField } from "_components/core/FormField";

export default function Index({ control, register, errors, hydration }) {
  const fields = [
    {
      id: 319,
      type: "text",
      md: 12,
    },
    {
      id: 320,
      type: "text",
      md: 12,
    },
    {
      id: 321,
      type: "text",
      md: 12,
    },
    {
      id: 322,
      type: "text",
      md: 12,
    },
    {
      id: 323,
      type: "text",
      md: 12,
    },
    {
      id: 324,
      type: "autocomplete",
      options: hydration[324].type.enumOptions.map(option => option.value),
      md: 12,
    },
  ];

  fields.map((field) => {
    if (hydration && hydration[field.id]) {
      // name
      field.name = `${hydration[field.id]?.["id"]}`;
      // label
      field.label = hydration[field.id]?.["label"];
      // required
      field.required = hydration[field.id].required;
      // maxLength
      field.maxLength = hydration[field.id].type.maxLength
    }
    return field;
  });

  return (
    <>
      <Grid container item spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" gutterBottom style={{ textAlign: "center" }}>
            Damaging Party
          </Typography>
        </Grid>
        {fields.map((field) => (
          <FormField field={field} register={register} control={control} errors={errors} />
        ))}
      </Grid>
    </>
  );
}

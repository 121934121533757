import { adminUserConstants } from "_constants";

const getAll = () => {
  return {
    type: adminUserConstants.ADMIN_USERS_GET_ALL_REQUEST,
  };
};

const getRoles = () => {
  return {
    type: adminUserConstants.ADMIN_USERS_GET_ROLES_REQUEST,
  };
};

const getPrgUsers = () => {
  return {
    type: adminUserConstants.ADMIN_PRG_USERS_GET_REQUEST,
  };
};


const createUser = (payload) => {
  return {
    type: adminUserConstants.ADMIN_USERS_CREATE_REQUEST,
    payload,
  };
};


const getGeneral = (userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_GET_GENERAL_REQUEST,
    userId,
  };
};

const updateUserGeneral = (userId, payload) => {
  return {
    type: adminUserConstants.ADMIN_USERS_UPDATE_GENERAL_REQUEST,
    userId,
    payload,
  };
};

const getDbAccess = (userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_GET_DB_ACCESS_REQUEST,
    userId,
  };
};

const updateUserDbAccess = (payload, userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_UPDATE_DB_ACCESS_REQUEST,
    payload,
    userId,
  };
};

const getUserEntities = (userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_GET_ENTITIES_ACCESS_REQUEST,
    userId,
  };
};

let updateUserEntities = (payload, userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_UPDATE_ENTITIES_ACCESS_REQUEST,
    payload,
    userId,
  };
};

let entityAccessToggle = (checked, userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_TOGGLE_ENTITY_ACCESS_REQUEST,
    checked,
    userId,
  };
};

const getUserTypes = () => {
  return {
    type: adminUserConstants.ADMIN_USERS_GET_USER_TYPES_REQUEST,
  };
};

const getUserActionAccess = (userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_GET_ACTION_ACCESS_REQUEST,
    userId,
  };
};

const updateUserActionAccess = (payload, userId, accessTypeLOB) => {
  return {
    type: adminUserConstants.ADMIN_USERS_UPDATE_ACTION_ACCESS_REQUEST,
    payload,
    userId,
    accessTypeLOB
  };
};

const deleteUserActionAccess = (userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_DELETE_ACTION_ACCESS_REQUEST,
    userId
  };
};

const getUserStatusAccess = (userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_GET_STATUS_ACCESS_REQUEST,
    userId,
  };
};

const updateUserStatusAccess = (payload, userId, LOBID, statusType) => {
  return {
    type: adminUserConstants.ADMIN_USERS_UPDATE_STATUS_ACCESS_REQUEST,
    payload, userId, LOBID, statusType
  };
};

const deleteUserStatusAccess = (userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_DELETE_STATUS_ACCESS_REQUEST,
    userId
  };
};

const getUserDamageInvestigator = (userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_DAMAGE_INVESTIGATOR_REQUEST,
    userId
  };
};

const updateUserDamageInvestigator = ({isDamageInvestigator, damageInvestigatorStates, damageInvestigatorLOB, superRegion, MidManager, TeamLead, MidManagerID, TeamLeadID, SecondTeamLeadID}, userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_REQUEST,
    userId, isDamageInvestigator, damageInvestigatorStates, damageInvestigatorLOB, superRegion, MidManager, TeamLead, MidManagerID, TeamLeadID, SecondTeamLeadID
  }
}

const deleteUserDamageInvestigator = (userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_REQUEST,
    userId
  }
}

const getUserNotifications = (userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_NOTIFICATIONS_REQUEST,
    userId
  };
};

const updateUserNotifications = ({notificationIds}, userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_UPDATE_NOTIFICATIONS_REQUEST,
    userId, notificationIds
  }
};

const deleteUserNotifications = (userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_DELETE_NOTIFICATIONS_REQUEST,
    userId
  }
};

const toggleClaimAssistant = (isCA, userId) => {
  return {
    type: adminUserConstants.ADMIN_USERS_TOGGLE_CLAIM_ASSISTANT,
    isCA,
    userId
  }
}


export const adminUsersActions = {
  getAll,
  createUser,
  getRoles,
  getGeneral,
  getPrgUsers,
  updateUserGeneral,
  getDbAccess,
  updateUserDbAccess,
  getUserEntities,
  updateUserEntities,
  entityAccessToggle,
  getUserTypes,
  getUserActionAccess,
  updateUserActionAccess,
  deleteUserActionAccess,
  getUserStatusAccess,
  updateUserStatusAccess,
  deleteUserStatusAccess,
  getUserDamageInvestigator,
  updateUserDamageInvestigator,
  deleteUserDamageInvestigator,
  getUserNotifications,
  updateUserNotifications,
  deleteUserNotifications,
  toggleClaimAssistant
};

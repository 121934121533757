import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import Popper from "@material-ui/core/Popper";
import Thumbnail from "./thumbnail";

import ViewFile from "./viewFile";
import { formatDate } from "_helpers";

const StyledTableCell = withStyles((theme) => ({
  root: {
    width: 500,
  },
  head: {
    backgroundColor: theme.palette.GrayPrg.main,
    color: theme.palette.DarkGrayPrg.main,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: { padding: "10px", height: "100%" },
  hover: { "&:hover": { cursor: "pointer" } },
}));

const columns = [
  { id: "fileName", label: "File Name", minWidth: 100, align: "center" },
  { id: "fileTimeStamp", label: "Time Stamp", minWidth: 100, align: "center" },
  { id: "fileMimeType", label: "File Type", minWidth: 100, align: "center" },
  { id: "preview", label: "Preview", minWidth: 100, align: "center" },
];

export default function AttachmentsTable({ files }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [hoverFileContentType, setHoverFileContentType] = useState(null);
  const [hoverfilePath, setHoverFilePath] = useState(null);
  const [openHover, setOpenHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [viewFile, setViewFile] = useState(false);
  const [targetFile, setTargetFile] = useState();

  let cleanupTooltip = () => {
    setAnchorEl(null);
    setOpenHover(false);
    setHoverFilePath(null);
    setHoverFileContentType(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewFile = ({ signedURL }) => {
    cleanupTooltip();
    setTargetFile(signedURL);
    setViewFile(true);
  };

  const handleViewFileCleanup = () => {
    setTargetFile();
    setViewFile(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  let handleMouseOver = (event, { signedURL, contentType }) => {
    if (event.currentTarget === null) {
      cleanupTooltip();
      return;
    }

    setAnchorEl(event.currentTarget);
    setOpenHover(true);
    setHoverFilePath(signedURL);
    setHoverFileContentType(contentType);
  };

  return (
    <FormWrapper>
      <Paper className={classes.root}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {files &&
                files
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.fileID}
                        onClick={() => handleViewFile(row)}
                        className={classes.hover}
                        onMouseOver={(e) => handleMouseOver(e, row)}
                        onMouseLeave={cleanupTooltip}
                      >
                        {columns.map((column) => {
                          if (column.id === "fileTimeStamp") {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <div>{formatDate(row[column.id])}</div>
                              </TableCell>
                            );
                          }
                          if (column.id === "preview") {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Thumbnail
                                  path={row["signedURL"]}
                                  contentType={row["contentType"]}
                                />
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <div>{row[column.id]}</div>
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={files ? files.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Popper
          id={"preview"}
          open={openHover}
          anchorEl={anchorEl}
          style={{
            zIndex: "1400",
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
          }}
          placement="bottom"
        >
          <Thumbnail
            path={hoverfilePath}
            contentType={hoverFileContentType}
            dimensions={350}
          />
        </Popper>
        <ViewFile
          openModal={viewFile}
          handleCleanup={handleViewFileCleanup}
          path={targetFile}
        />
      </Paper>
    </FormWrapper>
  );
}

export const FormWrapper = styled.div`
  .MuiGrid-item {
    text-alight: center;
  }
`;
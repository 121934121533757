import { put, call } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  telecomDispatchConstants,
} from "_constants";

import { telecomDispatchService } from "_services/lob/telecom/dispatch.service";


export function* getOneDispatchTelecomSaga({ claimId }) {
  try {
    const response = yield call(telecomDispatchService.getOneDispatch, claimId);

    yield put({
      type: telecomDispatchConstants.GET_ONE_DISPATCH_TELECOM_SUCCESS,
      payload: { claimId, response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateOneDispatchTelecomSaga({ claimId, data, callback }) {
  try {
    yield call(telecomDispatchService.updateOneDispatch, data, claimId, callback);

    const response = yield call(telecomDispatchService.getOneDispatch, claimId);

    yield put({
      type: telecomDispatchConstants.GET_ONE_DISPATCH_TELECOM_SUCCESS,
      payload: { claimId, response },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Dispatch updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateTenEightDispatchTelecomSaga({ claimId }) {
  try {
    yield call(telecomDispatchService.updateTenEightDispatch, claimId);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Dispatch updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

//Dispatch Stopwatch
export function* getDispatchTimer({ claims}) {
  try {
   yield put({
      type: telecomDispatchConstants.UPDATE_DISPATCH_TELECOM_TIMER_SUCCESS,
      payload: claims,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateDispatchTimerPause({ payload}) {
    try {
       yield call(telecomDispatchService.updateDispatchTimerPause, payload);

      yield put({
        type: alertConstants.SUCCESS,
        payload: { message: "Timer Stopped" },
      });
    } 
    catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
}

export function* getDispatchTimerColor( {payload}) {
  try {
    yield call(telecomDispatchService.updateDispatchTimerColor, payload);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Timer Updated" },
    });
    
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

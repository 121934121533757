import { legacy_createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootSaga from "_sagas";
import rootReducer from "_reducers";
import migrations from "_migrations/redux";
import localforage from "localforage";

const persistConfig = {
  key: "root",
  storage: localforage,
  version: 195,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: false }),
};

const sagaMiddleware = createSagaMiddleware();

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(sagaMiddleware)
    : composeWithDevTools(applyMiddleware(sagaMiddleware));

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = legacy_createStore(persistedReducer, devTools);

export let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

import { errorConstants } from "_constants";

const postError = (error, errorInfo, location, userId, timestamp) => {
  return {
    type: errorConstants.POST_ERROR_REQUEST,
    error,
    errorInfo,
    location,
    userId,
    timestamp,
  };
};

export const errorActions = {
  postError,
};

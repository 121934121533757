import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import Breadcrumbs from "_components/core/Breadcrumbs";
import Paper from "@material-ui/core/Paper";
import SaveButton from "./saveButton";
import { useDispatch, useSelector } from "react-redux";
import _getProperty from "lodash/get";
import { mobileActions } from "_actions";
import { ObjectTools } from "_utility";
import { FormField } from "_components/core/FormField";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import moment from "moment";

const { getNestedPropertySafe } = ObjectTools;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.HeaderGrayPrgmain,
    padding: 40,
  },
}));

export default function PowGasStartClaim() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState,
    formState: { errors },
  } = useForm();
  const { isDirty } = formState;

  const today = moment().utc().subtract(4, "hours");
  const watchDateNotified = watch("299");
  const dateNotifiedIsToday = watchDateNotified ? watchDateNotified.isSame(new Date(), "day") : false;
  const currentDate = today.format("l");
  const currentTime = today.format("HH:mm");

  useEffect(() => {
    dispatch(mobileActions.getInitial());
  }, [dispatch]);

  const entityID = useSelector((state) => getNestedPropertySafe(state, ["mobile", "entityID"]));
  const hydration = useSelector((state) => _getProperty(state, ["mobile", "form", "Basic"]));
  const email = useSelector((state) => _getProperty(state, ["mobile", "userId"]));

  const onSubmit = async (data) => {
    data.email = email;
    dispatch(mobileActions.createInitial(data, entityID));
    history.push(`/mobile-claim/dashboard`);
  };

  const [breadCrumbs, setBreadCrumbs] = useState();
  useEffect(() => {
    let breadCrumbs_x = [
      { label: "Invoices", url: `/mobile-claim/my-list` },
      {
        label: <span style={{ color: "#c7c2c2" }}>Start A Claim</span>,
      },
    ];
    setBreadCrumbs(breadCrumbs_x);
  }, [classes, entityID]);

  const fields = [
    {
      id: 296,
      type: "text",
    },
    {
      id: 297,
      type: "text",
    },
    {
      type: "divider",
      size: 12,
    },
    {
      id: 298,
      type: "autocomplete",
      options: hydration ? hydration[298]?.["type"]["enumOptions"]?.map((e) => e.value) : [],
    },
    {
      id: 299,
      type: "date",
      maxDate: new Date(currentDate),
    },
    {
      id: 300,
      type: "time",
      maxTime: dateNotifiedIsToday ? currentTime : null,
      customValidationError: dateNotifiedIsToday ? 'Time of Damage cannot exceed the current time' : null
    },
    {
      type: "divider",
      size: 12,
    },
    {
      id: 301,
      type: "text",
    },
    {
      id: 302,
      type: "text",
    },
    {
      id: 303,
      type: "autocomplete",
      options: hydration ? hydration[303]?.["type"]["enumOptions"]?.map((e) => e.value) : [],
    },
    {
      id: 304,
      type: "text",
    },
    {
      type: "divider",
      size: 12,
    },
    {
      id: 305,
      type: "autocomplete",
      options: hydration ? hydration[305]?.["type"]["enumOptions"] : [],
    },
    {
      id: 306,
      type: "text",
    },
    {
      id: 332,
      type: "textarea",
      md: 6,
    },
  ];

  fields.map((field) => {
    if (hydration && hydration[field.id]) {
      // name
      field.name = `${hydration[field.id]?.id}`;
      // label
      field.label = hydration[field.id]?.label;
      // required
      field.required = hydration[field.id].required;
      // maxLength
      field.maxLength = hydration[field.id].type.maxLength
    }
    return field;
  });

  return (
    <Container maxWidth="xl">
      <Paper className={classes.root}>
        {hydration && hydration && (
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <Breadcrumbs mapCrumbs={breadCrumbs || []} />
            </Grid>
            <Grid item xs={6}>
              <SaveButton isDirty={isDirty} handleSubmit={handleSubmit(onSubmit)} />
            </Grid>
            {fields.map((field) => (
              <FormField field={field} register={register} control={control} errors={errors} />
            ))}
          </Grid>
        )}
      </Paper>
    </Container>
  );
}

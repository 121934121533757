import { combineReducers } from "redux";
import { reports } from "./report.reducer";
import { claimsQueue } from "./claimsQueue.reducer";
import { ledgers } from "./ledgers.reducer";

const telRootReducer = combineReducers({
  reports,
  claimsQueue,
  ledgers
});

export default telRootReducer;

import { comparisonCheckSQL } from "_helpers";
import { ObjectTools } from "_utility";

// @Param claim
// type: object
// required: true

// @Param ruleType
// type: string
// enum: editable || required
// required: true

// @Param fieldValues
// type: object
// required: false
// note: This function will compare values form fieldValues for rule conditions, instead of values within claim

export let parseRuleTreeImmutable = (claim, ruleType, fieldValues) => {
  const { getNestedPropertySafe } = ObjectTools;

  // console.dir(claim, { depth: null });
  // console.dir(ruleType, { depth: null });
  // console.dir(fieldValues, { depth: null });

  // ------
  // pull field out of sections
  let field_obj_immutable = Object.keys(claim).reduce(
    (o, key) => ({ ...o, ...claim[key] }),
    {}
  );

  // checks the default ruleType editable || required value -> boolean
  let field_status_immutable = Object.keys(field_obj_immutable).reduce(
    (o, key) => ({
      ...o,
      [key]: field_obj_immutable[key]["meta"][ruleType],
    }),
    {}
  );

  // ------
  // Get fields with rules that match the ruleType
  let fieldHasRuleSet_immutable = Object.keys(field_obj_immutable).reduce(
    (o, key) => {
      let current_field = field_obj_immutable[key];
      let rule_set_type =
        ruleType === "editable" ? "editableRule" : "requiredRule";
      if (current_field["meta"][rule_set_type] === true) {
        let current_field_rules = current_field["meta"]["rules"];
        let current_field_rules_filtered = current_field_rules.filter(
          (f) => f.ruleType === ruleType
        );
        let current_field_rule_set = current_field_rules_filtered[0];
        if (current_field_rule_set) {
          return { ...o, [key]: { ...current_field_rule_set } };
        }
        return { ...o };
      }
      return { ...o };
    },
    {}
  );

  // ------
  // associate branches with fieldId
  let fieldBranches_immutable = Object.keys(fieldHasRuleSet_immutable).reduce(
    (o, key) => {
      let branches = fieldHasRuleSet_immutable[key]["branches"].reduce(
        (o_inner, branch_inner) => ({
          ...o_inner,
          [branch_inner["branchId"]]: { ...branch_inner, branches: {} },
        }),
        {}
      );

      return {
        ...o,
        [key]: {
          ...branches,
          root: {
            condSelect: fieldHasRuleSet_immutable[key]["condSelect"],
            rules: {},
          },
          targetFieldLookup: [],
        },
      };
    },
    {}
  );

  // console.log(fieldBranches_immutable);

  // =====
  // associate rules with branches by fieldId
  let field_rule_association_immutable = Object.keys(
    fieldHasRuleSet_immutable
  ).reduce((o, key) => {
    let root = fieldHasRuleSet_immutable[key];
    let targetFieldLookup = root["rules"].map((r) => r["targetFieldId"]);

    let obj = root["rules"].reduce((prev, current) => {
      if (current["parentBranchId"] === null) {
        if (prev["root"]) {
          return { ...prev, root: [...prev["root"], { ...current }] };
        }
        return { ...prev, root: [{ ...current }] };
      }

      if (prev[current["parentBranchId"]]) {
        return {
          ...prev,
          [current["parentBranchId"]]: [
            ...prev[current["parentBranchId"]],
            { ...current },
          ],
        };
      }

      return {
        ...prev,
        [current["parentBranchId"]]: [{ ...current }],
      };
    }, {});

    return {
      ...o,
      [key]: {
        targetFieldLookup: [...new Set(targetFieldLookup)],
        ...obj,
      },
    };
  }, {});

  // root, and branches have rules associated within them
  let field_branches_with_rules = Object.keys(fieldBranches_immutable).reduce(
    (prev, key) => {
      let assign_rule_branch = Object.keys(fieldBranches_immutable[key])
        .filter((itemKey) => !["root", "targetFieldLookup"].includes(itemKey))
        .reduce((prev_inner, key_inner) => {
          return {
            ...prev_inner,
            [key_inner]: {
              ...fieldBranches_immutable[key][key_inner],
              rules: field_rule_association_immutable[key][key_inner]
                ? [...field_rule_association_immutable[key][key_inner]]
                : [],
            },
          };
        }, {});

      return {
        ...prev,
        [key]: {
          targetFieldLookup:
            field_rule_association_immutable[key]["targetFieldLookup"],
          root: {
            ...fieldBranches_immutable[key]["root"],
            rules: field_rule_association_immutable[key]["root"]
              ? [...field_rule_association_immutable[key]["root"]]
              : [],
          },
          ...assign_rule_branch,
        },
      };
    },
    {}
  );

  // === note
  // branches are at branchId

  // =====
  // build tree with branches nested in parent branches
  // 1.) nest branches with parentBranchId === null within root
  let field_branches_nest_root = Object.keys(field_branches_with_rules).reduce(
    (prev, key) => {
      let root_branches = Object.keys(field_branches_with_rules[key])
        .filter((itemKey) => !["root", "targetFieldLookup"].includes(itemKey))
        .reduce((prev_inner, key_inner) => {
          if (
            field_branches_with_rules[key][key_inner]["parentBranchId"] === null
          ) {
            return {
              ...prev_inner,
              [key_inner]: { ...field_branches_with_rules[key][key_inner] },
            };
          }
          return { ...prev_inner };
        }, {});

      return {
        ...prev,
        [key]: {
          targetFieldLookup:
            field_branches_with_rules[key]["targetFieldLookup"],
          root: {
            ...field_branches_with_rules[key]["root"],
            branches: { ...root_branches },
          },
        },
      };
    },
    {}
  );

  // 2.) nest children branches with parentBranchId != null
  let rule_tree = Object.keys(field_branches_nest_root).reduce((prev, key) => {
    let available_branches = Object.keys(field_branches_with_rules[key]).filter(
      (itemKey) => !["root", "targetFieldLookup"].includes(itemKey)
    );

    let not_root_branches = available_branches
      .filter(
        (el) => field_branches_with_rules[key][el]["parentBranchId"] !== null
      )
      .map((branchId) => ({ ...field_branches_with_rules[key][branchId] }));

    let not_root_branches_at_parentBranchId = not_root_branches.reduce(
      (prev_inner, branch_inner) => {
        if (prev_inner[branch_inner["parentBranchId"]]) {
          return {
            ...prev_inner,
            [branch_inner["parentBranchId"]]: {
              ...prev_inner[branch_inner["parentBranchId"]],
              [branch_inner["branchId"]]: { ...branch_inner },
            },
          };
        }
        return {
          ...prev_inner,
          [branch_inner["parentBranchId"]]: {
            [branch_inner["branchId"]]: { ...branch_inner },
          },
        };
      },
      {}
    );

    let new_root_branches = Object.keys(
      field_branches_nest_root[key]["root"]["branches"]
    ).reduce((prev_inner, key_inner) => {
      let currentBranch = {
        ...field_branches_nest_root[key]["root"]["branches"][key_inner],
      };

      // has children
      if (not_root_branches_at_parentBranchId[currentBranch["branchId"]]) {
        return {
          ...prev_inner,
          [key_inner]: {
            ...currentBranch,
            branches: {
              ...not_root_branches_at_parentBranchId[currentBranch["branchId"]],
            },
          },
        };
      }
      return { ...prev_inner, [key_inner]: { ...currentBranch } };
    }, {});

    return {
      ...prev,
      [key]: {
        ...field_branches_nest_root[key],
        root: {
          ...field_branches_nest_root[key]["root"],
          branches: { ...new_root_branches },
        },
      },
    };
  }, {});

  // console.log({ rule_tree });

  // =====
  // parse rule tree for each fieldId to determine if ruleset condition is met

  let checkRuleCondition = (
    rule_inner,
    formFields_inner,
    formFieldsLatest_inner
  ) => {
    // @TODO
    // make immutable

    // formFieldsLatest is optional: will check that value vs whats in formFields at value
    let rule_targetFieldId = rule_inner["targetFieldId"];
    let rule_isValueField = rule_inner["isValueField"];

    let rule_value = rule_inner["value"];
    let rule_conditionName = rule_inner["conditionName"];

    let rule_targetFieldType = getNestedPropertySafe(formFields_inner, [
      rule_targetFieldId,
      "type",
      "sqlType",
    ]);

    let getFieldValue = (
      formFieldsLatest_inner_x,
      rule_targetFieldId_x,
      formFields_inner_x
    ) => {
      if (formFieldsLatest_inner_x) {
        return formFieldsLatest_inner_x[rule_targetFieldId_x];
      }
      let value_to_return = getNestedPropertySafe(formFields_inner_x, [
        rule_targetFieldId_x,
        "value",
      ]);
      return value_to_return;
    };

    let field_value = getFieldValue(
      formFieldsLatest_inner,
      rule_targetFieldId,
      formFields_inner
    );

    let getRuleValueToCompare = (
      rule_isValueField_x,
      rule_value_x,
      formFields_inner_x
    ) => {
      if (rule_isValueField_x) {
        let value_to_return = getNestedPropertySafe(formFields_inner_x, [
          rule_value_x,
          "value",
        ]);
        return value_to_return;
      }
      return rule_value_x;
    };

    let rule_valueToCompare = getRuleValueToCompare(
      rule_isValueField,
      rule_value,
      formFields_inner
    );

    let result = comparisonCheckSQL(
      rule_targetFieldType,
      rule_conditionName,
      field_value,
      rule_valueToCompare
    );

    return result;
  };

  let checkRules = (
    branch_rules_inner,
    form_fields_inner,
    formValuesLatest_inner
  ) => {
    let result = branch_rules_inner.reduce((prev, rule) => {
      let checkResult = checkRuleCondition(
        rule,
        form_fields_inner,
        formValuesLatest_inner
      );

      return [...prev, checkResult];
    }, []);

    return result;
  };

  let recurseBranch = (
    formFields_inner,
    branch_inner,
    condition_inner,
    formValuesLatest_inner
  ) => {
    let branch_rules = branch_inner["rules"];
    let branch_branches = branch_inner["branches"];

    if (condition_inner === "any") {
      let checkRulesResult = checkRules(
        branch_rules,
        formFields_inner,
        formValuesLatest_inner
      );
      let anyRulesPassed = checkRulesResult.includes(true);
      if (anyRulesPassed) {
        return true;
      } else {
        if (Object.keys(branch_branches).length < 1) {
          return false;
        }
        let checkBranches = Object.keys(branch_branches).map((el) => {
          return recurseBranch(
            formFields_inner,
            branch_branches[el],
            branch_branches[el]["condSelect"]
          );
        });
        let anyBranchPassed = checkBranches.includes(true);
        if (anyBranchPassed) {
          return true;
        }
        return false;
      }
    }

    if (condition_inner === "all") {
      let checkRulesResult = checkRules(
        branch_rules,
        formFields_inner,
        formValuesLatest_inner
      );
      let allRulesPassed = !checkRulesResult.includes(false);
      if (allRulesPassed) {
        if (Object.keys(branch_branches).length < 1) {
          return true;
        }
        // check branches
        let checkBranches = Object.keys(branch_branches).map((el) => {
          return recurseBranch(
            formFields_inner,
            branch_branches[el],
            branch_branches[el]["condSelect"]
          );
        });
        let allBranchPassed = !checkBranches.includes(false);
        if (allBranchPassed) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    }
  };

  let field_condition_result = Object.keys(rule_tree).reduce(
    (prev, fieldId) => {
      let result = recurseBranch(
        field_obj_immutable,
        rule_tree[fieldId]["root"],
        rule_tree[fieldId]["root"]["condSelect"],
        fieldValues
      );

      return { ...prev, [fieldId]: result };
    },
    {}
  );

  let reconciledResult = Object.keys(field_condition_result).reduce(
    (prev, key) => ({ ...prev, [key]: field_condition_result[key] }),
    { ...field_status_immutable }
  );

  return reconciledResult;
};

export const adminSchemaConstants = {
  GET_ADMIN_ACCESS_TYPES_SCHEMA_REQUEST:
    "GET_ADMIN_ACCESS_TYPES_SCHEMA_REQUEST",
  GET_ADMIN_ACCESS_TYPES_SCHEMA_SUCCESS:
    "GET_ADMIN_ACCESS_TYPES_SCHEMA_SUCCESS",
  GET_ADMIN_CONDITIONAL_TYPES_SCHEMA_REQUEST:
    "GET_ADMIN_CONDITIONAL_TYPES_SCHEMA_REQUEST",
  GET_ADMIN_CONDITIONAL_TYPES_SCHEMA_SUCCESS:
    "GET_ADMIN_CONDITIONAL_TYPES_SCHEMA_SUCCESS",
  GET_ADMIN_NOTIFICATION_TYPES_SCHEMA_REQUEST:
    "GET_ADMIN_NOTIFICATION_TYPES_SCHEMA_REQUEST",
  GET_ADMIN_NOTIFICATION_TYPES_SCHEMA_SUCCESS:
    "GET_ADMIN_NOTIFICATION_TYPES_SCHEMA_SUCCESS",
  GET_ADMIN_NOTIFICATION_FORM_FIELDS_SCHEMA_REQUEST:
    "GET_ADMIN_NOTIFICATION_FORM_FIELDS_SCHEMA_REQUEST",
  GET_ADMIN_NOTIFICATION_FORM_FIELDS_SCHEMA_SUCCESS:
    "GET_ADMIN_NOTIFICATION_FORM_FIELDS_SCHEMA_SUCCESS",
  GET_ADMIN_NOTIFICATION_RECIPIENT_TYPES_SCHEMA_REQUEST:
    "GET_ADMIN_NOTIFICATION_RECIPIENT_TYPES_SCHEMA_REQUEST",
  GET_ADMIN_NOTIFICATION_RECIPIENT_TYPES_SCHEMA_SUCCESS:
    "GET_ADMIN_NOTIFICATION_RECIPIENT_TYPES_SCHEMA_SUCCESS",
  ADMIN_SCHEMA_CLEAR: "ADMIN_SCHEMA_CLEAR",
};

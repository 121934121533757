import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { userService } from "_services";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { validation } from "_utility";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import PRGLogo from "_assets/images/Logo/PRG-Logo.svg";
import { alertActions } from "_actions";
import { useDispatch } from "react-redux";

let { isEmailFormat } = validation;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.DarkGrayPrg.main,
    borderRadius: "4px",
    "& .MuiFormLabel-root": {
      color: `${theme.palette.DarkGrayPrg.main} !important`,
    },
    "& .MuiInputBase-root": {
      backgroundColor: `${theme.palette.WhitePrg.main} !important`,
    },
    "& .MuiInputBase-input": {
      color: `${theme.palette.DarkGrayPrg.main} !important`,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: theme.palette.DarkGrayPrg.main,
      margin: 0,
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "-100px",
    padding: "20px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.GreenPrg.main,
    "&:hover": {
      backgroundColor: theme.palette.GrayPrg.main,
      color: theme.palette.DarkGrayPrg.main,
    },
  },
  inputs: {
    backgroundColor: `${theme.palette.WhitePrg.main} !important`,
    color: `${theme.palette.DarkGrayPrg.main} !important`,
    borderRadius: "4px",
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  imgWrap: {
    position: "relative",
    top: "-100px",
    width: "202px",
    height: "202px",
    backgroundColor: theme.palette.BodyWhitePrg.main,
    borderRadius: "50%",
  },
  imgInnerWrap: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: 0,
    right: 0,
    margin: "auto",
    width: "166px",
    height: "166px",
    backgroundColor: theme.palette.WhitePrg.main,
    borderRadius: "50%",
    borderBottom: `solid 2px ${theme.palette.GreenPrg.main}`,
    boxShadow: `0 0 0px 8px ${theme.palette.GrayPrg.main}`,
  },
  img: {
    top: "50%",
    left: "-6px",
    width: "178px",
    position: "absolute",
    transform: "translateY(-50%)",
    right: 0,
    margin: "auto",
  },
  forgotPass: {
    cursor: "pointer",
    textAlign: "right",
    color: theme.palette.WhitePrg.main,
    "&:hover": {
      opacity: ".5",
    },
  },
  textWhite: {
    color: theme.palette.WhitePrg.main,
    textAlign: "center",
  },
}));

export default function Index() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  let goBack = () => {
    history.push("/");
  };

  const { register, handleSubmit, watch, reset } = useForm({
    shouldUnregister: false,
  });

  const [errors, setErrors] = useState();
  const [confirmation, setConfirmation] = useState(false);

  const onSubmit = async (data) => {
    let emailCheck = isEmailFormat(data["email"]);
    if (emailCheck === false) {
      setErrors({ email: "Please enter a valid email" });
      return false;
    }

    try {
      let response = await userService.forgotPassword(data);

      if (response["error"]) {
        dispatch(alertActions.error(`${response["error"]}`));
      }
      if (response["message"]) {
        setConfirmation(true);
        dispatch(alertActions.success(`${response["message"]}`));
      }
    } catch (error) {
      dispatch(alertActions.error(`${error}`));
    }

    setErrors();
  };

  let tryAgain = () => {
    reset();
    setConfirmation(false);
  };

  let email = watch("email");

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.root}>
        <div className={classes.imgWrap}>
          <div className={classes.imgInnerWrap}>
            <img
              src={PRGLogo}
              className={classes.img}
              alt="Project Resources Group logo"
            />
          </div>
        </div>
        {confirmation ? (
          <div className={classes.form}>
            <p className={classes.textWhite}>Reset link sent to {email}</p>
            <p className={classes.textWhite}>Please check your email</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className={classes.forgotPass} onClick={() => tryAgain()}>
                Try again
              </p>
              <p className={classes.forgotPass} onClick={() => goBack()}>
                Go Back
              </p>
            </div>
          </div>
        ) : (
          <form className={classes.form} noValidate>
            <TextField
              variant="filled"
              className={classes.inputs}
              inputProps={{ pattern: "/^[^@s]+@[^@s]+.[^@s]+$/" }}
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              inputRef={register({
                required: true,
              })}
              error={errors && errors["email"] ? true : false}
              helperText={errors && errors["email"] ? errors["email"] : null}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit(onSubmit)}
            >
              Request password reset
            </Button>
            <p className={classes.forgotPass} onClick={() => goBack()}>
              Go Back
            </p>
          </form>
        )}
      </div>
    </Container>
  );
}

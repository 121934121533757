export const stringParsers = {
  binary(string) {
    if (string === "active") {
      return 1;
    } else if (string === "inactive") {
      return 0;
    }
  },
  binaryBool(bool) {
    if (bool) {
      return 1;
    } else {
      return 0;
    }
  },
  stringBinary(string) {
    if (string === "true") {
      return 1;
    } else {
      return 0;
    }
  },
  boolString(bool) {
    if (bool === true) {
      return "Active";
    } else {
      return "Inactive";
    }
  },
  boolStringType(bool) {
    if (bool === "true") {
      return true;
    } else {
      return false;
    }
  },
};

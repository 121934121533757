import { put, call, all } from "redux-saga/effects";
import {
  adminEntityConstants,
  alertConstants,
  authConstants,
} from "../_constants";
import { adminEntityService } from "_services";
import { isEmpty } from "ramda";
//import { lobOptions } from "_helpers";
import {
  getUniqueObjValues,
  buildPayloadArray,
  buildAlertMessage,
} from "_utility";

export function* getClientInformationSaga({ entityId, lob }) {
  try {
    const response = yield call(
      adminEntityService.getClientInformation,
      entityId,
      lob
    );

    if (isEmpty(response)) {
      return;
    }

    let targetEntity = response;
    yield put({
      type: adminEntityConstants.GET_CLIENT_INFORMATION_SUCCESS,
      payload: {
        entityId,
        lob,
        information: { ...targetEntity },
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

// LOB Dispatch Entities
export function* getDisptachEntitesSaga() {
  try {
    const response = yield call(
      adminEntityService.getDispatchEntities,
    );
    if (isEmpty(response)) {
      return;
    }
    yield put({
      type: adminEntityConstants.GET_ALL_DISPATCH_ENTITIES_SUCCESS,
      payload: {...response},
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}


export function* updateClientInformationSaga({ 
   payload: { files, ...payload },
  id,
  lob }) {
  try {
    const response = yield call(
      adminEntityService.updateClientInformation,
      files,
      payload,
      id,
      lob
    );

    if (isEmpty(response)) {
      return;
    }

    const generalResponse = yield call(
      adminEntityService.getClientInformation,
      id,
      lob
    );

    if (isEmpty(generalResponse)) {
      return;
    }

    let targetEntity = generalResponse[0];

    yield put({
      type: adminEntityConstants.GET_CLIENT_INFORMATION_SUCCESS,
      payload: {
        id,
        lob,
        information: { ...targetEntity },
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "General Info updated" },
    });
    if( files !== null ){
      window.location.reload(false);
    }

  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}


export function* getPaymentFormSaga({ entityId, lob }) {
  try {
    const response = yield call(
      adminEntityService.getPaymentForm,
      entityId,
      lob
    );

    yield put({
      type: adminEntityConstants.GET_PAYMENT_FORM_SUCCESS,
      payload: {
        entityId,
        lob,
        data: { ...response },
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updatePaymentFormSaga({ entityId, lob, payload }) {
  try {
    const responseUpdate = yield call(
      adminEntityService.updatePaymentForm,
      payload,
      entityId,
      lob
    );

    if (isEmpty(responseUpdate)) {
      yield put({
        type: alertConstants.ERROR,
        payload: { message: "Error Updating Payment Form" },
      });
      return;
    }
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Payment Form updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}



export function* getPaymentFormFeeSaga({ lob, entityId}) {
  try {
    const response = yield call(
      adminEntityService.getPaymentFormFees,
      entityId,
    );
    yield put({
      type: adminEntityConstants.GET_PAYMENT_FORM_FEES_SUCCESS,
      payload: {
        lob,
        entityId,
        data: { ...response },
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updatePaymentFormFeesSaga({ payload, entityId, cb }) {
  try {
    const responseUpdate = yield call(
      adminEntityService.updatePaymentFormFees,
      payload,
      entityId,
    );

    if (isEmpty(responseUpdate)) {
      yield put({
        type: alertConstants.ERROR,
        payload: { message: "Error Updating Payment Fees" },
      });
      return;
    }
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Payment Fees Updated" },
    });

    if (cb) cb();
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

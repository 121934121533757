import { telecomClaimConstants, telecomDispatchConstants } from "_constants";

/**
 * @deprecated
 */
const getAll = () => {
  return {
    type: telecomDispatchConstants.GET_ALL_DISPATCH_TELECOM_REQUEST,
  };
};

const getOne = (claimId) => {
  return {
    type: telecomDispatchConstants.GET_ONE_DISPATCH_TELECOM_REQUEST,
    claimId,
  };
};

const updateOne = (data, claimId, callback) => {
  return {
    type: telecomDispatchConstants.UPDATE_ONE_DISPATCH_TELECOM_REQUEST,
    data,
    claimId,
    callback
  };
};

const updateTenEight = (claimId) => {
  return {
    type: telecomDispatchConstants.UPDATE_TEN_EIGHT_DISPATCH_TELECOM_REQUEST,
    claimId,
  };
};


//Disptach Stopwatch
const updateDispatchTimer = (claims) => {
  return {
    type: telecomDispatchConstants.UPDATE_DISPATCH_TELECOM_TIMER_REQUEST,
    claims
  }
}

const updateDispatchTimerPause = (payload) => {
  return {
    type: telecomDispatchConstants.UPDATE_DISPATCH_TELECOM_TIMER_PAUSE_REQUEST,
    payload
  }
}
const updateDispatchTimerColor = (payload) => {
  return {
    type: telecomDispatchConstants.UPDATE_DISPATCH_TELECOM_TIMER_COLOR_REQUEST,
    payload
  }
}

const updateDispatches = (claims) => {
  return {
    type: telecomDispatchConstants.GET_ALL_DISPATCH_TELECOM_SUCCESS,
    payload: claims
  }
}

const confirmDispatch = (claimId) => {
  return {
    type: telecomClaimConstants.CONFIRM_TEL_DISPATCH,
    payload: {claimId}
  }
}

export const telecomDispatchActions = {
  getAll,
  getOne,
  updateOne,
  updateTenEight,
  updateDispatchTimer,
  updateDispatchTimerPause,
  updateDispatchTimerColor,
  updateDispatches,
  confirmDispatch
};

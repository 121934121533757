import { put, call, all } from "redux-saga/effects";
import { adminJobCostingConstants, alertConstants, authConstants, jointLedgerConstants, telecomClaimConstants } from "_constants";
import { telecomClaimService, jointLedgerService } from "_services";
import { isEmpty } from "ramda";
import { buildAlertMessage } from "_utility";
import _getProperty from "lodash/get";

//Build filter params
let separator = "_|_";
let buildFilterQueryParams = (filterModal) => {
  if (!filterModal) return "";
  let { items, logicOperator } = filterModal;
  if (items.length < 1) return "";

  let itemsSanitized = items.reduce((prev, el) => {
    if (el.hasOwnProperty("value")) {
      return [...prev, el];
    }
    return prev;
  }, []);

  let buildString = itemsSanitized.reduce((prev, el) => {
    let field = encodeURIComponent(`${el["field"]}${separator}${el["id"]}`);
    let operator = encodeURIComponent(`${el["operator"]}${separator}${el["id"]}`);
    let value = encodeURIComponent(`${el["value"]}${separator}${el["id"]}`);

    let completeString = `&field=${field}&operator=${operator}&value=${value}`;

    return `${prev}${completeString}`;
  }, "");

  // Ensure the linkOperator is also encoded
  logicOperator = encodeURIComponent(logicOperator);

  return `${buildString}&linkOperator=${logicOperator}`;
}; // returns a string

export function* getAllClaimsTelecomSaga({
  page,
  rowsPerPage,
  filterModal,
  search,
  searchField,
  searchFields,
  isDispatchAdmin,
  allClaimsCount,
  restrictedStatusIds,
}) {
  try {
    // ready to use
    let filterParams = buildFilterQueryParams(filterModal);

    const response = yield call(
      telecomClaimService.getAllClaims,
      page,
      rowsPerPage,
      filterParams,
      search,
      searchField,
      searchFields,
      isDispatchAdmin,
      allClaimsCount,
      restrictedStatusIds
    );
    yield put({
      type: telecomClaimConstants.GET_ALL_CLAIMS_TELECOM_SUCCESS,
      payload: {
        page,
        claims: [...response["data"]],
        count: response["count"],
        columnFilters: response["columnFilters"],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getMyClaimsTelecomSaga({
  page,
  rowsPerPage,
  filterModal,
  search,
  searchField,
  searchFields,
  isDispatchAdmin,
  allClaimsCount,
  userLogginIn,
}) {
  try {
    let filterParams = buildFilterQueryParams(filterModal);

    const response = yield call(
      telecomClaimService.getMyClaims,
      page,
      rowsPerPage,
      filterParams,
      search,
      searchField,
      searchFields,
      isDispatchAdmin,
      allClaimsCount,
      userLogginIn
    );
    yield put({
      type: telecomClaimConstants.GET_MY_CLAIMS_TELECOM_SUCCESS,
      payload: {
        page,
        claims: [...response["data"]],
        count: response["count"],
        columnFilters: response["columnFilters"],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getMyTeamsClaimsTelecomSaga({
  page,
  rowsPerPage,
  filterModal,
  search,
  searchField,
  searchFields,
  isDispatchAdmin,
  allClaimsCount,
  userLogginIn,
}) {
  try {
    let filterParams = buildFilterQueryParams(filterModal);

    const response = yield call(
      telecomClaimService.getMyTeamsClaims,
      page,
      rowsPerPage,
      filterParams,
      search,
      searchField,
      searchFields,
      isDispatchAdmin,
      allClaimsCount,
      userLogginIn
    );
    yield put({
      type: telecomClaimConstants.GET_MY_TEAMS_CLAIMS_TELECOM_SUCCESS,
      payload: {
        page,
        claims: [...response["data"]],
        count: response["count"],
        columnFilters: response["columnFilters"],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getMyMobileClaimsTelecomSaga({ page, rowsPerPage, filterModal, search, searchField, searchFields, restrictedStatusIds }) {
  try {
    let filterParams = buildFilterQueryParams(filterModal);

    const response = yield call(
      telecomClaimService.getMyMobileClaims,
      page,
      rowsPerPage,
      filterParams,
      search,
      searchField,
      searchFields,
      restrictedStatusIds
    );
    if (response) {
      yield put({
        type: telecomClaimConstants.GET_MY_MOBILE_CLAIMS_TELECOM_SUCCESS,
        payload: {
          page,
          claims: [...response],
        },
      });
    }
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimByIdTelecomSaga({ claimId, restrictedStatusIds }) {
  try {
    // put: loading true
    yield put({
      type: telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_LOADING_TRUE,
    });

    const response = yield call(telecomClaimService.getClaimById, claimId, restrictedStatusIds);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_SUCCESS,
      payload: {
        claimId,
        claim: { ...response },
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateTelecomClaimSaga({ claimId, payload, restrictedStatusIds }) {
  try {
    const response = yield call(telecomClaimService.updateClaim, claimId, payload);

    if (response["status"] !== 200) {
      throw new Error("Error updating Claim");
    }

    const responseFetchUpdatedClaim = yield call(telecomClaimService.getClaimById, claimId, restrictedStatusIds);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_SUCCESS,
      payload: {
        claimId,
        claim: { ...responseFetchUpdatedClaim },
      },
    });

    const responseHistory = yield call(telecomClaimService.getClaimHistory, claimId);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_HISTORY_TELECOM_SUCCESS,
      payload: { data: [...responseHistory], claimId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Claim updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteTelecomClaimSaga({
  claimId,
  currentQuery,
  page,
  rowsPerPage,
  filterModal,
  search,
  isDispatchAdmin,
  allClaimsCount,
  userLogginIn,
  restrictedStatusIds,
  trackChanges,
  data,
  callback,
  searchField,
  searchFields,
}) {
  //have to explicitly set trackChanges to false, will need to look into further as to why is undefined
  let trackChangesSet = false;
  trackChanges = trackChangesSet;
  try {
    const response = yield call(
      telecomClaimService.deleteClaim,
      claimId,
      restrictedStatusIds,
      trackChanges,
      data,
      callback
    );

    if (response["status"] !== 201) {
      throw new Error("Error deleting Claim");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: `Claim ${claimId} deleted` },
    });

    // use current page,filter,search etc. to refresh data based on currentyQuery (all, myClaims, etc)

    let filterParams = buildFilterQueryParams(filterModal);
    switch (currentQuery) {
      case "all":
        const responseGetAllClaims = yield call(
          telecomClaimService.getAllClaims,
          page,
          rowsPerPage,
          filterParams,
          search,
          searchField,
          searchFields,
          isDispatchAdmin,
          allClaimsCount,
          restrictedStatusIds
        );
        yield put({
          type: telecomClaimConstants.GET_ALL_CLAIMS_TELECOM_SUCCESS,
          payload: {
            page: 0,
            claims: [...responseGetAllClaims["data"]],
            count: responseGetAllClaims["count"],
          },
        });
        break;
      case "myClaims":
        const responseGetMyClaims = yield call(
          telecomClaimService.getMyClaims,
          page,
          rowsPerPage,
          filterParams,
          search,
          isDispatchAdmin,
          allClaimsCount,
          userLogginIn
        );
        yield put({
          type: telecomClaimConstants.GET_MY_CLAIMS_TELECOM_SUCCESS,
          payload: {
            page,
            claims: [...responseGetMyClaims["data"]],
            count: responseGetMyClaims["count"],
          },
        });
        break;
      case "myMobileClaims":
        const responseGetMyMobileClaims = yield call(
          telecomClaimService.getMyMobileClaims,
          page,
          rowsPerPage,
          filterParams,
          search,
          restrictedStatusIds
        );
        if (responseGetMyMobileClaims) {
          yield put({
            type: telecomClaimConstants.GET_MY_MOBILE_CLAIMS_TELECOM_SUCCESS,
            payload: {
              page,
              claims: [...responseGetMyMobileClaims],
            },
          });
        }
        break;
      case "myTeams":
        const responseGetMyTeamClaims = yield call(
          telecomClaimService.getMyTeamsClaims,
          page,
          rowsPerPage,
          filterParams,
          search,
          isDispatchAdmin,
          allClaimsCount,
          userLogginIn
        );
        yield put({
          type: telecomClaimConstants.GET_MY_TEAMS_CLAIMS_TELECOM_SUCCESS,
          payload: {
            page,
            claims: [...responseGetMyTeamClaims["data"]],
            count: responseGetMyTeamClaims["count"],
          },
        });
        break;
      default:
        break;
    }
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSchemaEntityOptionsTelecomSaga({ lob }) {
  try {
    const response = yield call(telecomClaimService.getSchemaEntityOptions, lob);

    if (!Array.isArray(response)) {
      throw new Error("Error fetching entity options");
    }

    yield put({
      type: telecomClaimConstants.GET_SCHEMA_ENTITY_OPTIONS_TELECOM_SUCCESS,
      payload: { options: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSchemaRdaOptionsTelecomSaga({ entityId, getAll }) {
  try {
    const response = yield call(telecomClaimService.getSchemaRdaOptions, entityId, getAll);

    if (!Array.isArray(response)) {
      throw new Error("Error fetching rda options");
    }

    yield put({
      type: telecomClaimConstants.GET_SCHEMA_RDA_OPTIONS_TELECOM_SUCCESS,
      payload: { entityId, options: [...response] },
    });
    yield put({
      type: adminJobCostingConstants.GET_RDA_OPTIONS_SUCCESS,
      payload: { options: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getCreateClaimFieldRulesTelecomSaga({ entityId }) {
  try {
    const response = yield call(telecomClaimService.getCreateClaimFieldRules, entityId);

    if (isEmpty(response) || !response) {
      throw new Error("Error fetching field meta data");
    }

    yield put({
      type: telecomClaimConstants.GET_CREATE_CLAIM_FIELD_RULES_TELECOM_SUCCESS,
      payload: { entityId, rules: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSchemaFilterConditionsTelecomSaga() {
  try {
    const response = yield call(telecomClaimService.getSchemaFilterConditions);

    if (isEmpty(response) || !response) {
      throw new Error("Error fetching filter options");
    }
    yield put({
      type: telecomClaimConstants.GET_SCHEMA_FILTER_OPTIONS_TELECOM_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getFilterSetsTelecomSaga() {
  try {
    const response = yield call(telecomClaimService.getFilterSets);

    yield put({
      type: telecomClaimConstants.GET_FILTER_SETS_TELECOM_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* createFilterSetTelecomSaga({ payload }) {
  try {
    let { filters, activeColumns } = payload;

    let filtersEncoded = JSON.stringify(filters);
    let activeColumnsEncoded = activeColumns.join(",");

    const createSetRespponse = yield call(telecomClaimService.createFilterSet, {
      ...payload,
      filters: filtersEncoded,
      activeColumns: activeColumnsEncoded,
    });

    if (isEmpty(createSetRespponse)) {
      throw new Error("Error creating filter set");
    }

    const response = yield call(telecomClaimService.getFilterSets);

    yield put({
      type: telecomClaimConstants.GET_FILTER_SETS_TELECOM_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "New filter set created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* removeFilterSetTelecomSaga({ id }) {
  try {
    yield call(telecomClaimService.removeFilterSet, id);

    const response = yield call(telecomClaimService.getFilterSets);

    yield put({
      type: telecomClaimConstants.GET_FILTER_SETS_TELECOM_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Filter Set Removed" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* setFilterSetActiveTelecomSaga({ id }) {
  try {
    yield call(telecomClaimService.updateFilterSet, id, {
      active: true,
    });

    const response = yield call(telecomClaimService.getFilterSets);

    yield put({
      type: telecomClaimConstants.GET_FILTER_SETS_TELECOM_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Filter Set Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateFilterSetNameTelecomSaga({ name, id }) {
  try {
    yield call(telecomClaimService.updateFilterSet, id, {
      name,
    });

    const response = yield call(telecomClaimService.getFilterSets);

    yield put({
      type: telecomClaimConstants.GET_FILTER_SETS_TELECOM_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Filter Set Name Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* duplicateFilterSetTelecomSaga({ name, id }) {
  try {
    yield call(telecomClaimService.duplicateFilterSet, id, name);
    const response = yield call(telecomClaimService.getFilterSets);
    yield put({
      type: telecomClaimConstants.GET_FILTER_SETS_TELECOM_SUCCESS,
      payload: [...response],
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Filter Set Duplicated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateFilterSetTelecomSaga({ id, payload }) {
  try {
    yield call(telecomClaimService.updateFilterSet, id, payload);

    const response = yield call(telecomClaimService.getFilterSets);

    yield put({
      type: telecomClaimConstants.GET_FILTER_SETS_TELECOM_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Filter Set Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* uploadAttachmentTelecomSaga({ payload: { files, ...payload }, claimId }) {
  try {
    const responses = yield all(files.map((image) => call(telecomClaimService.uploadFile, image, payload)));

    if (isEmpty(responses)) {
      throw new Error("Error uploading files");
    }
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Upload Successful" },
    });

    //let statusMessage = buildAlertMessage("Uploads", responses);

    let startOffset = encodeURI(`Telecom/claims/${claimId}`);

    const responseFetch = yield call(telecomClaimService.getFiles, startOffset);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_ATTACHMENTS_TELECOM_SUCCESS,
      payload: { files: [...responseFetch], claimId },
    });

    // yield put({
    //   type: alertConstants.SUCCESS,
    //   payload: {
    //     message: `${statusMessage}`,
    //   },
    // });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* saveRotationAttachmentTelecomSaga({ payload }) {
  try {
    yield call(telecomClaimService.handleSaveRotation, payload);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Rotation Updated Successful" },
    });

    let startOffset = encodeURI(`Telecom/claims/${payload.claimId}`);

    const responseFetch = yield call(telecomClaimService.getFiles, startOffset);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_ATTACHMENTS_TELECOM_SUCCESS,
      payload: { files: [...responseFetch], claimId: payload.claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({ type: authConstants.LOGOUT });
    }
    yield put({ type: alertConstants.ERROR, payload: { message: error.message } });
  }
}

export function* getAttachmentsTelecomSaga({ claimId }) {
  try {
    let startOffset = encodeURI(`Telecom/claims/${claimId}`);
    const response = yield call(telecomClaimService.getFiles, startOffset);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_ATTACHMENTS_TELECOM_SUCCESS,
      payload: { files: [...response], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSortOrderAttachmentsTelecomSaga({ payload, claimId }) {
  try {
    yield call(telecomClaimService.sortOrder, payload);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Order Saved" },
    });

    let startOffset = encodeURI(`Telecom/claims/${claimId}`);

    const response = yield call(telecomClaimService.getFiles, startOffset);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_ATTACHMENTS_TELECOM_SUCCESS,
      payload: { files: [...response], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateCategoryAttachmentsTelecomSaga({ payload, claimId }) {
  try {
    yield call(telecomClaimService.updateCategory, payload);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Categories Updated" },
    });

    let startOffset = encodeURI(`Telecom/claims/${claimId}`);

    const response = yield call(telecomClaimService.getFiles, startOffset);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_ATTACHMENTS_TELECOM_SUCCESS,
      payload: { files: [...response], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSignedUrlTelecomSaga({ path, expire }) {
  try {
    let encodePath = encodeURI(path);

    yield call(telecomClaimService.getSignedUrl, encodePath, expire);
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* postPaymentTelecomSaga({ claimId, payload }) {
  try {
    yield call(telecomClaimService.postPayment, payload, claimId);

    const response = yield call(telecomClaimService.getClaimById, claimId, []);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_SUCCESS,
      payload: {
        claimId,
        claim: { ...response },
      },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Payment Posted" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimHistoryTelecomSaga({ claimId }) {
  try {
    const response = yield call(telecomClaimService.getClaimHistory, claimId);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_HISTORY_TELECOM_SUCCESS,
      payload: { data: [...response], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimJobCostingInvoiceTelecomSaga({ claimId, ccma }) {
  try {
    const responseData = yield call(telecomClaimService.getJobCostingInvoice, claimId, ccma);

    let { response } = responseData;

    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimRateCardContractorTelecomSaga({ claimId }) {
  try {
    const responseData = yield call(telecomClaimService.getClaimJobCostingRateCardContractor, claimId);

    let { response } = responseData;

    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_TELECOM_SUCCESS,
      payload: { claimId, data: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimRateCardMaterialTelecomSaga({ claimId }) {
  try {
    const responseData = yield call(telecomClaimService.getClaimJobCostingRateCardMaterial, claimId);

    let { response } = responseData;

    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_TELECOM_SUCCESS,
      payload: { claimId, data: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
export function* getClaimRateCardLaborTelecomSaga({ claimId, ccma }) {
  try {
    const responseData = yield call(telecomClaimService.getClaimJobCostingRateCardLabor, claimId, ccma);

    let { response } = responseData;

    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_TELECOM_SUCCESS,
      payload: { claimId, data: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimRateCardUsicTelecomSaga({ claimId }) {
  try {
    const responseData = yield call(telecomClaimService.getClaimJobCostingRateCardUsic, claimId);

    let { response } = responseData;

    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_USIC_TELECOM_SUCCESS,
      payload: { claimId, data: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimInvoiceTimeStampTelecomSaga({ claimId }) {
  try {
    const responseDataId = yield call(telecomClaimService.claimInvoiceTimeStamp, claimId);

    let { invoiceId } = responseDataId;
    if (!invoiceId) return;
    const responseData = yield call(telecomClaimService.getJobCostingInvoice, claimId);

    let { response } = responseData;

    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimPostInHouseLaborTelecomSaga({ payload, claimId, invoiceId, ccma, entityid }) {
  try {
    yield call(telecomClaimService.postClaimInHouseLaborItem, payload, claimId, invoiceId, ccma, entityid);

    const responseData = yield call(telecomClaimService.getJobCostingInvoice, claimId, ccma);

    let { response } = responseData;

    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS,
      payload: { claimId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "In-House Labor Item Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimPostMaterialTelecomSaga({ payload, claimId, invoiceId, ccma, entityid }) {
  try {
    yield call(telecomClaimService.postClaimMaterialItem, payload, claimId, invoiceId, ccma, entityid);
    const responseData = yield call(telecomClaimService.getJobCostingInvoice, claimId, ccma);
    let { response } = responseData;
    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Item Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimPostOtherItemTelecomSaga({ payload, claimId, invoiceId }) {
  try {
    yield call(telecomClaimService.postClaimOtherItem, payload, claimId, invoiceId);
    const responseData = yield call(telecomClaimService.getJobCostingInvoice, claimId, false);
    let { response } = responseData;
    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Other Item Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimPostUnlistedItemTelecomSaga({ payload, claimId, entityId, invoiceId }) {
  try {
    yield call(telecomClaimService.postClaimUnlistedItem, payload, claimId, entityId, invoiceId);
    const responseData = yield call(telecomClaimService.getJobCostingInvoice, claimId, true);
    let { response } = responseData;
    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Unlisted Item Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimPostContractorItemTelecomSaga({ payload, claimId, invoiceId, ccma, entityid }) {
  try {
    yield call(telecomClaimService.postClaimContractorItem, payload, claimId, invoiceId, ccma, entityid);
    const responseData = yield call(telecomClaimService.getJobCostingInvoice, claimId, ccma);
    let { response } = responseData;
    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Contractor Item Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimPostLOItemTelecomSaga({ payload, claimId, invoiceId, entityId }) {
  try {
    let responses = yield all(
      payload.map((item) => call(telecomClaimService.postClaimLOItem, item, claimId, invoiceId, entityId))
    );
    if (isEmpty(responses)) {
      throw new Error("Error adding lo items");
    }

    const response = yield call(telecomClaimService.getInvoiceDoc, claimId);
    const payloadInvoice = response.payload;
    yield put({
      type: telecomClaimConstants.GET_CLAIM_INVOICE_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, data: { ...payloadInvoice } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Line items added to invoice" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingRemoveOtherItemTelecomSaga({ invoiceOtherItemIds, invoiceId, claimId }) {
  try {
    let responses = yield all(
      invoiceOtherItemIds.map((itemId) => call(telecomClaimService.jobCostingRemoveOtherItem, itemId, invoiceId))
    );
    if (isEmpty(responses)) {
      throw new Error("Error updating oher items");
    }

    let statusMessage = buildAlertMessage("Other Items removed");

    const responseData = yield call(telecomClaimService.getJobCostingInvoice, claimId);

    let { response } = responseData;

    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS,
      payload: { claimId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingRemoveLaborItemTelecomSaga({ invoiceLaborItemIds, invoiceId, claimId, ccma }) {
  try {
    let responses = yield all(
      invoiceLaborItemIds.map((itemId) => call(telecomClaimService.jobCostingRemoveLaborItem, itemId, invoiceId, ccma))
    );
    if (isEmpty(responses)) {
      throw new Error("Error removing labor items");
    }
    let statusMessage = buildAlertMessage("Labor Items removed");
    const responseData = yield call(telecomClaimService.getJobCostingInvoice, claimId, ccma);
    let { response } = responseData;
    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingRemoveMaterialItemTelecomSaga({ invoiceMaterialItemIds, invoiceId, claimId, ccma }) {
  try {
    let responses = yield all(
      invoiceMaterialItemIds.map((itemId) =>
        call(telecomClaimService.jobCostingRemoveMaterialItem, itemId, invoiceId, ccma)
      )
    );
    if (isEmpty(responses)) {
      throw new Error("Error removing material items");
    }
    let statusMessage = buildAlertMessage("Material Items removed");
    const responseData = yield call(telecomClaimService.getJobCostingInvoice, claimId, ccma);
    let { response } = responseData;
    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingRemoveContractorItemTelecomSaga({
  invoiceContractorItemIds,
  invoiceId,
  claimId,
  ccma,
}) {
  try {
    let responses = yield all(
      invoiceContractorItemIds.map((itemId) =>
        call(telecomClaimService.jobCostingRemoveContractorItem, itemId, invoiceId, ccma)
      )
    );
    if (isEmpty(responses)) {
      throw new Error("Error removing contractor items");
    }
    let statusMessage = buildAlertMessage("Contractor Items removed");
    const responseData = yield call(telecomClaimService.getJobCostingInvoice, claimId);
    let { response } = responseData;
    yield put({
      type: telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingRemoveLOItemTelecomSaga({ invoiceLOItemIds, invoiceId, claimId, entityId }) {
  try {
    let responses = yield all(
      invoiceLOItemIds.map((itemId) =>
        call(telecomClaimService.jobCostingRemoveLOItem, itemId, invoiceId, claimId, entityId)
      )
    );
    if (isEmpty(responses)) {
      throw new Error("Error removing lo items");
    }

    const response = yield call(telecomClaimService.getInvoiceDoc, claimId);
    const payloadInvoice = response.payload;
    yield put({
      type: telecomClaimConstants.GET_CLAIM_INVOICE_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, data: { ...payloadInvoice } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Line items removed from invoice" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingSheetUploadTelecomSaga({ file, name, claimId, userId, username }) {
  try {
    yield call(telecomClaimService.uploadJobCostingFile, file, name, claimId, userId, username);

    let startOffset = encodeURI(`Telecom/claims/${claimId}`);

    const responseFetch = yield call(telecomClaimService.getFiles, startOffset);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_ATTACHMENTS_TELECOM_SUCCESS,
      payload: { files: [...responseFetch], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimInvoiceDocTelecomSaga({ claimId }) {
  try {
    const response = yield call(telecomClaimService.getInvoiceDoc, claimId);
    let { payload } = response;

    yield put({
      type: telecomClaimConstants.GET_CLAIM_INVOICE_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, data: { ...payload } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimDocumentUploadTelecomSaga({ file, name, claimId, userId, username, parent, category, fileType }) {
  try {
    yield call(
      telecomClaimService.claimUploadDocument,
      file,
      name,
      claimId,
      userId,
      username,
      parent,
      category,
      fileType
    );

    let startOffset = encodeURI(`Telecom/claims/${claimId}`);

    const responseFetch = yield call(telecomClaimService.getFiles, startOffset);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_ATTACHMENTS_TELECOM_SUCCESS,
      payload: { files: [...responseFetch], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimDocTelecomSaga({ claimId, letterType, contacts, reason }) {
  try {
    let encodeContacts = contacts ? `&contactsId=${contacts}` : "";

    let encodeReason = reason ? `&reason=${encodeURIComponent(reason)}` : "";

    const data = yield call(telecomClaimService.getClaimDoc, claimId, letterType, encodeContacts, encodeReason);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_DOCUMENT_TELECOM_SUCCESS,
      payload: { letterType, claimId, data: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimDocTelecomSchemaReasonSaga({ entityId }) {
  try {
    const data = yield call(telecomClaimService.getClaimDocSchemaReason, entityId);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS,
      payload: { data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* addClaimDocTelecomSchemaReasonSaga({ entityId, lob, reason }) {
  try {
    yield call(telecomClaimService.addClaimDocSchemaReason, entityId, lob, reason);
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Reason Added" },
    });

    const data = yield call(telecomClaimService.getClaimDocSchemaReason, entityId);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS,
      payload: { entityId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* editClaimDocTelecomSchemaReasonSaga({ LRID, LetterReason }) {
  try {
    yield call(telecomClaimService.editClaimDocSchemaReason, LRID, LetterReason);

    yield put({
      type: telecomClaimConstants.EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS,
      payload: {},
    });

    const data = yield call(telecomClaimService.getClaimDocSchemaReason);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS,
      payload: { data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* removeClaimDocTelecomSchemaReasonSaga({ entityId, lob, reasonid }) {
  try {
    yield all(reasonid.map((itemId) => call(telecomClaimService.removeClaimDocSchemaReason, entityId, lob, itemId)));
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Reason Deleted" },
    });

    const data = yield call(telecomClaimService.getClaimDocSchemaReason, entityId);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS,
      payload: { entityId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimDocTelecomSchemaContactsSaga() {
  try {
    const data = yield call(telecomClaimService.getClaimDocSchemaContacts);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_TELECOM_SUCCESS,
      payload: { data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimDocTelecomSchemaLastSentenceSaga({ entityId }) {
  try {
    const data = yield call(telecomClaimService.getClaimDocSchemaLastSentence, entityId);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_TELECOM_SUCCESS,
      payload: { entityId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimAttachmentSchemaImageCategories() {
  try {
    const data = yield call(telecomClaimService.getClaimAttachmentSchemaImageCategory);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_TELECOM_SUCCESS,
      payload: response,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimAttachmentSchemaVideoCategories() {
  try {
    const data = yield call(telecomClaimService.getClaimAttachmentSchemaVideoCategory);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_TELECOM_SUCCESS,
      payload: response,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimAttachmentSchemaDocumentCategories({ parentType }) {
  try {
    const data = yield call(telecomClaimService.getClaimAttachmentSchemaDocumentCategory, parentType);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_TELECOM_SUCCESS,
      payload: { data: response, parentType },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimDocTelecomSchemaDigLawsSaga({ claimId }) {
  try {
    const data = yield call(telecomClaimService.getClaimDocSchemaDigLaws, claimId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_DIG_LAWS_TELECOM_SUCCESS,
      payload: { data: response, claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimNarrativeCreatorDocTelecomSaga({ claimId }) {
  try {
    const data = yield call(telecomClaimService.getClaimNarrativeCreatorDoc, claimId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: telecomClaimConstants.GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimIntentToBillDocTelecomSaga({ claimId }) {
  try {
    const data = yield call(telecomClaimService.getClaimIntentToBillDoc, claimId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: telecomClaimConstants.GET_CLAIM_INTENT_TO_BILL_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimPromissoryNoteDocTelecomSaga({ claimId }) {
  try {
    const data = yield call(telecomClaimService.getClaimPromissoryNoteDoc, claimId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: telecomClaimConstants.GET_CLAIM_PROMISSORY_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimLegalFinalNoticeDocTelecomSaga({ claimId }) {
  try {
    const data = yield call(telecomClaimService.getClaimLegalFinalNoticeDoc, claimId);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimFinalNoticeDocTelecomSaga({ claimId }) {
  try {
    const data = yield call(telecomClaimService.getClaimFinalNoticeDoc, claimId);
    let response = _getProperty(data, ["response"]);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_FINAL_NOTICE_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimReleaseLetterDocTelecomSaga({ claimId }) {
  try {
    const data = yield call(telecomClaimService.getClaimReleaseLetterDoc, claimId);
    let response = _getProperty(data, ["response"]);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_RELEASE_LETTER_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimSecondNoticeDocTelecomSaga({ claimId }) {
  try {
    const data = yield call(telecomClaimService.getClaimSecondNoticeDoc, claimId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: telecomClaimConstants.GET_CLAIM_SECOND_NOTICE_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimPaymentReceiptTelecomSaga({ claimId, ledgerId }) {
  try {
    const data = yield call(telecomClaimService.getClaimPaymentReceiptDoc, claimId, ledgerId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: telecomClaimConstants.GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, ledgerId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimBalanceOwedTelecomSaga({ claimId }) {
  try {
    const data = yield call(telecomClaimService.getClaimBalanceOwedDoc, claimId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: telecomClaimConstants.GET_CLAIM_BALANCE_OWED_DOCUMENT_TELECOM_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getLogoTelecomSaga({ entityLogoName }) {
  // try {
  //   const response = yield call(
  //     telecomClaimService.getLogo,
  //     entityLogoName
  //   );
  //   yield put({
  //     type:
  //       telecomClaimConstants.GET_LOGO_TELECOM_SUCCESS,
  //     payload: { data: response },
  //   });
  // } catch (error) {
  //   if (error.message === "Invalid Auth Token") {
  //     yield put({
  //       type: authConstants.LOGOUT,
  //     });
  //   }
  //   yield put({
  //     type: alertConstants.ERROR,
  //     payload: { message: error.message },
  //   });
  // }
}

export function* deleteTelecomLedgerSaga({ ledgerId, claimId }) {
  try {
    const res = yield call(telecomClaimService.deleteLedger, ledgerId, claimId);

    const response = yield call(jointLedgerService.getLedgers, "telecom", claimId);

    yield put({
      type: jointLedgerConstants.GET_LEDGERS_SUCCESS,
      payload: {
        lob: "telecom",
        ledgers: { ...response },
      },
    });
    if (res["status"] !== 200) {
      throw new Error("Error Deleting Ledger");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Ledger Deleted" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateTelecomLedgerSaga({ payload, watchId, cb }) {
  try {
    let transformData = Object.keys(payload).reduce((prev, id) => {
      let item = { ...payload[id], id };
      return [...prev, item];
    }, []);

    yield all(transformData.map((item) => call(telecomClaimService.updateLedger, item, watchId, cb)));

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Ledger Updated" },
    });

    const response = yield call(telecomClaimService.getClaimById, watchId, []);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_SUCCESS,
      payload: {
        watchId,
        claim: { ...response },
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteTelecomStatusNoteSaga({ id, watchId }) {
  try {
    const res = yield call(telecomClaimService.deleteStatusNote, id);

    const response = yield call(telecomClaimService.getClaimById, watchId, []);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_SUCCESS,
      payload: {
        watchId,
        claim: { ...response },
      },
    });

    if (res["status"] !== 200) {
      throw new Error("Error Deleting Status Note");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Status Note Deleted" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteTelecomActionNoteSaga({ actionId, claimId }) {
  try {
    const res = yield call(telecomClaimService.deleteActionNote, actionId, claimId);

      // put: loading true
      yield put({
        type: telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_LOADING_TRUE,
      });

    const response = yield call(telecomClaimService.getClaimById, claimId, []);

    yield put({
      type: telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_SUCCESS,
      payload: {
        claimId,
        claim: { ...response },
      },
    });

    if (res["status"] !== 200) {
      throw new Error("Error Deleting Action Note");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Action Note Deleted" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}


export function* saveTelecomColumnFiltersSaga({ filters }) {
  try {
    const res = yield call(telecomClaimService.saveColumnFilters, filters);

    if (res["status"] !== 200) {
      throw new Error("Error Saving Column Filters");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Column Filters Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

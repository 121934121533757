import { combineReducers } from "redux";
import { claim } from "./claim.reducer.js";
import { dispatch } from "./dispatch.reducer.js";
import { reports } from "./report.reducer";
const powerGasRootReducer = combineReducers({
  claim,
  dispatch,
  reports,
});

export default powerGasRootReducer;

import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider as ThemeProviderV4 } from "@material-ui/core/styles";
import { ThemeProvider as ThemeProviderV5 } from "@mui/material/styles";
import { PrivateRoute, PrivateRouteMobile } from "_components/routing";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Dashboard } from "../Dashboard";
import { alertActions, settingsActions } from "_actions";
// mobile
//claims
//import  MobileClaim  from "../App/MobileClaim/entity";

import MyInvoices from "../App/MobileClaim/view/myInvoices";
import StartInvoice from "../App/MobileClaim/view/startInvoice";
import UpdateInvoice from "../App/MobileClaim/view/updateInvoice";

import { Login } from "../Login";
import NotFound from "../NotFound";
import Payment from "../Payment";
import AlertSnackbar from "_components/core/SnackBar";
import theme from "_theme/theme"; // v4
import themeDark from "_theme/themeDark"; // v4
import themeV5 from "_theme/theme"; // v5
import themeDarkV5 from "_theme/themeDark"; // v5
import ForgotPassword from "../ForgotPassword/index";
import ResetPassword from "../ResetPassword/index";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useDispatch, useSelector } from "react-redux";

import { ObjectTools } from "_utility";
import { UseWebSocket } from "_helpers/websocket";
import { Box, Typography } from "@mui/material";

let checkAppVersionInterval;
// version from `meta.json` - first param
// version in bundle file - second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA?.split(/\./g) ?? null;
  const versionsB = versionB?.split(/\./g) ?? null;

  if (!versionsA || !versionsB) return false;

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

function App() {
  const { getNestedPropertySafe } = ObjectTools;
  const dispatch = useDispatch();

  const themeSetting = useSelector((state) => getNestedPropertySafe(state, ["settings", "theme"]));

  let themeMap = {
    light: theme,
    dark: themeDark,
  };

  // clear settings to force dark mode while light mode is disabled
  useEffect(() => {
    dispatch(settingsActions.clearSettings());
  },[])

  UseWebSocket().initWebSocket();

    // the version single source of truth is located in package.json. Version is set as env REACT_APP_VERSION in the build script (package.json)
    const version = process.env.REACT_APP_VERSION;
    console.log(`React app running version ${version}`);
    console.log(`Application instance running version ${version}`);
  
    useEffect(() => {
  
      const checkAppVersion = async () => {
        try {
          const response = await fetch("/meta.json");
        const contentType = response.headers.get("content-type");
        // make sure meta.json file exists and returned json
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const metaData = await response.json();
          const latestVersion = metaData.version;
          const currentVersion = version;

          // compare versions
          const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

          if (shouldForceRefresh) {
            // The following code is commented out for now. Would clear cache programmatically and force hard reload
            // ===============
            // if ('caches' in window) {
            //     // Service worker cache should be cleared with caches.delete()
            //     caches.keys().then(function(names) {
            //       for (let name of names) caches.delete(name);
            //     });
            //   }
            //   // delete browser cache and hard reload
            //   window.location.reload(true);

            dispatch(
              alertActions.versionCheck(
                <span>
                  A newer version of this application is available. For the best experience, we recommend you {" "}
                  <button
                    style={{
                      color: "#8bc34a",
                      backgroundColor: "transparent",
                      padding: "0",
                      border: "none",
                      cursor: "pointer",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      fontSize: "inherit"
                    }}
                    onClick={() => {
                      window.location.reload(true);
                    }}
                  >
                    refresh
                  </button>{" "}
                  the page to update. <br/><br/>If this message persists, please close all tabs and windows of this application and open a new instance.
                </span>,
                null
              )
            );
          } else {
            console.log(`Application instance is running the latest version. No cache refresh needed.`);
          }
        } else {
          console.log("No meta.json file was found. Cannot perform version check");
        }
        } catch (error) {
          console.log({ error });
        }
      };
  
      checkAppVersion();
      clearInterval(checkAppVersionInterval); // clear any previous interval duplicates
      checkAppVersionInterval = setInterval(checkAppVersion, 1000 * 60 * 60);
  
    }, []);
    
  return (
    <div className="App">
      <ThemeProviderV4 theme={themeSetting && themeMap[themeSetting] ? themeMap[themeSetting] : theme}>
        <ThemeProviderV5 theme={themeSetting === "dark" ? themeDarkV5 : themeV5}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CssBaseline />
            <Router>
              <Switch>
                <PrivateRoute path="/dashboard" component={Dashboard} />

                {/* Mobile claim */}

                {/* <Route path="/mobile-claim/:entity/:entityId/:id/start-claim">
                <StartInvoice />
              </Route> */}
                {/* <Route path="/mobile-claim/:entity/:entityId/:id/:mobileClaimID">
                <UpdateInvoice />
              </Route> */}

                <Route path="/mobile-claim/start-claim/">
                  {" "}
                  <StartInvoice />{" "}
                </Route>
                <Route path="/mobile-claim/my-list/:entityID/:mobileClaimID">
                  {" "}
                  <UpdateInvoice />{" "}
                </Route>
                {/* <Route path="/mobile-claim/my-list/">
                <MyInvoices />
              </Route> */}
                <PrivateRouteMobile path="/mobile-claim/my-list/"><MyInvoices /></PrivateRouteMobile>
                {/* <Route path="/mobile-claim/:entity">
                <MobileClaim />
              </Route> */}

                <Route path="/reset-password/:token">
                  <ResetPassword />
                </Route>
                <Route path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route path="/payment">
                  <Payment />
                </Route>
                <Route path="/">
                  <Login />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Router>
            <AlertSnackbar />
            <Box sx={{ position: "fixed", bottom: "5px", left: "10px", zIndex: 99999 }}>
              <Typography variant="caption">v{version}</Typography>
            </Box>
          </MuiPickersUtilsProvider>
        </ThemeProviderV5>
      </ThemeProviderV4>
    </div>
  );
}

export default App;

import { freloReportConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";
let initState = {
  all: [],
  individual: {},
  fields: {},
  loading: false
};
export function reports(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case freloReportConstants.GET_ALL_REPORTS_FRELO_SUCCESS:
      return produce(state, (draft) => {
        draft["all"] = payload;
      });
    case freloReportConstants.GET_ONE_REPORT_FRELO_SUCCESS:
      return produce(state, (draft) => {
        let { reportId, data } = payload;
        draft["fields"] = { [reportId]: data };
      });
    case freloReportConstants.POST_RUN_REPORT_FRELO_REQUEST:
      return produce(state, (draft) => {
        draft["loading"] = true;
      })
    case freloReportConstants.POST_RUN_REPORT_FRELO_SUCCESS:
      return produce(state, (draft) => {
        let { reportId, data, fields } = payload;

        draft["individual"] = { [reportId]: { data, fields } };
        draft["loading"] = false;
      });

    case REHYDRATE: {
      if (payload && payload.frelo && payload.frelo.reports) {
        return { ...payload.frelo.reports };
      } else {
        return { ...initState };
      }
    }
    case freloReportConstants.FRELO_REPORTS_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

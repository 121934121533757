export const adminEntityConstants = {
  GET_CLIENT_INFORMATION: "GET_CLIENT_INFORMATION",
  GET_CLIENT_INFORMATION_SUCCESS: "GET_CLIENT_INFORMATION_SUCCESS",
  GET_ALL_DISPATCH_ENTITIES_REQUEST:"GET_ALL_DISPATCH_ENTITIES_REQUEST",
  GET_ALL_DISPATCH_ENTITIES_SUCCESS:"GET_ALL_DISPATCH_ENTITIES_SUCCESS",
  UPDATE_CLIENT_INFORMATION: "UPDATE_CLIENT_INFORMATION",
  ENTITY_CLEAR: "ENTITY_CLEAR",
  ADD_SOW_CONTRACT_REQUEST: "ADD_SOW_CONTRACT_REQUEST",
  UPDATE_SOW_CONTRACTS_REQUEST: "UPDATE_SOW_CONTRACTS_REQUEST",
  UPDATE_SOW_CONTRACTS_SUCCESS: "UPDATE_SOW_CONTRACTS_SUCCESS",
  GET_FORM_CONTROL_RULES_REQUEST: "GET_FORM_CONTROL_RULES_REQUEST",
  GET_FORM_CONTROL_RULES_SUCCESS: "GET_FORM_CONTROL_RULES_SUCCESS",
  GET_FORM_CONTROL_FIELDS_REQUEST: "GET_FORM_CONTROL_FIELDS_REQUEST",
  GET_FORM_CONTROL_FIELDS_SUCCESS: "GET_FORM_CONTROL_FIELDS_SUCCESS",
  GET_FORMS_REQUEST: "GET_FORMS_REQUEST",
  GET_FORMS_SUCCESS: " GET_FORMS_SUCCESS",
  CLONE_FORM_CONTROL_COLLECTION_REQUEST:
    "CLONE_FORM_CONTROL_COLLECTION_REQUEST",
  CLONE_FORM_CONTROL_COLLECTION_SUCCESS:
    "CLONE_FORM_CONTROL_COLLECTION_SUCCESS",
  UPDATE_FORM_CONTROL_RULES_REQUEST: "UPDATE_FORM_CONTROL_RULES_REQUEST",
  UPDATE_FORM_CONTROL_RULES_SUCCESS: "UPDATE_FORM_CONTROL_RULES_SUCCESS",
  CREATE_FORM_CONTROL_RULES_REQUEST: "CREATE_FORM_CONTROL_RULES_REQUEST",
  CREATE_FORM_CONTROL_RULES_SUCCESS: "CREATE_FORM_CONTROL_RULES_SUCCESS",
  GET_FORM_CONTROL_COLLECTIONS_FORMID_REQUEST:
    "GET_FORM_CONTROL_COLLECTIONS_FORMID_REQUEST",
  GET_FORM_CONTROL_COLLECTIONS_FORMID_SUCCESS:
    "GET_FORM_CONTROL_COLLECTIONS_FORMID_SUCCESS",
  GET_FORM_CONTROL_COLLECTIONS_REQUEST: "GET_FORM_CONTROL_COLLECTIONS_REQUEST",
  GET_FORM_CONTROL_COLLECTIONS_SUCCESS: "GET_FORM_CONTROL_COLLECTIONS_SUCCESS",
  CREATE_FORM_CONTROL_COLLECTION_REQUEST:
    "CREATE_FORM_CONTROL_COLLECTION_REQUEST",
  CREATE_FORM_CONTROL_COLLECTION_SUCCESS:
    "CREATE_FORM_CONTROL_COLLECTION_SUCCESS",
  REMOVE_FORM_CONTROL_COLLECTION_REQUEST:
    "REMOVE_FORM_CONTROL_COLLECTION_REQUEST",
  REMOVE_FORM_CONTROL_COLLECTION_SUCCESS:
    "REMOVE_FORM_CONTROL_COLLECTION_SUCCESS",
  UPDATE_FORM_CONTROL_FIELD_META_REQUEST:
    "UPDATE_FORM_CONTROL_FIELD_META_REQUEST",
  UPDATE_FORM_CONTROL_COLLECTION_REQUEST:
    "UPDATE_FORM_CONTROL_COLLECTION_REQUEST",
  UPDATE_FORM_CONTROL_COLLECTION_SUCCESS:
    "UPDATE_FORM_CONTROL_COLLECTION_SUCCESS",
  REMOVE_FORM_CONTROL_RULE_SET_REQUEST: "REMOVE_FORM_CONTROL_RULE_SET_REQUEST",
  GET_PAYMENT_FORM_REQUEST: "GET_PAYMENT_FORM_REQUEST",
  GET_PAYMENT_FORM_SUCCESS: "GET_PAYMENT_FORM_SUCCESS",
  GET_PAYMENT_FORM_FEES_REQUEST:"GET_PAYMENT_FORM_FEES_REQUEST",
  GET_PAYMENT_FORM_FEES_SUCCESS:"GET_PAYMENT_FORM_FEES_SUCCESS",
  UPDATE_PAYMENT_FORM_FEES_REQUEST:'UPDATE_PAYMENT_FORM_FEES_REQUEST',
  UPDATE_PAYMENT_FORM_FEES_SUCCESS:"UPDATE_PAYMENT_FORM_FEES_SUCCESS",
  UPDATE_PAYMENT_FORM_REQUEST: "UPDATE_PAYMENT_FORM_REQUEST",
  UPDATE_PAYMENT_FORM_SUCCESS: "UPDATE_PAYMENT_FORM_SUCCESS",
};

import { jointClaimsQueueConstants } from "_constants";

const updateClaimsQueue = (queues, roleId) => {
    return {
        type: jointClaimsQueueConstants.GET_CLAIMS_QUEUE_SUCCESS,
        payload: {queues, roleId}
    }
}

const clearClaimsQueue = () => {
    return {
        type: jointClaimsQueueConstants.CLEAR_QUEUE
    }
}


export const jointClaimsQueueActions = {
    updateClaimsQueue,
    clearClaimsQueue
}
export const adminFormControlConstants = {
  GET_FIELDS_REQUEST: "GET_FIELDS_REQUEST",
  GET_FIELDS_SUCCESS: "GET_FIELDS_SUCCESS",

  GET_ENTITY_RULES_REQUEST: "GET_ENTITY_RULES_REQUEST",
  GET_ENTITY_RULES_SUCCESS: "GET_ENTITY_RULES_SUCCESS",

  GET_ROLE_RULES_REQUEST: "GET_ROLE_RULES_REQUEST",
  GET_ROLE_RULES_SUCCESS: "GET_ROLE_RULES_SUCCESS",

  CREATE_AND_UPDATE_FIELD_RULES_REQUEST: "CREATE_AND_UPDATE_FIELD_RULES_REQUEST",
  CREATE_AND_UPDATE_FIELD_RULES_SUCCESS: "CREATE_AND_UPDATE_FIELD_RULES_SUCCESS",

  DELETE_ENTITY_RULES_REQUEST: "DELETE_ENTITY_RULES_REQUEST",
  DELETE_ENTITY_RULES_SUCCESS: "DELETE_ENTITY_RULES_SUCCESS",

  DELETE_ROLE_RULES_REQUEST: "DELETE_ROLE_RULES_REQUEST",
  DELETE_ROLE_RULES_SUCCESS: "DELETE_ROLE_RULES_SUCCESS",

  ADMIN_FORMCONTROL_CLEAR: "ADMIN_FORMCONTROL_CLEAR",
};

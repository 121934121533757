import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@material-ui/core/styles";
import { AcceptHosted } from "react-acceptjs";
import { useSelector } from "react-redux";
import { ObjectTools } from "_utility";
import moment from "moment";
import { TextField } from "@material-ui/core";
import { publicService } from "_services";
import Tooltip from "@material-ui/core/Tooltip";
import zIndex from "@material-ui/core/styles/zIndex";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "-100px",
    padding: "40px",
  },
  formHidden: {
    display: "none",
  },
  button: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "1.75",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#8bc34a",
    minWidth: "64px",
    width: "100%",
    padding: "6px 16px",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  btn: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "0.8rem",
    lineHeight: "1.75",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    color: "#424242 !important",
    backgroundColor: theme.palette.GreenPrg.main + " !important",
    padding: "6px 16px",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  input: {
    marginLeft: "1rem",
  },
  iframeContainer: {
    position: "relative",
    height: "900px", // Increase height to accommodate full form
  },
}));

export default function MakePaymentForm({ lobid, isRestricted, isEmpty }) {
  const classes = useStyles();
  const { getNestedPropertySafe } = ObjectTools;

  const [anchorNeedHelp, setAnchorNeedHelp] = useState();
  const [open, setOpen] = useState(null);
  const handleClick = (event, id) => {
    setAnchorNeedHelp(event.currentTarget);
    setOpen(id);
  };
  const [isSmallViewport, setIsSmallViewport] = useState(false);

  const handleClose = () => {
    setAnchorNeedHelp(null);
    setOpen(null);
  };

  useEffect(() => {
    const checkViewportHeight = () => {
      setIsSmallViewport(window.innerHeight < 1000 && window.innerWidth < 600);
    };

    checkViewportHeight();

    window.addEventListener("resize", checkViewportHeight);

    return () => {
      window.removeEventListener("resize", checkViewportHeight);
    };
  }, []);

  // const open = Boolean(anchorNeedHelp);
  const id = open ? "simple-popover" : undefined;

  const payment = useSelector((state) => getNestedPropertySafe(state, ["payment", "info"]));
  const token = useSelector((state) => getNestedPropertySafe(state, ["payment", "token"]));
  const {
    claimID,
    RespName2,
    OutDate,
    LocAddress,
    LocCity,
    LocState,
    LocZip,
    addressUpdate,
    updatedAddress,
    updatedCity,
    updatedState,
    updatedZip,
    CreditCardPayAmount,
    RAEmail,
    Entity,
    SuperRegionID,
  } = payment || {};

  const [paid, setPaid] = useState(false);
  const [response, setResponse] = useState();

  const printReceipt = () => {
    const w = window.open();
    if (!w) {
      window.alert("Please allow popups for this website in order to print your receipt.");
      return;
    }
    w.document.write(document.getElementById("receipt-print").innerHTML);
    w.document.title = "OPD Payment Receipt";
    w.print();
    w.close();
  };

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [pendingEmail, setPendingEmail] = useState(false);
  const [showEmailResponse, setShowEmailResponse] = useState(false);
  const [emailResponse, setEmailResponse] = useState({ message: "", type: "" });

  const handleEmail = (e) => {
    setEmail(e);
  };

  const emailReceipt = () => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError(true);
      return;
    }
    setError(false);
    setPendingEmail(true);
    publicService.emailPaymentReceipt(email, response, (data) => {
      setPendingEmail(false);
      setEmail("");
      setEmailResponse(data);
      setShowEmailResponse(true);
      setTimeout(() => {
        setShowEmailResponse(false);
        setEmailResponse("");
      }, 5000);
    });
  };

  useEffect(() => {
    if (paid && response) {
      const payload = {
        entity: lobid === 2 && SuperRegionID === 4 ? "Piedmont Gas" : Entity,
        email: RAEmail,
        receipt: response,
      };
      publicService.emailRecoveryAgent(payload);
    }
  }, [paid, response]);

  return (
    <Box className={!isRestricted && !isEmpty ? classes.form : classes.formHidden}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h5">Outside Plant Damage (OPD) Recovery Services</Typography>
        <Typography variant="h5">PAYMENT CENTER</Typography>
      </Box>

      <Divider sx={{ margin: "20px 0 30px" }} />

      {payment && !paid ? (
        <Grid container spacing={{ xs: 4, md: 12 }}>
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Typography variant="button">Claim Details</Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2">Claim Invoice No.</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{claimID}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Responsible Party</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{RespName2}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Date of Damage</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{moment(OutDate).format("MM/DD/YYYY")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Location of Damage</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{addressUpdate ? updatedAddress : LocAddress}</Typography>
                  <Typography variant="body2">
                    {addressUpdate ? updatedCity : LocCity}, {addressUpdate ? updatedState : LocState}{" "}
                    {addressUpdate ? updatedZip : LocZip}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Amount to be paid
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {formatter.format(CreditCardPayAmount)}
                  </Typography>
                </Grid>
              </Grid>

              <img alt="logo" className={classes.image} src="/images/cardlogos.jpg" />
              {/* <Typography variant="caption">Need help? Click here</Typography> */}
              <Button
                aria-describedby={id}
                variant="text"
                onClick={(event) => handleClick(event, "need-help")}
                sx={{ width: "fit-content" }}
              >
                Need help?
              </Button>
              <Popover
                id={id}
                open={open === "need-help"}
                anchorNeedHelp={anchorNeedHelp}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box sx={{ p: 4 }}>
                  <Stack spacing={2}>
                    <Typography variant="button">HELP WITH YOUR OPD PAYMENT</Typography>
                    <Typography variant="body1">
                      Your PRG Recovery Agent can assist you with making your claim payment.
                    </Typography>
                    <Typography variant="body2">
                      <a href="mailto:jlosch@PRGconsulting.net">Jay Losch</a> - (720) 795-9960
                    </Typography>
                    <Typography variant="body2">
                      <a href="mailto:rking@prgconsulting.net">Rochelle King</a> - (720) 795-9971
                    </Typography>
                  </Stack>
                </Box>
              </Popover>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={8} sx={{ textAlign: "center", padding: "30px", borderRadius: "4px" }}>
              <Typography variant="button" sx={{ marginBottom: "20px", paddingBottom: "20px" }}>
                OPD CLAIM PAYMENT {process.env.REACT_APP_ENV !== "prod" && "(TESTING)"}
              </Typography>
              {token && !paid ? (
                <AcceptHosted
                  formToken={token}
                  environment={process.env.REACT_APP_ENV === "prod" ? "PRODUCTION" : "SANDBOX"}
                  integration="iframe"
                  onTransactionResponse={(response) => {
                    if (response.responseCode === "1") {
                      setResponse(response);
                      setPaid(true);
                    }
                    console.log("transaction");
                  }}
                  onCancel={() => console.log("canceled")}
                  onSuccessfulSave={() => console.log("saved")}
                >
                  <AcceptHosted.Button className={classes.button}>
                    Pay {formatter.format(payment.CreditCardPayAmount)}
                  </AcceptHosted.Button>
                  <AcceptHosted.IFrameBackdrop />
                  {isSmallViewport ? (
                    <Box className={classes.iframeContainer}>
                      <AcceptHosted.IFrameContainer className={classes.iframeContainer}>
                        <AcceptHosted.IFrame />
                      </AcceptHosted.IFrameContainer>
                    </Box>
                  ) : (
                    <AcceptHosted.IFrameContainer>
                      <AcceptHosted.IFrame />
                    </AcceptHosted.IFrameContainer>
                  )}
                </AcceptHosted>
              ) : (
                <Typography sx={{ color: "red", fontSize: "12px", marginTop: "10px" }}>
                  No amount due at this time
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      ) : paid ? (
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">
              Your payment has been processed. Please print a copy of your receipt for your records. Please exit out of
              this page when you are finished. Thank you.
            </Typography>
            <Button className={classes.btn} onClick={() => printReceipt()}>
              Print Receipt
            </Button>
            <br />
            <br />
            <Button className={classes.btn} disabled={pendingEmail} onClick={() => emailReceipt()}>
              {pendingEmail ? "Sending..." : "Email Receipt"}
            </Button>
            <Tooltip
              open={showEmailResponse}
              title={<h1 style={{ color: emailResponse.type }}>{emailResponse.message}</h1>}
              placement="right"
            >
              <TextField
                className={classes.input}
                placeholder="Enter Email Address"
                onChange={(e) => handleEmail(e.target.value)}
                helperText={error ? "Please enter a valid email address" : ""}
                type="email"
                error={error}
              ></TextField>
            </Tooltip>
            <Divider sx={{ margin: "20px 0" }} />

            <Stack spacing={2} id="receipt-print">
              <Typography variant="body2">
                <span style={{ fontWeight: 700 }}>Transaction ID:</span> {response.transId}
              </Typography>
              <Typography variant="body2">
                <span style={{ fontWeight: 700 }}>Account:</span> {`${response.accountType} ${response.accountNumber}`}
              </Typography>
              <Typography variant="body2">
                <span style={{ fontWeight: 700 }}>Amount:</span> {formatter.format(response.totalAmount)}
              </Typography>
              <Divider sx={{ margin: "20px 0" }} />
              <Typography variant="body2">
                <span style={{ fontWeight: 700 }}>Claim Invoice #:</span> {response.orderInvoiceNumber}
              </Typography>
              <Typography variant="body2">
                <span style={{ fontWeight: 700 }}>Date:</span> {moment(response.dateTime).format("MM/DD/YYYY")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Typography sx={{ textAlign: "center", marginBottom: "40px", color: "red" }}>No claim found</Typography>
      )}

      <Stack sx={{ textAlign: "center", alignItems: "center", mt: "20px" }}>
        <Typography variant="caption">
          This website is secured with SSL Encryption. The safe processing of your claim is our top priority.
        </Typography>
        <Button
          variant="text"
          onClick={(event) => handleClick(event, "privacy-statement")}
          sx={{ width: "fit-content" }}
        >
          Privacy Statement
        </Button>
        <Popover
          id={id}
          open={open === "privacy-statement"}
          anchorNeedHelp={anchorNeedHelp}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 4 }}>
            <Stack spacing={2}>
              <Typography variant="button">PRIVACY STATEMENT</Typography>
              <Typography variant="body2">
                This privacy policy discloses the privacy practices for https://app.prgconsulting.net. This privacy
                policy applies solely to information collected by this web site.
              </Typography>
              <Typography variant="button">Security</Typography>
              <Typography variant="body2">
                PRG takes precautions to protect your information. When you submit sensitive information via the
                website, your information is protected both online and offline. Wherever you are asked for sensitive
                information (such as credit card data), that information is encrypted and transmitted to a PCI Compliant
                third-party processor in a secure way. You can verify this by looking for a closed lock icon in the
                address section of the website, or looking for "https" at the beginning of the address of the web page.
                Each page within this site also links to the Secure Certificate which users can validate.
              </Typography>
              <Typography variant="body2">
                While PRG uses encryption to protect sensitive information transmitted online, we also protect your
                information offline. PRG never collects or stores your credit card number, expiration date or type of
                card used. PRG does collect the transaction authorization number, which will allow us to reference the
                transaction with the third-party processor should a need arise.
              </Typography>
            </Stack>
          </Box>
        </Popover>
        <Typography variant="caption">©Project Resources Group, 2024.</Typography>
      </Stack>
    </Box>
  );
}

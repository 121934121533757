import { freloProjectConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";
import { normalize } from "normalizr";
import { freloProjectSchema } from "_schema";
let { allProjectsSchema, myProjectsSchema } = freloProjectSchema;

let initState = {
  all: {},
  my: {},
  individual: {
    loading: false,
  },
  create: {},
  attachments: {},
  history: {},
  schema: {
    entityOptions: [],
    rdaOptions: {},
    filterOptions: [],
    filterSets: [],
  },
};

export function project(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case freloProjectConstants.GET_ALL_PROJECTS_FRELO_SUCCESS:
      return produce(state, (draft) => {
        const normalizedData = normalize(payload, allProjectsSchema);
        draft["all"] = normalizedData;
      });
    case freloProjectConstants.GET_MY_PROJECTS_FRELO_SUCCESS:
      return produce(state, (draft) => {
        const normalizedData = normalize(payload, myProjectsSchema);
        draft["my"] = normalizedData;
      });
      case freloProjectConstants.GET_CLAIM_BY_ID_FRELO_LOADING_TRUE:
        return {
          ...state,
          individual: {
            loading: true,
          },
        };
    case freloProjectConstants.GET_PROJECT_BY_ID_FRELO_SUCCESS:
      // Note
      // we want to only store one claim at a time, since claims are 200kb each.
      // that can get expensive rather quickly -> regarding storage
      let { projectId, project } = payload;
      return {
        ...state,
        individual: {
          [projectId]: { ...project },
          loading: false,
        },
      };
    case freloProjectConstants.GET_SCHEMA_ENTITY_OPTIONS_FRELO_SUCCESS:
      return produce(state, (draft) => {
        let { options } = payload;
        draft["schema"]["entityOptions"] = [...options];
      });
    case freloProjectConstants.GET_CREATE_PROJECT_FIELD_RULES_FRELO_SUCCESS:
      return produce(state, (draft) => {
        let { rules, entityId } = payload;
        draft["create"][entityId] = { ...rules };
      });
    case freloProjectConstants.GET_SCHEMA_RDA_OPTIONS_FRELO_SUCCESS:
      return produce(state, (draft) => {
        let { options, entityId } = payload;
        draft["schema"]["rdaOptions"][entityId] = [...options];
      });
    case freloProjectConstants.GET_SCHEMA_FILTER_OPTIONS_FRELO_SUCCESS:
      return produce(state, (draft) => {
        draft["schema"]["filterOptions"] = [...payload];
      });
    case freloProjectConstants.GET_FILTER_SETS_FRELO_SUCCESS:
      return produce(state, (draft) => {
        draft["schema"]["filterSets"] = [...payload];
      });
    case freloProjectConstants.GET_CLAIM_ATTACHMENTS_FRELO_SUCCESS:
      return produce(state, (draft) => {
        let { claimId, files } = payload;
        draft["attachments"][claimId] = [...files];
      });
    case freloProjectConstants.GET_PROJECT_HISTORY_FRELO_SUCCESS:
      return produce(state, (draft) => {
        let { data, projectId } = payload;
        draft["history"][projectId] = [...data];
      });
    case REHYDRATE: {
      if (payload && payload.frelo && payload.frelo.project) {
        return { ...payload.frelo.project };
      } else {
        return { ...initState };
      }
    }
    case freloProjectConstants.FRELO_PROJECT_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

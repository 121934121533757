import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  sectionWrap: {
     position: "relative",
    padding: "0 10px",
    zIndex: 5
  },
  save: {
    width: "100px",
    color: theme.palette.WhitePrg.main,
    backgroundColor: theme.palette.GreenPrg.main,
    border: `solid thin ${theme.palette.GreenPrg.main}`
  },
  btn: {
    textAlign:'right'
  },
}));

const SaveButton = ({ isDirty, handleSubmit }) => {
  const classes = useStyles();

  if (!isDirty) {
    return null;
  }

  return (
    <div className={classes.sectionWrap}>
      <div className={classes.btn}>
        <Button  variant="outlined" className={classes.save} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default SaveButton;
import { authConstants } from "_constants";

const login = (user) => {
  return {
    type: authConstants.LOGIN_REQUEST,
    user,
  };
};

const logout = () => {
  return {
    type: authConstants.LOGOUT,
  };
};

const assumeUser = (payload,currentUser) => {
  return {
    type: authConstants.ASSUME_USER_REQUEST,
    payload,
    currentUser
  };
};

const assumeRole = (payload,assumerole,currentRole,username,user) => {
  return {
    type: authConstants.ASSUME_ROLE_REQUEST,
    payload,
    assumerole,
    currentRole,
    username,
    user
  };
};

const endImpersonation = () => {
  return {
    type: authConstants.IMPERSONATION_END_REQUEST,
  };
};

export const userActions = {
  login,
  logout,
  assumeUser,
  assumeRole,
  endImpersonation
};

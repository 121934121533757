import moment from "moment";

export const toSqlDateString = (date) => {
  if (date instanceof Date) {
    return new Date(date).toISOString().slice(0, 19).replace("T", " ");
  } else {
    return "";
  }
};

export const formatDate = (date,lob) => {
  if (!date) return "";
  let newDate = new Date(date);
  return lob && lob === 'TEL' ? moment.utc(newDate).add(1, 'hours').format('MM/DD/YYYY h:mm a') :moment.utc(newDate).format('MM/DD/YYYY h:mm a')
};

export const formatDateNoTime = (date) => {
  if (!date) return "";
  let newDate = new Date(date);
  return moment.utc(newDate).format('MM/DD/YYYY');
};

export const formatDateMoment = (date, format = "MM/DD/YYYY") => {
  if (!date) return "";
  let newDate = new Date(date);
  return moment.utc(newDate).format(format);
};

export const formatTime = (date, format = "h:mm a") => {
  if (!date) return "";
  let newDate = new Date(date);
  return moment.utc(newDate).format(format);
};


export const formatSqlDate = (date) => {
  if (!date) return "";
  let newDate = date.substr(0, 10) + "T" + date.substr(11, 8);

  return moment(newDate).calendar();
};

export const formatSqlDateTime = (date) => {
  if (!date) return "";
  let newDate = date.substr(0, 10) + "T" + date.substr(11, 8);

  return moment(newDate).format();
};

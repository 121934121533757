import { put, call } from "redux-saga/effects";

import _getProperty from "lodash/get";
import { jointLedgerService } from "_services";
import { alertConstants, jointLedgerConstants } from "_constants";



export function* getLedgers({lob, claimId}) {
    try {
       const response = yield call(jointLedgerService.getLedgers, lob, claimId);

       yield put({
        type: jointLedgerConstants.GET_LEDGERS_SUCCESS,
        payload: {lob, ledgers: response},
      });

    } catch (error) {
        yield put({
            type: alertConstants.ERROR,
            payload: { message: "Failed to get ledgers" },
          });
    }

}
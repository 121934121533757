import { adminJobCostingConstants } from "_constants";

const getRates = (payload) => {
  return {
    type: adminJobCostingConstants.GET_RATES_REQUEST,
    payload,
  };
};

const addRate = (payload) => {
  return {
    type: adminJobCostingConstants.ADD_RATE_REQUEST,
    payload,
  };
};

const updateRate = (payload) => {
  return {
    type: adminJobCostingConstants.UPDATE_RATE_REQUEST,
    payload,
  };
};

const deleteRate = (payload) => {
  return {
    type: adminJobCostingConstants.DELETE_RATE_REQUEST,
    payload,
  };
};

export const adminJobCostingActions = {
  getRates,
  addRate,
  updateRate,
  deleteRate,
};

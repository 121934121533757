import { adminUserConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";

let initState = {
  all: [],
  roles: [],
  users: [],
  general: {},
  dbAccess: {},
  entities: {},
  userTypes: [],
  actionAccess: {},
  actionAccessLoading: {
    GET: false,
    PUT: false,
    DELETE: false
  },
  statusAccess: {},
  statusAccessLoading: {
    GET: false,
    PUT: false,
    DELETE: false
  },
  damageInvestigator: {},
  damageInvestigatorLoading: {
    GET: false,
    PUT: false,
    DELETE: false
  },
  notifications: {},
  notificationsLoading: {
    GET: false,
    PUT: false,
    DELETE: false
  }
};

export function users(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminUserConstants.ADMIN_USERS_GET_ALL_SUCCESS:
      return produce(state, (draft) => {
        draft["all"] = [...payload];
      });
    case adminUserConstants.ADMIN_USERS_GET_ROLES_SUCCESS:
      return produce(state, (draft) => {
        draft["roles"] = [...payload];
      });
    case adminUserConstants.ADMIN_PRG_USERS_GET_SUCCESS:
      return produce(state, (draft) => {
        draft["users"] = [...payload];
      });
    case adminUserConstants.ADMIN_USERS_GET_GENERAL_SUCCESS:
      return produce(state, (draft) => {
        let { data, userId } = payload;

        draft["general"][userId] = { ...data };
      });
    case adminUserConstants.ADMIN_USERS_GET_DB_ACCESS_SUCCESS:
      return produce(state, (draft) => {
        let { data, userId } = payload;
        draft["dbAccess"][userId] = { ...data };
      });
    case adminUserConstants.ADMIN_USERS_GET_ENTITIES_ACCESS_SUCCESS:
      return produce(state, (draft) => {
        let { data, userId } = payload;
        draft["entities"][userId] = [...data];
      });
    case adminUserConstants.ADMIN_USERS_GET_USER_TYPES_SUCCESS:
      return produce(state, (draft) => {
        draft["userTypes"] = [...payload];
      });
    case adminUserConstants.ADMIN_USERS_GET_ACTION_ACCESS_SUCCESS:
      return produce(state, (draft) => {
        let { userId, data } = payload;
        const obj = {};
        data.actionAccessUserData.forEach(e => {
          obj[e.accessTypeLOB] = e
        })
        draft["actionAccess"][userId] = { ...obj };
        draft["actionAccessLoading"]["GET"] = false;
        draft["actionAccessLoading"]["PUT"] = false;
      });
    case adminUserConstants.ADMIN_USERS_GET_ACTION_ACCESS_LOADING:
      return produce(state, (draft) => {
        draft["actionAccessLoading"]["GET"] = payload;
      });
    case adminUserConstants.ADMIN_USERS_UPDATE_ACTION_ACCESS_LOADING:
      return produce(state, (draft) => {
        draft["actionAccessLoading"]["PUT"] = payload;
      });
    case adminUserConstants.ADMIN_USERS_DELETE_ACTION_ACCESS_LOADING:
      return produce(state, (draft) => {
        draft["actionAccessLoading"]["DELETE"] = payload;
      });
    case adminUserConstants.ADMIN_USERS_GET_STATUS_ACCESS_SUCCESS:
      return produce(state, (draft) => {
        let { userId, data } = payload;
        const statusAccessDataFormatted = data.statusAccessUserData.reduce((prev, el) => {
          return {
            ...prev,
            [el.LOBID]: {
              ...prev[el.LOBID],
              [el.statusType]: {
                ...prev[el.LOBID][el.statusType],
                [el.statusId]: el
              }
            }
          }
        }, {
          // designate default values here where 1/2/3 are LOBIDs (TEL, P&G, FRELO)
          // within each LOBID are statusTypes (1 claimStatus or 2 paymentStatus)
          1: { 1: {}, 2: {} },
          2: { 1: {}, 2: {} },
          3: { 1: {}, 2: {} }
        })

        draft["statusAccess"][userId] = { ...statusAccessDataFormatted };
        draft["statusAccessLoading"]["GET"] = false;
        draft["statusAccessLoading"]["PUT"] = false;
      });
    case adminUserConstants.ADMIN_USERS_GET_STATUS_ACCESS_LOADING:
      return produce(state, (draft) => {
        draft["statusAccessLoading"]["GET"] = payload;
      });
    case adminUserConstants.ADMIN_USERS_UPDATE_STATUS_ACCESS_LOADING:
      return produce(state, (draft) => {
        draft["statusAccessLoading"]["PUT"] = payload;
      });
    case adminUserConstants.ADMIN_USERS_DELETE_STATUS_ACCESS_LOADING:
      return produce(state, (draft) => {
        draft["statusAccessLoading"]["DELETE"] = payload;
      });

    case adminUserConstants.ADMIN_USERS_DAMAGE_INVESTIGATOR_LOADING:
      return produce(state, (draft) => {
        draft["damageInvestigatorLoading"]["GET"] = true;
      });
    case adminUserConstants.ADMIN_USERS_DAMAGE_INVESTIGATOR_SUCCESS:
      return produce(state, (draft) => {
        draft["damageInvestigator"] = payload;
        draft["damageInvestigatorLoading"]["GET"] = false;
        draft["damageInvestigatorLoading"]["PUT"] = false;
        draft["damageInvestigatorLoading"]["DELETE"] = false;
      });
    case adminUserConstants.ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_LOADING:
      return produce(state, (draft) => {
        draft["damageInvestigatorLoading"]["DELETE"] = true;
      });
    case adminUserConstants.ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_SUCCESS:
      return produce(state, (draft) => {
        draft["damageInvestigator"] = {...state.damageInvestigator, states: [], superRegion: null, isDamageInvestigator: false};
        draft["damageInvestigatorLoading"]["DELETE"] = false;
      });
    case adminUserConstants.ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_LOADING:
      return produce(state, (draft) => {
        draft["damageInvestigatorLoading"]["PUT"] = true;
      });
    case adminUserConstants.ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_SUCCESS:
      return produce(state, (draft) => {
        draft["damageInvestigatorLoading"]["PUT"] = false;
      });


    case adminUserConstants.ADMIN_USERS_NOTIFICATIONS_LOADING:
      return produce(state, (draft) => {
        draft["notificationsLoading"]["GET"] = true;
      });
    case adminUserConstants.ADMIN_USERS_NOTIFICATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft["notifications"] = payload;
        draft["notificationsLoading"]["GET"] = false;
        draft["notificationsLoading"]["PUT"] = false;
        draft["notificationsLoading"]["DELETE"] = false;
      });
    case adminUserConstants.ADMIN_USERS_DELETE_NOTIFICATIONS_LOADING:
      return produce(state, (draft) => {
        draft["notificationsLoading"]["DELETE"] = true;
      });
    case adminUserConstants.ADMIN_USERS_DELETE_NOTIFICATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft["notifications"] = {...state.notifications, notifications: state.notifications.notifications.map(n => {return {...n, access: false}})};
        draft["notificationsLoading"]["DELETE"] = false;
      });
    case adminUserConstants.ADMIN_USERS_UPDATE_NOTIFICATIONS_LOADING:
      return produce(state, (draft) => {
        draft["notificationsLoading"]["PUT"] = true;
      });
    case adminUserConstants.ADMIN_USERS_UPDATE_NOTIFICATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft["notificationsLoading"]["PUT"] = false;
      });


    case REHYDRATE: {
      if (payload && payload.admin && payload.admin.users) {
        return { ...payload.admin.users };
      } else {
        return { ...initState };
      }
    }
    case adminUserConstants.ADMIN_USERS_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

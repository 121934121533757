import { put, call, all } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  adminRoundRobinConstants,
} from "_constants";
import { adminRoundRobinService } from "_services";

export function* getAllRoundRobinAdminSaga() {
  try {
    const result = yield call(adminRoundRobinService.getAll);
    let { response } = result;

    yield put({
      type: adminRoundRobinConstants.ADMIN_GET_ALL_ROUNDROBIN_SUCCESS,
      payload: response,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getOneRoundRobinAdminSaga({ id }) {
  try {
    yield put({
      type:
        adminRoundRobinConstants.SET_ADMIN_INDIVIDUAL_ROUNDROBIN_LOADING_TRUE,
    });
    const result = yield call(adminRoundRobinService.getOne, id);
    let { response } = result;

    yield put({
      type: adminRoundRobinConstants.ADMIN_GET_ONE_ROUNDROBIN_SUCCESS,
      payload: {
        id,
        data: { ...response },
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getLocateSchemaRoundRobinAdminSaga() {
  try {
    const result = yield call(adminRoundRobinService.getLocateSchema);
    let { response } = result;

    yield put({
      type: adminRoundRobinConstants.ADMIN_GET_LOCATE_SCHEMA_ROUNDROBIN_SUCCESS,
      payload: response,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getEntitiesSchemaRoundRobinAdminSaga() {
  try {
    const result = yield call(adminRoundRobinService.getEntitySchema);
    let { response } = result;

    yield put({
      type:
        adminRoundRobinConstants.ADMIN_GET_ENTITIES_SCHEMA_ROUNDROBIN_SUCCESS,
      payload: response,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateAgentRoundRobinAdminSaga({ id, payload }) {
  try {
    yield put({
      type:
        adminRoundRobinConstants.SET_ADMIN_INDIVIDUAL_ROUNDROBIN_LOADING_TRUE,
    });
    yield call(adminRoundRobinService.updateAgent, id, payload);

    const resultAll = yield call(adminRoundRobinService.getAll);
    let { response: responseAll } = resultAll;

    yield put({
      type: adminRoundRobinConstants.ADMIN_GET_ALL_ROUNDROBIN_SUCCESS,
      payload: responseAll,
    });

    const result = yield call(adminRoundRobinService.getOne, id);
    let { response } = result;

    yield put({
      type: adminRoundRobinConstants.ADMIN_GET_ONE_ROUNDROBIN_SUCCESS,
      payload: {
        id,
        data: { ...response },
      },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Agent Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateAgentStatusRoundRobinAdminSaga({ agents }) {
  try {
    yield put({
      type: adminRoundRobinConstants.SET_ADMIN_INACTIVE_ROUNDROBIN_LOADING_TRUE,
    });
    yield all(
      agents.map((agent) => call(adminRoundRobinService.updateStatus, agent))
    );

    const result = yield call(adminRoundRobinService.getAll);
    let { response } = result;

    yield put({
      type: adminRoundRobinConstants.ADMIN_GET_ALL_ROUNDROBIN_SUCCESS,
      payload: response,
    });
    yield put({
      type:
        adminRoundRobinConstants.SET_ADMIN_INACTIVE_ROUNDROBIN_LOADING_FALSE,
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Agents Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

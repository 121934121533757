import { powerGasDispatchConstants } from "_constants";

/**
 * @deprecated
 */
const getAll = () => {
  return {
    type: powerGasDispatchConstants.GET_ALL_DISPATCH_POWER_GAS_REQUEST,
  };
};

const getOne = (claimId) => {
  return {
    type: powerGasDispatchConstants.GET_ONE_DISPATCH_POWER_GAS_REQUEST,
    claimId,
  };
};

const updateOne = (data, claimId, callback) => {
  return {
    type: powerGasDispatchConstants.UPDATE_ONE_DISPATCH_POWER_GAS_REQUEST,
    data,
    claimId,
    callback
  };
};

const updateTenEight = (claimId) => {
  return {
    type: powerGasDispatchConstants.UPDATE_TEN_EIGHT_DISPATCH_POWER_GAS_REQUEST,
    claimId,
  };
};

//Disptach Stopwatch
const updateDispatchTimer = (claims) => {
  return {
    type: powerGasDispatchConstants.UPDATE_DISPATCH_POWER_GAS_TIMER_REQUEST,
    claims
  }
}
const updateDispatchTimerPause = (payload) => {
  return {
    type: powerGasDispatchConstants.UPDATE_DISPATCH_POWER_GAS_TIMER_PAUSE_REQUEST,
    payload
  }
}
const updateDispatchTimerColor = (payload) => {
  return {
    type: powerGasDispatchConstants.UPDATE_DISPATCH_POWER_GAS_TIMER_COLOR_REQUEST,
    payload
  }
}

const updateDispatches = (claims) => {
  return {
    type: powerGasDispatchConstants.GET_ALL_DISPATCH_POWER_GAS_SUCCESS,
    payload: claims
  }
}


export const powerGasDispatchActions = {
  getAll,
  getOne,
  updateOne,
  updateTenEight,
  updateDispatchTimer,
  updateDispatchTimerPause,
  updateDispatchTimerColor,
  updateDispatches
};

import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const getLedgers = async (lob, claimId) => {
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    };

    let apiBase = getApiBase();
    // let apiBase = "http://localhost:3000/api"; // local host testing
    // was using v2 for testing of new filters

    const LOBID = lob === "Telecom" ? 1 : lob === "P&G" ? 2 : 3;
    let jwtCookie = "";
    if (process.env.NODE_ENV !== "production") {
        jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
    }

    try {
        const response = await fetch(`${apiBase}/joint/ledger?lobid=${LOBID}&claimid=${claimId}${jwtCookie}`, parameters);
        if (response.status === 403) {
            throw new Error("Invalid Auth Token");
        }
        if (response.status !== 200) {
            throw new Error("error fetching ledgers");
        }
        return await response.json();
    } catch (err) {
        throw err;
    }
};


export const jointLedgerService = {
    getLedgers,
};

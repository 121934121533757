import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import { NavBar } from "./components/NavBar";
import NestedList from "./components/NestedList";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Route } from "react-router-dom";
import Routes from "./_routes";
import Scoreboard from "./_views/Scoreboard";
// import { LicenseInfo } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_XGRID);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiDrawer-paper": {
      backgroundColor: `#505050 !important`,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: 56,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "85vh",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    padding: "20px 0 20px",
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
}));

export function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const toggleDrawerOpen = (value) => {
    if (value === "close") {
      setOpen(false);
    } else {
    setOpen(!open);
    }
  };

  const openDrawer = () => {
    setOpen(true);
  };

  let handleBOneReportModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <NavWrapper className={classes.root}>
      {window.location !== window.parent.location ? (
        ""
      ) : (
        <>
          <NavBar
            openDrawer={openDrawer}
            toggleDrawer={toggleDrawerOpen}
            open={open}
          />

          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
            // open={open}
          >
            <NestedList
              collapse={open}
              openDrawer={openDrawer}
              toggleDrawer={toggleDrawerOpen}
              handleBOneReportModal={handleBOneReportModal}
            />
          </Drawer>
        </>
      )}
      <main className={classes.content}>
        <Scoreboard openModal={openModal} setOpenModal={setOpenModal} />
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <Route component={Routes} />
            </Grid>
          </Grid>
        </Container>
      </main>
    </NavWrapper>
  );
}

const NavWrapper = styled.div``;

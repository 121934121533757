import { put, call, all } from "redux-saga/effects";
import { buildAlertMessage } from "_utility";
import { alertConstants, authConstants } from "_constants";
import { freloProjectService } from "_services";
import { freloProjectConstants } from "_constants";
import { isEmpty } from "ramda";

export function* getAllProjectsFreloSaga({
  page,
  rowsPerPage,
  filterModal,
  search,
  restrictedStatusIds
}) {
  try {
    let filterParams = "";

    const response = yield call(
      freloProjectService.getAllProjects,
      page,
      rowsPerPage,
      filterParams,
      search,
      restrictedStatusIds
    );

    yield put({
      type: freloProjectConstants.GET_ALL_PROJECTS_FRELO_SUCCESS,
      payload: {
        page,
        projects: [...response],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getMyProjectsFreloSaga({
  page,
  rowsPerPage,
  filterModal,
  search,
}) {
  try {
    let filterParams = "";

    const response = yield call(
      freloProjectService.getMyProjects,
      page,
      rowsPerPage,
      filterParams,
      search
    );

    yield put({
      type: freloProjectConstants.GET_MY_PROJECTS_FRELO_SUCCESS,
      payload: {
        page,
        projects: [...response],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getProjectByIdFreloSaga({ projectId, restrictedStatusIds }) {
  try {
    yield put({
      type: freloProjectConstants.GET_CLAIM_BY_ID_FRELO_LOADING_TRUE,
    });

    const response = yield call(freloProjectService.getProjectById, projectId, restrictedStatusIds);
    yield put({
      type: freloProjectConstants.GET_PROJECT_BY_ID_FRELO_SUCCESS,
      payload: {
        projectId,
        project: { ...response },
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateFreloProjectSaga({ projectId, payload }) {
  try {
    const response = yield call(
      freloProjectService.updateFreloProject,
      projectId,
      payload
    );

    if (response["status"] !== 200) {
      throw new Error("Error updating project");
    }

    const responseFetchUpdatedProject = yield call(
      freloProjectService.getProjectById,
      projectId
    );

    yield put({
      type: freloProjectConstants.GET_PROJECT_BY_ID_FRELO_SUCCESS,
      payload: {
        projectId,
        project: { ...responseFetchUpdatedProject },
      },
    });

    const responseHistory = yield call(
      freloProjectService.getProjectHistory,
      projectId
    );

    yield put({
      type: freloProjectConstants.GET_PROJECT_HISTORY_FRELO_SUCCESS,
      payload: { data: [...responseHistory], projectId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "project updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSchemaEntityOptionsFreloSaga() {
  try {
    const response = yield call(freloProjectService.getSchemaEntityOptions);

    if (!Array.isArray(response)) {
      throw new Error("Error fetching entity options");
    }
    yield put({
      type: freloProjectConstants.GET_SCHEMA_ENTITY_OPTIONS_FRELO_SUCCESS,
      payload: { options: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSchemaRdaOptionsFreloSaga({ entityId }) {
  try {
    const response = yield call(
      freloProjectService.getSchemaRdaOptions,
      entityId
    );

    if (!Array.isArray(response)) {
      throw new Error("Error fetching rda options");
    }
    yield put({
      type: freloProjectConstants.GET_SCHEMA_RDA_OPTIONS_FRELO_SUCCESS,
      payload: { entityId, options: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getCreateProjectFieldRulesFreloSaga({ entityId }) {
  try {
    const response = yield call(
      freloProjectService.getCreateProjectFieldRules,
      entityId
    );

    if (!response || isEmpty(response)) {
      throw new Error("Error fetching field meta data");
    }
    yield put({
      type: freloProjectConstants.GET_CREATE_PROJECT_FIELD_RULES_FRELO_SUCCESS,
      payload: { entityId, rules: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSchemaFilterConditionsFreloSaga() {
  try {
    const response = yield call(freloProjectService.getSchemaFilterConditions);

    if (isEmpty(response) || !response) {
      throw new Error("Error fetching filter options");
    }
    yield put({
      type: freloProjectConstants.GET_SCHEMA_FILTER_OPTIONS_FRELO_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getFilterSetsFreloSaga() {
  try {
    const response = yield call(freloProjectService.getFilterSets);

    yield put({
      type: freloProjectConstants.GET_FILTER_SETS_FRELO_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateFilterSetFreloSaga({ id, payload }) {
  try {
    yield call(freloProjectService.updateFilterSet, id, payload);
    const response = yield call(freloProjectService.getFilterSets);

    yield put({
      type: freloProjectConstants.GET_FILTER_SETS_FRELO_SUCCESS,
      payload: [...response],
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Filter Set Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* removeFilterSetFreloSaga({ id }) {
  try {
    yield call(freloProjectService.removeFilterSet, id);
    const response = yield call(freloProjectService.getFilterSets);

    yield put({
      type: freloProjectConstants.GET_FILTER_SETS_FRELO_SUCCESS,
      payload: [...response],
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Filter Set Removed" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
export function* duplicateFilterSetFreloSaga({ name, id }) {
  try {
    yield call(freloProjectService.duplicateFilterSet, id, name);
    const response = yield call(freloProjectService.getFilterSets);

    yield put({
      type: freloProjectConstants.GET_FILTER_SETS_FRELO_SUCCESS,
      payload: [...response],
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Filter Set Duplicated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* createFilterSetFreloSaga({ payload }) {
  try {
    let { filters, activeColumns } = payload;

    let filtersEncoded = JSON.stringify(filters);
    let activeColumnsEncoded = activeColumns.join(",");

    const createSetRespponse = yield call(freloProjectService.createFilterSet, {
      ...payload,
      filters: filtersEncoded,
      activeColumns: activeColumnsEncoded,
    });

    if (isEmpty(createSetRespponse)) {
      throw new Error("Error creating filter set");
    }

    const response = yield call(freloProjectService.getFilterSets);

    yield put({
      type: freloProjectConstants.GET_FILTER_SETS_FRELO_SUCCESS,
      payload: [...response],
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "New filter set created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* uploadAttachmentFreloSaga({
  payload: { files, ...payload },
  claimId,
}) {
  try {
    const responses = yield all(
      files.map((image) => call(freloProjectService.uploadFile, image, payload))
    );

    if (isEmpty(responses)) {
      throw new Error("Error uploading files");
    }

    let statusMessage = buildAlertMessage("Uploads", responses);

    let startOffset = encodeURI(`Frelo/projects/${claimId}`);

    const responseFetch = yield call(freloProjectService.getFiles, startOffset);

    yield put({
      type: freloProjectConstants.GET_CLAIM_ATTACHMENTS_FRELO_SUCCESS,
      payload: { files: [...responseFetch], claimId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAttachmentsFreloSaga({ claimId }) {
  try {
    let startOffset = encodeURI(`Frelo/projects/${claimId}`);

    const response = yield call(freloProjectService.getFiles, startOffset);

    yield put({
      type: freloProjectConstants.GET_CLAIM_ATTACHMENTS_FRELO_SUCCESS,
      payload: { files: [...response], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* postPaymentFreloSaga({ claimId, payload }) {
  try {
    // yield call(freloProjectService.postPayment, payload, claimId);
    // const response = yield call(telecomClaimService.getClaimById, claimId);
    // yield put({
    //   type: telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_SUCCESS,
    //   payload: {
    //     claimId,
    //     claim: { ...response },
    //   },
    // });
    // yield put({
    //   type: alertConstants.SUCCESS,
    //   payload: { message: "Payment Posted" },
    // });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getProjectHistoryFreloSaga({ projectId }) {
  try {
    const response = yield call(
      freloProjectService.getProjectHistory,
      projectId
    );

    yield put({
      type: freloProjectConstants.GET_PROJECT_HISTORY_FRELO_SUCCESS,
      payload: { data: [...response], projectId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteFreloLedgerSaga({
  id,
}) {
  try {
     const res = yield call(
      freloProjectService.deleteLedger,
      id,
    );
    const response = yield call(freloProjectService.getProjectById, id, []);
    yield put({
      type: freloProjectConstants.GET_PROJECT_BY_ID_FRELO_SUCCESS,
      payload: {
        id,
        project: { ...response },
      },
    });

    if (res["status"] !== 200) {
      throw new Error("Error Deleting Ledger");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Ledger Deleted" },
    });

  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteFreloStatusNoteSaga({
  id,
  watchId
}) {
  try {
    const res = yield call(
      freloProjectService.deleteStatusNote,
      id,
    );

    const response = yield call(freloProjectService.getClaimById, watchId, []);

    yield put({
      type: freloProjectConstants.GET_PROJECT_BY_ID_FRELO_SUCCESS,
      payload: {
        watchId,
        claim: { ...response },
      },
    });

    if (res["status"] !== 200) {
      throw new Error("Error Deleting Status Note");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Status Note Deleted" },
    });

  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
export const adminJobCostingConstants = {
  GET_RATES_REQUEST: "GET_RATES_REQUEST",
  GET_RATES_SUCCESS: "GET_RATES_SUCCESS",
  ADD_RATE_REQUEST: "ADD_RATE_REQUEST",
  ADD_RATE_SUCCESS: "ADD_RATE_SUCCESS",
  UPDATE_RATE_REQUEST: "UPDATE_RATE_REQUEST",
  UPDATE_RATE_SUCCESS: "UPDATE_RATE_SUCCESS",
  DELETE_RATE_REQUEST: "DELETE_RATE_REQUEST",
  DELETE_RATE_SUCCESS: "DELETE_RATE_SUCCESS",
  GET_RDA_OPTIONS_REQUEST: "GET_RDA_OPTIONS_REQUEST",
  GET_RDA_OPTIONS_SUCCESS: "GET_RDA_OPTIONS_SUCCESS",
  ADMIN_JOB_COSTING_CLEAR: "ADMIN_JOB_COSTING_CLEAR",
};

export const telecomClaimConstants = {
  GET_ALL_CLAIMS_TELECOM_REQUEST: "GET_ALL_CLAIMS_TELECOM_REQUEST",
  GET_ALL_CLAIMS_TELECOM_SUCCESS: "GET_ALL_CLAIMS_TELECOM_SUCCESS",
  GET_MY_MOBILE_CLAIMS_TELECOM_REQUEST: "GET_MY_MOBILE_CLAIMS_TELECOM_REQUEST",
  GET_MY_MOBILE_CLAIMS_TELECOM_SUCCESS: "GET_MY_MOBILE_CLAIMS_TELECOM_SUCCESS",
  GET_MY_CLAIMS_TELECOM_REQUEST: "GET_MY_CLAIMS_TELECOM_REQUEST",
  GET_MY_CLAIMS_TELECOM_SUCCESS: "GET_MY_CLAIMS_TELECOM_SUCCESS",
  GET_MY_TEAMS_CLAIMS_TELECOM_REQUEST: "GET_MY_TEAMS_CLAIMS_TELECOM_REQUEST",
  GET_MY_TEAMS_CLAIMS_TELECOM_SUCCESS: "GET_MY_TEAMS_CLAIMS_TELECOM_SUCCESS",
  GET_CLAIM_BY_ID_TELECOM_REQUEST: "GET_CLAIM_BY_ID_TELECOM_REQUEST",
  GET_CLAIM_BY_ID_TELECOM_SUCCESS: "GET_CLAIM_BY_ID_TELECOM_SUCCESS",
  GET_CLAIM_BY_ID_TELECOM_LOADING_TRUE: "GET_CLAIM_BY_ID_TELECOM_LOADING_TRUE",
  UPDATE_TELECOM_CLAIM_REQUEST: "UPDATE_TELECOM_CLAIM_REQUEST",
  DELETE_TELECOM_CLAIM_REQUEST: "DELETE_TELECOM_CLAIM_REQUEST",
  GET_SCHEMA_ENTITY_OPTIONS_TELECOM_REQUEST: "GET_SCHEMA_ENTITY_OPTIONS_TELECOM_REQUEST",
  GET_SCHEMA_ENTITY_OPTIONS_TELECOM_SUCCESS: "GET_SCHEMA_ENTITY_OPTIONS_TELECOM_SUCCESS",
  GET_SCHEMA_RDA_OPTIONS_TELECOM_REQUEST: "GET_SCHEMA_RDA_OPTIONS_TELECOM_REQUEST",
  GET_SCHEMA_RDA_OPTIONS_TELECOM_SUCCESS: "GET_SCHEMA_RDA_OPTIONS_TELECOM_SUCCESS",
  GET_CREATE_CLAIM_FIELD_RULES_TELECOM_REQUEST: "GET_CREATE_CLAIM_FIELD_RULES_TELECOM_REQUEST",
  GET_CREATE_CLAIM_FIELD_RULES_TELECOM_SUCCESS: "GET_CREATE_CLAIM_FIELD_RULES_TELECOM_SUCCESS",
  GET_SCHEMA_FILTER_OPTIONS_TELECOM_REQUEST: "GET_SCHEMA_FILTER_OPTIONS_TELECOM_REQUEST",
  GET_SCHEMA_FILTER_OPTIONS_TELECOM_SUCCESS: "GET_SCHEMA_FILTER_OPTIONS_TELECOM_SUCCESS",
  GET_FILTER_SETS_TELECOM_REQUEST: "GET_FILTER_SETS_TELECOM_REQUEST",
  GET_FILTER_SETS_TELECOM_SUCCESS: "GET_FILTER_SETS_TELECOM_SUCCESS",
  CREATE_FILTER_SET_TELECOM_REQUEST: "CREATE_FILTER_SET_TELECOM_REQUEST",
  CREATE_FILTER_SET_TELECOM_SUCCESS: "CREATE_FILTER_SET_TELECOM_SUCCESS",
  REMOVE_FILTER_SET_TELECOM_REQUEST: "REMOVE_FILTER_SET_TELECOM_REQUEST",
  REMOVE_FILTER_SET_TELECOM_SUCCESS: "REMOVE_FILTER_SET_TELECOM_SUCCESS",
  SET_FILTER_SET_ACTIVE_TELECOM_REQUEST: "SET_FILTER_SET_ACTIVE_TELECOM_REQUEST",
  SET_FILTER_SET_ACTIVE_TELECOM_SUCCESS: "SET_FILTER_SET_ACTIVE_TELECOM_SUCCESS",
  UPDATE_FILTER_SET_NAME_TELECOM_REQUEST: "UPDATE_FILTER_SET_NAME_TELECOM_REQUEST",
  UPDATE_FILTER_SET_NAME_TELECOM_SUCCESS: "UPDATE_FILTER_SET_NAME_TELECOM_SUCCESS",
  DUPLICATE_FILTER_SET_TELECOM_REQUEST: "DUPLICATE_FILTER_SET_TELECOM_REQUEST",
  DUPLICATE_FILTER_SET_TELECOM_SUCCESS: "DUPLICATE_FILTER_SET_TELECOM_SUCCESS",
  UPDATE_FILTER_SET_TELECOM_REQUEST: "UPDATE_FILTER_SET_TELECOM_REQUEST",
  UPDATE_FILTER_SET_TELECOM_SUCCESS: "UPDATE_FILTER_SET_TELECOM_SUCCESS",
  UPLOAD_CLAIM_ATTACHMENT_TELECOM_REQUEST: "UPLOAD_CLAIM_ATTACHMENT_TELECOM_REQUEST",
  UPLOAD_CLAIM_ATTACHMENT_TELECOM_SUCCESS: "UPLOAD_CLAIM_ATTACHMENT_TELECOM_SUCCESS",
  UPLOAD_CLAIM_ROTATED_IMAGE_TELECOM_REQUEST: "UPLOAD_CLAIM_ROTATED_IMAGE_TELECOM_REQUEST",
  SAVE_ROTATION_TELECOM_REQUEST: "SAVE_ROTATION_TELECOM_REQUEST",
  GET_CLAIM_ATTACHMENTS_TELECOM_REQUEST: "GET_CLAIM_ATTACHMENTS_TELECOM_REQUEST",
  GET_CLAIM_ATTACHMENTS_TELECOM_SUCCESS: "GET_CLAIM_ATTACHMENTS_TELECOM_SUCCESS",
  GET_SIGNED_ATTACHMENT_LINK_TELECOM_REQUEST: "GET_SIGNED_ATTACHMENT_LINK_TELECOM_REQUEST",
  GET_SIGNED_ATTACHMENT_LINK_TELECOM_SUCCESS: "GET_SIGNED_ATTACHMENT_LINK_TELECOM_SUCCESS",
  POST_PAYMENT_TELECOM_REQUEST: "POST_PAYMENT_TELECOM_REQUEST",
  POST_PAYMENT_TELECOM_SUCCESS: "POST_PAYMENT_TELECOM_SUCCESS",
  GET_CLAIM_HISTORY_TELECOM_REQUEST: "GET_CLAIM_HISTORY_TELECOM_REQUEST",
  GET_CLAIM_HISTORY_TELECOM_SUCCESS: "GET_CLAIM_HISTORY_TELECOM_SUCCESS",
  GET_JOB_COSTING_INVOICE_TELECOM_REQUEST: "GET_JOB_COSTING_INVOICE_TELECOM_REQUEST",
  GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS: "GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS",
  GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_TELECOM_REQUEST:
    "GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_TELECOM_REQUEST",
  GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_TELECOM_SUCCESS:
    "GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_TELECOM_SUCCESS",
  GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_TELECOM_REQUEST: "GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_TELECOM_REQUEST",
  GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_TELECOM_SUCCESS: "GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_TELECOM_SUCCESS",
  GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_TELECOM_REQUEST: "GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_TELECOM_REQUEST",
  GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_TELECOM_SUCCESS: "GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_TELECOM_SUCCESS",
  GET_JOB_COSTING_LABOR_RATE_CARD_USIC_TELECOM_REQUEST: "GET_JOB_COSTING_LABOR_RATE_CARD_USIC_TELECOM_REQUEST",
  GET_JOB_COSTING_LABOR_RATE_CARD_USIC_TELECOM_SUCCESS: "GET_JOB_COSTING_LABOR_RATE_CARD_USIC_TELECOM_SUCCESS",
  CLAIM_INVOICE_TIMESTAMP_TELECOM_REQUEST: "CLAIM_INVOICE_TIMESTAMP_TELECOM_REQUEST",
  CLAIM_INVOICE_TIMESTAMP_TELECOM_SUCCESS: "CLAIM_INVOICE_TIMESTAMP_TELECOM_SUCCESS",
  POST_CLAIM_IN_HOUSE_LABOR_ITEM_TELECOM_REQUEST: "POST_CLAIM_IN_HOUSE_LABOR_ITEM_TELECOM_REQUEST",
  POST_CLAIM_IN_HOUSE_LABOR_ITEM_TELECOM_SUCCESS: "POST_CLAIM_IN_HOUSE_LABOR_ITEM_TELECOM_SUCCESS",
  POST_CLAIM_MATERIAL_ITEM_TELECOM_REQUEST: "POST_CLAIM_MATERIAL_ITEM_TELECOM_REQUEST",
  POST_CLAIM_MATERIAL_ITEM_TELECOM_SUCCESS: "POST_CLAIM_MATERIAL_ITEM_TELECOM_SUCCESS",
  POST_CLAIM_OTHER_ITEM_TELECOM_REQUEST: "POST_CLAIM_OTHER_ITEM_TELECOM_REQUEST",
  POST_CLAIM_OTHER_ITEM_TELECOM_SUCCESS: "POST_CLAIM_OTHER_ITEM_TELECOM_SUCCESS",
  POST_CLAIM_UNLISTED_ITEM_TELECOM_REQUEST: "POST_CLAIM_UNLISTED_ITEM_TELECOM_REQUEST",
  POST_CLAIM_UNLISTED_ITEM_TELECOM_SUCCESS: "POST_CLAIM_UNLISTED_ITEM_TELECOM_SUCCESS",
  DELETE_CLAIM_JOB_COSTING_OTHER_ITEM_REQUEST: "DELETE_CLAIM_JOB_COSTING_OTHER_ITEM_REQUEST",
  DELETE_CLAIM_JOB_COSTING_OTHER_ITEM_SUCCESS: "DELETE_CLAIM_JOB_COSTING_OTHER_ITEM_SUCCESS",
  DELETE_CLAIM_JOB_COSTING_LABOR_ITEM_REQUEST: "DELETE_CLAIM_JOB_COSTING_LABOR_ITEM_REQUEST",
  DELETE_CLAIM_JOB_COSTING_LABOR_ITEM_SUCCESS: "DELETE_CLAIM_JOB_COSTING_LABOR_ITEM_SUCCESS",
  DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_REQUEST: "DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_REQUEST",
  DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_SUCCESS: "DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_SUCCESS",
  POST_CLAIM_CONTRACTOR_ITEM_TELECOM_REQUEST: "POST_CLAIM_CONTRACTOR_ITEM_TELECOM_REQUEST",
  POST_CLAIM_CONTRACTOR_ITEM_TELECOM_SUCCESS: "POST_CLAIM_CONTRACTOR_ITEM_TELECOM_SUCCESS",
  POST_CLAIM_LO_ITEM_TELECOM_REQUEST: "POST_CLAIM_LO_ITEM_TELECOM_REQUEST",
  POST_CLAIM_LO_ITEM_TELECOM_SUCCESS: "POST_CLAIM_LO_ITEM_TELECOM_SUCCESS",
  DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_REQUEST: "DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_REQUEST",
  DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_SUCCESS: "DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_SUCCESS",
  DELETE_CLAIM_JOB_COSTING_LO_ITEM_REQUEST: "DELETE_CLAIM_JOB_COSTING_LO_ITEM_REQUEST",
  DELETE_CLAIM_JOB_COSTING_LO_ITEM_SUCCESS: "DELETE_CLAIM_JOB_COSTING_LO_ITEM_SUCCESS",
  UPLOAD_CLAIM_JOB_COSTING_SHEET_TELECOM_REQUEST: "UPLOAD_CLAIM_JOB_COSTING_SHEET_TELECOM_REQUEST",
  UPLOAD_CLAIM_JOB_COSTING_SHEET_TELECOM_SUCCESS: "UPLOAD_CLAIM_JOB_COSTING_SHEET_TELECOM_SUCCESS",
  GET_CLAIM_INVOICE_DOCUMENT_TELECOM_REQUEST: "GET_CLAIM_INVOICE_DOCUMENT_TELECOM_REQUEST",
  GET_CLAIM_INVOICE_DOCUMENT_TELECOM_SUCCESS: "GET_CLAIM_INVOICE_DOCUMENT_TELECOM_SUCCESS",
  UPLOAD_CLAIM_DOCUMENT_TELECOM_REQUEST: "UPLOAD_CLAIM_DOCUMENT_TELECOM_REQUEST",
  UPLOAD_CLAIM_DOCUMENT_TELECOM_SUCCESS: "UPLOAD_CLAIM_DOCUMENT_TELECOM_SUCCESS",
  GET_CLAIM_DOCUMENT_TELECOM_REQUEST: "GET_CLAIM_DOCUMENT_TELECOM_REQUEST",
  GET_CLAIM_DOCUMENT_TELECOM_SUCCESS: "GET_CLAIM_DOCUMENT_TELECOM_SUCCESS",
  GET_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST: "GET_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST",
  GET_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS: "GET_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS",
  ADD_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST: "ADD_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST",
  ADD_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS: "ADD_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS",
  EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST: "EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST",
  EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS: "EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS",
  REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST: "REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST",
  REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_UCCESS: "REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_UCCESS",
  GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_TELECOM_REQUEST: "GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_TELECOM_REQUEST",
  GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_TELECOM_SUCCESS: "GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_TELECOM_SUCCESS",
  GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_TELECOM_REQUEST: "GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_TELECOM_REQUEST",
  GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_TELECOM_SUCCESS: "GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_TELECOM_SUCCESS",
  GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_TELECOM_REQUEST:
    "GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_TELECOM_REQUEST",
  GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_TELECOM_SUCCESS:
    "GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_TELECOM_SUCCESS",
  GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_TELECOM_REQUEST:
    "GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_TELECOM_REQUEST",
  GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_TELECOM_SUCCESS:
    "GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_TELECOM_SUCCESS",
  GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_TELECOM_REQUEST:
    "GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_TELECOM_REQUEST",
  GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_TELECOM_SUCCESS:
    "GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_TELECOM_SUCCESS",
  GET_CLAIM_DOCUMENT_SCHEMA_DIG_LAWS_TELECOM_REQUEST: "GET_CLAIM_DOCUMENT_SCHEMA_DIG_LAWS_TELECOM_REQUEST",
  GET_CLAIM_DOCUMENT_SCHEMA_DIG_LAWS_TELECOM_SUCCESS: "GET_CLAIM_DOCUMENT_SCHEMA_DIG_LAWS_TELECOM_SUCCESS",
  GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_TELECOM_REQUEST: "GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_TELECOM_REQUEST",
  GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_TELECOM_SUCCESS: "GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_TELECOM_SUCCESS",
  GET_CLAIM_INTENT_TO_BILL_DOCUMENT_TELECOM_REQUEST: "GET_CLAIM_INTENT_TO_BILL_DOCUMENT_TELECOM_REQUEST",
  GET_CLAIM_INTENT_TO_BILL_DOCUMENT_TELECOM_SUCCESS: "GET_CLAIM_INTENT_TO_BILL_DOCUMENT_TELECOM_SUCCESS",
  GET_CLAIM_PROMISSORY_DOCUMENT_TELECOM_REQUEST: "GET_CLAIM_PROMISSORY_DOCUMENT_TELECOM_REQUEST",
  GET_CLAIM_PROMISSORY_DOCUMENT_TELECOM_SUCCESS: "GET_CLAIM_PROMISSORY_DOCUMENT_TELECOM_SUCCESS",
  GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_TELECOM_REQUEST: "GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_TELECOM_REQUEST",
  GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_TELECOM_SUCCESS: "GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_TELECOM_SUCCESS",
  GET_CLAIM_FINAL_NOTICE_DOCUMENT_TELECOM_REQUEST: "GET_CLAIM_FINAL_NOTICE_DOCUMENT_TELECOM_REQUEST",
  GET_CLAIM_FINAL_NOTICE_DOCUMENT_TELECOM_SUCCESS: "GET_CLAIM_FINAL_NOTICE_DOCUMENT_TELECOM_SUCCESS",
  GET_CLAIM_RELEASE_LETTER_DOCUMENT_TELECOM_REQUEST: "GET_CLAIM_RELEASE_LETTER_DOCUMENT_TELECOM_REQUEST",
  GET_CLAIM_RELEASE_LETTER_DOCUMENT_TELECOM_SUCCESS: "GET_CLAIM_RELEASE_LETTER_DOCUMENT_TELECOM_SUCCESS",
  GET_CLAIM_SECOND_NOTICE_DOCUMENT_TELECOM_REQUEST: "GET_CLAIM_SECOND_NOTICE_DOCUMENT_TELECOM_REQUEST",
  GET_CLAIM_SECOND_NOTICE_DOCUMENT_TELECOM_SUCCESS: "GET_CLAIM_SECOND_NOTICE_DOCUMENT_TELECOM_SUCCESS",
  REMOVE_LEDGER_TELECOM_REQUEST: "REMOVE_LEDGER_TELECOM_REQUEST",
  REMOVE_LEDGER_TELECOM_SUCCESS: "REMOVE_LEDGER_TELECOM_SUCCESS",

  UPDATE_LEDGER_TELECOM_REQUEST: "UPDATE_LEDGER_TELECOM_REQUEST",
  UPDATE_LEDGER_TELECOM_SUCCESS: "UPDATE_LEDGER_TELECOM_SUCCESS",
  REMOVE_STATUS_NOTE_TELECOM_REQUEST: "REMOVE_STATUS_NOTE_TELECOM_REQUEST",
  REMOVE_STATUS_NOTE_TELECOM_SUCCESS: "REMOVE_STATUS_NOTE_TELECOM_SUCCESS",

  GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_TELECOM_REQUEST: "GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_TELECOM_REQUEST",
  GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_TELECOM_SUCCESS: "GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_TELECOM_SUCCESS",
  GET_CLAIM_BALANCE_OWED_DOCUMENT_TELECOM_REQUEST: "GET_CLAIM_BALANCE_OWED_DOCUMENT_TELECOM_REQUEST",
  GET_CLAIM_BALANCE_OWED_DOCUMENT_TELECOM_SUCCESS: "GET_CLAIM_BALANCE_OWED_DOCUMENT_TELECOM_SUCCESS",
  SORT_ATTACHMENTS_TELECOM_CLAIM_REQUEST: "SORT_ATTACHMENTS_TELECOM_CLAIM_REQUEST",
  SORT_ATTACHMENTS_TELECOM_CLAIM_SUCCESS: "SORT_ATTACHMENTS_TELECOM_CLAIM_SUCCESS",
  UPDATE_CATEGORY_ATTACHMENTS_TELECOM_CLAIM_REQUEST: "UPDATE_CATEGORY_ATTACHMENTS_TELECOM_CLAIM_REQUEST",
  UPDATE_CATEGORY_ATTACHMENTS_TELECOM_CLAIM_SUCCESS: "UPDATE_CATEGORY_ATTACHMENTS_TELECOM_CLAIM_SUCCESS",
  GET_LOGO_TELECOM_REQUEST: "GET_LOGO_TELECOM_REQUEST",
  GET_LOGO_TELECOM_SUCCESS: "GET_LOGO_TELECOM_SUCCESS",
  TELECOM_CLAIM_CLEAR: "TELECOM_CLAIM_CLEAR",
  SAVE_TELECOM_COLUMN_FILTER_REQUEST: "SAVE_TELECOM_COLUMN_FILTER_REQUEST",

  CONFIRM_TEL_DISPATCH: "CONFIRM_TEL_DISPATCH",
  DELETE_ACTION_NOTE_TELECOM_REQUEST: "DELETE_ACTION_NOTE_TELECOM_REQUEST"
};

export const adminknowledgeBaseConstants = {
  GET_ALL_FAQ_REQUEST: "GET_ALL_FAQ_REQUEST",
  GET_ALL_FAQ_SUCCESS: "GET_ALL_FAQ_SUCCESS",
  ADD_FAQ_REQUEST: "ADD_FAQ_REQUEST",
  ADD_FAQ_SUCCESS: "ADD_FAQ_SUCCESS",
  UPDATE_FAQ_REQUEST: "UPDATE_FAQ_REQUEST",
  UPDATE_FAQ_SUCCESS: "UPDATE_FAQ_SUCCESS",
  REMOVE_FAQ_REQUEST: "REMOVE_FAQ_REQUEST",
  REMOVE_FAQ_SUCCESS: "REMOVE_FAQ_SUCCESS",
  GET_ALL_KB_REQUEST: "GET_ALL_KB_REQUEST",
  GET_ALL_KB_SUCCESS: "GET_ALL_KB_SUCCESS",
  GET_ALL_KBTOPICS_REQUEST: "GET_ALL_KBTOPICS_REQUEST",
  GET_ALL_KBTOPICS_SUCCESS: "GET_ALL_KBTOPICS_SUCCESS",
  GET_ALL_KBMARKETS_REQUEST: "GET_ALL_KBMARKETS_REQUEST",
  GET_ALL_KBMARKETS_SUCCESS: "GET_ALL_KBMARKETS_SUCCESS",
  GET_ALL_KBRESOURCETYPES_REQUEST: "GET_ALL_KBRESOURCETYPES_REQUEST",
  GET_ALL_KBRESOURCETYPES_SUCCESS: "GET_ALL_KBRESOURCETYPES_SUCCESS",
  ADD_KB_TOPIC_REQUEST:"ADD_KB_TOPIC_REQUEST",
  ADD_KB_TOPIC_SUCCESS:"ADD_KB_TOPIC_SUCCESS",
  UPDATE_KB_TOPIC_REQUEST:"UPDATE_KB_TOPIC_REQUEST",
  UPDATE_KB_TOPIC_SUCCESS:"UPDATE_KB_TOPIC_SUCCESS",
  ADD_KB_RESOURCE_REQUEST:"ADD_KB_RESOURCE_REQUEST",
  ADD_KB_RESOURCE_SUCCESS:"ADD_KB_RESOURCE_SUCCESS",
  UPDATE_KB_RESOURCE_REQUEST:"UPDATE_KB_RESOURCE_REQUEST",
  UPDATE_KB_RESOURCE_SUCCESS:"UPDATE_KB_RESOURCE_SUCCESS",
  ADMIN_KNOWLEDGE_BASE_CLEAR: "ADMIN_KNOWLEDGE_BASE_CLEAR",
};

import { adminSchemaConstants } from "_constants";

const getPermissionAccessTypes = () => {
  return {
    type: adminSchemaConstants.GET_ADMIN_ACCESS_TYPES_SCHEMA_REQUEST,
  };
};

const getConditionTypes = (lob) => {
  return {
    type: adminSchemaConstants.GET_ADMIN_CONDITIONAL_TYPES_SCHEMA_REQUEST,
    lob,
  };
};

const getNotificationTypes = (lob) => {
  return {
    type: adminSchemaConstants.GET_ADMIN_NOTIFICATION_TYPES_SCHEMA_REQUEST,
    lob,
  };
};

const getNotificationFormFields = (lob, formId) => {
  return {
    type:
      adminSchemaConstants.GET_ADMIN_NOTIFICATION_FORM_FIELDS_SCHEMA_REQUEST,
    lob,
    formId,
  };
};

const getRecipientTypes = (lob) => {
  return {
    type:
      adminSchemaConstants.GET_ADMIN_NOTIFICATION_RECIPIENT_TYPES_SCHEMA_REQUEST,
    lob,
  };
};

export const adminSchemaActions = {
  getPermissionAccessTypes,
  getConditionTypes,
  getNotificationTypes,
  getNotificationFormFields,
  getRecipientTypes,
};

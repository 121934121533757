import { activeLinksConstants, permissionConstants } from "../_constants";
import { produce } from "immer";
import { view } from "ramda";
import { REHYDRATE } from "redux-persist/lib/constants";

let initState = {
  routes: {},
  isAdmin: false,
  LOBAccess: [],
  actionAccess: {},
  statusAccess: {},
  activeLinks: {},
  formControl: {},
};
export function permission(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case permissionConstants.PERMISSION_SET:
      return produce(state, (draft) => {
        // routes
        draft["routes"] = { ...payload["routes"] };

        // isAdmin
        draft["isAdmin"] = payload.isAdmin;

        // LOBAccess
        draft["LOBAccess"] = [...payload.LOBAccess];

        // actionAccess
        // reformat actionAccess from array -> obj
        const actionAccessObj = {};
        payload.actionAccess?.forEach((e) => {
          actionAccessObj[e.accessTypeLOB] = e;
        });
        draft["actionAccess"] = actionAccessObj;

        // statusAccess
        // reformat statusAccess from array -> obj
        const statusAccessObj = {
          1: {
            claimStatus: {
              revoked: [],
              viewable: [],
              assignable: [],
            },
            claimStatusPayment: {
              revoked: [],
              viewable: [],
            },
          },
          2: {
            claimStatus: {
              revoked: [],
              viewable: [],
              assignable: [],
            },
            claimStatusPayment: {
              revoked: [],
              viewable: [],
            },
          },
          3: {
            claimStatus: {
              revoked: [],
              viewable: [],
              assignable: [],
            },
            claimStatusPayment: {
              revoked: [],
              viewable: [],
            },
          },
        };
        const statusTypeMap = {
          1: "claimStatus",
          2: "claimStatusPayment",
        };
        const statusAccessTypeMap = {
          0: "revoked",
          1: "viewable", // this is only used for Claim Status
          2: "assignable",
        };

        payload.statusAccess?.forEach((e) => {
          statusAccessObj[e.LOBID][statusTypeMap[e.statusType]][statusAccessTypeMap[e.statusAccessType]]?.push(
            e.statusId
          );
        });
        draft["statusAccess"] = statusAccessObj;

        // formControl
        // reformat formControl from array -> obj
        const formControlObj = payload.formControl.reduce(function (obj, elem) {
          if (!obj[elem.LOBID]) obj[elem.LOBID] = {};
          // store entity Rules by fieldId > entityId. Which entity is chosen is dependent on the claim
          if (!obj[elem.LOBID]["entity"]) obj[elem.LOBID]["entity"] = {};
          if (!obj[elem.LOBID]["entity"][elem.fieldId]) obj[elem.LOBID]["entity"][elem.fieldId] = {};
          // store role rules by fieldId. There will only ever be one role, so we can store by fieldId
          if (!obj[elem.LOBID]["role"]) obj[elem.LOBID]["role"] = {};
          // if rule has entityFormFieldId then it is an entity rule (role rules have roleFormFieldId)
          if (elem.entityFormFieldId) {
            obj[elem.LOBID]["entity"][elem.fieldId][elem.entityId] = elem;
          } else {
            obj[elem.LOBID]["role"][elem.fieldId] = elem;
          }
          return obj;
        }, {});

        draft["formControl"] = formControlObj;
      });

    case activeLinksConstants.ACTIVE_LINKS_SET:
      return produce(state, (draft) => {
        draft["activeLinks"] = payload;
      });

    case REHYDRATE: {
      if (payload && payload.permission) {
        return { ...payload.permission };
      } else {
        return { ...initState };
      }
    }
    case permissionConstants.PERMISSION_CLEAR:
      return { ...initState };
    default:
      return state;
  }
}

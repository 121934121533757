import { jointLedgerConstants } from "_constants";

const getLedgers = (lob, claimId) => {
    return {
        type: jointLedgerConstants.GET_LEDGERS_REQUEST,
        lob, 
        claimId
    }
}


export const jointLedgerActions = {
    getLedgers
}
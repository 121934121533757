import { telecomDispatchConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";
let initState = {
  all: {},
  individual: {},
  timers: {},
};
export function dispatch(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case telecomDispatchConstants.GET_ONE_DISPATCH_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { claimId, response } = payload;
        draft["individual"] = { [claimId]: response };
      });
    case telecomDispatchConstants.GET_ALL_DISPATCH_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        draft["all"] = payload;
      });
      case telecomDispatchConstants.UPDATE_DISPATCH_TELECOM_TIMER_SUCCESS:
        return produce(state, (draft) => {
          draft["timers"] = payload
        });
    case REHYDRATE: {
      if (payload && payload.telecom && payload.telecom.dispatch) {
        return { ...payload.telecom.dispatch };
      } else {
        return { ...initState };
      }
    }
    case telecomDispatchConstants.TELECOM_DISPATCH_CLEAR:
      return {
        ...initState,
      };

    default:
      return state;
  }
}

import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const getAllReports = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  // was using v2 for testing of new filters

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/frelo/reporting/all${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching reports for telecom");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getOneReport = (reportId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  // was using v2 for testing of new filters

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/frelo/reporting/onereport?reportid=${reportId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching report for telecom");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const runReport = (reportId, payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  // let apiBase = "http://localhost:3000/api"; // local host testing
  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/frelo/reporting/sproc?reportid=${reportId}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching report");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

export const freloReportsService = {
  getAllReports,
  getOneReport,
  runReport,
};

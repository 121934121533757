import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const getAll = () => {
    const parameters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
  
    let apiBase = getApiBase();
    let jwtCookie = "";
    if (process.env.NODE_ENV !== "production") {
      jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
    }
  
    return fetch(`${apiBase}/admin/recoveryAgentManagement${jwtCookie}`, parameters)
      .then((response) => {
        if (response.status === 403) {
          throw new Error("Invalid Auth Token");
        }
        if (response.status !== 200) {
          throw new Error("error fetching All Agents");
        }
        return response.json();
      })
      .catch((err) => {
        throw err;
      });
  };

  const getOne = (id) => {
    const parameters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
  
    let apiBase = getApiBase();
    let jwtCookie = "";
    if (process.env.NODE_ENV !== "production") {
      jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
    }
  
    return fetch(
      `${apiBase}/admin/recoveryAgentManagement/getRecTeamHist?nurid=${id}${jwtCookie}`,
      parameters
    )
      .then((response) => {
        if (response.status === 403) {
          throw new Error("Invalid Auth Token");
        }
        if (response.status !== 200) {
          throw new Error("error fetching Agent");
        }
        return response.json();
      })
      .catch((err) => {
        throw err;
      });
  };


  const getTeamList = (id) => {
    const parameters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
  
    let apiBase = getApiBase();
    let jwtCookie = "";
    if (process.env.NODE_ENV !== "production") {
      jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
    }
  
    return fetch(
      `${apiBase}/admin/recoveryAgentManagement/getNewTeams?nurid=${id}${jwtCookie}`,
      parameters
    )
      .then((response) => {
        if (response.status === 403) {
          throw new Error("Invalid Auth Token");
        }
        if (response.status !== 200) {
          throw new Error("error fetching Team List");
        }
        return response.json();
      })
      .catch((err) => {
        throw err;
      });
  };
  

  export const adminRecoveryAgentService = {
    getAll,
    getOne,
    getTeamList
  };
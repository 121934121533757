import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const getNotifications = async () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(`${apiBase}/admin/notifications${jwtCookie}`, parameters);
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error fetching notifications");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const toggleNotifications = async (notifications) => {
  const body = {
    notifications
  }
  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body)
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(`${apiBase}/admin/notifications${jwtCookie}`, parameters);
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error fetching notifications");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};



export const adminNotificationsService = {
  getNotifications,
  toggleNotifications
};

import React, { useEffect, useCallback, useState } from "react";
import moment from "moment";
import Fade from "@material-ui/core/Fade";
import Skeleton from "@material-ui/lab/Skeleton";
import { telecomClaimService } from "_services";

export default function Index({ path, contentType, dimensions = 20 }) {
  const [link, setLink] = useState();

  const fetchMyAPI = useCallback(async () => {
    if (path && (contentType === "image/jpeg" || contentType === "image/png")) {
      let expireDate = moment().add(7, "days").calendar();
      let encodePath = encodeURI(path);
      let response = await telecomClaimService.getSignedUrl(
        encodePath,
        expireDate
      );
      setLink(response["url"]);
      return;
    }
    setLink();
  }, [path, contentType]);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  return (
    <div>
      {path ? (
        <Fade in={!!path}>
          <img
            style={{
              width: `${dimensions}px`,
              height: `${dimensions}px`,
              objectFit: "cover",
            }}
            src={path}
            alt=""
          />
        </Fade>
      ) : (
        <Skeleton
          variant="rect"
          width={dimensions}
          height={dimensions}
          style={{ margin: "auto" }}
        />
      )}
    </div>
  );
}
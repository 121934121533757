

/**
 * 
 * @param {Number} entityID (Joint)
 * @param {Number} regionID (Joint)
 * @param {Boolean} thirdPartyFee
 * @param {String} status
 * @returns {Number} 
 */
export const getPayoutModel = (entityID, regionID, thirdPartyFee, status) => {

    const _3rdPartyStatuses = ["E6_", "E9L", "F1_", "F1A", "F1B", "F2_", "F2A", "F3_", "F4_", "F5_", "F6_", "F6A", "F6B", "F7_", "F8_"]
    // third party fee box checked and claim in F status

    if (thirdPartyFee == 1 && _3rdPartyStatuses.includes(status?.substring(0,3))) {
        return 3;
    }
    // duke energy / piedmont, intersection, optical communications group, united communications, zenfi
    if ([43, 26, 31, 35, 24].includes(entityID)) {
        //line item plus
        return 1;
    }

    // fayetteville, suwannee, AT&T, charter, altice, comporium, duke university, 
    // entouch, everstream, ezee, firstlight, google fiber, logix, mcnc, mediacom, ncec,
    // phonoscope, PS Lightwave, SEGRA, united fiber, WOW
    if ([44,46, 32, 20, 28, 17, 29, 18, 36, 37, 27, 25, 38, 15, 19, 21, 23, 22, 16, 39, 34].includes(entityID)) {
        // flat
        return 2;
    }

    // astound
    if (entityID === 14) {
        return regionID === 56 ? 1 : 2;
    }

    // default to flat
    return 2;
}
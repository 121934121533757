import { adminNotificationsConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";

let initState = {
  notifications: [],
  notificationsLoading: {
    GET: false,
    PUT: false
  }
};

export function notifications(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminNotificationsConstants.ADMIN_NOTIFICATIONS_GET_ALL_REQUEST:
      return produce(state, (draft) => {
        draft["notificationsLoading"]["GET"] = true;
      });
    case adminNotificationsConstants.ADMIN_NOTIFICATIONS_GET_ALL_SUCCESS:
      return produce(state, (draft) => {
        draft["notifications"] = [...payload];
        draft["notificationsLoading"]["GET"] = false;
        draft["notificationsLoading"]["PUT"] = false;
      });
    case adminNotificationsConstants.ADMIN_NOTIFICATIONS_UPDATE_REQUEST:
      return produce(state, (draft) => {
        draft["notificationsLoading"]["PUT"] = true;
      });
    case adminNotificationsConstants.ADMIN_NOTIFICATIONS_UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft["notificationsLoading"]["PUT"] = false;
      });
    case REHYDRATE: {
      if (payload && payload.admin && payload.admin.notifications) {
        return { ...payload.admin.notifications };
      } else {
        return { ...initState };
      }
    }
    default:
      return state;
  }
}

import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const getUserReports = () => {

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    };

    let apiBase = getApiBase();
    // let apiBase = "http://localhost:3000/api"; // local host testing
    // was using v2 for testing of new filters

    let jwtCookie = "";
    if (process.env.NODE_ENV !== "production") {
        jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
    }

    return fetch(`${apiBase}/joint/reporting/getUserReports${jwtCookie}`, parameters)
        .then((response) => {
            if (response.status === 403) {
                throw new Error("Invalid Auth Token");
            }
            if (response.status !== 200) {
                throw new Error("error fetching reports for joint");
            }
            return response.json();
        })
        .catch((err) => {
            throw err;
        });
};

const getReport = (reportID) => {

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    };

    let apiBase = getApiBase();
    // let apiBase = "http://localhost:3000/api"; // local host testing
    // was using v2 for testing of new filters

    let jwtCookie = "";
    let reportIDParam = `?id=${reportID}`
    if (process.env.NODE_ENV !== "production") {
        jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
        reportIDParam = `&id=${reportID}`
    }

    return fetch(`${apiBase}/joint/reporting/getReport${jwtCookie}${reportIDParam}`, parameters)
        .then((response) => {
            if (response.status === 403) {
                throw new Error("Invalid Auth Token");
            }
            if (response.status !== 200) {
                throw new Error("error fetching report for joint");
            }
            return response.json();
        })
        .catch((err) => {
            throw err;
        });
};

const newReport = (payload) => {
    let body = { ...payload };

    if (process.env.NODE_ENV !== "production") {
        body = {
            ...body,
            jwtCookie: cookieService.get("token"),
        };
    }

    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body),
    };

    // let apiBase = "http://localhost:3000/api"; // local host testing
    let apiBase = getApiBase();

    return fetch(`${apiBase}/joint/reporting/newReport`, parameters)
        .then((response) => {
            if (response.status === 403) {
                throw new Error("Invalid Auth Token");
            }
            if (response.status !== 200 && response.status !== 201) {
                throw new Error("Error creating report");
            }

            return response.json();
        })
        .catch((err) => {
            throw err;
        });
};

const saveReport = (payload) => {
    let body = { ...payload };

    if (process.env.NODE_ENV !== "production") {
        body = {
            ...body,
            jwtCookie: cookieService.get("token"),
        };
    }

    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body),
    };

    // let apiBase = "http://localhost:3000/api"; // local host testing
    let apiBase = getApiBase();
    
    return fetch(`${apiBase}/joint/reporting/saveReport`, parameters)
        .then((response) => {
            if (response.status === 403) {
                throw new Error("Invalid Auth Token");
            }
            if (response.status !== 200 && response.status !== 201) {
                throw new Error("Error saving report");
            }

            return response.json();
        })
        .catch((err) => {
            throw err;
        });
};

const archiveReport = (payload) => {
    let body = { ...payload };

    if (process.env.NODE_ENV !== "production") {
        body = {
            ...body,
            jwtCookie: cookieService.get("token"),
        };
    }

    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body),
    };

    // let apiBase = "http://localhost:3000/api"; // local host testing
    let apiBase = getApiBase();

    return fetch(`${apiBase}/joint/reporting/archiveReport`, parameters)
        .then((response) => {
            if (response.status === 403) {
                throw new Error("Invalid Auth Token");
            }
            if (response.status !== 200 && response.status !== 201) {
                throw new Error("Error archiving report");
            }

            return response.json();
        })
        .catch((err) => {
            throw err;
        });
};

const getProcs = () => {

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    };

    let apiBase = getApiBase();
    // let apiBase = "http://localhost:3000/api"; // local host testing
    // was using v2 for testing of new filters

    let jwtCookie = "";
    if (process.env.NODE_ENV !== "production") {
        jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
    }

    return fetch(`${apiBase}/joint/reporting/getProcs${jwtCookie}`, parameters)
        .then((response) => {
            if (response.status === 403) {
                throw new Error("Invalid Auth Token");
            }
            if (response.status !== 200) {
                throw new Error("error fetching procs for joint");
            }
            return response.json();
        })
        .catch((err) => {
            throw err;
        });
};

const queryProc = (procedures, filters, fields, inputs, reportName, shared, rowsPerPage, page) => {

    let body = {
        procedures: procedures,
        filters: filters,
        fields: fields,
        inputs: inputs,
        reportName: reportName,
        shared: shared
    }

    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body)
    };

    let apiBase = getApiBase();
    // let apiBase = "http://localhost:3000/api"; // local host testing
    // was using v2 for testing of new filters

    let jwtCookie = "";
    if (process.env.NODE_ENV !== "production") {
        jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
    }

    return fetch(`${apiBase}/joint/reporting/queryProc?rows=${rowsPerPage}&page=${page}${jwtCookie}`, parameters)
        .then((response) => {
            if (response.status === 403) {
                throw new Error("Invalid Auth Token");
            }
            if (response.status !== 200) {
                throw new Error("error querying proc for joint");
            }
            return response.json();
        })
        .catch((err) => {
            throw err;
        });
};

const getScoreboard = async (startDate) => {
    const parameters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
  
    let apiBase = getApiBase();
    // let apiBase = "http://localhost:3000/api"; // local host testing
    // was using v2 for testing of new filters
  
    let jwtCookie = "";
    if (process.env.NODE_ENV !== "production") {
      jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
    }
  
    const params = `?startDate=${startDate}`;
  
    try {
        const response = await fetch(
            `${apiBase}/joint/reporting/b1Scoreboard${params}${jwtCookie}`,
            parameters
        );
        if (response.status === 403) {
            throw new Error("Invalid Auth Token");
        }
        if (response.status !== 200) {
            throw new Error("Error Fetching B1 Report");
        }
        return await response.json();
    } catch (err) {
        throw err;
    }
  };
  
  const getAList = async (regionId, marketId) => {
    const parameters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
  
    let apiBase = getApiBase();
    // let apiBase = "http://localhost:3000/api"; // local host testing
    // was using v2 for testing of new filters
  
    let jwtCookie = "";
    if (process.env.NODE_ENV !== "production") {
      jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
    }
  
    try {
          const response = await fetch(
              `${apiBase}/joint/reporting/b1Scoreboard/checkAList?scRegionId=${regionId}&scMarketId=${marketId}${jwtCookie}`,
              parameters
          );
          if (response.status === 403) {
              throw new Error("Invalid Auth Token");
          }
          if (response.status !== 200) {
              throw new Error("error fetching reports for telecom");
          }
          return await response.json();
      } catch (err) {
          throw err;
      }
  };

  const getRemittance = (payload) => {
    const { entityIDs, startDate, endDate} = payload;
    
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    };

    let apiBase = getApiBase();
    // let apiBase = "http://localhost:3000/api"; // local host testing
    // was using v2 for testing of new filters

    let jwtCookie = "";
    if (process.env.NODE_ENV !== "production") {
        jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
    }

    return fetch(`${apiBase}/joint/reporting/remittance?entityids=${JSON.stringify(entityIDs)}&startdate=${startDate}&enddate=${endDate}${jwtCookie}`, parameters)
        .then((response) => {
            if (response.status === 403) {
                throw new Error("Invalid Auth Token");
            }
            if (response.status !== 200) {
                throw new Error("error fetching remittance");
            }
            return response.json();
        })
        .catch((err) => {
            throw err;
        });
};


export const jointReportsService = {
    getUserReports,
    getReport,
    newReport,
    saveReport,
    archiveReport,
    getProcs,
    queryProc,
    getScoreboard,
    getAList,
    getRemittance
};

import { powerGasDispatchConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";
let initState = {
  all: {},
  individual: {},
  timers: {}
};
export function dispatch(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case powerGasDispatchConstants.GET_ONE_DISPATCH_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { claimId, response } = payload;
        draft["individual"] = { [claimId]: response };
      });
    case powerGasDispatchConstants.GET_ALL_DISPATCH_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        draft["all"] = payload;
      });
    case powerGasDispatchConstants.UPDATE_DISPATCH_POWER_GAS_TIMER_SUCCESS:
      return produce(state, (draft) => {
        draft["timers"]= payload;
      });
    case REHYDRATE: {
      if (payload && payload.powerGas && payload.powerGas.dispatch) {
        return { ...payload.powerGas.dispatch };
      } else {
        return { ...initState };
      }
    }
    case powerGasDispatchConstants.POWER_GAS_DISPATCH_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

// context:isEmailFormat
// check if format: anystring@anystring.anystring
const isEmailFormat = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

// context:checkPassword
// min 8 letter password, with at least a symbol, upper and lower case letters and a number
// returns: true if passed, false if failed
function checkPassword(str) {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}
export const validation = {
  isEmailFormat,
  checkPassword,
};

export const telecomReportConstants = {
  GET_ALL_REPORTS_TELECOM_REQUEST: "GET_ALL_REPORTS_TELECOM_REQUEST",
  GET_ALL_REPORTS_TELECOM_SUCCESS: "GET_ALL_REPORTS_TELECOM_SUCCESS",
  GET_ONE_REPORT_TELECOM_REQUEST: "GET_ONE_REPORT_TELECOM_REQUEST",
  GET_ONE_REPORT_TELECOM_SUCCESS: "GET_ONE_REPORT_TELECOM_SUCCESS",
  POST_RUN_REPORT_TELECOM_REQUEST: "POST_RUN_REPORT_TELECOM_REQUEST",
  POST_RUN_REPORT_TELECOM_SUCCESS: "POST_RUN_REPORT_TELECOM_SUCCESS",
  POST_RUN_REPORT_TELECOM_ERROR: "POST_RUN_REPORT_TELECOM_ERROR",
  TELECOM_REPORTS_CLEAR: "TELECOM_REPORTS_CLEAR",
};

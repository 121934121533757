import React from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { lobUrlMask } from "_helpers";
import { makeStyles } from "@material-ui/core/styles";
import { ObjectTools } from "_utility";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.GreenPrg.main,
    width:'100%',
    "&:hover": {
      backgroundColor: theme.palette.DarkGrayPrg.main,
    },
  },
  startClaimDark:{
    backgroundColor: `${theme.palette.GreenPrg.main} !important`
  }
}));

export default function ClaimSelect({toggleDrawer}) {
  const history = useHistory();
  const classes = useStyles();
  const { getNestedPropertySafe } = ObjectTools;

  const selectedLob = useSelector((state) =>
    getNestedPropertySafe(state, ["lob", "current"])
  );
  const selectedTheme = useSelector((state) =>
  getNestedPropertySafe(state, ["settings", "theme"])
);
  const handleClick = () => {
    history.push(`/dashboard/claim/${lobUrlMask[selectedLob]}/create`);
    toggleDrawer("close");
  };

  return (
    <ClaimWrapper>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={`${classes.btn} ${selectedTheme === "dark" ? classes.startClaimDark : ""}`}
        size="small"
      >
        {lobUrlMask[selectedLob] === 'frelo' ? "Start Project": "Start Claim"}
      </Button>
    </ClaimWrapper>
  );
}


export const ClaimWrapper = styled.div`
  display: flex;
  margin: 12px;
  @media(max-width:600px){
    margin: 0;
    button{
     font-size: 20px;
    }
  }
`;

import { adminClaimStatusConstants_ } from "_constants";


const getAllStatus = (lob) => {
  return {
    type: adminClaimStatusConstants_.GET_ALL_STATUS_REQUEST,
    lob
  };
};

const addStatus = (payload,lob) => {
  return {
    type: adminClaimStatusConstants_.ADD_STATUS_REQUEST,
    payload,
    lob
  };
};

const getOneStatus = (lob,id) => {
  return {
    type: adminClaimStatusConstants_.GET_ONE_STATUS_REQUEST,
    lob,
    id
  };
};

const getStatusAccess = (id) => {
  return {
    type: adminClaimStatusConstants_.GET_STATUS_ACCESS_REQUEST,
    id
  };
};

const getUsers = () => {
  return {
    type: adminClaimStatusConstants_.GET_USERS_REQUEST,
  };
};

const addUsers = (payload,id) => {
  return {
    type: adminClaimStatusConstants_.ADD_USERS_REQUEST,
    payload,
    id
  };
};

const removeUser = (payload, statusId) => {
  return {
    type: adminClaimStatusConstants_.REMOVE_USER_REQUEST,
    payload,
    statusId
  };
};

const updateOneStatus = (payload,lob,id) => {
  return {
    type: adminClaimStatusConstants_.UPDATE_ONE_STATUS_REQUEST,
    payload,
    lob,
    id
  };
};


export const adminClaimStatusActions_ = {
    addStatus,
    getAllStatus,
    getOneStatus,
    getStatusAccess,
    getUsers,
    addUsers,
    removeUser,
    updateOneStatus
};

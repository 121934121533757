import { adminRecoveryAgentConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";

let initState = {
    all:[],
    one:[],
    teamList:{}
};


export function recoveryAgent(state = { ...initState }, action) {
    const { payload, type } = action;

    switch (type) {
        case adminRecoveryAgentConstants.ADMIN_GET_ALL_RECOVERY_AGENTS_SUCCESS:
            return produce(state, (draft) => {
              draft["all"] = [...payload];
            });
            case adminRecoveryAgentConstants.ADMIN_GET_ONE_RECOVERY_AGENT_SUCCESS:
              return produce(state, (draft) => {
                let { id, data } = payload;
                draft["one"][id] = [ ...data ];
              });
              case adminRecoveryAgentConstants.ADMIN_GET_TEAM_LIST_SUCCESS:
              return produce(state, (draft) => {
                let { id, data } = payload;
                draft["teamList"][id] = [ ...data ];
              });
    case REHYDRATE: {
        if (payload && payload.admin && payload.admin.recoveryAgent) {
          return { ...payload.admin.recoveryAgent };
        } else {
          return { ...initState };
        }
      }
      case adminRecoveryAgentConstants.ADMIN_RECOVERYAGENT_CLEAR:
        return {
          ...initState,
        };
      default:
        return state;
    }
};
import { put, call } from "redux-saga/effects";
import {
  adminStatesConstants,
  alertConstants,
  authConstants,
} from "_constants";
import {
  adminStatesService,
} from "_services";

export function* getAllStatesSaga() {
  try {
    const response = yield call(adminStatesService.getAllStates);

    yield put({
      type: adminStatesConstants.GET_ALL_STATES_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
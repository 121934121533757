import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import styled from "styled-components";
import SecurityIcon from "@material-ui/icons/Security";
import { lobActions } from "_actions";
import { lobUserMask, lobUrlMask } from "_helpers";
import { useDispatch, useSelector } from "react-redux";
import { ObjectTools } from "_utility";
import { useHistory } from "react-router-dom";
import { isEmpty } from "ramda";
import { store } from "_helpers/store";
import { lobConstants } from "_constants";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.DarkGrayPrg.main,
    "& .MuiSelect-select": {
      height: "44px",
      lineHeight: "3",
      backgroundColor: 'transparent !important',
      color: theme.palette.DarkGrayPrg.main,
      border: "none !important",
      fontWeight: "bold",
      minWidth: "85px",
      textAlign: "left",
    },
  },
  Links: {
    marginTop: "0px",
    fontSize: "17px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  admin: {
    borderRadius: "4px",
    textAlign: "center",
    backgroundColor: theme.palette.DarkGrayPrg.main,
    color: theme.palette.WhitePrg.main,
    "&:hover": {
      color: theme.palette.DarkGrayPrg.main,
    },
    "&:focus": {
      color: theme.palette.DarkGrayPrg.main,
    },
    margin: "5px",
  },
  iconWrap: {
    margin: "-1px auto 0",
    paddingRight: "10px",
    maxWidth: "100px",
  },
  icon: {
    margin: "-2px 5px 0 0",
    float: "left",
  },
  dropdownStyle: {
    "& ul": {
      padding: 0,
    },
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      left:'0 !important',
      minWidth:'100% !important'
    },
  },
  span: { marginLeft: "10px", },
}));

export default function Index({ openDrawer }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getNestedPropertySafe } = ObjectTools;

  const isAdmin = useSelector((state) =>
    getNestedPropertySafe(state, ["permission", "isAdmin"])
  );

  const LOBAccess = useSelector((state) =>
    getNestedPropertySafe(state, ["permission", "LOBAccess"])
  );

  const selectedLob = useSelector((state) =>
    getNestedPropertySafe(state, ["lob", "current"])
  );

  const demoAccess = useSelector((state) =>
    getNestedPropertySafe(state, [
      "permission",
      "routes",
      "Demo",
      "Home",
      "Overview",
      "GET",
    ])
  );

  const activeLinks = useSelector((state) => getNestedPropertySafe(state, ["permission", "activeLinks"]));

  const [lobChanged, setLobChanged] = useState(false);
  useEffect(() => {
    if (lobChanged) {
    const accessibleLinks = activeLinks.filter(e => e.access);
    const pathMatch = accessibleLinks.find(l => {
      let finalId = l.url?.replace(/\/dashboard/g,"")
                            .replace(/\/telecom/g,"")
                            .replace(/\/power-gas/g,"")
                            .replace(/\/frelo/g,"") || "{null}";
      const pathIsLobSpecific = window.location.pathname.includes(...["telecom", "power-gas", "frelo"]);
      if (finalId[finalId.length-1] !== "/" && pathIsLobSpecific) finalId += "/";
      return (
        (window.location.pathname.match(new RegExp(`${finalId}`, "g"))
        &&
        (l.url?.match(new RegExp(`${lobUrlMask[selectedLob]}`, "g")) || !pathIsLobSpecific))
      )
    }
    );
    history.push(pathMatch?.url || accessibleLinks[0]?.url || '/dashboard');
      setLobChanged(false);
    }
  },[activeLinks]);

  const handleChange = (event) => {
    dispatch(lobActions.setCurrentLob(event.target.value))
    setLobChanged(true);
  };

  const urlLOB =  window.location.pathname.match(/telecom/g) ? "TEL" :
                  window.location.pathname.match(/power-gas/g) ? "POWGAS" :
                  window.location.pathname.match(/frelo/g) ? "FRELO" :
                  window.location.pathname.match(/admin/g) ? "Admin" : null;

  if (urlLOB && selectedLob && urlLOB !== selectedLob && !lobChanged) {
    store.dispatch({
      type: lobConstants.CURRENT_LOB_SET,
      payload: urlLOB,
    });
  }


  const [renderPlaceholder, setRenderPlaceholder] = useState(false);

  useEffect(() => {
    if (isEmpty(LOBAccess) && !demoAccess && !isAdmin) {
      // user has no access to the app
      setRenderPlaceholder(true);
      return;
    }
    setRenderPlaceholder(false);
  }, [LOBAccess, demoAccess, isAdmin]);

  if (renderPlaceholder) return <></>;

  return (
    <Wrapper className={classes.root}>
      <FormControl className={classes.margin}>
        <Select
          MenuProps={{
            classes: { paper: classes.dropdownStyle },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          value={selectedLob ? selectedLob : ""}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <MenuItem
            value={"TEL"}
            className={classes.Links}
            style={{
              display: LOBAccess && LOBAccess.includes("TEL") ? "flex" : "none",
            }}
          >
            <span className={classes.span}>{lobUserMask["TEL"]}</span>
          </MenuItem>

          <MenuItem
            value={"FRELO"}
            className={classes.Links}
            style={{
              display:
                LOBAccess && LOBAccess.includes("FRELO") ? "flex" : "none",
            }}
          >
            <span className={classes.span}>{lobUserMask["FRELO"]}</span>
          </MenuItem>

          <MenuItem
            value={"POWGAS"}
            className={classes.Links}
            style={{
              display:
                LOBAccess && LOBAccess.includes("POWGAS") ? "flex" : "none",
            }}
          >
            <span className={classes.span}>{lobUserMask["POWGAS"]}</span>
          </MenuItem>

          <MenuItem
            value="Demo"
            className={classes.Links}
            style={{
              display: demoAccess && demoAccess === true ? "flex" : "none",
            }}
          >
            <span className={classes.span}>Demo</span>
          </MenuItem>

          <MenuItem
            value="Admin"
            className={classes.admin}
            onClick={openDrawer}
            style={{
              display: LOBAccess && LOBAccess.includes("Admin") ? "flex" : "none",
            }}
          >
            <div className={classes.iconWrap}>
              <SecurityIcon className={classes.icon} />
              Admin
            </div>
          </MenuItem>
        </Select>
      </FormControl>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  Xbackground-color: var(--Gray);

  .MuiSelect-select {
    .MuiSvgIcon-root {
      margin-top: 10px;
    }
  }
  @media (max-width: 900px) {
    border-radius: 4px;
  }
`;

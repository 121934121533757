import { combineReducers } from "redux";
import { project } from "./project.reducer.js";
import { reports } from "./report.reducer";

const freloRootReducer = combineReducers({
  project,
  reports,
});

export default freloRootReducer;

import { put, call } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  freloReportConstants,
} from "_constants";

import { freloReportsService } from "_services";

// import { formatDate } from "_helpers";

export function* getAllReportsFreloSaga() {
  try {
    const response = yield call(freloReportsService.getAllReports);

    yield put({
      type: freloReportConstants.GET_ALL_REPORTS_FRELO_SUCCESS,
      payload: response,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getOneReportFreloSaga({ reportId }) {
  try {
    const response = yield call(freloReportsService.getOneReport, reportId);
    let { payload } = response;

    yield put({
      type: freloReportConstants.GET_ONE_REPORT_FRELO_SUCCESS,
      payload: {
        reportId,
        data: payload,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* runOneReportFreloSaga({ reportId, payload, fields }) {
  try {
    let data = Object.keys(payload).reduce((prev, el) => {
      return [...prev, { ReportParamID: el, value: payload[el] }];
    }, []);

    const result = yield call(freloReportsService.runReport, reportId, {
      params: data,
    });

    let { response } = result;

    yield put({
      type: freloReportConstants.POST_RUN_REPORT_FRELO_SUCCESS,
      payload: {
        reportId,
        data: response,
        fields,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

// export function* getScoreboardTelecomSaga({ startDate, endDate }) {
//   try {
//     let startDate_x = startDate ? formatDate(startDate) : null;
//     let endDate_x = endDate ? formatDate(endDate) : null;

//     let startDate_ = startDate_x === "Invalid date" ? null : startDate_x;
//     let endDate_ = endDate_x === "Invalid date" ? null : endDate_x;

//     const result = yield call(
//       telecomReportsService.getScoreboard,
//       startDate_,
//       endDate_
//     );
//     let { response } = result;

//     if (response) {
//       let [header, targets, stats] = response;
//       yield put({
//         type: telecomReportConstants.GET_SCORE_BOARD_TELECOM_SUCCESS,
//         payload: { header, targets, stats },
//       });
//     }
//   } catch (error) {
//     if (error.message === "Invalid Auth Token") {
//       yield put({
//         type: authConstants.LOGOUT,
//       });
//     }
//     yield put({
//       type: alertConstants.ERROR,
//       payload: { message: error.message },
//     });
//   }
// }

// export function* getAListReportTelecomSaga({ regionId, marketId }) {
//   try {
//     yield put({
//       type: telecomReportConstants.GET_A_LIST_REPORT_TELECOM_LOADING_TRUE,
//     });
//     const result = yield call(
//       telecomReportsService.getAList,
//       regionId,
//       marketId
//     );

//     let { response } = result;

//     if (response) {
//       yield put({
//         type: telecomReportConstants.GET_A_LIST_REPORT_TELECOM_SUCCESS,
//         payload: { data: response, marketId, regionId },
//       });
//     }
//   } catch (error) {
//     if (error.message === "Invalid Auth Token") {
//       yield put({
//         type: authConstants.LOGOUT,
//       });
//     }
//     yield put({
//       type: alertConstants.ERROR,
//       payload: { message: error.message },
//     });
//   }
// }

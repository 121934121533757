import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { FormField } from "_components/core/FormField";
import moment from "moment";

export default function Index({ control, register, errors, watch, hydration }) {

  const today = moment().utc().subtract(4, "hours");
  const watchDateNotified = watch("299");
  const dateNotifiedIsToday = watchDateNotified ? moment(watchDateNotified).isSame(new Date(), "day") : false;
  const currentDate = today.format("l");
  const currentTime = today.format("HH:mm");

  const fields = [
    {
      id: 296,
      type: "text",
    },
    {
      id: 297,
      type: "text",
    },
    {
      type: "divider",
      size: 12,
    },
    {
      id: 298,
      type: "autocomplete",
      options: hydration ? hydration[298]?.["type"]["enumOptions"]?.map((e) => e.value) : [],
    },
    {
      id: 299,
      type: "date",
      maxDate: new Date(currentDate),
    },
    {
      id: 300,
      type: "time",
      maxTime: dateNotifiedIsToday ? currentTime : null,
      customValidationError: dateNotifiedIsToday ? 'Time of Damage cannot exceed the current time' : null
    },
    {
      type: "divider",
      size: 12,
    },
    {
      id: 301,
      type: "text",
    },
    {
      id: 302,
      type: "text",
    },
    {
      id: 303,
      type: "autocomplete",
      options: hydration ? hydration[303]?.["type"]["enumOptions"]?.map((e) => e.value) : [],
    },
    {
      id: 304,
      type: "text",
    },
    {
      type: "divider",
      size: 12,
    },
    {
      id: 305,
      type: "autocomplete",
      options: hydration ? hydration[305]?.["type"]["enumOptions"] : [],
    },
    {
      id: 306,
      type: "text",
    },
    {
      id: 332,
      type: "textarea",
      md: 6
    },
  ];

  fields.map((field) => {
    if (hydration && hydration[field.id]) {
      // name
      field.name = `${hydration[field.id]?.["id"]}`;
      // label
      field.label = hydration[field.id]?.["label"];
      // required
      field.required = hydration[field.id].required;
      // maxLength
      field.maxLength = hydration[field.id].type.maxLength
    }
    return field;
  });

  return (
    <>
      <Grid container item spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" gutterBottom style={{ textAlign: "center" }}>
            Basic
          </Typography>
        </Grid>
        {fields.map((field) => (
          <FormField field={field} register={register} control={control} errors={errors} />
        ))}
      </Grid>
    </>
  );
}

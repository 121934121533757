import { getApiBase } from "_helpers";
import { cookieService } from "_services";
import _uniqueId from "lodash/uniqueId";

const getFields = (LOBID, formId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/formcontrol/get-fields?lob=${LOBID}&formId=${formId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching fields");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getEntityRules = (LOBID, fieldId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/formcontrol/field-all-entities?lob=${LOBID}&fieldId=${fieldId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching entity rules for field");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getRoleRules = (LOBID, fieldId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/formcontrol/field-all-roles?lob=${LOBID}&fieldId=${fieldId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching role rules for field");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const createEntityRules = (LOBID, entityRules) => {
  let body = {
    lob: LOBID,
    entities: entityRules,
  };

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/formcontrol/post-field-entities${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 201) {
        throw new Error("error creating entity rules for field");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const createRoleRules = (LOBID, roleRules) => {
  let body = {
    lob: LOBID,
    roles: roleRules,
  };

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/formcontrol/post-field-roles${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 201) {
        throw new Error("error creating role rules for field");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateEntityRules = (LOBID, entityRules) => {
  let body = {
    lob: LOBID,
    entities: entityRules,
  };

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/formcontrol/update-field-entities${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error updating entity rules for field");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateRoleRules = (LOBID, roleRules) => {
  let body = {
    lob: LOBID,
    roles: roleRules,
  };

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/formcontrol/update-field-roles${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error updating role rules for field");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const deleteEntityRules = (LOBID, fieldId) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/formcontrol/delete-field-entities?lob=${LOBID}&fieldId=${fieldId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error deleting entity rules for field");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const deleteRoleRules = (LOBID, fieldId) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/formcontrol/delete-field-roles?lob=${LOBID}&fieldId=${fieldId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error deleting role rules for field");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

export const adminFormControlService = {
  getFields,
  getEntityRules,
  getRoleRules,
  createEntityRules,
  createRoleRules,
  updateEntityRules,
  updateRoleRules,
  deleteEntityRules,
  deleteRoleRules,
};

import { adminEntityConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { normalize } from "normalizr";
import { adminEntityManagementSchema } from "_schema";
import { produce } from "immer";

let initState = {
  entities:{},
  telecom: {
    general: {},
    paymentForm: {},
    paymentFormFees: {},
    formControl: {
      forms: {},
      rules: {},
      fields: {},
      collections: {
        all: {},
      },
    },
  },
  frelo: {
    general: {},
    paymentForm: {},
    paymentFormFees: {},
    formControl: {
      forms: {},
      rules: {},
      fields: {},
      collections: {
        all: {},
      },
    },
  },
  "power-gas": {
    general: {},
    paymentForm: {},
    paymentFormFees: {},
    formControl: {
      forms: {},
      rules: {},
      fields: {},
      collections: {
        all: {},
      },
    },
  },
};

export function adminEntityManagement(state = { ...initState }, action) {
  const { payload, type } = action;
  switch (type) {
    case adminEntityConstants.GET_CLIENT_INFORMATION_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, information } = payload;
        draft[lob]["general"][entityId] = { ...information };
      });
      case adminEntityConstants.GET_ALL_DISPATCH_ENTITIES_SUCCESS:
        return produce(state, (draft) => {
          draft["entities"] = payload;
        });
    case adminEntityConstants.GET_FORMS_SUCCESS:
      return produce(state, (draft) => {
        let { lob } = payload;
        let { formControlFormSchema } = adminEntityManagementSchema;
        const normalizedData = normalize(payload.forms, formControlFormSchema);

        draft[lob]["formControl"]["forms"] = normalizedData;
      });
    case adminEntityConstants.GET_FORM_CONTROL_COLLECTIONS_SUCCESS:
      return produce(state, (draft) => {
        
        let { lobNew, collections } = payload;
        let { formControlCollectionSchema } = adminEntityManagementSchema;
        const normalizedData = normalize(
          collections,
          formControlCollectionSchema
        );

        draft[lobNew]["formControl"]["collections"]["all"] = normalizedData;
      });
    case adminEntityConstants.GET_FORM_CONTROL_COLLECTIONS_FORMID_SUCCESS:
      return produce(state, (draft) => {
        let { lobNew, entityId, formId, collections } = payload;
        let { collectionByIdSchema } = adminEntityManagementSchema;

        const normalizedData = normalize(
          { formId, collections },
          collectionByIdSchema
        );
        draft[lobNew]["formControl"]["collections"][entityId] = normalizedData;
      });
    case adminEntityConstants.GET_FORM_CONTROL_RULES_SUCCESS:
      return produce(state, (draft) => {
        let { entityId, formId, lob, ruleSetId, rules } = payload;
        let { collectionRuleSetSchema } = adminEntityManagementSchema;

        const normalizedData = normalize(
          { formId, ruleSetId, rules },
          collectionRuleSetSchema
        );

        draft[lob]["formControl"]["rules"][entityId] = normalizedData;
      });
    case adminEntityConstants.GET_FORM_CONTROL_FIELDS_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, formId, fields } = payload;
        let { formFieldsSchema } = adminEntityManagementSchema;

        const normalizedData = normalize({ formId, fields }, formFieldsSchema);

        draft[lob]["formControl"]["fields"][entityId] = normalizedData;
      });
    case adminEntityConstants.GET_PAYMENT_FORM_SUCCESS:
      return produce(state, (draft) => {
        let { lob, entityId, data } = payload;

        draft[lob]["paymentForm"][entityId] = { ...data };
      });
    
    case adminEntityConstants.GET_PAYMENT_FORM_FEES_REQUEST:
      return produce(state, (draft) => {
        draft["telecom"]["paymentFormFees"]["loading"] = true;
        draft["frelo"]["paymentFormFees"]["loading"] = true;
        draft["power-gas"]["paymentFormFees"]["loading"] = true;
      });
      case adminEntityConstants.GET_PAYMENT_FORM_FEES_SUCCESS:
        return produce(state, (draft) => {
          let { lob, entityId, data } = payload;
  
          draft[lob]["paymentFormFees"][entityId] = { ...data };
          draft["telecom"]["paymentFormFees"]["loading"] = false;
          draft["frelo"]["paymentFormFees"]["loading"] = false;
          draft["power-gas"]["paymentFormFees"]["loading"] = false;
        });
    case REHYDRATE: {
      if (payload && payload.adminEntityManagement) {
        return { ...payload.adminEntityManagement };
      } else {
        return { ...initState };
      }
    }
    case adminEntityConstants.ENTITY_CLEAR:
      return { ...initState };
    default:
      return state;
  }
}

import { getApiBase } from "_helpers";


/**
 * Sends an email with a payment receipt.
 *
 * @param {string} email - The email address to send the receipt to.
 * @param {string} receipt - The payment receipt content.
 * @param {function} cb - The callback function to be called with the response data.
 */

const emailPaymentReceipt = (email, receipt, cb) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, receipt })
    };

    fetch(`${getApiBase()}/public/email-payment-receipt`, requestOptions)
        .then(response => response.json())
        .then(data => {
            cb(data);
        });
}


const emailRecoveryAgent = (payload) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

   fetch(`${getApiBase()}/public/email-recovery-agent`, requestOptions);
}


export const publicService = {
    emailPaymentReceipt,
    emailRecoveryAgent
};
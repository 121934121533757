import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const getAll = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/joint/roundrobin${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching All Agents");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getOne = (id) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/joint/roundrobin/one?nurid=${id}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching Agent");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getLocateSchema = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/joint/roundrobin/schema/locatecompanies${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching Locate Companies");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getEntitySchema = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/joint/roundrobin/schema/entities${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching Locate Companies");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateAgent = (id, payload) => {
 
  let body = {
    ...payload,
  };
  console.log(id,body)
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/joint/roundrobin?nurid=${id}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating Agent");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateStatus = ({ agentId, ...payload }) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/joint/roundrobin/active?nurid=${agentId}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating Agent status");
      }

      return { agentId, message: "updated" };
    })
    .catch((err) => {
      throw err;
    });
};

export const adminRoundRobinService = {
  getAll,
  getOne,
  getLocateSchema,
  getEntitySchema,
  updateAgent,
  updateStatus,
};

import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";
import { normalize } from "normalizr";
import { telecomClaimConstants } from "_constants";
import { telecomClaimSchema } from "_schema";
let { allClaimsSchema, myClaimsSchema } = telecomClaimSchema;

let initState = {
  all: {},
  my: {},
  myTeams: {},
  mobile: {},
  individual: {
    loading: false,
    logo:{}
  },
  create: {},
  attachments: {},
  history: {},
  schema: {
    entityOptions: [],
    rdaOptions: {},
    filterOptions: [],
    filterSets: [],
    attachmentImageCategories: [],
    attachmentVideoCategories: [],
    attachmentDocumentCategories: {}, // {[parentType]: data}
  },
  jobCosting: {
    laborRateCardContractor: {}, // [claimId]: []
    laborRateCardMaterial: {}, // [claimId]: []
    laborRateCardUsic: {}, // [claimId]: []
    laborRateCard: {}, // [claimId]: []
    invoice: {}, // [claimId]: {}
  },
  docs: {
    invoice: {},
    claimLetter: {},
    intentToBill: {}, // {[claimId]: data}
    secondNotice: {},
    legalFinalNotice: {},
    finalNotice: {},
    releaseLetter: {},
    paymentReceipt: {}, // { [`${claimId}-${ledgerId}`]: data }
    balanceOwed:{},
    narrativeCreator: {},
    promissoryNote: {}, // {[claimId]: data}
    schemaClaimLetterReasons: [],
    schemaClaimLetterContacts: [],
    schemaClaimLetterLastSentence: {},
    schemaDigLaws: {}, // {[claimId]: [...data]}
  },
  // logo:{},
};

export function claim(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case telecomClaimConstants.GET_ALL_CLAIMS_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        const normalizedData = normalize(payload, allClaimsSchema);
        draft["all"] = normalizedData;
        draft["all"]["columns"] = payload.columnFilters;
      });
    case telecomClaimConstants.GET_MY_CLAIMS_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        const normalizedData = normalize(payload, myClaimsSchema);
        draft["my"] = normalizedData;
        draft["all"]["columns"] = payload.columnFilters;
      });
      case telecomClaimConstants.GET_MY_TEAMS_CLAIMS_TELECOM_SUCCESS:
        return produce(state, (draft) => {
          const normalizedData = normalize(payload, myClaimsSchema);
          draft["myTeams"] = normalizedData;
          draft["all"]["columns"] = payload.columnFilters;
        });
      case telecomClaimConstants.GET_MY_MOBILE_CLAIMS_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        draft["mobile"] =  [payload];
      });
    case telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_LOADING_TRUE:
      return {
        ...state,
        individual: {
          loading: true,
        },
      };
    case telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_SUCCESS:
      // Note
      // we want to only store one claim at a time, since claims are 200kb each.
      // that can get expensive rather quickly -> regarding storage
      let { claimId, claim } = payload;
      return {
        ...state,
        individual: {
          [claimId]: { ...claim },
          loading: false,
        },
      };
    case telecomClaimConstants.GET_SCHEMA_ENTITY_OPTIONS_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { options } = payload;
        draft["schema"]["entityOptions"] = [...options];
      });
    case telecomClaimConstants.GET_SCHEMA_RDA_OPTIONS_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { options, entityId } = payload;
        draft["schema"]["rdaOptions"][entityId] = [...options];
      });
    case telecomClaimConstants.GET_CREATE_CLAIM_FIELD_RULES_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { rules, entityId } = payload;
        draft["create"][entityId] = { ...rules };
      });
    case telecomClaimConstants.GET_SCHEMA_FILTER_OPTIONS_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        draft["schema"]["filterOptions"] = [...payload];
      });
    case telecomClaimConstants.GET_FILTER_SETS_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        draft["schema"]["filterSets"] = [...payload];
      });
    case telecomClaimConstants.GET_CLAIM_ATTACHMENTS_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { claimId, files } = payload;
        draft["attachments"][claimId] = [...files];
      });
    case telecomClaimConstants.GET_CLAIM_HISTORY_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;
        draft["history"][claimId] = [...data];
      });
    case telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;
        // only store one at a time
        draft["jobCosting"]["invoice"] = { [claimId]: { ...data } };
      });
    case telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;
        // only store one at a time
        draft["jobCosting"]["laborRateCardContractor"] = {
          [claimId]: [...data],
        };
      });
    case telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;
        // only store one at a time
        draft["jobCosting"]["laborRateCardMaterial"] = {
          [claimId]: [...data],
        };
      });
    case telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;
        // only store one at a time
        draft["jobCosting"]["laborRateCard"] = {
          [claimId]: [...data],
        };
      });
    case telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_USIC_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;
        // only store one at a time
        draft["jobCosting"]["laborRateCardUsic"] = {
          [claimId]: [...data],
        };
      });
    case telecomClaimConstants.GET_CLAIM_INVOICE_DOCUMENT_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;
        draft["docs"]["invoice"] = {
          [claimId]: data,
        };
      });
    case telecomClaimConstants.GET_CLAIM_DOCUMENT_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId, letterType } = payload;

        draft["docs"]["claimLetter"] = {
          [`${claimId}-${letterType}`]: data,
        };
      });
    case telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data } = payload;
        draft["docs"]["schemaClaimLetterReasons"] = data;
      });
    case telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data } = payload;
        draft["docs"]["schemaClaimLetterContacts"] = [...data];
      });
    case telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, entityId } = payload;

        draft["docs"]["schemaClaimLetterLastSentence"] = {
          [entityId]: data,
        };
      });
    case telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        draft["schema"]["attachmentImageCategories"] = payload;
      });
    case telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        draft["schema"]["attachmentVideoCategories"] = payload;
      });
    case telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, parentType } = payload;

        draft["schema"]["attachmentDocumentCategories"] = {
          [parentType]: data,
        };
      });
    case telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_DIG_LAWS_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["schemaDigLaws"] = {
          [claimId]: data,
        };
      });
    case telecomClaimConstants.GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["narrativeCreator"] = { [claimId]: data };
      });
    case telecomClaimConstants.GET_CLAIM_INTENT_TO_BILL_DOCUMENT_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["intentToBill"] = { [claimId]: data };
      });

    case telecomClaimConstants.GET_CLAIM_PROMISSORY_DOCUMENT_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["promissoryNote"] = { [claimId]: data };
      });
    case telecomClaimConstants.GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["legalFinalNotice"] = { [claimId]: data };
      });
    case telecomClaimConstants.GET_CLAIM_FINAL_NOTICE_DOCUMENT_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["finalNotice"] = { [claimId]: data };
      });
    case telecomClaimConstants.GET_CLAIM_RELEASE_LETTER_DOCUMENT_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["releaseLetter"] = { [claimId]: data };
      });

    case telecomClaimConstants.GET_CLAIM_SECOND_NOTICE_DOCUMENT_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["secondNotice"] = { [claimId]: data };
      });
    case telecomClaimConstants.GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId, ledgerId } = payload;

        draft["docs"]["paymentReceipt"] = { [`${claimId}-${ledgerId}`]: data };
      });
      case telecomClaimConstants.GET_CLAIM_BALANCE_OWED_DOCUMENT_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["balanceOwed"] = { [claimId]: data };
      });
      case telecomClaimConstants.GET_LOGO_TELECOM_SUCCESS:
      return produce(state, (draft) => {
        let {data } = payload;
        draft["individual"]["logo"] = { data };
      });
      case telecomClaimConstants.CONFIRM_TEL_DISPATCH:
        return produce(state, (draft) => {
          draft["individual"][payload.claimId]["Claim"]["Overview"]["24"]["value"] = 'A0__Open';
        });
    case REHYDRATE: {
      if (payload && payload.telecom && payload.telecom.claim) {
        return { ...payload.telecom.claim };
      } else {
        return { ...initState };
      }
    }
    case telecomClaimConstants.TELECOM_CLAIM_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

export const mobileConstants = {
    LOGIN_REQUEST:"LOGIN_REQUEST",
    LOGIN_SUCCESS:"LOGIN_SUCCESS",
    GET_INITIAL_MOBILE_REQUEST:"GET_INITIAL_MOBILE_REQUEST",
    GET_INITIAL_MOBILE_SUCCESS:"GET_INITIAL_MOBILE_SUCCESS",
    GET_PRECLAIMS_MOBILE_REQUEST:"GET_PRECLAIMS_MOBILE_REQUEST",
    GET_PRECLAIMS_MOBILE_SUCCESS:"GET_PRECLAIMS_MOBILE_SUCCESS",
    GET_ALL_MOBILE_CLAIMS_REQUEST: "GET_ALL_MOBILE_CLAIMS_REQUEST",
    GET_ALL_MOBILE_CLAIMS_SUCCESS: "GET_ALL_MOBILE_CLAIMS_SUCCESS",
    // GET_RDA_MOBILE_REQUEST:"GET_RDA_MOBILE_REQUEST",
    // GET_RDA_MOBILE_SUCCESS:"GET_RDA_MOBILE_SUCCESS",
    CREATE_INITIAL_MOBILE_REQUEST:"CREATE_INITIAL_MOBILE_REQUEST",
    CREATE_INITIAL_MOBILE_SUCCESS:"CREATE_INITIAL_MOBILE_SUCCESS",
    GET_FULL_MOBILE_REQUEST:"GET_FULL_MOBILE_REQUEST",
    GET_FULL_MOBILE_SUCCESS:'GET_FULL_MOBILE_SUCCESS',
    UPDATE_FULL_MOBILE_REQUEST:"UPDATE_FULL_MOBILE_REQUEST",
    UPDATE_FULL_MOBILE_SUCCESS:'UPDATE_FULL_MOBILE_SUCCESS',
    CREATE_MOVE_TO_PRG_MOBILE_REQUEST:"CREATE_MOVE_TO_PRG_MOBILE_REQUEST",
    CREATE_MOVE_TO_PRG_MOBILE_SUCCESS:"CREATE_MOVE_TO_PRG_MOBILE_SUCCESS",
    GET_MOBILE_ATTACHMENTS_REQUEST:"GET_MOBILE_ATTACHMENTS_REQUEST",
    GET_MOBILE_ATTACHMENTS_SUCCESS:"GET_MOBILE_ATTACHMENTS_SUCCESS",
    UPLOAD_CLAIM_ATTACHMENT_MOBILE_REQUEST:"UPLOAD_CLAIM_ATTACHMENT_MOBILE_REQUEST",
    UPLOAD_CLAIM_ATTACHMENT_MOBILE_SUCCESS:"UPLOAD_CLAIM_ATTACHMENT_MOBILE_SUCCESS",
    DELETE_MOBILE_CLAIM_REQUEST: "DELETE_MOBILE_CLAIM_REQUEST",
    DELETE_MOBILE_CLAIM_SUCCESS: "DELETE_MOBILE_CLAIM_SUCCESS",
    // GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_MOBILE_REQUEST:"GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_MOBILE_REQUEST",
    // GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_MOBILE_SUCCESS:"GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_MOBILE_SUCCESS",
    // GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_MOBILE_REQUEST:"GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_MOBILE_REQUEST",
    // GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_MOBILE_SUCCESS:"GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_MOBILE_SUCCESS",
    // GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_MOBILE_REQUEST:"GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_MOBILE_REQUEST",
    // GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_MOBILE_SUCCESS:"GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_MOBILE_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
    MOBILE_CLEAR: "MOBILE_CLEAR",

}
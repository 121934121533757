import React from "react";
import { Controller } from "react-hook-form";
import theme from "_theme/theme";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker, TimeField } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

require("moment-timezone");
moment.tz.setDefault("Etc/UTC");

export default function Index({
  control,
  name,
  placeholder,
  label,
  disabled,
  defaultValue,
  darkMode,
  testId,
  error,
  helperText,
  size,
  disableOpenPicker,
  minTime,
  maxTime,
  useTimeField,
  timezone,
  customValidationError,
  required
}) {
 
  return (
    <Controller
    rules={{ validate: value => {
      if (required && !value) {
        return customValidationError ?? "Required"
      }
      if (maxTime) {
        const valueFormatted = moment(value).format("HH:mm");
        const maxTimeFormatted = typeof(maxTime) === "string" ? maxTime : moment(maxTime).format("HH:mm");
        // catch case where user manually entered time via keyboard (value entered exceeds maxTime value)
        if (valueFormatted > maxTimeFormatted) {
          return customValidationError ?? 'Value exceeds maximum time allowed'
        }
      }
      return true
    }
}}
      render={({ ref, ...props }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {useTimeField ? (
               <TimeField
               {...props}
               value={
                 props.value &&
                 props.value !== null &&
                 props.value !== undefined &&
                 props.value !== "" ? 
                  //  maxTime && moment(props.value) > maxTime ? maxTime :
                  //  minTime && moment(props.value) < minTime ? minTime : 
                   moment(props.value) : null
               }
               label={label}
               inputRef={ref}
               data-testid={testId ? testId : ""}
               ampm
               mask="__:__ _M"
               disabled={disabled ? disabled : false}
               error={error ? error : false}
               size={size ? size : "small"}
               margin="dense"
               sx={{
                 width: "100%",
                 minwidth: "100%",
                 backgroundColor: darkMode
                   ? theme.palette.HeaderGrayPrg.main
                   : theme.palette.WhitePrg.main,
                 input: { padding: "11px 14px 6px" }, // fix for small size (time picker has no size prop at this time)
                 label: {
                   transform: "translate(14px, -9px) scale(0.75) !important",
                 }, // fix for this component while Mui V4 is installed (v4 causes slight issue)
               }}
               slotProps={{ textField: { 
                InputLabelProps: { shrink: true },
                error: error,
                helperText: helperText
              } }}
               helperText={helperText ? helperText : null}
               disableOpenPicker={disableOpenPicker || false}
              //  maxTime={maxTime ? maxTime : moment(Date.UTC(0,0,0,23,59))}
              //  minTime={minTime ? minTime : moment(Date.UTC(0,0,0,0,0))}
               closeOnSelect={false}
             />
            ) : (
            <TimePicker
              {...props}
              value={
                props.value &&
                props.value !== null &&
                props.value !== undefined &&
                props.value !== "" ? 
                  // maxTime && moment(props.value) > maxTime ? maxTime :
                  // minTime && moment(props.value) < minTime ? minTime : 
                  moment(props.value) : null
              }
              label={label}
              inputRef={ref}
              data-testid={testId ? testId : ""}
              ampm
              mask="__:__ _M"
              disabled={disabled ? disabled : false}
              error={error ? error : false}
              size={size ? size : "small"}
              margin="dense"
              sx={{
                width: "100%",
                minwidth: "100%",
                backgroundColor: darkMode
                  ? theme.palette.HeaderGrayPrg.main
                  : theme.palette.WhitePrg.main,
                input: { padding: "11px 14px 6px" }, // fix for small size (time picker has no size prop at this time)
                label: {
                  transform: "translate(14px, -9px) scale(0.75) !important",
                }, // fix for this component while Mui V4 is installed (v4 causes slight issue)
              }}
              slotProps={{ textField: { 
                InputLabelProps: { shrink: true },
                error: error,
                helperText: helperText
               } }}
              helperText={helperText ? helperText : null}
              disableOpenPicker={disableOpenPicker || false}
              // maxTime={maxTime ? maxTime : moment(Date.UTC(0,0,0,23,59))}
              // minTime={minTime ? minTime : moment(Date.UTC(0,0,0,0,0))}
              closeOnSelect={false}
              timezone={timezone ? timezone : null}
            />
            )}
          </LocalizationProvider>
        );
      }}
      control={control}
      name={name}
      placeholder={placeholder ? placeholder : ""}
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
}

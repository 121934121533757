import { adminRoleConstants } from "_constants";

const getRoles = () => {
  return {
    type: adminRoleConstants.GET_ALL_ROLES_REQUEST,
  };
};

const createRole = (payload) => {
  return {
    type: adminRoleConstants.CREATE_NEW_ROLE_REQUEST,
    payload,
  };
};

const cloneRole = (payload) => {
  return {
    type: adminRoleConstants.CLONE_ROLE_REQUEST,
    payload,
  };
};

const updateRoleGeneral = (payload, roleId) => {
  return {
    type: adminRoleConstants.UPDATE_ROLE_GENERAL_REQUEST,
    roleId,
    payload,
  };
};

const getGeneral = (roleId) => {
  return {
    type: adminRoleConstants.GET_ROLE_GENERAL_REQUEST,
    roleId,
  };
};

const getDbAccess = (roleId) => {
  return {
    type: adminRoleConstants.GET_ROLE_DB_ACCESS_REQUEST,
    roleId,
  };
};

const updateRoleDbAccess = (payload, roleId) => {
  return {
    type: adminRoleConstants.UPDATE_ROLE_DB_ACCESS_REQUEST,
    payload,
    roleId,
  };
};

const getRoleDashboard = (roleId) => {
  return {
    type: adminRoleConstants.GET_ROLE_DASHBOARD_REQUEST,
    roleId,
  };
};

const addRoleDashboard = (payload, roleId) => {
  return {
    type: adminRoleConstants.ADD_ROLE_DASHBOARD_REQUEST,
    payload,
    roleId,
  };
};

const removeRoleDashboard = (pannelId, roleId) => {
  return {
    type: adminRoleConstants.REMOVE_ROLE_DASHBOARD_REQUEST,
    pannelId,
    roleId,
  };
};

const updateRoleDashboard = (payload, roleId) => {
  return {
    type: adminRoleConstants.UPDATE_ROLE_DASHBOARD_REQUEST,
    payload,
    roleId,
  };
};

const updateRoleDashboardPosition = (payload, roleid, dashid) => {
  return {
    type: adminRoleConstants.UPDATE_ROLE_DASHBOARD_POSITION_REQUEST,
    payload,
    roleid,
    dashid,
  };
};

const getRoleRDA = (roleId) => {
  return {
    type: adminRoleConstants.GET_ROLE_RDA_REQUEST,
    roleId,
  };
};

const getRoleEntities = (roleId) => {
  return {
    type: adminRoleConstants.GET_ROLE_ENTITIES_REQUEST,
    roleId,
  };
};

const updateRoleEntities = (payload, roleId) => {
  return {
    type: adminRoleConstants.UPDATE_ROLE_ENTITIES_REQUEST,
    payload,
    roleId,
  };
};

const getRoleActionAccess = (roleId) => {
  return {
    type: adminRoleConstants.GET_ROLE_ACTION_ACCESS_REQUEST,
    roleId,
  };
};

const updateRoleActionAccess = (payload, roleId, accessTypeLOB) => {
  return {
    type: adminRoleConstants.UPDATE_ROLE_ACTION_ACCESS_REQUEST,
    payload,
    roleId,
    accessTypeLOB
  };
};

const getRoleStatusAccess = (roleId) => {
  return {
    type: adminRoleConstants.GET_ROLE_STATUS_ACCESS_REQUEST,
    roleId,
  };
};

const updateRoleStatusAccess = (payload, roleId, LOBID, statusType) => {
  return {
    type: adminRoleConstants.UPDATE_ROLE_STATUS_ACCESS_REQUEST,
    payload, roleId, LOBID, statusType
  };
};


const getRoleNotifications = (roleId) => {
  return {
    type: adminRoleConstants.ADMIN_ROLES_NOTIFICATIONS_REQUEST,
    roleId
  };
};

const updateRoleNotifications = ({notificationIds}, roleId) => {
  return {
    type: adminRoleConstants.ADMIN_ROLES_UPDATE_NOTIFICATIONS_REQUEST,
    roleId, notificationIds
  }
};

const deleteRoleNotifications = (roleId) => {
  return {
    type: adminRoleConstants.ADMIN_ROLES_DELETE_NOTIFICATIONS_REQUEST,
    roleId
  }
};

export const adminRoleActions = {
  getRoles,
  createRole,
  cloneRole,
  getGeneral,
  getRoleRDA,
  updateRoleGeneral,
  getDbAccess,
  updateRoleDbAccess,
  getRoleDashboard,
  addRoleDashboard,
  removeRoleDashboard,
  updateRoleDashboard,
  updateRoleDashboardPosition,
  getRoleEntities,
  updateRoleEntities,
  getRoleActionAccess,
  updateRoleActionAccess,
  getRoleStatusAccess,
  updateRoleStatusAccess,
  getRoleNotifications,
  updateRoleNotifications,
  deleteRoleNotifications
};

import { freloProjectConstants } from "_constants";

const getAll = (page, rowsPerPage, filterModal, search) => {
  return {
    type: freloProjectConstants.GET_ALL_PROJECTS_FRELO_REQUEST,
    page,
    rowsPerPage,
    filterModal,
    search,
  };
};

const getMy = (page, rowsPerPage, filterModal, search) => {
  return {
    type: freloProjectConstants.GET_MY_PROJECTS_FRELO_REQUEST,
    page,
    rowsPerPage,
    filterModal,
    search,
  };
};

const getById = (projectId) => {
  return {
    type: freloProjectConstants.GET_PROJECT_BY_ID_FRELO_REQUEST,
    projectId,
  };
};

const update = (projectId, payload) => {
  return {
    type: freloProjectConstants.UPDATE_FRELO_PROJECT_REQUEST,
    projectId,
    payload,
  };
};

const getCreateProjectFieldRules = (entityId) => {
  return {
    type: freloProjectConstants.GET_CREATE_PROJECT_FIELD_RULES_FRELO_REQUEST,
    entityId,
  };
};

const getSchemaEntityOptions = () => {
  return {
    type: freloProjectConstants.GET_SCHEMA_ENTITY_OPTIONS_FRELO_REQUEST,
  };
};

const getSchemaRdaOptions = (entityId) => {
  return {
    type: freloProjectConstants.GET_SCHEMA_RDA_OPTIONS_FRELO_REQUEST,
    entityId,
  };
};

const getSchemaFilterConditions = () => {
  return {
    type: freloProjectConstants.GET_SCHEMA_FILTER_OPTIONS_FRELO_REQUEST,
  };
};

const getFilterSets = () => {
  return {
    type: freloProjectConstants.GET_FILTER_SETS_FRELO_REQUEST,
  };
};
const createFilterSet = (payload) => {
  return {
    type: freloProjectConstants.CREATE_FILTER_SET_FRELO_REQUEST,
    payload,
  };
};
const updateFilterSet = (id, payload) => {
  return {
    type: freloProjectConstants.UPDATE_FILTER_SET_FRELO_REQUEST,
    id,
    payload,
  };
};

const removeFilterSet = (id) => {
  return {
    type: freloProjectConstants.REMOVE_FILTER_SET_FRELO_REQUEST,
    id,
  };
};

const duplicateFilterSet = (name, id) => {
  return {
    type: freloProjectConstants.DUPLICATE_FILTER_SET_FRELO_REQUEST,
    name,
    id,
  };
};

const uploadFile = (files, fieldValues, claimId) => {
  return {
    type: freloProjectConstants.UPLOAD_CLAIM_ATTACHMENT_FRELO_REQUEST,
    payload: { ...fieldValues, files },
    claimId,
  };
};

const getFiles = (claimId) => {
  return {
    type: freloProjectConstants.GET_CLAIM_ATTACHMENTS_FRELO_REQUEST,
    claimId,
  };
};

const postPayment = (claimId, data) => {
  return {
    type: freloProjectConstants.POST_PAYMENT_FRELO_REQUEST,
    claimId,
    payload: { ...data },
  };
};

const getProjectHistory = (projectId) => {
  return {
    type: freloProjectConstants.GET_PROJECT_HISTORY_FRELO_REQUEST,
    projectId,
  };
};
const deleteLedger = (id) => {
  return {
    type: freloProjectConstants.REMOVE_LEDGER_FRELO_REQUEST,
    id,
  };
};

const deleteStatusNote = (id,watchId) => {
  return {
    type: freloProjectConstants.REMOVE_STATUS_NOTE_FRELO_REQUEST,
    id,
    watchId
  };
};

export const freloProjectActions = {
  getAll,
  getMy,
  getById,
  update,
  getCreateProjectFieldRules,
  getSchemaEntityOptions,
  getSchemaRdaOptions,
  getSchemaFilterConditions,
  getFilterSets,
  createFilterSet,
  updateFilterSet,
  removeFilterSet,
  duplicateFilterSet,
  uploadFile,
  getFiles,
  postPayment,
  getProjectHistory,
  deleteLedger,
  deleteStatusNote
};

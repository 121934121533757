export const adminRoundRobinConstants = {
  ADMIN_GET_ALL_ROUNDROBIN_REQUEST: "ADMIN_GET_ALL_ROUNDROBIN_REQUEST",
  ADMIN_GET_ALL_ROUNDROBIN_SUCCESS: "ADMIN_GET_ALL_ROUNDROBIN_SUCCESS",
  ADMIN_GET_ONE_ROUNDROBIN_REQUEST: "ADMIN_GET_ONE_ROUNDROBIN_REQUEST",
  ADMIN_GET_ONE_ROUNDROBIN_SUCCESS: "ADMIN_GET_ONE_ROUNDROBIN_SUCCESS",
  ADMIN_GET_LOCATE_SCHEMA_ROUNDROBIN_REQUEST:
    "ADMIN_GET_LOCATE_SCHEMA_ROUNDROBIN_REQUEST",
  ADMIN_GET_LOCATE_SCHEMA_ROUNDROBIN_SUCCESS:
    "ADMIN_GET_LOCATE_SCHEMA_ROUNDROBIN_SUCCESS",
  ADMIN_GET_ENTITIES_SCHEMA_ROUNDROBIN_REQUEST:
    "ADMIN_GET_ENTITIES_SCHEMA_ROUNDROBIN_REQUEST",
  ADMIN_GET_ENTITIES_SCHEMA_ROUNDROBIN_SUCCESS:
    "ADMIN_GET_ENTITIES_SCHEMA_ROUNDROBIN_SUCCESS",
  ADMIN_UPDATE_AGENT_ROUNDROBIN_REQUEST:
    "ADMIN_UPDATE_AGENT_ROUNDROBIN_REQUEST",
  ADMIN_UPDATE_AGENT_ROUNDROBIN_SUCCESS:
    "ADMIN_UPDATE_AGENT_ROUNDROBIN_SUCCESS",
  ADMIN_UPDATE_AGENT_STATUS_ROUNDROBIN_REQUEST:
    "ADMIN_UPDATE_AGENT_STATUS_ROUNDROBIN_REQUEST",
  ADMIN_UPDATE_AGENT_STATUS_ROUNDROBIN_SUCCESS:
    "ADMIN_UPDATE_AGENT_STATUS_ROUNDROBIN_SUCCESS",
  SET_ADMIN_INDIVIDUAL_ROUNDROBIN_LOADING_TRUE:
    "SET_ADMIN_INDIVIDUAL_ROUNDROBIN_LOADING_TRUE",
  SET_ADMIN_INACTIVE_ROUNDROBIN_LOADING_TRUE:
    "SET_ADMIN_INACTIVE_ROUNDROBIN_LOADING_TRUE",
  SET_ADMIN_INACTIVE_ROUNDROBIN_LOADING_FALSE:
    "SET_ADMIN_INACTIVE_ROUNDROBIN_LOADING_FALSE",
  ADMIN_ROUNDROBIN_CLEAR: "ADMIN_ROUNDROBIN_CLEAR",
};

import { all, put, call } from "redux-saga/effects";
import {
  adminFormControlConstants,
  alertConstants,
  authConstants,
} from "_constants";
import { adminFormControlService } from "_services";

export function* getFieldsSaga({ LOBID, formId }) {
  try {
    const response = yield call(
      adminFormControlService.getFields,
      LOBID,
      formId
    );

    yield put({
      type: adminFormControlConstants.GET_FIELDS_SUCCESS,
      payload: { data: [...response], LOBID, formId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getEntityRulesSaga({ LOBID, fieldId }) {
  try {
    const response = yield call(
      adminFormControlService.getEntityRules,
      LOBID,
      fieldId
    );

    yield put({
      type: adminFormControlConstants.GET_ENTITY_RULES_SUCCESS,
      payload: { data: [...response], LOBID, fieldId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getRoleRulesSaga({ LOBID, fieldId }) {
  try {
    const response = yield call(
      adminFormControlService.getRoleRules,
      LOBID,
      fieldId
    );

    yield put({
      type: adminFormControlConstants.GET_ROLE_RULES_SUCCESS,
      payload: { data: [...response], LOBID, fieldId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* createAndUpdateFieldRulesSaga({
  LOBID,
  formId,
  entityRulesPOST,
  entityRulesPUT,
  roleRulesPOST,
  roleRulesPUT,
}) {

  try {

    // store calls for arrays that had values
    const servicesToCallAsync = [];
    
    if (entityRulesPOST.length > 0) {
      servicesToCallAsync.push(
        call(adminFormControlService.createEntityRules, LOBID, entityRulesPOST)
      );
    }
    if (entityRulesPUT.length > 0) {
      servicesToCallAsync.push(
        call(adminFormControlService.updateEntityRules, LOBID, entityRulesPUT)
      );
    }
    if (roleRulesPOST.length > 0) {
      servicesToCallAsync.push(
        call(adminFormControlService.createRoleRules, LOBID, roleRulesPOST)
      );
    }
    if (roleRulesPUT.length > 0) {
      servicesToCallAsync.push(
        call(adminFormControlService.updateRoleRules, LOBID, roleRulesPUT)
      );
    }

    // call services async
    yield all(servicesToCallAsync);

    // refresh field values
    const response = yield call(
      adminFormControlService.getFields,
      LOBID,
      formId
    );

    yield put({
      type: adminFormControlConstants.GET_FIELDS_SUCCESS,
      payload: { data: [...response], LOBID, formId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Field Rules Updated" },
    });

  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteEntityRulesSaga({ LOBID, formId, fieldId }) {
  try {

    const responseDELETE = yield call(
      adminFormControlService.deleteEntityRules,
      LOBID,
      fieldId
    );

    // refresh field values
    const responseGET = yield call(
      adminFormControlService.getFields,
      LOBID,
      formId
    );

    yield put({
      type: adminFormControlConstants.GET_FIELDS_SUCCESS,
      payload: { data: [...responseGET], LOBID, formId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Field Entity Rules Deleted" },
    });

  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteRoleRulesSaga({ LOBID, formId, fieldId }) {
  try {

    const responseDELETE = yield call(
      adminFormControlService.deleteRoleRules,
      LOBID,
      fieldId
    );

    // refresh field values
    const responseGET = yield call(
      adminFormControlService.getFields,
      LOBID,
      formId
    );

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Field Role Rules Deleted" },
    });

    yield put({
      type: adminFormControlConstants.GET_FIELDS_SUCCESS,
      payload: { data: [...responseGET], LOBID, formId },
    });

  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

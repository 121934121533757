import { alertConstants } from "../_constants";

function success(message, duration) {
  return { type: alertConstants.SUCCESS, payload: { message, duration } };
}

function info(message, duration) {
  return { type: alertConstants.INFO, payload: { message, duration} };
}

function versionCheck(message, duration) {
  return { type: alertConstants.VERSION_CHECK, payload: { message, duration} };
}

function error(message, duration) {
  return { type: alertConstants.ERROR, payload: { message, duration} };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

export const alertActions = {
  success,
  info,
  versionCheck,
  error,
  clear,
};

import { powerGasReportConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";
let initState = {
  all: [],
  individual: {},
  fields: {},
  loading: false
};
export function reports(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case powerGasReportConstants.GET_ALL_REPORTS_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        draft["loading"] = false;
        draft["all"] = payload;
      });
    case powerGasReportConstants.GET_ONE_REPORT_POWER_GAS_REQUEST:
      return produce(state, (draft) => {
        draft["loading"] = true;
        draft["individual"] = {};
      });
    case powerGasReportConstants.GET_ONE_REPORT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { reportId, data } = payload;

        if (data && data["payload"]) {
          draft["fields"] = { [reportId]: data["payload"] };
        }
        draft["loading"] = false;
      });
    case powerGasReportConstants.POST_RUN_REPORT_POWER_GAS_REQUEST:
      return produce(state, (draft) => {
        draft["loading"] = true;
      });
    case powerGasReportConstants.POST_RUN_REPORT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { reportId, data, fields } = payload;

        draft["individual"] = { [reportId]: { data, fields } };
        draft["loading"] = false;
      });
    case powerGasReportConstants.POST_RUN_REPORT_POWER_GAS_ERROR:
      return produce(state, (draft) => {
        draft["loading"] = false;
      });
    case REHYDRATE: {
      if (payload && payload.powerGas && payload.powerGas.reports) {
        return { ...payload.powerGas.reports };
      } else {
        return { ...initState };
      }
    }
    case powerGasReportConstants.POWER_GAS_REPORTS_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

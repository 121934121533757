import { put, call, all } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  adminknowledgeBaseConstants,
} from "_constants";
import { adminKnowledgebaseService } from "_services";
import { isEmpty } from "ramda";
import { buildAlertMessage } from "_utility";

//FAQ
export function* getFaqSaga() {
  try {
    const response = yield call(adminKnowledgebaseService.getFaq);

    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_FAQ_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateFaqSaga({ payload, faqid }) {
  try {
   yield call(adminKnowledgebaseService.updateFaq, {
      ...payload,
      faqid,
    });

    const response = yield call(adminKnowledgebaseService.getFaq);
    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_FAQ_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `FAQ updated`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* addFaqSaga({ payload }) {
  try {
    yield call(adminKnowledgebaseService.addFaq, {
      ...payload,
    });

    const response = yield call(adminKnowledgebaseService.getFaq);

    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_FAQ_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `FAQ Added`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* removeFaqSaga({ payload }) {
  try {
    const responses = yield all(
      payload.map((id) => call(adminKnowledgebaseService.removeFaq, id))
    );

    if (isEmpty(responses)) {
      throw new Error("Error removing FAQ");
    }

    let statusMessage = buildAlertMessage("FAQ", responses);

    const response = yield call(adminKnowledgebaseService.getFaq);

    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_FAQ_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

//KB
// Get a Topics resources
export function* getKBSaga({ targetTopic }) {
  try {
    const response = yield call(adminKnowledgebaseService.getKb, targetTopic);

    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_KB_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
//GET Topics
export function* getKBTopicsSaga() {
  try {
    const response = yield call(adminKnowledgebaseService.getKbTopics);

    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_KBTOPICS_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getKBMarketsSaga() {
  try {
    const response = yield call(adminKnowledgebaseService.getKbMarkets);

    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_KBMARKETS_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getKBResourcetypesSaga() {
  try {
    const response = yield call(adminKnowledgebaseService.getKbResourcetypes);

    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_KBRESOURCETYPES_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
//POST a new Topic
export function* addKbTopicSaga({ payload }) {
  try {
    yield call(adminKnowledgebaseService.addKbTopic, {
      ...payload,
    });

    const response = yield call(adminKnowledgebaseService.getKbTopics);

    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_KBTOPICS_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `Topic Added`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
//Update a Topic *Still not working
export function* updateKbTopicSaga({ payload }) {
  try {
    yield call(adminKnowledgebaseService.updateKbTopic,payload);

    const response = yield call(adminKnowledgebaseService.getKbTopics);

    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_KBTOPICS_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `Topic updated`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

//Add a new Resource to a Topic
export function* addKbResourceSaga({ payload, targetTopic }) {
  try {
    yield call(adminKnowledgebaseService.addKbResource, payload);
    const response = yield call(adminKnowledgebaseService.getKb, targetTopic);

    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_KB_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `Resource Added`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

//Update a Topics Resource
export function* updateKbResourceSaga({ payload, resourceid }) {
  try {
    yield call(adminKnowledgebaseService.updateKbResource, payload, resourceid);

    const response = yield call(
      adminKnowledgebaseService.getKb,
      payload.topicId
    );

    yield put({
      type: adminknowledgeBaseConstants.GET_ALL_KB_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `Resource updated`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

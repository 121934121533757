import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";
import { mobileConstants } from "_constants";

let initState = {
  loggedIn: false,
  loggingIn: false,
  userId: "",
  entityID: "",
  entityLogo: "",
  myClaims: {},
  form: {},
  formLoading: false,
  meta: {},
  convertLoading: false,
  convertClaimID: null,
  attachments: [],
  allClaims: [],
  allClaimsLoading: false,
  deleteLoading: false,
};

export function mobile(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case mobileConstants.LOGIN_REQUEST:
      return produce(state, (draft) => {
        draft["loggingIn"] = true;
      });
    case mobileConstants.LOGIN_SUCCESS:
      return produce(state, (draft) => {
        draft["loggedIn"] = true;
        draft["loggingIn"] = false;
        draft["userId"] = payload.userId;
        draft["entityID"] = payload.entityID;
        draft["entityLogo"] = payload.entityLogo;
      });

    case mobileConstants.GET_PRECLAIMS_MOBILE_SUCCESS:
      return produce(state, (draft) => {
        let { email, data } = payload;
        draft["myClaims"][email] = { ...data };
        draft["convertClaimID"] = null;
        draft["convertLoading"] = false;
      });
    case mobileConstants.GET_INITIAL_MOBILE_SUCCESS:
      return produce(state, (draft) => {
        let { data } = payload;
        draft["form"] = { ...state.form, ...data };
      });
    case mobileConstants.GET_FULL_MOBILE_REQUEST:
      return produce(state, (draft) => {
        draft["form"] = {};
        draft["formLoading"] = true;
      });
    case mobileConstants.GET_FULL_MOBILE_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimID } = payload;
        draft["form"] = { ...data };
        draft["formLoading"] = false;
        draft["convertLoading"] = false;
        draft["meta"] = { ...state.meta, claimID: claimID };
      });
    case mobileConstants.GET_MOBILE_ATTACHMENTS_REQUEST:
      return produce(state, (draft) => {
        draft["attachments"] = [];
      });
    case mobileConstants.GET_MOBILE_ATTACHMENTS_SUCCESS:
      return produce(state, (draft) => {
        let { files } = payload;
        draft["attachments"] = [...files];
      });
    case mobileConstants.UPDATE_FULL_MOBILE_REQUEST:
      return produce(state, (draft) => {
        let { files } = payload;
        draft["formLoading"] = true;
      });
    case mobileConstants.UPDATE_FULL_MOBILE_SUCCESS:
      return produce(state, (draft) => {
        let { files } = payload;
        draft["formLoading"] = false;
      });
    case mobileConstants.CREATE_MOVE_TO_PRG_MOBILE_REQUEST:
      return produce(state, (draft) => {
        draft["convertClaimID"] = null;
        draft["convertLoading"] = true;
      });
    case mobileConstants.CREATE_MOVE_TO_PRG_MOBILE_SUCCESS:
      return produce(state, (draft) => {
        let { claimID } = payload;
        draft["convertClaimID"] = claimID;
        draft["convertLoading"] = false;
      });

    case mobileConstants.GET_ALL_MOBILE_CLAIMS_REQUEST:
      return produce(state, (draft) => {
        draft["allClaimsLoading"] = true;
      });

    case mobileConstants.GET_ALL_MOBILE_CLAIMS_SUCCESS:
      return produce(state, (draft) => {
        let { data } = payload;
        draft["allClaims"] = data;
        draft["allClaimsLoading"] = false;
      });

    case mobileConstants.DELETE_MOBILE_CLAIM_REQUEST:
      return produce(state, (draft) => {
        draft["deleteLoading"] = true;
      });

    case mobileConstants.DELETE_MOBILE_CLAIM_SUCCESS:
      return produce(state, (draft) => {
        draft["deleteLoading"] = false;
      });

    case REHYDRATE: {
      if (payload && payload.mobile) {
        return { ...payload.mobile };
      } else {
        return { ...initState };
      }
    }
    case mobileConstants.MOBILE_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

import { adminknowledgeBaseConstants } from "_constants";


//FAQ
const getFaq = () => {
  return {
    type: adminknowledgeBaseConstants.GET_ALL_FAQ_REQUEST,
  };
};

const updateFaq = (payload, faqid) => {
  return {
    type: adminknowledgeBaseConstants.UPDATE_FAQ_REQUEST,
    payload,
    faqid,
  };
};

const addFaq = (payload) => {
  return {
    type: adminknowledgeBaseConstants.ADD_FAQ_REQUEST,
    payload,
  };
};


const removeFaq = (payload) => {
  return {
    type: adminknowledgeBaseConstants.REMOVE_FAQ_REQUEST,
    payload,
  };
};



//KB
const getKb = (targetTopic) => {
  return {
    type: adminknowledgeBaseConstants.GET_ALL_KB_REQUEST,
    targetTopic
  };
};

const getKbTopics = () => {
  return {
    type: adminknowledgeBaseConstants.GET_ALL_KBTOPICS_REQUEST,
  };
};
const getKbMarkets = () => {
  return {
    type: adminknowledgeBaseConstants.GET_ALL_KBMARKETS_REQUEST,
  };
};
const getKbResourcetypes = () => {
  return {
    type: adminknowledgeBaseConstants.GET_ALL_KBRESOURCETYPES_REQUEST,
  };
};

const addKbTopic = (payload) => {
  return {
    type: adminknowledgeBaseConstants.ADD_KB_TOPIC_REQUEST,
    payload,
  };
};
const updateKbTopic = (payload) => {
  return {
    type: adminknowledgeBaseConstants.UPDATE_KB_TOPIC_REQUEST,
    payload
    };
};
const addKbResource = (payload,targetTopic) => {
  return {
    type: adminknowledgeBaseConstants.ADD_KB_RESOURCE_REQUEST,
    payload,
    targetTopic
  };
};
const updateKbResource = (payload,resourceid) => {
  return {
    type: adminknowledgeBaseConstants.UPDATE_KB_RESOURCE_REQUEST,
    payload,
    resourceid
    };
};

export const adminKnowledgebaseActions = {
  getFaq,
  updateFaq,
  addFaq,
  removeFaq,
  getKb,
  getKbTopics,
  getKbMarkets,
  getKbResourcetypes,
  addKbTopic,
  updateKbTopic,
  addKbResource,
  updateKbResource
};

import { put, call } from "redux-saga/effects";
import { adminJobCostingConstants, alertConstants, authConstants } from "_constants";
import { adminJobCostingService } from "_services";

export function* getRatesSaga({ payload }) {
  try {
    const response = yield call(adminJobCostingService.getRates, payload);

    yield put({
      type: adminJobCostingConstants.GET_RATES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* addRateSaga({ payload }) {
  try {
    const responsePOST = yield call(adminJobCostingService.addRate, payload);

    // refresh rates
    const responseGET = yield call(adminJobCostingService.getRates, payload);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: responsePOST.message },
    });

    yield put({
      type: adminJobCostingConstants.GET_RATES_SUCCESS,
      payload: responseGET.data,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateRateSaga({ payload }) {
  try {
    const responsePUT = yield call(adminJobCostingService.updateRate, payload);

    // refresh rates
    const responseGET = yield call(adminJobCostingService.getRates, payload);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: responsePUT.message },
    });

    yield put({
      type: adminJobCostingConstants.GET_RATES_SUCCESS,
      payload: responseGET.data,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteRateSaga({ payload }) {
  try {
    const responseDELETE = yield call(adminJobCostingService.deleteRate, payload);

    // refresh rates
    const responseGET = yield call(adminJobCostingService.getRates, payload);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: responseDELETE.message },
    });

    yield put({
      type: adminJobCostingConstants.GET_RATES_SUCCESS,
      payload: responseGET.data,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

import { adminSchemaConstants } from "../_constants";
import { produce } from "immer";
import { REHYDRATE } from "redux-persist/lib/constants";

let initState = {
  accessTypes: [],
  conditionTypes: [],
  notificationTypes: {
    FRELO: [],
    POWGAS: [],
    TEL: [],
  },
  notificationFormFields: {
    FRELO: [],
    POWGAS: [],
    TEL: [],
  },
  recipientTypes: {
    FRELO: [],
    POWGAS: [],
    TEL: [],
  },
};

export function adminSchema(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminSchemaConstants.GET_ADMIN_ACCESS_TYPES_SCHEMA_SUCCESS:
      return produce(state, (draft) => {
        draft["accessTypes"] = [...payload];
      });
    case adminSchemaConstants.GET_ADMIN_CONDITIONAL_TYPES_SCHEMA_SUCCESS:
      return produce(state, (draft) => {
        draft["conditionTypes"] = [...payload];
      });
    case adminSchemaConstants.GET_ADMIN_NOTIFICATION_TYPES_SCHEMA_SUCCESS:
      return produce(state, (draft) => {
        let { data, lob } = payload;

        draft["notificationTypes"][lob] = [...data];
      });
    case adminSchemaConstants.GET_ADMIN_NOTIFICATION_RECIPIENT_TYPES_SCHEMA_SUCCESS:
      return produce(state, (draft) => {
        let { data, lob } = payload;
        draft["recipientTypes"][lob] = [...data];
      });
    case adminSchemaConstants.GET_ADMIN_NOTIFICATION_FORM_FIELDS_SCHEMA_SUCCESS:
      return produce(state, (draft) => {
        let { data, lob, formId } = payload;
        draft["notificationFormFields"][lob][formId] = [...data];
      });
    case REHYDRATE: {
      if (payload && payload.adminSchema) {
        return { ...payload.adminSchema };
      } else {
        return { ...initState };
      }
    }
    case adminSchemaConstants.ADMIN_SCHEMA_CLEAR:
      return { ...initState };
    default:
      return state;
  }
}

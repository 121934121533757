export const adminEntityConstants_ = {
  GET_ALL_ADMIN_ENTITY_REQUEST: "GET_ALL_ADMIN_ENTITY_REQUEST",
  GET_ALL_ADMIN_ENTITY_SUCCESS: "GET_ALL_ADMIN_ENTITY_SUCCESS",

  CREATE_ENTITY_REQUEST: "CREATE_ENTITY_REQUEST",
  CREATE_ENTITY_SUCCESS: "CREATE_ENTITY_SUCCESS",

  GET_ALL_ADMIN_ENTITY_STATUS_REQUEST: "GET_ALL_ADMIN_ENTITY_STATUS_REQUEST",
  GET_ALL_ADMIN_ENTITY_STATUS_SUCCESS: "GET_ALL_ADMIN_ENTITY_STATUS_SUCCESS",
  UPDATE_ADMIN_ENTITY_STATUS_REQUEST: "UPDATE_ADMIN_ENTITY_STATUS_REQUEST",
  UPDATE_ADMIN_ENTITY_STATUS_SUCCESS: "UPDATE_ADMIN_ENTITY_STATUS_SUCCESS",
  SET_ADMIN_ENTITY_STATUS_LOADING_TRUE: "SET_ADMIN_ENTITY_STATUS_LOADING_TRUE",
  GET_ALL_ADMIN_ENTITY_SOW_CONTRACTS_REQUEST: "GET_ALL_ADMIN_ENTITY_SOW_CONTRACTS_REQUEST",
  GET_ALL_ADMIN_ENTITY_SOW_CONTRACTS_SUCCESS: "GET_ALL_ADMIN_ENTITY_SOW_CONTRACTS_SUCCESS",
  UPDATE_ADMIN_ENTITY_SOW_CONTRACTS_REQUEST: "UPDATE_ADMIN_ENTITY_SOW_CONTRACTS_REQUEST",
  UPDATE_ADMIN_ENTITY_SOW_CONTRACTS_SUCCESS: "UPDATE_ADMIN_ENTITY_SOW_CONTRACTS_SUCCESS",
  SET_ADMIN_ENTITY_SOW_CONTRACTS_LOADING_TRUE: "SET_ADMIN_ENTITY_SOW_CONTRACTS_LOADING_TRUE",
  ADD_ADMIN_ENTITY_SOW_CONTRACT_REQUEST: "ADD_ADMIN_ENTITY_SOW_CONTRACT_REQUEST",
  ADD_ADMIN_ENTITY_SOW_CONTRACT_SUCCESS: "ADD_ADMIN_ENTITY_SOW_CONTRACT_SUCCESS",
  GET_ADMIN_ENTITY_SCHEMA_BILLING_TYPES_REQUEST: "GET_ADMIN_ENTITY_SCHEMA_BILLING_TYPES_REQUEST",
  GET_ADMIN_ENTITY_SCHEMA_BILLING_TYPES_SUCCESS: "GET_ADMIN_ENTITY_SCHEMA_BILLING_TYPES_SUCCESS",
  GET_ADMIN_ENTITY_SCHEMA_RDA_REQUEST: "GET_ADMIN_ENTITY_SCHEMA_RDA_REQUEST",
  GET_ADMIN_ENTITY_SCHEMA_RDA_SUCCESS: "GET_ADMIN_ENTITY_SCHEMA_RDA_SUCCESS",
  ADMIN_ENTITY_SCHEMA_SUB_ACCOUNTS_REQUEST: "ADMIN_ENTITY_SCHEMA_SUB_ACCOUNTS_REQUEST",
  ADMIN_ENTITY_SCHEMA_SUB_ACCOUNTS_SUCCESS: "ADMIN_ENTITY_SCHEMA_SUB_ACCOUNTS_SUCCESS",
  GET_ALL_ADMIN_SCHEMA_LOB_REQUEST: "GET_ALL_SCHEMA_LOB_REQUEST",
  GET_ALL_ADMIN_SCHEMA_LOB_SUCCESS: "GET_ALL_SCHEMA_LOB_SUCCESS",
  GET_ALL_ADMIN_ENTITY_RDA_CONTROL_REQUEST: "GET_ALL_ADMIN_ENTITY_RDA_CONTROL_REQUEST",
  GET_ALL_ADMIN_ENTITY_RDA_CONTROL_SUCCESS: "GET_ALL_ADMIN_ENTITY_RDA_CONTROL_SUCCESS",
  SET_ADMIN_ENTITY_RDA_CONTROL_LOADING_TRUE: "SET_ADMIN_ENTITY_RDA_CONTROL_LOADING_TRUE",
  GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_REQUEST: "GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_REQUEST",
  GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_SUCCESS: "GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_SUCCESS",
  UPDATE_ADMIN_ENTITY_RDA_CONTROL_REQUEST: "UPDATE_ADMIN_ENTITY_RDA_CONTROL_REQUEST",
  UPDATE_ADMIN_ENTITY_RDA_CONTROL_SUCCESS: "UPDATE_ADMIN_ENTITY_RDA_CONTROL_SUCCESS",
  ADD_ADMIN_ENTITY_RDA_CONTROL_REQUEST: "ADD_ADMIN_ENTITY_RDA_CONTROL_REQUEST",
  ADD_ADMIN_ENTITY_RDA_CONTROL_SUCCESS: "ADD_ADMIN_ENTITY_RDA_CONTROL_SUCCESS",
  GET_ADMIN_ENTITY_BILLING_TYPES_REQUEST: "GET_ADMIN_ENTITY_BILLING_TYPES_REQUEST",
  GET_ADMIN_ENTITY_BILLING_TYPES_SUCCESS: "GET_ADMIN_ENTITY_BILLING_TYPES_SUCCESS",
  SET_ADMIN_ENTITY_BILLING_TYPES_LOADING_TRUE: "SET_ADMIN_ENTITY_BILLING_TYPES_LOADING_TRUE",
  ADD_ADMIN_ENTITY_BILLING_TYPE_REQUEST: "ADD_ADMIN_ENTITY_BILLING_TYPE_REQUEST",
  ADD_ADMIN_ENTITY_BILLING_TYPE_SUCCESS: "ADD_ADMIN_ENTITY_BILLING_TYPE_SUCCESS",
  UPDATE_ADMIN_ENTITY_BILLING_TYPE_REQUEST: "UPDATE_ADMIN_ENTITY_BILLING_TYPE_REQUEST",
  UPDATE_ADMIN_ENTITY_BILLING_TYPE_SUCCESS: "UPDATE_ADMIN_ENTITY_BILLING_TYPE_SUCCESS",
  GET_ADMIN_ENTITY_SUB_ACCOUNTS_REQUEST: "GET_ADMIN_ENTITY_SUB_ACCOUNTS_REQUEST",
  GET_ADMIN_ENTITY_SUB_ACCOUNTS_SUCCESS: "GET_ADMIN_ENTITY_SUB_ACCOUNTS_SUCCESS",
  SET_ADMIN_ENTITY_SUB_ACCOUNTS_LOADING_TRUE: "SET_ADMIN_ENTITY_SUB_ACCOUNTS_LOADING_TRUE",
  GET_ADMIN_ENTITY_SUBACCOUNTS_RDA_SCHEMA_REQUEST: "GET_ADMIN_ENTITY_SUBACCOUNTS_RDA_SCHEMA_REQUEST",
  GET_ADMIN_ENTITY_SUBACCOUNTS_RDA_SCHEMA_SUCCESS: "GET_ADMIN_ENTITY_SUBACCOUNTS_RDA_SCHEMA_SUCCESS",
  UPDATE_ADMIN_ENTITY_SUB_ACCOUNTS_REQUEST: "UPDATE_ADMIN_ENTITY_SUB_ACCOUNTS_REQUEST",
  UPDATE_ADMIN_ENTITY_SUB_ACCOUNTS_SUCCESS: "UPDATE_ADMIN_ENTITY_SUB_ACCOUNTS_SUCCESS",
  ADD_ADMIN_ENTITY_SUB_ACCOUNT_REQUEST: "ADD_ADMIN_ENTITY_SUB_ACCOUNT_REQUEST",
  ADD_ADMIN_ENTITY_SUB_ACCOUNT_SUCCESS: "ADD_ADMIN_ENTITY_SUB_ACCOUNT_SUCCESS",
  GET_ADMIN_ENTITY_CONTACTS_REQUEST: "GET_ADMIN_ENTITY_CONTACTS_REQUEST",
  GET_ADMIN_ENTITY_CONTACTS_SUCCESS: "GET_ADMIN_ENTITY_CONTACTS_SUCCESS",
  SET_ADMIN_ENTITY_CONTACTS_LOADING_TRUE: "SET_ADMIN_ENTITY_CONTACTS_LOADING_TRUE",
  UPDATE_ADMIN_ENTITY_CONTACTS_REQUEST: "UPDATE_ADMIN_ENTITY_CONTACTS_REQUEST",
  UPDATE_ADMIN_ENTITY_CONTACTS_SUCCESS: "UPDATE_ADMIN_ENTITY_CONTACTS_SUCCESS",
  ADD_ADMIN_ENTITY_CONTACT_REQUEST: "ADD_ADMIN_ENTITY_CONTACT_REQUEST",
  ADD_ADMIN_ENTITY_CONTACT_SUCCESS: "ADD_ADMIN_ENTITY_CONTACT_SUCCESS",
  GET_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_REQUEST: "GET_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_REQUEST",
  GET_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_SUCCESS: "GET_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_SUCCESS",
  UPDATE_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_REQUEST: "UPDATE_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_REQUEST",
  UPDATE_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_SUCCESS: "UPDATE_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_SUCCESS",
  GET_ADMIN_ENTITY_SCHEMA_DOCS_FIELD_MENTIONS_REQUEST: "GET_ADMIN_ENTITY_SCHEMA_DOCS_FIELD_MENTIONS_REQUEST",
  GET_ADMIN_ENTITY_SCHEMA_DOCS_FIELD_MENTIONS_SUCCESS: "GET_ADMIN_ENTITY_SCHEMA_DOCS_FIELD_MENTIONS_SUCCESS",
  GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_REASONS_REQUEST: "GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_REASONS_REQUEST",
  GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_REASONS_SUCCESS: "GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_REASONS_SUCCESS",
  GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_LAST_SENTENCE_REQUEST: "GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_LAST_SENTENCE_REQUEST",
  GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_LAST_SENTENCE_SUCCESS: "GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_LAST_SENTENCE_SUCCESS",

  GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST: "GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST",
  GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_SUCCESS: "GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_SUCCESS",
  UPDATE_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST: "UPDATE_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST",
  UPDATE_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_SUCCESS: "UPDATE_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_SUCCESS",
  ADD_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST: "ADD_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST",
  ADD_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_SUCCESS: "ADD_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_SUCCESS",

  GET_ADMIN_ENTITY_DOC_CLAIM_TEMPLATE_REQUEST: "GET_ADMIN_ENTITY_DOC_CLAIM_TEMPLATE_REQUEST",
  GET_ADMIN_ENTITY_DOC_CLAIM_TEMPLATE_SUCCESS: "GET_ADMIN_ENTITY_DOC_CLAIM_TEMPLATE_SUCCESS",
  UPDATE_ADMIN_ENTITY_DOCS_CLAIM_REQUEST: "UPDATE_ADMIN_ENTITY_DOCS_CLAIM_REQUEST",
  UPDATE_ADMIN_ENTITY_DOCS_CLAIM_SUCCESS: "UPDATE_ADMIN_ENTITY_DOCS_CLAIM_SUCCESS",
  SET_ADMIN_ENTITY_DOC_MANAGEMENT_LOADING_TRUE: "SET_ADMIN_ENTITY_DOC_MANAGEMENT_LOADING_TRUE",
  GET_ADMIN_ENTITY_RDA_CONTROL_ALL_REGIONS_REQUEST: "GET_ADMIN_ENTITY_RDA_CONTROL_ALL_REGIONS_REQUEST",
  GET_ADMIN_ENTITY_RDA_CONTROL_ALL_REGIONS_SUCCESS: "GET_ADMIN_ENTITY_RDA_CONTROL_ALL_REGIONS_SUCCESS",
  POST_ADMIN_ENTITY_RDA_CONTROL_REGION_REQUEST: "POST_ADMIN_ENTITY_RDA_CONTROL_REGION_REQUEST",
  POST_ADMIN_ENTITY_RDA_CONTROL_REGION_SUCCESS: "POST_ADMIN_ENTITY_RDA_CONTROL_REGION_SUCCESS",
  UPDATE_ADMIN_ENTITY_RDA_CONTROL_REGIONS_REQUEST: "UPDATE_ADMIN_ENTITY_RDA_CONTROL_REGIONS_REQUEST",
  UPDATE_ADMIN_ENTITY_RDA_CONTROL_REGIONS_SUCCESS: "UPDATE_ADMIN_ENTITY_RDA_CONTROL_REGIONS_SUCCESS",
  GET_ADMIN_ENTITY_RDA_CONTROL_ALL_DIVISIONS_REQUEST: "GET_ADMIN_ENTITY_RDA_CONTROL_ALL_DIVISIONS_REQUEST",
  GET_ADMIN_ENTITY_RDA_CONTROL_ALL_DIVISIONS_SUCCESS: "GET_ADMIN_ENTITY_RDA_CONTROL_ALL_DIVISIONS_SUCCESS",
  POST_ADMIN_ENTITY_RDA_CONTROL_DIVISION_REQUEST: "POST_ADMIN_ENTITY_RDA_CONTROL_DIVISION_REQUEST",
  POST_ADMIN_ENTITY_RDA_CONTROL_DIVISION_SUCCESS: "POST_ADMIN_ENTITY_RDA_CONTROL_DIVISION_SUCCESS",
  UPDATE_ADMIN_ENTITY_RDA_CONTROL_DIVISIONS_REQUEST: "UPDATE_ADMIN_ENTITY_RDA_CONTROL_DIVISIONS_REQUEST",
  UPDATE_ADMIN_ENTITY_RDA_CONTROL_DIVISIONS_SUCCESS: "UPDATE_ADMIN_ENTITY_RDA_CONTROL_DIVISIONS_SUCCESS",
  GET_ADMIN_ENTITY_RDA_CONTROL_ALL_AREAS_REQUEST: "GET_ADMIN_ENTITY_RDA_CONTROL_ALL_AREAS_REQUEST",
  GET_ADMIN_ENTITY_RDA_CONTROL_ALL_AREAS_SUCCESS: "GET_ADMIN_ENTITY_RDA_CONTROL_ALL_AREAS_SUCCESS",
  POST_ADMIN_ENTITY_RDA_CONTROL_AREA_REQUEST: "POST_ADMIN_ENTITY_RDA_CONTROL_AREA_REQUEST",
  POST_ADMIN_ENTITY_RDA_CONTROL_AREA_SUCCESS: "POST_ADMIN_ENTITY_RDA_CONTROL_AREA_SUCCESS",
  UPDATE_ADMIN_ENTITY_RDA_CONTROL_AREAS_REQUEST: "UPDATE_ADMIN_ENTITY_RDA_CONTROL_AREAS_REQUEST",
  UPDATE_ADMIN_ENTITY_RDA_CONTROL_AREAS_SUCCESS: "UPDATE_ADMIN_ENTITY_RDA_CONTROL_AREAS_SUCCESS",

  GET_ADMIN_ENTITY_INVOICING_REQUEST: "GET_ADMIN_ENTITY_INVOICING_REQUEST",
  GET_ADMIN_ENTITY_INVOICING_SUCCESS: "GET_ADMIN_ENTITY_INVOICING_SUCCESS",
  UPDATE_ADMIN_ENTITY_INVOICING_REQUEST: "UPDATE_ADMIN_ENTITY_INVOICING_REQUEST",
  UPDATE_ADMIN_ENTITY_INVOICING_SUCCESS: "UPDATE_ADMIN_ENTITY_INVOICING_SUCCESS",
  GET_ADMIN_ENTITY_INVOICING_LINE_ITEMS_REQUEST: "GET_ADMIN_ENTITY_INVOICING_LINE_ITEMS_REQUEST",
  GET_ADMIN_ENTITY_INVOICING_LINE_ITEMS_SUCCESS: "GET_ADMIN_ENTITY_INVOICING_LINE_ITEMS_SUCCESS",
  CREATE_ADMIN_ENTITY_INVOICING_LINE_ITEM_REQUEST: "CREATE_ADMIN_ENTITY_INVOICING_LINE_ITEM_REQUEST",
  CREATE_ADMIN_ENTITY_INVOICING_LINE_ITEM_SUCCESS: "CREATE_ADMIN_ENTITY_INVOICING_LINE_ITEM_SUCCESS",
  UPDATE_ADMIN_ENTITY_INVOICING_LINE_ITEM_REQUEST: "UPDATE_ADMIN_ENTITY_INVOICING_LINE_ITEM_REQUEST",
  UPDATE_ADMIN_ENTITY_INVOICING_LINE_ITEM_SUCCESS: "UPDATE_ADMIN_ENTITY_INVOICING_LINE_ITEM_SUCCESS",
  DELETE_ADMIN_ENTITY_INVOICING_LINE_ITEM_REQUEST: "DELETE_ADMIN_ENTITY_INVOICING_LINE_ITEM_REQUEST",
  DELETE_ADMIN_ENTITY_INVOICING_LINE_ITEM_SUCCESS: "DELETE_ADMIN_ENTITY_INVOICING_LINE_ITEM_SUCCESS",

  ADMIN_ENTITY_CLEAR: "ADMIN_ENTITY_CLEAR",
};
import { put, call } from "redux-saga/effects";
import {
  adminPowerBiRoleConstants,
  alertConstants,
  authConstants,
} from "_constants";
import { adminPowerBiRoleService } from "_services";
import { isEmpty } from "ramda";



export function* getPowerBiRolesSaga() {
  try {

    // put: loading true
    yield put({
      type: adminPowerBiRoleConstants.GET_ALL_POWERBI_ROLES_LOADING,
    });


    const response = yield call(adminPowerBiRoleService.getPowerBiRoles);

    yield put({
      type: adminPowerBiRoleConstants.GET_ALL_POWERBI_ROLES_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* createPowerBiRoleSaga({ payload }) {
  try {
    const responsePost = yield call(adminPowerBiRoleService.createPowerBiRole, payload);

    if (isEmpty(responsePost)) {
      return;
    }

    const response = yield call(adminPowerBiRoleService.getPowerBiRoles);

    yield put({
      type: adminPowerBiRoleConstants.GET_ALL_POWERBI_ROLES_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Power BI Role Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updatePowerBiRoleSaga({ payload, }) {
  try {

    // put: loading true
    yield put({
      type: adminPowerBiRoleConstants.UPDATE_POWERBI_ROLE_LOADING,
    });

    yield call(adminPowerBiRoleService.updatePowerBiRole, payload);

    // put: loading false
    yield put({
      type: adminPowerBiRoleConstants.UPDATE_POWERBI_ROLE_SUCCESS,
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Power BI Role Updated" },
    });

     // put: loading true
     yield put({
      type: adminPowerBiRoleConstants.GET_ALL_POWERBI_ROLES_LOADING,
    });

    const response = yield call(adminPowerBiRoleService.getPowerBiRoles);

    yield put({
      type: adminPowerBiRoleConstants.GET_ALL_POWERBI_ROLES_SUCCESS,
      payload: [...response],
    });

 

  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

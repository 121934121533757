import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckIcon from "@material-ui/icons/Check";


export default function GreenCheckbox(props) {
  const checkedColor = props.checkedColor ? props.checkedColor : "unset"
  return (
    <Checkbox
      {...props}
      style={{ color: "blue" }}
      // iconStyle={{ fill: "blue" }}
      icon={<CheckBoxOutlineBlankIcon />}
      checkedIcon={<CheckIcon style={{ color: checkedColor }} />}
    />
  );
}


import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ObjectTools } from "_utility";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Modal, Box, Typography } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

export default function AlertSnackbar() {
  const { getNestedPropertySafe } = ObjectTools;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  // eslint-disable-next-line
  const [type, setType] = useState();

  const cleanup = () => {
    setMessage();
    setType();
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    cleanup();
  };

  const alert = useSelector((state) => getNestedPropertySafe(state, ["alert"]));

  useEffect(() => {
    if (alert["message"] === "" || alert["type"] === "") return cleanup();

    setMessage(alert["message"]);
    setType(alert["type"]);
    setOpen(true);
    // eslint-disable-next-line
  }, [alert]);

  if (alert) {
    return (
      alert.isVersionCheck ?
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '10%', width: '50%', backgroundColor: 'rgb(0,0,0,0.40)', border: '2px solid white', borderRadius: '3px'}}>
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign: 'center'}}>
              {message}
            </Typography>
          </Box>
        </Modal>
      :
      <Snackbar open={open} autoHideDuration={alert.duration} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    );
  } else {
    return null;
  }

}

import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { jointReportActions } from "_actions";
import _getProperty from "lodash/get";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Stack from "@mui/material/Stack";
import TeamBreakdownModal from "./components/TeamBreakdownModal";
import { useForm } from "react-hook-form";
import Autocomplete from "_components/core/Autocomplete_";
import moment from "moment";
import roleGroups from "_views/Dashboard/_data/roleGroups";
import roles from "_views/Dashboard/_data/roleIdEnum";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.GrayPrg.main,
    color: theme.palette.DarkGrayPrg.main,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    backgroundColor: theme.palette.WhitePrg.main,
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "4px",
    minWidth: "50vw",
    maxWidth: "90vw",
    maxHeight: "90vh",
    overflow: "hide",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  close: {
    cursor: "pointer",
  },
  save: {
    backgroundColor: theme.palette.GreenPrg.main,
    color: theme.palette.WhitePrg.main,
  },
  divider: {
    margin: theme.spacing(4, 0),
    backgroundColor: theme.palette.WhitePrg.main,
  },
  h3: {
    color: "var(--LightGray)",
    margin: "0 0 10px 0",
  },
  goals: {
    textAlign: "center",
    fontWeight: "bolder",
    marginTop: "10px",
    padding: "10px",
  },
  goalsInner: {
    backgroundColor: theme.palette.WhitePrg.main,
    border: `solid 4px ${theme.palette.GrayPrg.main}`,
  },
  status: {
    backgroundColor: theme.palette.GreenPrg.main,
    color: theme.palette.WhitePrg.main,
    width: "100%",
    maxWidth: "400px",
    margin: "auto",
  },
  color: {
    backgroundColor: theme.palette.GrayPrg.main,
    padding: 5,
    maxHeight: 500,
    overflow: "auto",
  },
  countColor: {
    backgroundColor: theme.palette.OverlayPrg.main,
    color: theme.palette.DarkGrayPrg.main,
    padding: "5px 0",
    fontWeight: "bolder",
  },
  padding: {
    padding: "0 20px 0 0",
    maxHeight: "calc(90vh - 335px)",
    overflow: "auto",
  },
  verticalAlign: {
    verticalAlign: "top",
    padding: 0,
  },
  details: {
    display: "block",
    padding: "12px 24px 12px 16px",
    borderBottom: `1px solid ${theme.palette.WhitePrg.main}`,
    transition: ".25s ease",
    cursor: "pointer",
    border: "1px solid transparent",
    "&:hover": {
      border: `1px solid ${theme.palette.GreenPrg.main}`,
    },
  },
  completed: {
    backgroundColor: `${theme.palette.GreenPrg.main} !important`,
    color: `${theme.palette.WhitePrg.main} !important`,
  },
  startClaimDark: {
    backgroundColor: `${theme.palette.DarkGrayPrg.main} !important`,
  },
  dropdown: {
    width: "100%",
  },
}));

export default function Index({ openModal, setOpenModal }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { control, watch } = useForm();

  const handleClose = () => {
    setOpenModal(!openModal);
  };

  const watchDate = watch("startDate");
  const lobCurrent = useSelector((state) => _getProperty(state, ["lob", "current"]));
  const auth = useSelector((state) => _getProperty(state, ["auth", "role"]));

  useEffect(() => {
    dispatch(jointReportActions.getScoreboard(watchDate ?? moment().startOf("week").format("MM/DD/YYYY")));
  }, [lobCurrent, dispatch, watchDate, auth]);

  const selectedTheme = useSelector((state) => _getProperty(state, ["settings", "theme"]));
  const roleId = useSelector((state) => _getProperty(state, ["auth", "role", "roleId"]));

  const header = useSelector((state) => _getProperty(state, ["joint", "reports", "scoreboard", "header"]));

  const targets = useSelector((state) => _getProperty(state, ["joint", "reports", "scoreboard", "targets"]));

  // reorder power & gas column to end
  let targets_ = targets ? JSON.parse(JSON.stringify(targets)) : [];
  const powerGasIndex = targets_?.findIndex((target) => target.RegionName === "Power & Gas");
  if (powerGasIndex > -1) {
    const powerGas = targets_?.[powerGasIndex];
    targets_?.splice(powerGasIndex, 1);
    targets_?.push(powerGas);
  }

  const stats = useSelector((state) => _getProperty(state, ["joint", "reports", "scoreboard", "stats"]));
  // sort by marketName alphabetically
  let statsSorted = stats
    ? [...stats].sort(function (a, b) {
        var textA = a.marketName.toUpperCase();
        var textB = b.marketName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
    : [];

  const [teamBreakdown, setTeamBreakdown] = useState();

  let clearTeamBreakdown = () => {
    setTeamBreakdown();
  };

  const currentDate = moment().tz("America/New_York")
  const [weekOptions, setWeekOptions] = useState([]);
  const [weekOptionsSchema, setWeekOptionsSchema] = useState({});

  useEffect(() => {
    const weeks = new Array(94).fill(null).map((_, i) => {
      const startOfWeek = currentDate.clone().startOf("week").subtract(i, "weeks");
      const endOfWeek = currentDate.clone().endOf("week").subtract(i, "weeks");
      return {
        label: `Sun. ${startOfWeek.format("MM/DD/YYYY")} - Sat. ${endOfWeek.format("MM/DD/YYYY")}`,
        value: startOfWeek.format("MM/DD/YYYY"),
      };
    });

    const options = weeks.map((week) => week.value);
    const optionSchema = weeks.reduce((acc, cur) => {
      acc[cur.value] = cur;
      return acc;
    }, {});

    setWeekOptions(options);
    setWeekOptionsSchema(optionSchema);
  }, []);

  // external entity roles are restricted in what they can see
  const isRestricted = roleGroups.PG_EXTERNAL.includes(roleId); 
  let restrictedTotal = 0;
  let restrictedRegionTargets = 0;
  let restrictedATotal = 0;
  if (targets_.length > 0 && isRestricted) {
    // filter columns & data to only PG
    targets_ = targets_.filter((target) => target.regionid === 3); // remove tel columns
    statsSorted = statsSorted.filter((stat) => stat.RegionID === 3);

    // filter further by specific entity/markets

    if (roleId === roles.DUKE_ENERGY_EXT || roleId === roles.DUKE_ENERGY_2_EXT) {
      targets_[0].RegionName = "Duke Energy";
      statsSorted = statsSorted.filter((stat) => [53, 54, 55, 60, 61, 62].includes(stat.MarketID));
    }
    if (roleId === roles.SVEC_EXT) {
      targets_[0].RegionName = "Suwannee Valley Electric";
      statsSorted = statsSorted.filter((stat) => [75].includes(stat.MarketID));
    }
    if (roleId === roles.BREC_EXT) {
      targets_[0].RegionName = "Blue Ridge Electric";
      statsSorted = statsSorted.filter((stat) => [79].includes(stat.MarketID));
    }

    // recalculate stats for filtered data
    restrictedTotal = statsSorted.reduce((acc, curr) => acc + curr.total, 0);
    restrictedRegionTargets = statsSorted.reduce((acc, curr) => acc + curr.MarketTargets, 0);
    restrictedATotal = statsSorted.reduce((acc, curr) => acc + curr.aTotal, 0);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Autocomplete
                control={control}
                options={weekOptions}
                optionSchema={weekOptionsSchema}
                name={"startDate"}
                className={classes.dropdown}
                defaultValue={currentDate.clone().startOf("week").format("MM/DD/YYYY")}
              ></Autocomplete>
            </Grid>
          </Grid>

          <Box style={{ position: "absolute", top: "10px", right: "10px" }}>
            <CancelPresentationIcon fontSize="large" className={classes.close} onClick={handleClose} />
          </Box>

          {/* Header */}
          <Grid container spacing={3} className={classes.goals}>
            {!isRestricted && (
              <Grid
                item
                xs={4}
                className={`${classes.goalsInner} ${selectedTheme === "dark" ? classes.startClaimDark : ""}`}
              >
                <Typography variant="body1" component="h2">
                  <Box fontWeight="fontWeightBold" m={1}>
                    Team Total:
                    <div className="teamBlocks" style={{ textDecoration: "underline" }}>
                      {header && Object.values(header).map((el) => el["TeamTotal"])}
                    </div>
                  </Box>
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={isRestricted ? 12 : 4}
              className={`${classes.goalsInner} ${selectedTheme === "dark" ? classes.startClaimDark : ""}`}
            >
              <Typography variant="h6" component="h6" className="title">
                <Box fontWeight="fontWeightBold">B1 SCOREBOARD</Box>
              </Typography>
              <Typography variant="subtitle2" component="h2" className="subtitle">
                This Week Currently in A-Status:
              </Typography>
              <Typography variant="body1" component="h2" className={classes.status}>
                {header && Object.values(header).map((el) => el["ATotal"])}
              </Typography>
            </Grid>
            {!isRestricted && (
              <Grid
                item
                xs={4}
                className={`${classes.goalsInner} ${selectedTheme === "dark" ? classes.startClaimDark : ""}`}
              >
                <Typography variant="body1" component="h2">
                  <Box fontWeight="fontWeightBold" m={1}>
                    Team Goal:
                    <div className="teamBlocks" style={{ textDecoration: "underline" }}>
                      {header && Object.values(header).map((el) => el["TeamGoal"])}
                    </div>
                  </Box>
                </Typography>
              </Grid>
            )}
          </Grid>
          {/* Header */}
          <Divider className={classes.divider} />

          <TableContainer className={classes.container}>
            {!targets_ ? (
              <Typography variant="h6" style={{ textAlign: "center" }}>
                ...Loading
              </Typography>
            ) : (
              ""
            )}
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  {targets_ &&
                    targets_.map((column) => {
                      let {
                        // RegionName,
                        RegionTargets,
                        aTotal,
                        regionid,
                        total,
                      } = column;
                      return (
                        <StyledTableCell
                          key={column.regionid}
                          // align={column.align}
                          // style={{ minWidth: column.minWidth }}
                        >
                          <Stack>
                            <Typography variant="button">{column.RegionName}</Typography>
                            <Typography variant="button">
                              {isRestricted ? restrictedTotal : total} /{" "}
                              {isRestricted ? restrictedRegionTargets : RegionTargets} (
                              {isRestricted ? restrictedATotal : aTotal})
                            </Typography>
                          </Stack>
                        </StyledTableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow hover role="checkbox" tabIndex={-1}>
                  {targets_ &&
                    targets_.map((col) => {
                      let {
                        // RegionName,
                        RegionTargets,
                        aTotal,
                        regionid,
                        total,
                      } = col;

                      let currentStats = statsSorted.filter((e) => e["RegionID"] === regionid);

                      return (
                        <TableCell key={`${col.regionid}-${col.RegionName}`} className={classes.verticalAlign}>
                          {/* {total} / {RegionTargets} ({aTotal}) */}
                          <div className={`${classes.padding}`}>
                            {currentStats &&
                              currentStats.map((curr) => {
                                return (
                                  <span
                                    key={`${curr["RegionID"]}-${curr["MarketID"]}`}
                                    onClick={() => {
                                      setTeamBreakdown({
                                        regionId: curr.RegionID,
                                        marketId: curr.MarketID,
                                      });
                                    }}
                                    className={`${curr["total"] >= curr["MarketTargets"] ? classes.completed : ""} ${
                                      classes.details
                                    } flip`}
                                  >
                                    {curr.marketName} - {curr.total}/{curr.MarketTargets} ({curr.aTotal})
                                  </span>
                                );
                              })}
                          </div>
                        </TableCell>
                      );
                    })}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TeamBreakdownModal teamBreakdown={teamBreakdown} clear={clearTeamBreakdown} setOpenModal={setOpenModal} />
        </Paper>
      </Fade>
    </Modal>
  );
}

import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { FormField } from "_components/core/FormField";

export default function Index({ control, register, errors, hydration }) {
  const fields = [
    {
      id: 316,
      type: "radio",
      options: hydration[316].type.enumOptions,
      md: 12,
    },
    {
      id: 317,
      type: "text",
      md: 12,
    },
    {
      id: 318,
      type: "radio",
      md: 12,
    },
  ];

  fields.map((field) => {
    if (hydration && hydration[field.id]) {
      // name
      field.name = `${hydration[field.id]?.["id"]}`;
      // label
      field.label = hydration[field.id]?.["label"];
      // required
      field.required = hydration[field.id].required;
      // maxLength
      field.maxLength = hydration[field.id].type.maxLength
    }
    return field;
  });

  return (
    <>
      <Grid container item spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" gutterBottom style={{ textAlign: "center" }}>
            Locate Ticket
          </Typography>
        </Grid>
        {fields.map((field) => (
          <FormField field={field} register={register} control={control} errors={errors} />
        ))}
      </Grid>
    </>
  );
}

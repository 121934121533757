import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";
import { paymentConstants } from "_constants";

let initState = {
  loading: false,
  info: {},
  token: null
};

export function payment(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case paymentConstants.GET_PAYMENT_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case paymentConstants.GET_PAYMENT_SUCCESS:
      return produce(state, (draft) => {
        draft.info = payload.claim;
        draft.token = payload.token;
        draft.loading = false;
      });
    case REHYDRATE: {
      if (payload && payload.payment) {
        return { ...payload.payment };
      } else {
        return { ...initState };
      }
    }
    case paymentConstants.PAYMENT_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

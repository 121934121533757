import { put, call, all } from "redux-saga/effects";
import { adminRoleConstants, alertConstants, authConstants } from "../_constants";
import { adminRoleService } from "_services";
import { isEmpty } from "ramda";
import { buildAlertMessage } from "_utility";

export function* getRolesSaga() {
  try {
    const response = yield call(adminRoleService.getRoles);

    yield put({
      type: adminRoleConstants.GET_ALL_ROLES_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getRoleGeneralSaga({ roleId }) {
  try {
    const response = yield call(adminRoleService.getGeneral, roleId);

    if (isEmpty(response)) {
      return;
    }

    yield put({
      type: adminRoleConstants.GET_ROLE_GENERAL_SUCCESS,
      payload: { data: { ...response }, roleId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateRoleSaga({ payload, roleId }) {
  try {
    let data = { ...payload, roleId };

    const responsePost = yield call(adminRoleService.updateRoleGeneral, data);

    if (isEmpty(responsePost)) {
      return;
    }
    const response = yield call(adminRoleService.getGeneral, roleId);

    yield put({
      type: adminRoleConstants.GET_ROLE_GENERAL_SUCCESS,
      payload: { data: { ...response }, roleId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Role updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getRoleDbAccessSaga({ roleId }) {
  try {
    const response = yield call(adminRoleService.getDbAccess, roleId);

    if (isEmpty(response)) {
      return;
    }

    yield put({
      type: adminRoleConstants.GET_ROLE_DB_ACCESS_SUCCESS,
      payload: { data: { ...response }, roleId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateRoleDbAccessSaga({ payload, roleId }) {
  try {
    const responsePost = yield all(payload.map((item) => call(adminRoleService.updateDbAccess, item)));

    if (isEmpty(responsePost)) {
      return;
    }
    const response = yield call(adminRoleService.getDbAccess, roleId);

    yield put({
      type: adminRoleConstants.GET_ROLE_DB_ACCESS_SUCCESS,
      payload: { data: { ...response }, roleId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Role Access updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getRoleRdaSaga({ roleId }) {
  try {
    const response = yield call(adminRoleService.getRda, roleId);

    yield put({
      type: adminRoleConstants.GET_ROLE_RDA_SUCCESS,
      payload: { data: [...response], roleId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

//Dashboards
export function* getRoleDashboardSaga({ roleId }) {
  try {
    const response = yield call(adminRoleService.getDashboard, roleId);

    if (isEmpty(response)) {
      return;
    }

    yield put({
      type: adminRoleConstants.GET_ROLE_DASHBOARD_SUCCESS,
      payload: { roleId, data: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* addRoleDashboardSaga({ payload, roleId }) {
  try {
    const responsePost = yield call(adminRoleService.addDashboard, payload, roleId);

    if (isEmpty(responsePost)) {
      return;
    }

    const response = yield call(adminRoleService.getDashboard, roleId);

    yield put({
      type: adminRoleConstants.GET_ROLE_DASHBOARD_SUCCESS,
      payload: { roleId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Role Dashboard Added" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* removeRoleDashboardSaga({ pannelId, roleId }) {
  try {
    const responsePost = yield call(adminRoleService.removeDashboard, pannelId, roleId);

    if (isEmpty(responsePost)) {
      return;
    }

    const response = yield call(adminRoleService.getDashboard, roleId);

    yield put({
      type: adminRoleConstants.GET_ROLE_DASHBOARD_SUCCESS,
      payload: { roleId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Role Dashboard Removed" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateRoleDashboardSaga({ payload, roleId }) {
  try {
    const responsePost = yield call(adminRoleService.updateDashboard, payload, roleId);

    if (isEmpty(responsePost)) {
      return;
    }

    const response = yield call(adminRoleService.getDashboard, roleId);

    yield put({
      type: adminRoleConstants.GET_ROLE_DASHBOARD_SUCCESS,
      payload: { roleId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Role Dashboard Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateRoleDashboardPositionSaga({ payload, roleid, dashid }) {
  try {
    const responsePost = yield all(payload.map((item) => call(adminRoleService.updateDashboardPosition, item, dashid)));

    if (isEmpty(responsePost)) {
      throw new Error("Error updating pannels");
    }

    const response = yield call(adminRoleService.getDashboard, roleid);

    yield put({
      type: adminRoleConstants.GET_ROLE_DASHBOARD_SUCCESS,
      payload: { roleid, data: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* createRoleSaga({ payload }) {
  try {
    const responsePost = yield call(adminRoleService.createRole, payload);

    if (isEmpty(responsePost)) {
      return;
    }

    const response = yield call(adminRoleService.getRoles);

    yield put({
      type: adminRoleConstants.GET_ALL_ROLES_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Role Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* cloneRoleSaga({ payload }) {
  try {
    const responsePost = yield call(adminRoleService.cloneRole, payload);

    if (isEmpty(responsePost)) {
      return;
    }

    const response = yield call(adminRoleService.getRoles);

    yield put({
      type: adminRoleConstants.GET_ALL_ROLES_SUCCESS,
      payload: [...response],
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Role Cloned" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getRoleEntitiesSaga({ roleId }) {
  try {
    const response = yield call(adminRoleService.getEntities, roleId);
    yield put({
      type: adminRoleConstants.GET_ROLE_ENTITIES_SUCCESS,
      payload: { roleId, data: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateRoleEntitiesSaga({ payload, roleId }) {
  try {
    const responsePost = yield all(
      Object.keys(payload).map((item) => call(adminRoleService.updateEntities, { ...payload[item] }, roleId))
    );

    if (isEmpty(responsePost)) {
      throw new Error("Error updating entities");
    }
    let statusMessage = buildAlertMessage("Status", responsePost);

    const response = yield call(adminRoleService.getEntities, roleId);
    yield put({
      type: adminRoleConstants.GET_ROLE_ENTITIES_SUCCESS,
      payload: { roleId, data: [...response] },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

// action access
// ==============

export function* getRoleActionAccessSaga({ roleId }) {
  try {
    // put: loading true
    yield put({
      type: adminRoleConstants.GET_ROLE_ACTION_ACCESS_LOADING,
      payload: true,
    });

    const response = yield call(adminRoleService.getRoleActionAccess, roleId);

    if (isEmpty(response)) {
      return;
    }

    yield put({
      type: adminRoleConstants.GET_ROLE_ACTION_ACCESS_SUCCESS,
      payload: { data: { ...response }, roleId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateRoleActionAccessSaga({ payload, roleId, accessTypeLOB }) {
  try {
    // put: loading true
    yield put({
      type: adminRoleConstants.UPDATE_ROLE_ACTION_ACCESS_LOADING,
      payload: true,
    });

    const responsePUT = yield call(adminRoleService.updateRoleActionAccess, payload, roleId, accessTypeLOB);

    if (isEmpty(responsePUT)) {
      return;
    }

    // put: loading false
    yield put({
      type: adminRoleConstants.UPDATE_ROLE_ACTION_ACCESS_LOADING,
      payload: false,
    });

    const responseGET = yield call(adminRoleService.getRoleActionAccess, roleId);

    yield put({
      type: adminRoleConstants.GET_ROLE_ACTION_ACCESS_SUCCESS,
      payload: { data: { ...responseGET }, roleId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Role Action Access updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

// ==============

// status access
// ==============

export function* getRoleStatusAccessSaga({ roleId }) {
  try {
    // put: loading true
    yield put({
      type: adminRoleConstants.GET_ROLE_STATUS_ACCESS_LOADING,
      payload: true,
    });

    const response = yield call(adminRoleService.getRoleStatusAccess, roleId);

    if (isEmpty(response)) {
      return;
    }

    yield put({
      type: adminRoleConstants.GET_ROLE_STATUS_ACCESS_SUCCESS,
      payload: { data: { ...response }, roleId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateRoleStatusAccessSaga({ payload, roleId, LOBID, statusType }) {
  try {
    // put: loading true
    yield put({
      type: adminRoleConstants.UPDATE_ROLE_STATUS_ACCESS_LOADING,
      payload: true,
    });

    const response = yield all(
      payload.map((item) => {
        // determine if should be a delete
        // statusType 1 (claim Status) is delete if statusAccessType is 0
        // statusType 1 (claim Status (Payment)) is delete if statusAccessType is 0
        if (
          (statusType === 1 && item.statusAccessType === "0") ||
          (statusType === 2 && item.statusAccessType === "0")
        ) {
          // delete
          return call(adminRoleService.deleteRoleStatusAccess, item.roleStatusAccessId);
        } else {
          // update value
          return call(
            adminRoleService.updateRoleStatusAccess,
            roleId,
            item.statusId,
            LOBID,
            statusType,
            item.statusAccessType
          );
        }
      })
    );

    if (isEmpty(response)) {
      return;
    }

    // put: loading false
    yield put({
      type: adminRoleConstants.UPDATE_ROLE_STATUS_ACCESS_LOADING,
      payload: false,
    });

    const responseGET = yield call(adminRoleService.getRoleStatusAccess, roleId);

    yield put({
      type: adminRoleConstants.GET_ROLE_STATUS_ACCESS_SUCCESS,
      payload: { data: { ...responseGET }, roleId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Role Status Access updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getRoleNotificationSaga({ roleId }) {
  try {
    // put: loading true
    yield put({
      type: adminRoleConstants.ADMIN_ROLES_NOTIFICATIONS_LOADING,
      payload: true,
    });

    const response = yield call(adminRoleService.getRoleNotifications, roleId);

    // if (isEmpty(response)) {
    //   return;
    // }

    yield put({
      type: adminRoleConstants.ADMIN_ROLES_NOTIFICATIONS_SUCCESS,
      payload: { notifications: [...response], roleId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateRoleNotificationSaga({ roleId, notificationIds }) {
  try {
    // put: loading true
    yield put({
      type: adminRoleConstants.ADMIN_ROLES_UPDATE_NOTIFICATIONS_LOADING,
      payload: true,
    });

    const response = yield call(adminRoleService.updateRoleNotifications, roleId, notificationIds);

    yield put({
      type: adminRoleConstants.ADMIN_ROLES_UPDATE_NOTIFICATIONS_SUCCESS,
      payload: { response, roleId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Notifications Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteRoleNotificationSaga({ roleId }) {
  try {
    // put: loading true
    yield put({
      type: adminRoleConstants.ADMIN_ROLES_DELETE_NOTIFICATIONS_LOADING,
      payload: true,
    });

    const response = yield call(adminRoleService.deleteRoleNotifications, roleId);

    yield put({
      type: adminRoleConstants.ADMIN_ROLES_DELETE_NOTIFICATIONS_SUCCESS,
      payload: { response, roleId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Notifications Disabled" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
// ==============

import { adminJobCostingConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";

let initState = {
  loading: false,
  rates: [],
  rdaOptions: [],
};

export function jobCosting(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminJobCostingConstants.GET_RATES_REQUEST:
    case adminJobCostingConstants.ADD_RATE_REQUEST:
    case adminJobCostingConstants.UPDATE_RATE_REQUEST:
    case adminJobCostingConstants.DELETE_RATE_REQUEST:
      return produce(state, (draft) => {
        draft["loading"] = true;
      });
    case adminJobCostingConstants.GET_RATES_SUCCESS:
      return produce(state, (draft) => {
        draft["rates"] = payload;
        draft["loading"] = false;
      });
    case adminJobCostingConstants.GET_RDA_OPTIONS_SUCCESS:
      return produce(state, (draft) => {
        const { options } = payload;
        draft["rdaOptions"] = options;
      });
    case REHYDRATE: {
      // if (payload && payload.admin && payload.admin.jobCosting) {
      //   return { ...payload.admin.jobCosting };
      // } else {
      return { ...initState };
      // }
    }
    case adminJobCostingConstants.ADMIN_JOB_COSTING_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

import { jointReportConstants } from "_constants";

const getUserReports = () => {
    return {
        type: jointReportConstants.GET_USER_REPORTS_REQUEST
    }
}

const getReport = (reportID) => {
    return {
        type: jointReportConstants.GET_REPORT_REQUEST,
        reportID
    }
}

const getProcs = () => {
    return {
        type: jointReportConstants.GET_REPORT_PROCS_REQUEST
    }
}

const queryProc = (procedures, filters, fields, inputs, reportName, shared, rowsPerPage, page) => {

    return {
        type: jointReportConstants.QUERY_REPORT_PROC_REQUEST,
        procedures, 
        filters, 
        fields, 
        inputs, 
        reportName, 
        shared, 
        rowsPerPage, 
        page
    }
}

const getScoreboard = (startDate) => {
    return {
      type: jointReportConstants.GET_SCORE_BOARD_REQUEST,
      startDate
    };
  };
  
  const getAList = (regionId, marketId) => {
    return {
      type: jointReportConstants.GET_A_LIST_REPORT_REQUEST,
      regionId,
      marketId,
    };
  };

  const getRemittance = (payload) => {
    return {
        type: jointReportConstants.GET_REMITTANCE_REQUEST,
        payload
    }
  }

  function clearRemittance() {
    return {
        type: jointReportConstants.GET_REMITTANCE_CLEAR
    }}

export const jointReportActions = {
    getUserReports,
    getReport,
    getProcs,
    queryProc,
    getScoreboard,
    getAList,
    getRemittance,
    clearRemittance
};

import { put, call } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  paymentConstants,
} from "_constants";
import { paymentService } from "_services";

 export function* getPaymentSaga({payload}) {
    try {
      const response = yield call(
        paymentService.getPayment,
        payload
      );
  
      if (!response) {
        throw new Error("Error fetching payment");
      }
  
      yield put({
        type: paymentConstants.GET_PAYMENT_SUCCESS,
        payload: { ...response },
      });
    } catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
  }

import { powerBiConstants } from "_constants";
import { produce } from "immer";

let initState = {
  reports: {
    all: [], // all reports for powerBiRole
    loading: false, // all reports loading
    default: "", // defaultReports ids to display in dashboard
    individual: {}
  }
};

export function powerbi(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case powerBiConstants.GET_REPORTS_SUCCESS:
      return produce(state, (draft) => {
        draft["reports"]["all"] = [...payload["reports"]];
        draft["reports"]["default"] = payload["defaultReports"];
        draft["reports"]["loading"] = false;
      });
      case powerBiConstants.GET_REPORTS_LOADING:
        return produce(state, (draft) => {
          draft["reports"]["loading"] = true;
        });
    default:
      return state;
  }
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  name: {
    color: "var(--LightGray)",
    fontWeight: "lighter",
    textDecoration: "underline",
  },
  h3: {
    color: "var(--LightGray)",
  },
}));

export default function Index({ mapCrumbs }) {
  const classes = useStyles();
  const history = useHistory();

  // history.goBack()
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {mapCrumbs.map((crumb, i) => (
        <ButtonBase centerRipple={true} key={i}>
          <Typography
            color="textPrimary"
            className={classes.h3}
            onClick={() => (crumb.url ? history.push(crumb.url) : "")}
          >
            {crumb.label}
          </Typography>
        </ButtonBase>
      ))}
      ;
    </Breadcrumbs>
  );
}

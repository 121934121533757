// Api
// @min, @max: int
// required
const randomGenerator = (min = 0, max = 999) =>
  Math.floor(Math.random() * (max - min)) + min;

  /**
   * 
   * @param {Number} value 
   * @param {Number} place 
   * @returns 
   */
const roundToDecimalPlace = (value, place) => {
  return +(Math.round(value + `e+${place}`)+`e-${place}`);
}

export const numbers = {
  randomGenerator,
  roundToDecimalPlace
};

import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const getPayment = (payload) => {
  const { claimid, ccconfcode, lobid } = payload;

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/public/payment?claimid=${claimid}&ccconfcode=${ccconfcode}&lobid=${lobid}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching payment");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

export const paymentService = {
  getPayment,
};
export const jointReportConstants = {
  GET_USER_REPORTS_REQUEST: "GET_USER_REPORTS_REQUEST",
  GET_USER_REPORTS_SUCCESS: "GET_USER_REPORTS_SUCCESS",
  GET_REPORT_REQUEST: "GET_REPORT_REQUEST",
  GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
  GET_REPORT_PROCS_REQUEST: "GET_REPORT_PROCS_REQUEST",
  GET_REPORT_PROCS_SUCCESS: "GET_REPORT_PROCS_SUCCESS",
  QUERY_REPORT_PROC_REQUEST: "QUERY_REPORT_PROC_REQUEST",
  QUERY_REPORT_PROC_SUCCESS: "QUERY_REPORT_PROC_SUCCESS",
  QUERY_REPORT_PROC_LOADING: "QUERY_REPORT_PROC_LOADING",
  JOINT_REPORTS_CLEAR: "JOINT_REPORTS_CLEAR",

  GET_SCORE_BOARD_REQUEST: "GET_SCORE_BOARD_REQUEST",
  GET_SCORE_BOARD_SUCCESS: "GET_SCORE_BOARD_SUCCESS",
  GET_A_LIST_REPORT_REQUEST: "GET_A_LIST_REPORT_REQUEST",
  GET_A_LIST_REPORT_SUCCESS: "GET_A_LIST__REPORT_SUCCESS",
  GET_A_LIST_REPORT_LOADING_TRUE: "GET_A_LIST_REPORT_LOADING_TRUE",

  GET_REMITTANCE_REQUEST: "GET_REMITTANCE_REQUEST",
  GET_REMITTANCE_SUCCESS: "GET_REMITTANCE_SUCCESS",
  GET_REMITTANCE_CLEAR: "GET_REMITTANCE_CLEAR"

  };
  
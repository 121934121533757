import moment from "moment";
import { formatSqlDateTime } from "_helpers";

export const sqlTypesToEnglish = {
  char: "Character",
  datetime: "Date",
  decimal: "Decimal",
  float: "Number",
  int: "Number",
  money: "Currency",
  numeric: "Number",
  nvarchar: "Text",
  smalldatetime: "Date",
  varchar: "Text",
  bit: "True / False",
};

// for money
// https://www.npmjs.com/package/big.js
// or
// https://dinerojs.com/
// or
// https://currency.js.org/

export const sqlTypesToJavascript = {
  char: "String",
  datetime: "Date",
  decimal: "Number",
  float: "Number",
  int: "Number",
  money: "Money",
  numeric: "Number",
  nvarchar: "String",
  smalldatetime: "Date",
  varchar: "String",
  bit: "Boolean",
};

export const sqlTypesToJavascriptConversion = (sqlType, value) => {
  if (sqlType === "char") {
    // char: "String",
    if (value === null || value === "undefined" || value === "") {
      return "";
    }

    return `${value}`;
  }

  if (sqlType === "datetime") {
    // datetime: "Date",
    if (value === null || value === "undefined" || value === "") {
      return "";
    }

    return formatSqlDateTime(value);
  }

  if (sqlType === "decimal") {
    // decimal: "Number",
    if (value === null || value === "undefined" || value === "") {
      return 0;
    }
    return parseFloat(value);
  }

  if (sqlType === "float") {
    // float: "Number",

    if (value === null || value === "undefined" || value === "") {
      return 0;
    }
    return parseFloat(value);
  }

  if (sqlType === "int") {
    // int: "Number",

    if (value === null || value === "undefined" || value === "") {
      return 0;
    }
    return Number(value);
  }
  if (sqlType === "money") {
    // money: "Money",

    if (value === null || value === "undefined" || value === "") {
      return 0;
    }

    return Number(value);
  }

  if (sqlType === "numeric") {
    // numeric: "Number",

    if (value === null || value === "undefined" || value === "") {
      return 0;
    }
    return Number(value);
  }

  if (sqlType === "nvarchar") {
    // nvarchar: "String",

    if (value === null || value === "undefined" || value === "") {
      return "";
    }
    return `${value}`;
  }

  if (sqlType === "smalldatetime") {
    // smalldatetime: "Date",
    if (value === null || value === "undefined" || value === "") {
      return "";
    }

    return formatSqlDateTime(value);
  }

  if (sqlType === "varchar") {
    // varchar: "String",

    if (value === null || value === "undefined" || value === "") {
      return "";
    }
    return `${value}`;
  }

  if (sqlType === "bit") {
    // bit: "Boolean",
    if (value === "1" || value === 1) {
      return true;
    }
    if (value === "0" || value === 0) {
      return false;
    }

    return false;
  }

  return value;
};

export const allowedConditionsSqlTypes = {
  datetime: [
    "is equal to",
    "is not equal to",
    "is less than",
    "contains in list",
    "is less than or equal to",
    "is greater than",
    "is greater than or equal to",
  ],
  decimal: [
    "is equal to",
    "is not equal to",
    "contains in list",
    "is less than",
    "is less than or equal to",
    "is greater than",
    "is greater than or equal to",
  ],
  float: [
    "is equal to",
    "is not equal to",
    "contains in list",
    "is less than",
    "is less than or equal to",
    "is greater than",
    "is greater than or equal to",
  ],
  int: [
    "is equal to",
    "is not equal to",
    "contains in list",
    "is less than",
    "is less than or equal to",
    "is greater than",
    "is greater than or equal to",
  ],
  money: [
    "is equal to",
    "is not equal to",
    "contains in list",
    "is less than",
    "is less than or equal to",
    "is greater than",
    "is greater than or equal to",
  ],
  numeric: [
    "is equal to",
    "is not equal to",
    "contains in list",
    "is less than",
    "is less than or equal to",
    "is greater than",
    "is greater than or equal to",
  ],
  nvarchar: [
    "contains",
    "does not contain",
    "is equal to",
    "contains in list",
    "does not start with",
    "starts with",
    "is not equal to",
  ],
  smalldatetime: [
    "is equal to",
    "is not equal to",
    "is less than",
    "contains in list",
    "is less than or equal to",
    "is greater than",
    "is greater than or equal to",
  ],
  varchar: [
    "contains",
    "does not contain",
    "is equal to",
    "contains in list",
    "does not start with",
    "starts with",
    "is not equal to",
  ],
  bit: ["is equal to", "is not equal to"],
};

let compareVarChar = (
  condition_inner,
  field_value_inner,
  value_to_compare_inner
) => {
  if (field_value_inner === null) {
    return false;
  }
  // TODO
  // "contains in list", = no case requirements
  switch (condition_inner) {
    case "contains":
      // no case requirements, the whole string has to be included
      let resultContains = field_value_inner
        .toLowerCase()
        .includes(value_to_compare_inner.toLowerCase());
      return resultContains;
    case "does not contain":
      // no case requirements, the whole string has to be not included
      let resultContainsNot = field_value_inner
        .toLowerCase()
        .includes(value_to_compare_inner.toLowerCase());
      return resultContainsNot === true ? false : true;
    case "is equal to":
      // strict, case sensitive
      let resultEqualTo = field_value_inner === value_to_compare_inner;
      return resultEqualTo;
    case "is not equal to":
      // strict, case sensitive
      let resultEqualToNot = field_value_inner !== value_to_compare_inner;
      return resultEqualToNot;
    case "starts with":
      // has to have the whole string at position zero
      let resultStartsWith = field_value_inner
        .toLowerCase()
        .startsWith(value_to_compare_inner.toLowerCase());
      return resultStartsWith;
    case "does not start with":
      // take the value to comparea nd make sure it is not at position zero
      let resultStartsWithNot = field_value_inner
        .toLowerCase()
        .startsWith(value_to_compare_inner.toLowerCase());
      return resultStartsWithNot === true ? false : true;
    default:
      return false;
  }
};

let compareDateTime = (
  condition_inner,
  field_value_inner,
  value_to_compare_inner
) => {
  // TODO
  // "contains in list", = no case requirements

  let trim_time_from_field_value = field_value_inner.substring(
    0,
    field_value_inner.indexOf("T")
  );

  switch (condition_inner) {
    case "is equal to":
      let resultIsEqualTo = moment(value_to_compare_inner).isSame(
        moment(trim_time_from_field_value)
      );
      return resultIsEqualTo;
    case "is not equal to":
      let resultIsEqualToNot = moment(value_to_compare_inner).isSame(
        moment(trim_time_from_field_value)
      );
      return resultIsEqualToNot === true ? false : true;
    case "is less than":
      let resultIsLessThan = moment(trim_time_from_field_value).isBefore(
        moment(value_to_compare_inner)
      );
      return resultIsLessThan;
    case "is less than or equal to":
      let resultIsLessThanOrEqual = moment(
        trim_time_from_field_value
      ).isSameOrBefore(moment(value_to_compare_inner));
      return resultIsLessThanOrEqual;
    case "is greater than":
      let resultIsGreaterThan = moment(trim_time_from_field_value).isAfter(
        moment(value_to_compare_inner)
      );
      return resultIsGreaterThan;
    case "is greater than or equal to":
      let resultIsGreaterThanOrEqual = moment(
        trim_time_from_field_value
      ).isSameOrAfter(moment(value_to_compare_inner));
      return resultIsGreaterThanOrEqual;
    default:
      return false;
  }
};

let compareDecimal = (
  condition_inner,
  field_value_inner,
  value_to_compare_inner
) => {
  // TODO
  // "contains in list", = no case requirements

  switch (condition_inner) {
    case "is equal to":
      let isEqualTo =
        parseFloat(field_value_inner) === parseFloat(value_to_compare_inner);
      return isEqualTo;
    case "is not equal to":
      let isEqualToNot =
        parseFloat(field_value_inner) === parseFloat(value_to_compare_inner);
      return isEqualToNot === true ? false : true;
    case "is less than":
      let isLessThan =
        parseFloat(field_value_inner) < parseFloat(value_to_compare_inner);
      return isLessThan;
    case "is less than or equal to":
      let isLessThanOrEqualTo =
        parseFloat(field_value_inner) <= parseFloat(value_to_compare_inner);
      return isLessThanOrEqualTo;
    case "is greater than":
      let isGreaterThan =
        parseFloat(field_value_inner) > parseFloat(value_to_compare_inner);
      return isGreaterThan;
    case "is greater than or equal to":
      let isGreaterThanOrEqualTo =
        parseFloat(field_value_inner) >= parseFloat(value_to_compare_inner);
      return isGreaterThanOrEqualTo;
    default:
      return false;
  }
};

let compareFloat = (
  condition_inner,
  field_value_inner,
  value_to_compare_inner
) => {
  // TODO
  // "contains in list", = no case requirements

  switch (condition_inner) {
    case "is equal to":
      let isEqualTo =
        parseFloat(field_value_inner) === parseFloat(value_to_compare_inner);

      return isEqualTo;
    case "is not equal to":
      let isEqualToNot =
        parseFloat(field_value_inner) === parseFloat(value_to_compare_inner);
      return isEqualToNot === true ? false : true;
    case "is less than":
      let isLessThan =
        parseFloat(field_value_inner) < parseFloat(value_to_compare_inner);
      return isLessThan;
    case "is less than or equal to":
      let isLessThanOrEqualTo =
        parseFloat(field_value_inner) <= parseFloat(value_to_compare_inner);
      return isLessThanOrEqualTo;
    case "is greater than":
      let isGreaterThan =
        parseFloat(field_value_inner) > parseFloat(value_to_compare_inner);
      return isGreaterThan;
    case "is greater than or equal to":
      let isGreaterThanOrEqualTo =
        parseFloat(field_value_inner) >= parseFloat(value_to_compare_inner);
      return isGreaterThanOrEqualTo;
    default:
      return false;
  }
};

let compareInt = (
  condition_inner,
  field_value_inner,
  value_to_compare_inner
) => {
  // TODO
  // "contains in list", = no case requirements

  switch (condition_inner) {
    case "is equal to":
      let isEqualTo =
        parseInt(field_value_inner) === parseInt(value_to_compare_inner);
      return isEqualTo;
    case "is not equal to":
      let isEqualToNot =
        parseInt(field_value_inner) === parseInt(value_to_compare_inner);
      return isEqualToNot === true ? false : true;
    case "is less than":
      let isLessThan =
        parseInt(field_value_inner) < parseInt(value_to_compare_inner);
      return isLessThan;
    case "is less than or equal to":
      let isLessThanOrEqualTo =
        parseInt(field_value_inner) <= parseInt(value_to_compare_inner);
      return isLessThanOrEqualTo;
    case "is greater than":
      let isGreaterThan =
        parseInt(field_value_inner) > parseInt(value_to_compare_inner);
      return isGreaterThan;
    case "is greater than or equal to":
      let isGreaterThanOrEqualTo =
        parseInt(field_value_inner) >= parseInt(value_to_compare_inner);
      return isGreaterThanOrEqualTo;
    default:
      return false;
  }
};

let compareMoney = (
  condition_inner,
  field_value_inner,
  value_to_compare_inner
) => {
  // TODO
  // "contains in list", = no case requirements

  switch (condition_inner) {
    case "is equal to":
      let isEqualTo =
        parseFloat(field_value_inner) === parseFloat(value_to_compare_inner);

      return isEqualTo;
    case "is not equal to":
      let isEqualToNot =
        parseFloat(field_value_inner) === parseFloat(value_to_compare_inner);
      return isEqualToNot === true ? false : true;
    case "is less than":
      let isLessThan =
        parseFloat(field_value_inner) < parseFloat(value_to_compare_inner);
      return isLessThan;
    case "is less than or equal to":
      let isLessThanOrEqualTo =
        parseFloat(field_value_inner) <= parseFloat(value_to_compare_inner);
      return isLessThanOrEqualTo;
    case "is greater than":
      let isGreaterThan =
        parseFloat(field_value_inner) > parseFloat(value_to_compare_inner);
      return isGreaterThan;
    case "is greater than or equal to":
      let isGreaterThanOrEqualTo =
        parseFloat(field_value_inner) >= parseFloat(value_to_compare_inner);
      return isGreaterThanOrEqualTo;
    default:
      return false;
  }
};

let compareNumeric = (
  condition_inner,
  field_value_inner,
  value_to_compare_inner
) => {
  // TODO
  // "contains in list", = no case requirements

  switch (condition_inner) {
    case "is equal to":
      let isEqualTo =
        parseFloat(field_value_inner) === parseFloat(value_to_compare_inner);

      return isEqualTo;
    case "is not equal to":
      let isEqualToNot =
        parseFloat(field_value_inner) === parseFloat(value_to_compare_inner);
      return isEqualToNot === true ? false : true;
    case "is less than":
      let isLessThan =
        parseFloat(field_value_inner) < parseFloat(value_to_compare_inner);
      return isLessThan;
    case "is less than or equal to":
      let isLessThanOrEqualTo =
        parseFloat(field_value_inner) <= parseFloat(value_to_compare_inner);
      return isLessThanOrEqualTo;
    case "is greater than":
      let isGreaterThan =
        parseFloat(field_value_inner) > parseFloat(value_to_compare_inner);
      return isGreaterThan;
    case "is greater than or equal to":
      let isGreaterThanOrEqualTo =
        parseFloat(field_value_inner) >= parseFloat(value_to_compare_inner);
      return isGreaterThanOrEqualTo;
    default:
      return false;
  }
};

let compareBit = (
  condition_inner,
  field_value_inner,
  value_to_compare_inner
) => {
  let fieldValue;
  if (field_value_inner === 1) {
    fieldValue = true;
  } else if (field_value_inner === true) {
    fieldValue = true;
  }

  if (field_value_inner === 0) {
    fieldValue = false;
  } else if (field_value_inner === false) {
    fieldValue = false;
  }

  let fieldValueToCompare;
  if (value_to_compare_inner === "true") {
    fieldValueToCompare = true;
  } else if (value_to_compare_inner === 1) {
    fieldValueToCompare = true;
  } else if (value_to_compare_inner === "1") {
    fieldValueToCompare = true;
  }

  if (value_to_compare_inner === "false") {
    fieldValueToCompare = false;
  } else if (value_to_compare_inner === 0) {
    fieldValueToCompare = false;
  } else if (value_to_compare_inner === "0") {
    fieldValueToCompare = false;
  }

  switch (condition_inner) {
    case "is equal to":
      let resultEqualTo = fieldValue === fieldValueToCompare;
      return resultEqualTo;
    case "is not equal to":
      let resultEqualToNot = fieldValue !== fieldValueToCompare;
      return resultEqualToNot;
    default:
      return false;
  }
};

export let comparisonCheckSQL = (
  type_inner,
  condition_inner,
  field_value_inner,
  value_to_compare_inner
) => {
  switch (type_inner) {
    case "varchar":
      let resultVarChar = compareVarChar(
        condition_inner,
        field_value_inner,
        value_to_compare_inner
      );
      return resultVarChar;
    case "datetime":
      let resultDateTime = compareDateTime(
        condition_inner,
        field_value_inner,
        value_to_compare_inner
      );
      return resultDateTime;
    case "decimal":
      let resultDecimal = compareDecimal(
        condition_inner,
        field_value_inner,
        value_to_compare_inner
      );
      return resultDecimal;
    case "float":
      let resultFloat = compareFloat(
        condition_inner,
        field_value_inner,
        value_to_compare_inner
      );
      return resultFloat;
    case "int":
      let resultInt = compareInt(
        condition_inner,
        field_value_inner,
        value_to_compare_inner
      );
      return resultInt;
    case "money":
      let resultMoney = compareMoney(
        condition_inner,
        field_value_inner,
        value_to_compare_inner
      );
      return resultMoney;
    case "numeric":
      let resultNumeric = compareNumeric(
        condition_inner,
        field_value_inner,
        value_to_compare_inner
      );
      return resultNumeric;
    case "nvarchar":
      let resultNVarChar = compareVarChar(
        condition_inner,
        field_value_inner,
        value_to_compare_inner
      );
      return resultNVarChar;
    case "smalldatetime":
      let resultSmallDateTime = compareDateTime(
        condition_inner,
        field_value_inner,
        value_to_compare_inner
      );
      return resultSmallDateTime;
    case "bit":
      let resultBit = compareBit(
        condition_inner,
        field_value_inner,
        value_to_compare_inner
      );
      return resultBit;
    default:
      return false;
  }
};

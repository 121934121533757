import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const loginMobile = (payload) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ email: payload.payload }),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/mobile/login`, parameters)
    .then((response) => {
      if (response.status !== 200) throw new Error("login failed");
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getMyclaims = (cookie) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${cookie}`;
  }
  
console.log('getMyClaims');
console.log(`${apiBase}/tel/mobileclaim/getMobileClaims${jwtCookie}`);

  return fetch(`${apiBase}/tel/mobileclaim/getMobileClaims${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching pre-claims");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getAllMobileClaims = () => {

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/internalmobile/getAllMobileClaims${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching pre-claims");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getInitial = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.getMobile("token"))}`;
  }

  return fetch(`${apiBase}/tel/mobileclaim/initial${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching claims");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const createInitial = (payload, entityID) => {
  let body = { ...payload };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.getMobile("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/mobileclaim?entityid=${entityID}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating mobile claim");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getFull = (mobileclaimid, internal) => {

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.getMobile("token"))}`;
  }

  let path = internal ? "internalmobile" : "mobileclaim";

  return fetch(`${apiBase}/tel/${path}?mobileclaimid=${mobileclaimid}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching claims");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateFull = (payload, mobileclaimid, internal) => {
  let body = { ...payload };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.getMobile("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  let path = internal ? "internalmobile" : "mobileclaim";

  return fetch(`${apiBase}/tel/${path}?mobileclaimid=${mobileclaimid}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating mobile claim");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const createMoveToPrg = (mobileclaimid, lobid) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.getMobile("token"))}`;
  }

  return fetch(
    `${apiBase}/tel/mobileclaim/movetoprg?mobileclaimid=${mobileclaimid}&lobid=${lobid}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching claims");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getFiles = (mobileclaimid, internal) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.getMobile("token"))}`;
  }

  let path = internal ? "internalmobile" : "mobileclaim";

  let apiBase = getApiBase();
  return fetch(`${apiBase}/${path}/files?lobid=1&&mobileclaimid=${mobileclaimid}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching attachments");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const uploadFile = (image, mobileClaimID, claimID, categoryID, userEmail, internal) => {
  const dataFile = new FormData();

  const fileNameWithType = image.name;
  const originalFileName = image.name.substr(0, image.name.lastIndexOf("."));

  const fileTypeNative = image.name.substr(image.name.lastIndexOf("."));

  dataFile.append("file", image, `${mobileClaimID} - misc`);
  dataFile.append("fileName", `${mobileClaimID} - misc`);
  dataFile.append("mobileClaimID", mobileClaimID);
  dataFile.append('claimID', claimID ? claimID : "");
  dataFile.append("userEmail", userEmail);
  dataFile.append("categoryID", categoryID);
  dataFile.append("originalFileName", originalFileName);
  dataFile.append("fileNameWithType", fileNameWithType);
  dataFile.append("fileTypeNative", fileTypeNative);
  dataFile.append("lob", "TEL"); // TEL , POWGAS , FRELO

  if (process.env.NODE_ENV !== "production") {
    dataFile.append("jwtCookie", encodeURI(cookieService.getMobile("token")));
  }

  const parameters = {
    method: "POST",
    credentials: "include",
    body: dataFile,
  };

  let path = internal ? "internalmobile" : "mobileclaim";

  let apiBase = getApiBase();
  return fetch(`${apiBase}/${path}/upload?jwtCookie=${encodeURI(cookieService.getMobile("token"))}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id: originalFileName };
    })
    .catch((err) => {
      throw err;
    });
};

const deleteMobileClaim = (mobileclaimid) => {
  
  let body = {}
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.getMobile("token"),
    };
  }

  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/internalmobile?mobileclaimid=${mobileclaimid}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error deleting mobile claim");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

export const mobileService = {
  loginMobile,
  getInitial,
  getMyclaims,
  getAllMobileClaims,
  createInitial,
  getFull,
  updateFull,
  getFiles,
  createMoveToPrg,
  uploadFile,
  deleteMobileClaim
};

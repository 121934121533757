import { call, put } from "redux-saga/effects";
import { alertConstants } from "../_constants";
import { errorReportingService } from "_services";

export function* postErrorSaga({
  error,
  errorInfo,
  location,
  userId,
  timestamp,
}) {
  try {
    const response = yield call(
      errorReportingService.postError,
      JSON.stringify(errorInfo),
      location,
      userId,
      timestamp,
      error.message
    );

    console.log(response);
    return;
  } catch (error) {
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export const powerGasDispatchConstants = {
  GET_ALL_DISPATCH_POWER_GAS_REQUEST: "GET_ALL_DISPATCH_POWER_GAS_REQUEST",
  GET_ALL_DISPATCH_POWER_GAS_SUCCESS: "GET_ALL_DISPATCH_POWER_GAS_SUCCESS",
  GET_ONE_DISPATCH_POWER_GAS_REQUEST: "GET_ONE_DISPATCH_POWER_GAS_REQUEST",
  GET_ONE_DISPATCH_POWER_GAS_SUCCESS: "GET_ONE_DISPATCH_POWER_GAS_SUCCESS",
  UPDATE_ONE_DISPATCH_POWER_GAS_REQUEST:
    "UPDATE_ONE_DISPATCH_POWER_GAS_REQUEST",
  UPDATE_ONE_DISPATCH_POWER_GAS_SUCCESS:
    "UPDATE_ONE_DISPATCH_POWER_GAS_SUCCESS",
  UPDATE_TEN_EIGHT_DISPATCH_POWER_GAS_REQUEST:
    "UPDATE_TEN_EIGHT_DISPATCH_POWER_GAS_REQUEST",
  UPDATE_TEN_EIGHT_DISPATCH_POWER_GAS_SUCCESS:
    "UPDATE_TEN_EIGHT_DISPATCH_POWER_GAS_SUCCESS",

    UPDATE_DISPATCH_POWER_GAS_TIMER_REQUEST: "UPDATE_DISPATCH_POWER_GAS_TIMER_REQUEST",
    UPDATE_DISPATCH_POWER_GAS_TIMER_SUCCESS: "UPDATE_DISPATCH_POWER_GAS_TIMER_SUCCESS",
    UPDATE_DISPATCH_POWER_GAS_TIMER_PAUSE_REQUEST:"UPDATE_DISPATCH_POWER_GAS_TIMER_PAUSE_REQUEST",
    UPDATE_DISPATCH_POWER_GAS_TIMER_PAUSE_SUCCESS:"UPDATE_DISPATCH_POWER_GAS_TIMER_PAUSE_SUCCESS",
    UPDATE_DISPATCH_POWER_GAS_TIMER_COLOR_REQUEST:"UPDATE_DISPATCH_POWER_GAS_TIMER_COLOR_REQUEST",
    UPDATE_DISPATCH_POWER_GAS_TIMER_COLOR_SUCCESS:"UPDATE_DISPATCH_POWER_GAS_TIMER_COLOR_SUCCESS",

  POWER_GAS_DISPATCH_CLEAR: "POWER_GAS_DISPATCH_CLEAR",

};

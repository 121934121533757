import { adminEntityConstants } from "_constants";

const getClientInformation = (entityId, lob) => {
  return {
    type: adminEntityConstants.GET_CLIENT_INFORMATION,
    entityId,
    lob,
  };
};

const getAllDispatchEntities = () => {
  return {
    type: adminEntityConstants.GET_ALL_DISPATCH_ENTITIES_REQUEST,
  };
};

const updateClientInformation = (files, fieldValues, id,lob) => {
  return {
    type: adminEntityConstants.UPDATE_CLIENT_INFORMATION,
    payload: { ...fieldValues, files },
    id,
    lob
  };
};

const getPaymentForm = (entityId, lob) => {
  return {
    type: adminEntityConstants.GET_PAYMENT_FORM_REQUEST,
    entityId,
    lob,
  };
};

const updatePaymentForm = (payload, entityId, lob) => {
  return {
    type: adminEntityConstants.UPDATE_PAYMENT_FORM_REQUEST,
    payload,
    entityId,
    lob,
  };
};

const getPaymentFormFees = (lob,entityId) => {
  return {
    type: adminEntityConstants.GET_PAYMENT_FORM_FEES_REQUEST,
    lob,
    entityId,
  };
};

const updatePaymentFormFees = (payload, entityId, cb = null) => {
  return {
    type: adminEntityConstants.UPDATE_PAYMENT_FORM_FEES_REQUEST,
    payload,
    entityId,
    cb
  };
};

export const adminEntityActions = {
  getClientInformation,
  getAllDispatchEntities,
  updateClientInformation,
  getPaymentForm,
  updatePaymentForm,
  getPaymentFormFees,
  updatePaymentFormFees
};

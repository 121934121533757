import { adminRecoveryAgentConstants } from "_constants";


const getAll = () => {
    return {
      type: adminRecoveryAgentConstants.ADMIN_GET_ALL_RECOVERY_AGENTS_REQUEST,
    };
  };

  const getAgent = (id) => {
    return {
      type: adminRecoveryAgentConstants.ADMIN_GET_ONE_RECOVERY_AGENT_REQUEST,
      id
    };
  };

  const getTeamList = (id) => {
    return {
      type: adminRecoveryAgentConstants.ADMIN_GET_TEAM_LIST_REQUEST,
      id
    };
  };


export const adminRecoveryAgentActions = {
    getAll,
    getAgent,
    getTeamList
  };
export const adminUserConstants = {
  ADMIN_USERS_GET_ALL_REQUEST: "ADMIN_USERS_GET_ALL_REQUEST",
  ADMIN_USERS_GET_ALL_SUCCESS: "ADMIN_USERS_GET_ALL_SUCCESS",
  ADMIN_USERS_GET_ROLES_REQUEST: "ADMIN_USERS_GET_ROLES_REQUEST",
  ADMIN_USERS_GET_ROLES_SUCCESS: "ADMIN_USERS_GET_ROLES_SUCCESS",
  ADMIN_PRG_USERS_GET_REQUEST: "ADMIN_PRG_USERS_GET_REQUEST",
  ADMIN_PRG_USERS_GET_SUCCESS: "ADMIN_PRG_USERS_GET_SUCCESS",
  ADMIN_USERS_CREATE_REQUEST: "ADMIN_USERS_CREATE_REQUEST",
  ADMIN_USERS_CREATE_SUCCESS: "ADMIN_USERS_CREATE_SUCCESS",
  ADMIN_USERS_GET_GENERAL_REQUEST: "ADMIN_USERS_GET_GENERAL_REQUEST",
  ADMIN_USERS_GET_GENERAL_SUCCESS: "ADMIN_USERS_GET_GENERAL_SUCCESS",
  ADMIN_USERS_UPDATE_GENERAL_REQUEST: "ADMIN_USERS_UPDATE_GENERAL_REQUEST",
  ADMIN_USERS_UPDATE_GENERAL_SUCCESS: "ADMIN_USERS_UPDATE_GENERAL_SUCCESS",
  ADMIN_USERS_GET_DB_ACCESS_REQUEST: "ADMIN_USERS_GET_DB_ACCESS_REQUEST",
  ADMIN_USERS_GET_DB_ACCESS_SUCCESS: "ADMIN_USERS_GET_DB_ACCESS_SUCCESS",
  ADMIN_USERS_UPDATE_DB_ACCESS_REQUEST: "ADMIN_USERS_UPDATE_DB_ACCESS_REQUEST",
  ADMIN_USERS_UPDATE_DB_ACCESS_SUCCESS: "ADMIN_USERS_UPDATE_DB_ACCESS_SUCCESS",
  ADMIN_USERS_GET_ENTITIES_ACCESS_REQUEST: "ADMIN_USERS_GET_ENTITIES_ACCESS_REQUEST",
  ADMIN_USERS_GET_ENTITIES_ACCESS_SUCCESS: "ADMIN_USERS_GET_ENTITIES_ACCESS_SUCCESS",
  ADMIN_USERS_UPDATE_ENTITIES_ACCESS_REQUEST: "ADMIN_USERS_UPDATE_ENTITIES_ACCESS_REQUEST",
  ADMIN_USERS_UPDATE_ENTITIES_ACCESS_SUCCESS: "ADMIN_USERS_UPDATE_ENTITIES_ACCESS_SUCCESS",
  ADMIN_USERS_TOGGLE_ENTITY_ACCESS_REQUEST: "ADMIN_USERS_TOGGLE_ENTITY_ACCESS_REQUEST",
  ADMIN_USERS_TOGGLE_ENTITY_ACCESS_SUCCESS: "ADMIN_USERS_TOGGLE_ENTITY_ACCESS_SUCCESS",
  ADMIN_USERS_GET_USER_TYPES_REQUEST: "ADMIN_USERS_GET_USER_TYPES_REQUEST",
  ADMIN_USERS_GET_USER_TYPES_SUCCESS: "ADMIN_USERS_GET_USER_TYPES_SUCCESS",
  ADMIN_USERS_GET_ACTION_ACCESS_REQUEST: "ADMIN_USERS_GET_ACTION_ACCESS_REQUEST",
  ADMIN_USERS_GET_ACTION_ACCESS_SUCCESS: "ADMIN_USERS_GET_ACTION_ACCESS_SUCCESS",
  ADMIN_USERS_GET_ACTION_ACCESS_LOADING: "ADMIN_USERS_GET_ACTION_ACCESS_LOADING",
  ADMIN_USERS_UPDATE_ACTION_ACCESS_REQUEST: "ADMIN_USERS_UPDATE_ACTION_ACCESS_REQUEST",
  ADMIN_USERS_UPDATE_ACTION_ACCESS_SUCCESS: "ADMIN_USERS_UPDATE_ACTION_ACCESS_SUCCESS",
  ADMIN_USERS_UPDATE_ACTION_ACCESS_LOADING: "ADMIN_USERS_UPDATE_ACTION_ACCESS_LOADING",
  ADMIN_USERS_DELETE_ACTION_ACCESS_REQUEST: "ADMIN_USERS_DELETE_ACTION_ACCESS_REQUEST",
  ADMIN_USERS_DELETE_ACTION_ACCESS_SUCCESS: "ADMIN_USERS_DELETE_ACTION_ACCESS_SUCCESS",
  ADMIN_USERS_DELETE_ACTION_ACCESS_LOADING: "ADMIN_USERS_DELETE_ACTION_ACCESS_LOADING",
  ADMIN_USERS_GET_STATUS_ACCESS_REQUEST: "ADMIN_USERS_GET_STATUS_ACCESS_REQUEST",
  ADMIN_USERS_GET_STATUS_ACCESS_SUCCESS: "ADMIN_USERS_GET_STATUS_ACCESS_SUCCESS",
  ADMIN_USERS_GET_STATUS_ACCESS_LOADING: "ADMIN_USERS_GET_STATUS_ACCESS_LOADING",
  ADMIN_USERS_UPDATE_STATUS_ACCESS_REQUEST: "ADMIN_USERS_UPDATE_STATUS_ACCESS_REQUEST",
  ADMIN_USERS_UPDATE_STATUS_ACCESS_SUCCESS: "ADMIN_USERS_UPDATE_STATUS_ACCESS_SUCCESS",
  ADMIN_USERS_UPDATE_STATUS_ACCESS_LOADING: "ADMIN_USERS_UPDATE_STATUS_ACCESS_LOADING",
  ADMIN_USERS_DELETE_STATUS_ACCESS_REQUEST: "ADMIN_USERS_DELETE_STATUS_ACCESS_REQUEST",
  ADMIN_USERS_DELETE_STATUS_ACCESS_SUCCESS: "ADMIN_USERS_DELETE_STATUS_ACCESS_SUCCESS",
  ADMIN_USERS_DELETE_STATUS_ACCESS_LOADING: "ADMIN_USERS_DELETE_STATUS_ACCESS_LOADING",
  ADMIN_USERS_CLEAR: "ADMIN_USERS_CLEAR",
  ADMIN_USERS_DAMAGE_INVESTIGATOR_REQUEST: "ADMIN_USERS_DAMAGE_INVESTIGATOR_REQUEST",
  ADMIN_USERS_DAMAGE_INVESTIGATOR_SUCCESS: "ADMIN_USERS_DAMAGE_INVESTIGATOR_SUCCESS",
  ADMIN_USERS_DAMAGE_INVESTIGATOR_LOADING: "ADMIN_USERS_DAMAGE_INVESTIGATOR_LOADING",
  ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_REQUEST: "ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_REQUEST",
  ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_SUCCESS: "ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_SUCCESS",
  ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_LOADING: "ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_LOADING",
  ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_REQUEST: "ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_REQUEST",
  ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_SUCCESS: "ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_SUCCESS",
  ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_LOADING: "ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_LOADING",
  ADMIN_USERS_NOTIFICATIONS_REQUEST: "ADMIN_USERS_NOTIFICATIONS_REQUEST",
  ADMIN_USERS_NOTIFICATIONS_SUCCESS: "ADMIN_USERS_NOTIFICATIONS_SUCCESS",
  ADMIN_USERS_NOTIFICATIONS_LOADING: "ADMIN_USERS_NOTIFICATIONS_LOADING",
  ADMIN_USERS_UPDATE_NOTIFICATIONS_REQUEST: "ADMIN_USERS_UPDATE_NOTIFICATIONS_REQUEST",
  ADMIN_USERS_UPDATE_NOTIFICATIONS_SUCCESS: "ADMIN_USERS_UPDATE_NOTIFICATIONS_SUCCESS",
  ADMIN_USERS_UPDATE_NOTIFICATIONS_LOADING: "ADMIN_USERS_UPDATE_NOTIFICATIONS_LOADING",
  ADMIN_USERS_DELETE_NOTIFICATIONS_REQUEST: "ADMIN_USERS_DELETE_NOTIFICATIONS_REQUEST",
  ADMIN_USERS_DELETE_NOTIFICATIONS_SUCCESS: "ADMIN_USERS_DELETE_NOTIFICATIONS_SUCCESS",
  ADMIN_USERS_DELETE_NOTIFICATIONS_LOADING: "ADMIN_USERS_DELETE_NOTIFICATIONS_LOADING",
  ADMIN_USERS_TOGGLE_CLAIM_ASSISTANT: "ADMIN_USERS_TOGGLE_CLAIM_ASSISTANT"
};

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { DataGridPro } from "@mui/x-data-grid-pro";
import _getProperty from "lodash/get";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jointReportActions } from "_actions";
import moment from "moment";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: `${theme.palette.WhitePrg.main}!important`,
    "& .MuiTablePagination-root":{
      color: `${theme.palette.WhitePrg.main}!important`,
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      height: '8px'
    },
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.WhitePrg.main,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "4px",
    height: "70%",
    width: "80%",
    outline: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  close: {
    top: "0",
    right: 0,
    position: "absolute",
    borderRadius: "4px 4px 0 0",
    padding: "2px 5px",
    margin: 0,
  },
  icon: {
    color: theme.palette.DarkGrayPrg.main,
    cursor: "pointer",
  },
  h3: {
    color: theme.palette.LightGrayPrg.main,
  },
  hoverStyle: {
    cursor: "pointer",
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.WhitePrg.main,
  },
}));

export default function TeamBreakdownModal({ clear, teamBreakdown }) {
  const classes = useStyles();
  const handleClose = () => clear();
  const dispatch = useDispatch();
  const history = useHistory();

  const lobCurrent = useSelector((state) =>
  _getProperty(state, [
    "lob",
    "current",
  ])
);

const theme = useSelector((state) =>
_getProperty(state, [
  "settings",
  "theme",
])
);


  useEffect(() => {
    if (teamBreakdown) {
      const { regionId, marketId } = teamBreakdown;
      dispatch(jointReportActions.getAList(regionId, marketId));
    }
  }, [lobCurrent,teamBreakdown, dispatch]);

  const data = useSelector((state) =>
    _getProperty(state, [
      "joint",
      "reports",
      "aList",
      `${
        teamBreakdown && teamBreakdown["regionId"]
          ? teamBreakdown["regionId"]
          : null
      }-${
        teamBreakdown && teamBreakdown["marketId"]
          ? teamBreakdown["marketId"]
          : null
      }`,
    ])
  );

  const loading = useSelector((state) =>
    _getProperty(state, [
    "joint",
    "reports", 
    "aList",
    `loading`
    ])
  );

  const [entityName, setEntityName] = useState();
  // const [columns, setColumns] = useState();

  const columns = [
    {
      field: "id",
      headerName: "id",
      flex: 1,
      editable: false,
      hide: true
    },
    {
      field: "claimID",
      headerName: "Claim ID / Status",
      flex: 1,
      editable: false,
      minWidth: 220,
      renderCell: (data) => {
        const split = data.row.claimID.split("/");
        return (
          <>
          {split.map((item) => (
            <>{item}<br/></>
          ))}
          </>
        )
      }
    },
    {
      field: "entity",
      headerName: "Entity",
      flex: 1,
      editable: false,
      minWidth: 150,
    },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
      editable: false,
      minWidth: 110,
    },
    {
      field: "division",
      headerName: "Division",
      flex: 1,
      editable: false,
      minWidth: 120,
    },
    {
      field: "area",
      headerName: "Area",
      flex: 1,
      editable: false,
      minWidth: 120,
    },
    {
      field: "techName",
      headerName: "Tech Name",
      flex: 1,
      editable: false,
      minWidth: 150,
    },
    {
      field: "outDate",
      headerName: "Outage Date",
      flex: 1,
      editable: false,
      minWidth: 150,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      editable: false,
      minWidth: 200,
      renderCell: (data) => {
        const address = data.row.address.split("|");
        return (
          <>
          {address.map((item) => (
            <>{item}<br/></>
          ))}
          </>
        )
      }
    },
    {
      field: "investigator",
      headerName: "Investigator",
      flex: 1,
      editable: false,
      minWidth: 150,
    },
  ]


  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data?.[0]) {
      setEntityName(data[0].Entity);

      const rows = data.map((item) => {
        return {
          id: item.claimID,
          claimID: `${item.claimID} / ${item.status}`,
          entity: item.Entity,
          region: item.region,
          division: item.Division,
          area: item.area,
          techName: item.OutTechName,
          outDate: moment(item.OutDate).format("MM/DD/YYYY"),
          address: `${item.LocAddress}|${item.LocCity}, ${item.LocState}|${item.LocZIP}`,
          investigator: `${item.userFName ?? item.userFname ?? 'Not'} ${item.userLName ?? item.userLname ?? 'Assigned'}`,
        };
      });
      setRows(rows);
    }
  }, [data]);

  let handleRoute = (id) => {
    history.push(`/dashboard/claim/${id > 400000 ? 'power-gas' : 'telecom'}/${id}`);
    // setOpenModal(false)
    // clear()
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={teamBreakdown ? true : false}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={teamBreakdown ? true : false}>
        <Paper className={classes.paper}>
          <div className={classes.close}>
            <CancelPresentationIcon
              fontSize="large"
              className={classes.icon}
              onClick={handleClose}
            />
          </div>
          <Typography
            variant="h5"
            component="h2"
            className="title"
            gutterBottom={true}
          >
            <Box fontWeight="fontWeightBold">{entityName}</Box>
          </Typography>
          <Divider variant="fullWidth" className={classes.divider} />
          <div style={{ height: "90%", width: "100%" }}>
            <DataGridPro
              style={{ width: "100%", height: "100%"}}
              className={theme === "dark"?classes.root : ""}
              rows={rows}
              columns={columns ? columns : []}
              pagination
              pageSize={100}
              rowCount={data && data.length > 0 ? data.length : 0}
              rowsPerPageOptions={[100]}
              getRowId={(row) => {
                return `${row.id}`;
              }}
              onSelectionModelChange={(ids) => {
                if (ids[0]) {
                  handleRoute(ids[0]);
                }
              }}
              disableColumnFilter={true}
              disableColumnMenu={true}
              hideFooterSelectedRowCount={true}
              rowHeight={80}
              loading={loading}
              getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? classes.even : ''}
            />
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
}

import Cookies from "universal-cookie";
const cookies = new Cookies();

async function set(key, cookie, options = { path: "/", sameSite: "lax" }) {
  try {
    await cookies.set(`${key}`, `${cookie}`, { ...options });
    return;
  } catch (error) {
    throw new Error("error setting cookie");
  }
}

async function remove(key) {
  try {
    await cookies.remove(key, { path: "/" });
    return;
  } catch (error) {
    throw new Error("error removing cookie");
  }
}

function get(key) {
  try {
    return cookies.get(`${key}`);
  } catch (error) {
    throw new Error("error fetching cookie");
  }
}

// Mobile
async function setMobile(key, cookie, options = { path: "/mobile-claim/", sameSite: "lax" }) {
  try {
    await cookies.set(`${key}`, `${cookie}`, { ...options });
    return;
  } catch (error) {
    throw new Error("error setting cookie");
  }
}

async function removeMobile(key) {
  try {
    await cookies.remove(key, { path: "/mobile-claim/" });
    return;
  } catch (error) {
    throw new Error("error removing cookie");
  }
}

function getMobile(key) {
  try {
    return cookies.get(`${key}`);
  } catch (error) {
    throw new Error("error fetching cookie");
  }
}


export const cookieService = {
  set,
  remove,
  get,
  setMobile,
  removeMobile,
  getMobile
};

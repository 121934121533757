import { jointLedgerConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";

let initState = {
    Telecom: {},
    "P&G": {},
    loading: false
};

export function ledgers(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case jointLedgerConstants.GET_LEDGERS_REQUEST:
      return produce(state, (draft) => {
        draft["loading"] = true;
      })
    case jointLedgerConstants.GET_LEDGERS_SUCCESS:
      return produce(state, (draft) => {
        draft[payload.lob] = {...payload.ledgers};
        draft["loading"] = false;
      })
    case REHYDRATE: {
      if (payload && payload.joint && payload.joint.ledgers) {
        return { ...payload.joint.ledgers };
      } else {
        return { ...initState };
      }
    }
    default:
      return state;
  }
}

export const inputParsers = {
  date(input) {
    const [month, day, year] = input.split("/");
    return `${year}-${month}-${day}`;
  },
  uppercase(input) {
    return input.toUpperCase();
  },
  lowercase(input) {
    return input.toLowerCase();
  },
  number(input) {
    return parseFloat(input);
  },
};

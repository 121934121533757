import { authConstants } from "../_constants";
import { produce } from "immer";
import { REHYDRATE } from 'redux-persist/lib/constants'; 

let initState = {
  loggedIn: false, 
  loggingIn: false, 
  userId: '', 
  username: '',
  role:{},
  assumeUser:"",
  currentUser:"",
  assumeRole:"",
  defaultRoleId:'',
  powerBiRoleId: null
} 

export function auth(
  state = { ...initState },
  action
) {
  // eslint-disable-next-line
  const { payload, type } = action;

  switch (type) {
    case authConstants.LOGIN_REQUEST:
      return produce(state, (draft) => {
        draft["loggingIn"] = true;
      });
    case authConstants.LOGIN_SUCCESS:
      return produce(state, (draft) => {
        draft["loggedIn"] = true;
        draft["loggingIn"] = false;
        draft["userId"] = payload.user;
        draft["username"] = payload.username;
        draft["role"] = payload.role;
        draft['assumeUser'] = payload.assumeUser;
        draft['currentUser'] = payload.currentUser;
        draft['assumeRole'] = payload.assumeRole;
        draft['defaultRoleId'] = payload.defaultRoleId;
        draft['powerBiRoleId'] = payload.powerBiRoleId;
      });
    case REHYDRATE:
      {
        if(payload && payload.auth) {
          return {...payload.auth}
        } else {
          return {...initState}
        }
      }
    case authConstants.LOGIN_FAILURE:
      return {
        ...initState
      };
    case authConstants.LOGOUT:
      return {
        ...initState
      };
    default:
      return state;
  }
}

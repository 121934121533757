import { put, call } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  adminNotificationsConstants,
} from "_constants";
import { adminNotificationsService } from "_services";

export function* getAllNotificationsAdminSaga() {
  try {
    const response = yield call(adminNotificationsService.getNotifications);

    yield put({
      type: adminNotificationsConstants.ADMIN_NOTIFICATIONS_GET_ALL_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateNotificationsAdminSaga({ notifications }) {
  try {
    const response = yield call(
      adminNotificationsService.toggleNotifications,
      notifications
    );

    yield put({
      type: adminNotificationsConstants.ADMIN_NOTIFICATIONS_UPDATE_SUCCESS,
      payload: {
        data: { ...response },
      },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Notification Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export const FreloLinks = [
  {
    id: "Dashboard",
    name: "Dashboard",
    url: "/dashboard",
    root: true,
    icon: "pie_chart",
    access: false,
  },
  {
    id: "Dispatch",
    name: "Dispatch",
    url: "/dashboard/dispatch/frelo",
    icon: "headset_mic",
    root: true,
    access: false,
  },
  {
    id: "MyTeam",
    name: "MyTeam",
    url: "/dashboard/my-team",
    icon: "group",
    root: true,
    access: false,
  },
  {
    id: "Claims",
    name: "Projects",
    url: "/dashboard/project/frelo",
    icon: "list_alt",
    root: true,
    access: false,
  },
  {
    id: "Reports",
    name: "Reports",
    icon: "description",
    url: "/dashboard/reports/frelo",
    root: true,
    access: false,
  },
  {
    id: "ResourceTools",
    name: "Resource Tools",
    icon: "emoji_objects",
    root: true,
    access: false,
  },
  {
    parentId: "ResourceTools",
    id: "KnowledgeBase",
    name: "Knowledge Base",
    icon: "",
    url: "/dashboard/resource-tools/knowledge-base",
    root: false,
    access: false,
  },
  {
    parentId: "ResourceTools",
    id: "FAQS",
    name: "FAQ's",
    icon: "",
    url: "/dashboard/resource-tools/faqs",
    root: false,
    access: false,
  },
];

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import Grid from "@material-ui/core/Grid";
import { adminUsersActions , adminRoleActions,userActions} from "_actions";
import _getProperty from "lodash/get";
import Autocomplete from "_components/core/Autocomplete_";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import Fade from "@material-ui/core/Fade";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ObjectTools } from "_utility";
const { getNestedPropertySafe } = ObjectTools;


const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    backgroundColor: theme.palette.WhitePrg.main,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "4px",
    maxWidth: "500px",
    width: "100%",
    outline: "none",
  },
  Icon: {
    marginLeft: "10px",
  },
  save: {
    backgroundColor: theme.palette.GreenPrg.main,
    color: theme.palette.WhitePrg.main,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow:'auto',
  },
  close: {
    top: "-15px",
    right: 0,
    position: "absolute",
    backgroundColor: theme.palette.WhitePrg.main,
    borderRadius: "4px 4px 0 0",
    padding: "2px 5px",
    margin: 0,
  },
  btns:{
    backgroundColor: `${theme.palette.GrayPrg.main} !important`,
    borderColor: `${theme.palette.WhitePrg.main} !important`,
    marginLeft:5
  },
  wrapper:{
    display:'flex',
    alignItems: 'center',
  },
  activeBtn:{
    backgroundColor: `${theme.palette.GreenPrg.main} !important`,
  },
  btn:{
    backgroundColor: `${theme.palette.GreenPrg.main} !important`
  },
  btnEnd:{
    backgroundColor: `${theme.palette.YellowPrg.main} !important`,
    marginTop:'-123px !important',
    marginLeft: '15px !important'
  }
}));

const AssumModal = ({ openModal, setOpenModal }) => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState
  } = useForm();
  const { isDirty } = formState;

  const dispatch = useDispatch();


  const currentUser = useSelector((state) =>
  _getProperty(state, [
    "auth",
  ])
);

  const handleClose = () => {
    setOpenModal(!openModal)
  };
  
  const [assumeRole,setAssumeRole] = useState(false);
  const [assumeUser,setAssumeUser] = useState(false);
  const [rolesSet, setRolesSet ] = useState();

  useEffect(() => {
    if(currentUser && currentUser && currentUser.role.roleId === 1){
    dispatch(adminUsersActions.getAll());
    }
  }, [dispatch,currentUser]);

  useEffect(() => {
    if(currentUser && currentUser && currentUser.role.roleId === 1){
    dispatch(adminRoleActions.getRoles());
    }
  }, [dispatch,currentUser]);

  const allUsers = useSelector((state) =>
    getNestedPropertySafe(state, ["admin", "users", "all"])
  );

  const roles = useSelector((state) =>
  getNestedPropertySafe(state, ["adminRoles", "roles"])
);

useEffect(() => {
if(roles){
  let rolesOptions = roles.reduce((prev,el)=>{
    return {
      ...prev,
      [el['roleId']]:{ 
      label:el['roleName'],
      value: el['roleId']
    }
    }
  },{})
  return setRolesSet(rolesOptions)
}
},[roles])

let currentRole = currentUser && currentUser['defaultRoleId']
let assumerole = false
let username = currentUser && currentUser['username']
let user = currentUser && currentUser['userId']
let currentUserName = currentUser && currentUser['currentUser']

const onSubmit = (data) =>{
  if(assumeUser){
   dispatch(userActions.assumeUser({username: data['user']},currentUser['username']));
  }
  if(assumeRole){
    let assumerole = true
    dispatch(userActions.assumeRole({roleId: data['roleId']},assumerole,currentRole,username,user));
  }
   setOpenModal(!openModal)
}

const endUserImpersonation = () =>{
  dispatch(userActions.assumeUser({username:currentUserName}))
  dispatch(userActions.endImpersonation())
}

const endRoleImpersonation = () =>{
  dispatch(userActions.assumeRole({roleId:currentRole},assumerole,currentRole,username,user))
  dispatch(userActions.endImpersonation())
}

  return (
    <Modal
      disableEnforceFocus
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
                <h3>Assume Role/User</h3>
            </Grid>
            <Grid item xs={6}>
                <CancelPresentationIcon
                    fontSize="large"
                    className={classes.icon}
                    style={{ float: "right", cursor: "pointer" }}
                    onClick={handleClose}
                />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.wrapper}>
                Assume As...
                <ButtonGroup variant="contained" className={classes.btns}>
                    <Button 
                        onClick={() =>{ setAssumeRole(true); setAssumeUser(false);}} 
                        className={`${classes.btns} ${assumeRole ? classes.activeBtn : ""}`}
                        startIcon={<SocialDistanceIcon />}
                        >
                        Role
                    </Button>
                     <Button 
                        onClick={() => { setAssumeRole(false); setAssumeUser(true);}} 
                        className={`${classes.btns} ${assumeUser ? classes.activeBtn : ""}`}
                        startIcon={<HowToRegIcon />}
                        >
                        User
                    </Button>
             </ButtonGroup>
            </Grid>

            <Grid item xs={12}>
              {(currentUser && currentUser['assumeRole']) || (currentUser && !currentUser['assumeUser']) ?
                <Autocomplete
                options={assumeRole && rolesSet ?  Object.keys(rolesSet).map((e) => rolesSet[e]["value"]).sort((a,b) => rolesSet[a]["label"].localeCompare(rolesSet[b]["label"])) : assumeUser ?  allUsers.map((u) => u['username']) : []}
                optionSchema={assumeRole && rolesSet ? rolesSet : null}
                label=""
                name={assumeRole ? 'roleId' : "user"}
                control={control}
                darkMode={true}
                unlock={true}
                placeholder={assumeRole ? "Select A Role..." : assumeUser ? "Select A User..." : "Select A Role or User"}
                />
                :""
              }
            </Grid>
            <Grid item xs={12}>
              { currentUser && currentUser['assumeUser']?
              <Button className={classes.btnEnd} onClick={endUserImpersonation} variant="contained">End Current User Session </Button>

              :currentUser && currentUser['assumeRole'] ?
              <Button className={classes.btnEnd} onClick={endRoleImpersonation} variant="contained">End Current Role Session 
              </Button>

              : isDirty ?
              <Button className={classes.btn} onClick={handleSubmit(onSubmit)} variant="contained">Assume</Button>
              :""
              }
            </Grid>
          </Grid>          
        </Paper>
      </Fade>
    </Modal>
  );
};

export default AssumModal;

import { takeLatest } from "redux-saga/effects";
import { loginSaga, logoutSaga, assumeUserSaga, assumeRoleSaga, impersonationSaga } from "./authSaga";

import {
  authConstants,
  adminknowledgeBaseConstants,
  adminUserConstants,
  adminEntityConstants,
  adminEntityConstants_,
  adminPowerBiRoleConstants,
  adminRoleConstants,
  adminSchemaConstants,
  adminNotificationsConstants,
  errorConstants,
  telecomClaimConstants,
  telecomDispatchConstants,
  powerGasClaimConstants,
  freloProjectConstants,
  powerGasDispatchConstants,
  powerGasReportConstants,
  telecomReportConstants,
  freloReportConstants,
  adminRoundRobinConstants,
  mobileConstants,
  adminClaimStatusConstants_,
  jointReportConstants,
  powerBiConstants,
  adminRecoveryAgentConstants,
  adminStatesConstants,
  adminFormControlConstants,
  adminJobCostingConstants,
  paymentConstants,
  jointLedgerConstants,
} from "_constants";

import {
  getClientInformationSaga,
  updateClientInformationSaga,
  getDisptachEntitesSaga,
  getPaymentFormSaga,
  updatePaymentFormSaga,
  getPaymentFormFeeSaga,
  updatePaymentFormFeesSaga,
} from "./adminEntitySaga";

import {
  getAllEntitySaga,
  getSchemaLobSaga,
  createEntitySaga,
  getAdminEntityStatusSaga,
  updateAdminEntityStatusSaga,
  getAdminEntitySowContractsSaga,
  updateAdminEntitySowContractsSaga,
  addAdminEntitySowContractSaga,
  getAdminBillingTypesSchemaSaga,
  getAdminRdaSchemaSaga,
  getAdminSubaccountSchemaSaga,
  getAdminEntityRdaControlSaga,
  getAdminEntityRdaOptionsSchemaSaga,
  updateAdminEntityRdaControlsSaga,
  addAdminEntityRdaControlSaga,
  getAdminEntityBillingTypesSaga,
  addAdminEntityBillingTypeSaga,
  updateAdminEntityBillingTypeSaga,
  getAdminEntitySubAccountsSaga,
  getAdminEntitySubaccountRdaSchemaSaga,
  updateAdminEntitySubAccountsSaga,
  addAdminEntitySubAccountSaga,
  getAdminEntityContactsSaga,
  updateAdminEntityContactsSaga,
  addAdminEntityContactSaga,
  getDocTemplateInvoiceSaga,
  updateDocTemplateInvoiceSaga,
  getDocFieldMentionsSaga,
  getDocTemplateClaimReasonSchemaSaga,
  getDocTemplateClaimLastSentenceSchemaSaga,
  getDocTemplateClaimContactsSchemaSaga,
  updateDocTemplateClaimContactsSchemaSaga,
  addDocTemplateClaimContactsSchemaSaga,
  updateDocTemplateClaimSaga,
  getDocTemplateClaimSaga,
  getAdminEntityRdaControlRegionsSaga,
  postAdminEntityRdaControlRegionsSaga,
  updateAdminEntityRdaControlsRegionsSaga,
  getAdminEntityRdaControlDivisionsSaga,
  postAdminEntityRdaControlDivisionsSaga,
  updateAdminEntityRdaControlsDivisionsSaga,
  getAdminEntityRdaControlAreasSaga,
  postAdminEntityRdaControlAreasSaga,
  updateAdminEntityRdaControlsAreasSaga,
  getInvoicingSaga,
  updateInvoicingSaga,
  getInvoicingLineItemsSaga,
} from "./admin/entity";

import {
  getFaqSaga,
  updateFaqSaga,
  addFaqSaga,
  removeFaqSaga,
  getKBSaga,
  getKBTopicsSaga,
  getKBMarketsSaga,
  getKBResourcetypesSaga,
  addKbTopicSaga,
  updateKbTopicSaga,
  addKbResourceSaga,
  updateKbResourceSaga,
} from "./admin/knowledgeBase";

import {
  getAllRoundRobinAdminSaga,
  getOneRoundRobinAdminSaga,
  getLocateSchemaRoundRobinAdminSaga,
  getEntitiesSchemaRoundRobinAdminSaga,
  updateAgentRoundRobinAdminSaga,
  updateAgentStatusRoundRobinAdminSaga,
} from "./admin/roundRobin";

import {
  getAllRecoveryAgentAdminSaga,
  getOneRecoveryAgentAdminSaga,
  getTeamListRecoveryAgentAdminSaga,
} from "./admin/recoveryAgent";

import {
  getAllClaimStatusAdminSaga,
  addStatusAdminSaga,
  getOneClaimStatusAdminSaga,
  getUsersAdminSaga,
  getStatusAccessAdminSaga,
  addUsersAdminSaga,
  removeUserSaga,
  updateOneClaimStatusAdminSaga,
} from "./admin/claimStatus";

import {
  getAllUsersAdminSaga,
  getPRGUsersAdminSaga,
  getRolesSchemaUserAdminSaga,
  createUserAdminSaga,
  getGeneralUserAdminSaga,
  updateGeneralUserAdminSaga,
  getDbAccessUserAdminSaga,
  updateDbAccessUserAdminSaga,
  getUserEntitiesAdminSaga,
  updateUserEntitiesAdminSaga,
  toggleEntityAccessUserAdminSaga,
  getUserTypesSchemaUserAdminSaga,
  getUserActionAccessSaga,
  updateUserActionAccessSaga,
  deleteUserActionAccessSaga,
  getUserStatusAccessSaga,
  updateUserStatusAccessSaga,
  deleteUserStatusAccessSaga,
  getUserDamageInvestigatorSaga,
  updateUserDamageInvestigatorSaga,
  deleteUserDamageInvestigatorSaga,
  getUserNotificationSaga,
  updateUserNotificationSaga,
  deleteUserNotificationSaga,
  toggleClaimAssistantSaga,
} from "./admin/users";

import { getPowerBiRolesSaga, createPowerBiRoleSaga, updatePowerBiRoleSaga } from "./admin/powerBiRolesSaga";

import { getAllStatesSaga } from "./admin/states";

import {
  getFieldsSaga,
  getEntityRulesSaga,
  getRoleRulesSaga,
  createAndUpdateFieldRulesSaga,
  deleteEntityRulesSaga,
  deleteRoleRulesSaga,
} from "./admin/formControl";

import { getRatesSaga, addRateSaga, updateRateSaga, deleteRateSaga } from "./admin/jobCosting";

import {
  getRolesSaga,
  createRoleSaga,
  cloneRoleSaga,
  updateRoleSaga,
  getRoleGeneralSaga,
  getRoleDbAccessSaga,
  updateRoleDbAccessSaga,
  getRoleDashboardSaga,
  addRoleDashboardSaga,
  removeRoleDashboardSaga,
  updateRoleDashboardSaga,
  updateRoleDashboardPositionSaga,
  getRoleRdaSaga,
  getRoleEntitiesSaga,
  updateRoleEntitiesSaga,
  getRoleActionAccessSaga,
  updateRoleActionAccessSaga,
  getRoleStatusAccessSaga,
  updateRoleStatusAccessSaga,
  getRoleNotificationSaga,
  updateRoleNotificationSaga,
  deleteRoleNotificationSaga,
} from "./adminRoleSaga";

import {
  getPermissionAccessTypesSaga,
  getConditionTypesSaga,
  getNotificationTypesSaga,
  getNotificationFormFieldsSaga,
  getNotificationRecipientTypesSaga,
} from "./adminSchemaSaga";

import { getAllNotificationsAdminSaga, updateNotificationsAdminSaga } from "./admin/notifications";

import {
  getAllClaimsTelecomSaga,
  getMyClaimsTelecomSaga,
  getMyTeamsClaimsTelecomSaga,
  getMyMobileClaimsTelecomSaga,
  getClaimByIdTelecomSaga,
  updateTelecomClaimSaga,
  deleteTelecomClaimSaga,
  getSchemaEntityOptionsTelecomSaga,
  getSchemaRdaOptionsTelecomSaga,
  getCreateClaimFieldRulesTelecomSaga,
  getSchemaFilterConditionsTelecomSaga,
  getFilterSetsTelecomSaga,
  createFilterSetTelecomSaga,
  removeFilterSetTelecomSaga,
  setFilterSetActiveTelecomSaga,
  updateFilterSetNameTelecomSaga,
  duplicateFilterSetTelecomSaga,
  updateFilterSetTelecomSaga,
  uploadAttachmentTelecomSaga,
  getAttachmentsTelecomSaga,
  getSignedUrlTelecomSaga,
  postPaymentTelecomSaga,
  getClaimHistoryTelecomSaga,
  getClaimJobCostingInvoiceTelecomSaga,
  getClaimRateCardContractorTelecomSaga,
  getClaimRateCardMaterialTelecomSaga,
  getClaimRateCardLaborTelecomSaga,
  getClaimRateCardUsicTelecomSaga,
  claimInvoiceTimeStampTelecomSaga,
  claimPostInHouseLaborTelecomSaga,
  claimPostMaterialTelecomSaga,
  claimPostOtherItemTelecomSaga,
  claimPostUnlistedItemTelecomSaga,
  claimPostContractorItemTelecomSaga,
  claimPostLOItemTelecomSaga,
  claimJobCostingRemoveOtherItemTelecomSaga,
  claimJobCostingRemoveLaborItemTelecomSaga,
  claimJobCostingRemoveMaterialItemTelecomSaga,
  claimJobCostingRemoveContractorItemTelecomSaga,
  claimJobCostingRemoveLOItemTelecomSaga,
  claimJobCostingSheetUploadTelecomSaga,
  claimInvoiceDocTelecomSaga,
  claimDocTelecomSaga,
  claimDocTelecomSchemaReasonSaga,
  addClaimDocTelecomSchemaReasonSaga,
  editClaimDocTelecomSchemaReasonSaga,
  removeClaimDocTelecomSchemaReasonSaga,
  claimDocTelecomSchemaContactsSaga,
  claimDocTelecomSchemaLastSentenceSaga,
  claimAttachmentSchemaImageCategories,
  claimAttachmentSchemaVideoCategories,
  claimAttachmentSchemaDocumentCategories,
  claimDocTelecomSchemaDigLawsSaga,
  getClaimNarrativeCreatorDocTelecomSaga,
  getClaimIntentToBillDocTelecomSaga,
  getClaimPromissoryNoteDocTelecomSaga,
  getClaimLegalFinalNoticeDocTelecomSaga,
  getClaimFinalNoticeDocTelecomSaga,
  getClaimReleaseLetterDocTelecomSaga,
  getClaimSecondNoticeDocTelecomSaga,
  getClaimPaymentReceiptTelecomSaga,
  getClaimBalanceOwedTelecomSaga,
  getSortOrderAttachmentsTelecomSaga,
  updateCategoryAttachmentsTelecomSaga,
  getLogoTelecomSaga,
  deleteTelecomLedgerSaga,
  updateTelecomLedgerSaga,
  deleteTelecomStatusNoteSaga,
  deleteTelecomActionNoteSaga,
  saveTelecomColumnFiltersSaga,
  saveRotationAttachmentTelecomSaga,
  claimDocumentUploadTelecomSaga,
} from "./lob/telecom/claimSaga";

import {
  getOneDispatchTelecomSaga,
  updateOneDispatchTelecomSaga,
  updateTenEightDispatchTelecomSaga,
  getDispatchTimer,
  updateDispatchTimerPause,
  getDispatchTimerColor,
} from "./lob/telecom/dispatchSaga";

import {
  getAllReportsTelecomSaga,
  getOneReportTelecomSaga,
  runOneReportTelecomSaga,
} from "./lob/telecom/reportSaga";

import {
  getAllClaimsPowerGasSaga,
  getMyClaimsPowerGasSaga,
  getMyTeamsClaimsPowerGasSaga,
  getClaimByIdPowerGasSaga,
  updatePowerGasClaimSaga,
  deletePowerGasClaimSaga,
  getSchemaEntityOptionsPowerGasSaga,
  getCreateClaimFieldRulesPowGasSaga,
  getSchemaRdaOptionsPowerGasSaga,
  getSchemaFilterConditionsPowerGasSaga,
  getFilterSetsPowerGasSaga,
  createFilterSetPowerGasSaga,
  updateFilterSetPowerGasSaga,
  removeFilterSetPowerGasSaga,
  duplicateFilterSetPowerGasSaga,
  uploadAttachmentPowerGasSaga,
  getAttachmentsPowerGasSaga,
  postPaymentPowerGasSaga,
  getClaimHistoryPowerGasSaga,
  getClaimJobCostingInvoicePowerGasSaga,
  claimInvoiceTimeStampPowerGasSaga,
  claimPostMaterialPowerGasSaga,
  claimPostInHouseLaborPowerGasSaga,
  claimPostVehicleItemPowerGasSaga,
  claimPostContractorItemPowerGasSaga,
  claimPostLossOfGasItemPowerGasSaga,
  claimJobCostingRemoveMaterialItemPowerGasSaga,
  claimJobCostingRemoveInHouseLaborItemPowerGasSaga,
  claimJobCostingRemoveVehicleItemPowerGasSaga,
  claimJobCostingRemoveContractorItemPowerGasSaga,
  claimJobCostingRemoveLossOfGasItemPowerGasSaga,
  claimJobCostingSheetUploadPowerGasSaga,
  claimInvoiceDocPowerGasSaga,
  claimDocumentUploadPowerGasSaga,
  claimDocPowerGasSaga,
  claimDocPowerGasSchemaReasonSaga,
  editClaimDocPowerGasSchemaReasonSaga,
  addClaimDocPowerGasSchemaReasonSaga,
  removeClaimDocPowerGasSchemaReasonSaga,
  claimDocPowerGasSchemaContactsSaga,
  claimDocPowerGasSchemaLastSentenceSaga,
  claimAttachmentSchemaImageCategoriesPowerGas,
  claimAttachmentSchemaVideoCategoriesPowerGas,
  claimAttachmentSchemaDocumentCategoriesPowerGas,
  getClaimNarrativeCreatorDocPowerGasSaga,
  getClaimIntentToBillDocPowerGasSaga,
  getClaimPromissoryNoteDocPowerGasSaga,
  getClaimLegalFinalNoticeDocPowerGasSaga,
  getClaimFinalNoticeDocPowerGasSaga,
  getClaimReleaseLetterDocPowerGasSaga,
  getClaimSecondNoticeDocPowerGasSaga,
  getClaimPaymentReceiptPowerGasSaga,
  getClaimBalanceOwedPowerGasSaga,
  getSortOrderAttachmentsPowerGasSaga,
  updateCategoryAttachmentsPowerGasSaga,
  deletePowerGasLedgerSaga,
  updatePowerGasLedgerSaga,
  deletePowerGasStatusNoteSaga,
  deletePowerGasActionNoteSaga,
  savePowGasColumnFiltersSaga,
  saveRotationAttachmentPowerGasSaga,
  uploadDocumentsPowerGasSaga,
} from "./lob/powerGas/claimSaga";

import {
  getOneDispatchPowerGasSaga,
  updateOneDispatchPowerGasSaga,
  updateTenEightDispatchPowerGasSaga,
  getDispatchTimerPowerGasSaga,
  updateDispatchTimerPausePowerGasSaga,
  getDispatchTimerColorPowerGasSaga,
} from "./lob/powerGas/dispatchSaga";

import {
  getAllReportsPowerGasSaga,
  getOneReportPowerGasSaga,
  runOneReportPowerGasSaga,
} from "./lob/powerGas/reportSaga";

import {
  getAllProjectsFreloSaga,
  getMyProjectsFreloSaga,
  getProjectByIdFreloSaga,
  updateFreloProjectSaga,
  getSchemaEntityOptionsFreloSaga,
  getCreateProjectFieldRulesFreloSaga,
  getSchemaRdaOptionsFreloSaga,
  getSchemaFilterConditionsFreloSaga,
  getFilterSetsFreloSaga,
  updateFilterSetFreloSaga,
  removeFilterSetFreloSaga,
  duplicateFilterSetFreloSaga,
  createFilterSetFreloSaga,
  uploadAttachmentFreloSaga,
  getAttachmentsFreloSaga,
  postPaymentFreloSaga,
  getProjectHistoryFreloSaga,
  deleteFreloLedgerSaga,
  deleteFreloStatusNoteSaga,
} from "./lob/frelo/projectSaga";

import {
  loginMobileSaga,
  logoutMobileSaga,
  getPreClaimsMobileSaga,
  getAllMobileClaimsSaga,
  // getMobileRdaOptionsTelecomSaga,
  getInitialMobileSaga,
  createInitialMobileSaga,
  getFullMobileSaga,
  updateFullMobileSaga,
  createMoveToPrgMobileSaga,
  getAttachmentsMobileSaga,
  uploadAttachmentMobileSaga,
  deleteMobileClaimSaga
} from "./mobile/mobileSaga";

import {
  getAllReportsFreloSaga,
  getOneReportFreloSaga,
  runOneReportFreloSaga,
} from "./lob/frelo/reportSaga";

import { 
  getUserReportsSaga, 
  getReportSaga, 
  getReportsProcsSaga, 
  queryReportsProcSaga,
  getScoreboardSaga,
  getAListReportSaga,
  getRemittanceSaga
} from "./lob/joint/reportSaga";

import { getPowerBiReportsSaga } from "./powerbi/powerBiSaga";

import { getPaymentSaga } from "./payment/paymentSaga";

import { postErrorSaga } from "./errorSaga";
import { getLedgers } from "./lob/joint/ledgerSaga";

export function* watchUserAuthentication() {
  yield takeLatest(authConstants.LOGIN_REQUEST, loginSaga);
  yield takeLatest(authConstants.LOGOUT, logoutSaga);
  yield takeLatest(authConstants.ASSUME_USER_REQUEST, assumeUserSaga);
  yield takeLatest(authConstants.ASSUME_ROLE_REQUEST, assumeRoleSaga);
  yield takeLatest(authConstants.IMPERSONATION_END_REQUEST, impersonationSaga);
}

export function* watchAdminEntity_() {
  yield takeLatest(adminEntityConstants_.GET_ALL_ADMIN_ENTITY_REQUEST, getAllEntitySaga);
  yield takeLatest(adminEntityConstants_.CREATE_ENTITY_REQUEST, createEntitySaga);

  yield takeLatest(adminEntityConstants_.GET_ALL_ADMIN_SCHEMA_LOB_REQUEST, getSchemaLobSaga);

  yield takeLatest(adminEntityConstants_.GET_ALL_ADMIN_ENTITY_STATUS_REQUEST, getAdminEntityStatusSaga);

  yield takeLatest(adminEntityConstants_.UPDATE_ADMIN_ENTITY_STATUS_REQUEST, updateAdminEntityStatusSaga);

  yield takeLatest(adminEntityConstants_.GET_ALL_ADMIN_ENTITY_SOW_CONTRACTS_REQUEST, getAdminEntitySowContractsSaga);

  yield takeLatest(adminEntityConstants_.UPDATE_ADMIN_ENTITY_SOW_CONTRACTS_REQUEST, updateAdminEntitySowContractsSaga);

  yield takeLatest(adminEntityConstants_.ADD_ADMIN_ENTITY_SOW_CONTRACT_REQUEST, addAdminEntitySowContractSaga);

  yield takeLatest(adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_BILLING_TYPES_REQUEST, getAdminBillingTypesSchemaSaga);

  yield takeLatest(adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_RDA_REQUEST, getAdminRdaSchemaSaga);

  yield takeLatest(adminEntityConstants_.ADMIN_ENTITY_SCHEMA_SUB_ACCOUNTS_REQUEST, getAdminSubaccountSchemaSaga);

  yield takeLatest(adminEntityConstants_.GET_ALL_ADMIN_ENTITY_RDA_CONTROL_REQUEST, getAdminEntityRdaControlSaga);

  yield takeLatest(
    adminEntityConstants_.GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_REQUEST,
    getAdminEntityRdaOptionsSchemaSaga
  );

  yield takeLatest(adminEntityConstants_.UPDATE_ADMIN_ENTITY_RDA_CONTROL_REQUEST, updateAdminEntityRdaControlsSaga);

  yield takeLatest(adminEntityConstants_.ADD_ADMIN_ENTITY_RDA_CONTROL_REQUEST, addAdminEntityRdaControlSaga);

  yield takeLatest(adminEntityConstants_.GET_ADMIN_ENTITY_BILLING_TYPES_REQUEST, getAdminEntityBillingTypesSaga);

  yield takeLatest(adminEntityConstants_.ADD_ADMIN_ENTITY_BILLING_TYPE_REQUEST, addAdminEntityBillingTypeSaga);

  yield takeLatest(adminEntityConstants_.UPDATE_ADMIN_ENTITY_BILLING_TYPE_REQUEST, updateAdminEntityBillingTypeSaga);

  yield takeLatest(adminEntityConstants_.GET_ADMIN_ENTITY_SUB_ACCOUNTS_REQUEST, getAdminEntitySubAccountsSaga);

  yield takeLatest(
    adminEntityConstants_.GET_ADMIN_ENTITY_SUBACCOUNTS_RDA_SCHEMA_REQUEST,
    getAdminEntitySubaccountRdaSchemaSaga
  );

  yield takeLatest(adminEntityConstants_.UPDATE_ADMIN_ENTITY_SUB_ACCOUNTS_REQUEST, updateAdminEntitySubAccountsSaga);

  yield takeLatest(adminEntityConstants_.ADD_ADMIN_ENTITY_SUB_ACCOUNT_REQUEST, addAdminEntitySubAccountSaga);

  yield takeLatest(adminEntityConstants_.GET_ADMIN_ENTITY_CONTACTS_REQUEST, getAdminEntityContactsSaga);

  yield takeLatest(adminEntityConstants_.UPDATE_ADMIN_ENTITY_CONTACTS_REQUEST, updateAdminEntityContactsSaga);

  yield takeLatest(adminEntityConstants_.ADD_ADMIN_ENTITY_CONTACT_REQUEST, addAdminEntityContactSaga);

  yield takeLatest(adminEntityConstants_.GET_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_REQUEST, getDocTemplateInvoiceSaga);

  yield takeLatest(
    adminEntityConstants_.UPDATE_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_REQUEST,
    updateDocTemplateInvoiceSaga
  );

  yield takeLatest(adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_FIELD_MENTIONS_REQUEST, getDocFieldMentionsSaga);

  yield takeLatest(
    adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_REASONS_REQUEST,
    getDocTemplateClaimReasonSchemaSaga
  );

  yield takeLatest(
    adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_LAST_SENTENCE_REQUEST,
    getDocTemplateClaimLastSentenceSchemaSaga
  );

  yield takeLatest(
    adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST,
    getDocTemplateClaimContactsSchemaSaga
  );

  yield takeLatest(
    adminEntityConstants_.UPDATE_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST,
    updateDocTemplateClaimContactsSchemaSaga
  );

  yield takeLatest(
    adminEntityConstants_.ADD_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST,
    addDocTemplateClaimContactsSchemaSaga
  );

  yield takeLatest(adminEntityConstants_.UPDATE_ADMIN_ENTITY_DOCS_CLAIM_REQUEST, updateDocTemplateClaimSaga);

  yield takeLatest(adminEntityConstants_.GET_ADMIN_ENTITY_DOC_CLAIM_TEMPLATE_REQUEST, getDocTemplateClaimSaga);

  yield takeLatest(
    adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_REGIONS_REQUEST,
    getAdminEntityRdaControlRegionsSaga
  );

  yield takeLatest(
    adminEntityConstants_.POST_ADMIN_ENTITY_RDA_CONTROL_REGION_REQUEST,
    postAdminEntityRdaControlRegionsSaga
  );

  yield takeLatest(
    adminEntityConstants_.UPDATE_ADMIN_ENTITY_RDA_CONTROL_REGIONS_REQUEST,
    updateAdminEntityRdaControlsRegionsSaga
  );

  yield takeLatest(
    adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_DIVISIONS_REQUEST,
    getAdminEntityRdaControlDivisionsSaga
  );

  yield takeLatest(
    adminEntityConstants_.POST_ADMIN_ENTITY_RDA_CONTROL_DIVISION_REQUEST,
    postAdminEntityRdaControlDivisionsSaga
  );

  yield takeLatest(
    adminEntityConstants_.UPDATE_ADMIN_ENTITY_RDA_CONTROL_DIVISIONS_REQUEST,
    updateAdminEntityRdaControlsDivisionsSaga
  );

  yield takeLatest(
    adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_AREAS_REQUEST,
    getAdminEntityRdaControlAreasSaga
  );

  yield takeLatest(
    adminEntityConstants_.POST_ADMIN_ENTITY_RDA_CONTROL_AREA_REQUEST,
    postAdminEntityRdaControlAreasSaga
  );
  yield takeLatest(
    adminEntityConstants_.UPDATE_ADMIN_ENTITY_RDA_CONTROL_AREAS_REQUEST,
    updateAdminEntityRdaControlsAreasSaga
  );

  yield takeLatest(adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_REQUEST, getInvoicingSaga);
  yield takeLatest(adminEntityConstants_.UPDATE_ADMIN_ENTITY_INVOICING_REQUEST, updateInvoicingSaga);
  yield takeLatest(adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_LINE_ITEMS_REQUEST, getInvoicingLineItemsSaga);
}

export function* watchAdminEntity() {
  yield takeLatest(adminEntityConstants.GET_CLIENT_INFORMATION, getClientInformationSaga);

  yield takeLatest(adminEntityConstants.UPDATE_CLIENT_INFORMATION, updateClientInformationSaga);

  // Dispatch  Entities
  yield takeLatest(adminEntityConstants.GET_ALL_DISPATCH_ENTITIES_REQUEST, getDisptachEntitesSaga);

  yield takeLatest(adminEntityConstants.GET_PAYMENT_FORM_REQUEST, getPaymentFormSaga);

  yield takeLatest(adminEntityConstants.UPDATE_PAYMENT_FORM_REQUEST, updatePaymentFormSaga);

  yield takeLatest(adminEntityConstants.GET_PAYMENT_FORM_FEES_REQUEST, getPaymentFormFeeSaga);
  yield takeLatest(adminEntityConstants.UPDATE_PAYMENT_FORM_FEES_REQUEST, updatePaymentFormFeesSaga);
}

export function* watchAdminKnowledgeBase() {
  yield takeLatest(adminknowledgeBaseConstants.GET_ALL_FAQ_REQUEST, getFaqSaga);
  yield takeLatest(adminknowledgeBaseConstants.UPDATE_FAQ_REQUEST, updateFaqSaga);
  yield takeLatest(adminknowledgeBaseConstants.ADD_FAQ_REQUEST, addFaqSaga);
  yield takeLatest(adminknowledgeBaseConstants.REMOVE_FAQ_REQUEST, removeFaqSaga);

  yield takeLatest(adminknowledgeBaseConstants.GET_ALL_KB_REQUEST, getKBSaga);
  yield takeLatest(adminknowledgeBaseConstants.GET_ALL_KBTOPICS_REQUEST, getKBTopicsSaga);
  yield takeLatest(adminknowledgeBaseConstants.GET_ALL_KBMARKETS_REQUEST, getKBMarketsSaga);
  yield takeLatest(adminknowledgeBaseConstants.GET_ALL_KBRESOURCETYPES_REQUEST, getKBResourcetypesSaga);
  yield takeLatest(adminknowledgeBaseConstants.ADD_KB_TOPIC_REQUEST, addKbTopicSaga);
  yield takeLatest(adminknowledgeBaseConstants.UPDATE_KB_TOPIC_REQUEST, updateKbTopicSaga);
  yield takeLatest(adminknowledgeBaseConstants.ADD_KB_RESOURCE_REQUEST, addKbResourceSaga);
  yield takeLatest(adminknowledgeBaseConstants.UPDATE_KB_RESOURCE_REQUEST, updateKbResourceSaga);
}

export function* watchAdminRoundRobin() {
  yield takeLatest(adminRoundRobinConstants.ADMIN_GET_ALL_ROUNDROBIN_REQUEST, getAllRoundRobinAdminSaga);

  yield takeLatest(adminRoundRobinConstants.ADMIN_GET_ONE_ROUNDROBIN_REQUEST, getOneRoundRobinAdminSaga);

  yield takeLatest(
    adminRoundRobinConstants.ADMIN_GET_LOCATE_SCHEMA_ROUNDROBIN_REQUEST,
    getLocateSchemaRoundRobinAdminSaga
  );

  yield takeLatest(
    adminRoundRobinConstants.ADMIN_GET_ENTITIES_SCHEMA_ROUNDROBIN_REQUEST,
    getEntitiesSchemaRoundRobinAdminSaga
  );

  yield takeLatest(adminRoundRobinConstants.ADMIN_UPDATE_AGENT_ROUNDROBIN_REQUEST, updateAgentRoundRobinAdminSaga);

  yield takeLatest(
    adminRoundRobinConstants.ADMIN_UPDATE_AGENT_STATUS_ROUNDROBIN_REQUEST,
    updateAgentStatusRoundRobinAdminSaga
  );
}

export function* watchAdminRecoveryAgent() {
  yield takeLatest(adminRecoveryAgentConstants.ADMIN_GET_ALL_RECOVERY_AGENTS_REQUEST, getAllRecoveryAgentAdminSaga);
  yield takeLatest(adminRecoveryAgentConstants.ADMIN_GET_ONE_RECOVERY_AGENT_REQUEST, getOneRecoveryAgentAdminSaga);
  yield takeLatest(adminRecoveryAgentConstants.ADMIN_GET_TEAM_LIST_REQUEST, getTeamListRecoveryAgentAdminSaga);
}

export function* watchAdminClaimStatus() {
  yield takeLatest(adminClaimStatusConstants_.GET_ALL_STATUS_REQUEST, getAllClaimStatusAdminSaga);
  yield takeLatest(adminClaimStatusConstants_.ADD_STATUS_REQUEST, addStatusAdminSaga);
  yield takeLatest(adminClaimStatusConstants_.GET_ONE_STATUS_REQUEST, getOneClaimStatusAdminSaga);
  yield takeLatest(adminClaimStatusConstants_.GET_USERS_REQUEST, getUsersAdminSaga);
  yield takeLatest(adminClaimStatusConstants_.GET_STATUS_ACCESS_REQUEST, getStatusAccessAdminSaga);
  yield takeLatest(adminClaimStatusConstants_.ADD_USERS_REQUEST, addUsersAdminSaga);
  yield takeLatest(adminClaimStatusConstants_.REMOVE_USER_REQUEST, removeUserSaga);
  yield takeLatest(adminClaimStatusConstants_.UPDATE_ONE_STATUS_REQUEST, updateOneClaimStatusAdminSaga);
}

export function* watchAdminUsers() {
  yield takeLatest(adminUserConstants.ADMIN_USERS_GET_ALL_REQUEST, getAllUsersAdminSaga);
  yield takeLatest(adminUserConstants.ADMIN_PRG_USERS_GET_REQUEST, getPRGUsersAdminSaga);

  yield takeLatest(adminUserConstants.ADMIN_USERS_GET_ROLES_REQUEST, getRolesSchemaUserAdminSaga);

  yield takeLatest(adminUserConstants.ADMIN_USERS_CREATE_REQUEST, createUserAdminSaga);

  yield takeLatest(adminUserConstants.ADMIN_USERS_GET_GENERAL_REQUEST, getGeneralUserAdminSaga);

  yield takeLatest(adminUserConstants.ADMIN_USERS_UPDATE_GENERAL_REQUEST, updateGeneralUserAdminSaga);

  yield takeLatest(adminUserConstants.ADMIN_USERS_GET_DB_ACCESS_REQUEST, getDbAccessUserAdminSaga);

  yield takeLatest(adminUserConstants.ADMIN_USERS_UPDATE_DB_ACCESS_REQUEST, updateDbAccessUserAdminSaga);

  yield takeLatest(adminUserConstants.ADMIN_USERS_GET_ENTITIES_ACCESS_REQUEST, getUserEntitiesAdminSaga);

  yield takeLatest(adminUserConstants.ADMIN_USERS_UPDATE_ENTITIES_ACCESS_REQUEST, updateUserEntitiesAdminSaga);

  yield takeLatest(adminUserConstants.ADMIN_USERS_TOGGLE_ENTITY_ACCESS_REQUEST, toggleEntityAccessUserAdminSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_GET_USER_TYPES_REQUEST, getUserTypesSchemaUserAdminSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_GET_ACTION_ACCESS_REQUEST, getUserActionAccessSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_UPDATE_ACTION_ACCESS_REQUEST, updateUserActionAccessSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_DELETE_ACTION_ACCESS_REQUEST, deleteUserActionAccessSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_GET_STATUS_ACCESS_REQUEST, getUserStatusAccessSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_UPDATE_STATUS_ACCESS_REQUEST, updateUserStatusAccessSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_DELETE_STATUS_ACCESS_REQUEST, deleteUserStatusAccessSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_DAMAGE_INVESTIGATOR_REQUEST, getUserDamageInvestigatorSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_UPDATE_DAMAGE_INVESTIGATOR_REQUEST, updateUserDamageInvestigatorSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_DELETE_DAMAGE_INVESTIGATOR_REQUEST, deleteUserDamageInvestigatorSaga);

  yield takeLatest(adminUserConstants.ADMIN_USERS_NOTIFICATIONS_REQUEST, getUserNotificationSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_UPDATE_NOTIFICATIONS_REQUEST, updateUserNotificationSaga);
  yield takeLatest(adminUserConstants.ADMIN_USERS_DELETE_NOTIFICATIONS_REQUEST, deleteUserNotificationSaga);

  yield takeLatest(adminUserConstants.ADMIN_USERS_TOGGLE_CLAIM_ASSISTANT, toggleClaimAssistantSaga);
}

export function* watchAdminRoles() {
  yield takeLatest(adminRoleConstants.CREATE_NEW_ROLE_REQUEST, createRoleSaga);

  yield takeLatest(adminRoleConstants.CLONE_ROLE_REQUEST, cloneRoleSaga);

  yield takeLatest(adminRoleConstants.GET_ALL_ROLES_REQUEST, getRolesSaga);

  yield takeLatest(adminRoleConstants.UPDATE_ROLE_GENERAL_REQUEST, updateRoleSaga);

  yield takeLatest(adminRoleConstants.GET_ROLE_RDA_REQUEST, getRoleRdaSaga);

  yield takeLatest(adminRoleConstants.GET_ROLE_DASHBOARD_REQUEST, getRoleDashboardSaga);
  yield takeLatest(adminRoleConstants.ADD_ROLE_DASHBOARD_REQUEST, addRoleDashboardSaga);
  yield takeLatest(adminRoleConstants.REMOVE_ROLE_DASHBOARD_REQUEST, removeRoleDashboardSaga);
  yield takeLatest(adminRoleConstants.UPDATE_ROLE_DASHBOARD_REQUEST, updateRoleDashboardSaga);
  yield takeLatest(adminRoleConstants.UPDATE_ROLE_DASHBOARD_POSITION_REQUEST, updateRoleDashboardPositionSaga);

  yield takeLatest(adminRoleConstants.GET_ROLE_GENERAL_REQUEST, getRoleGeneralSaga);
  yield takeLatest(adminRoleConstants.GET_ROLE_DB_ACCESS_REQUEST, getRoleDbAccessSaga);

  yield takeLatest(adminRoleConstants.UPDATE_ROLE_DB_ACCESS_REQUEST, updateRoleDbAccessSaga);

  yield takeLatest(adminRoleConstants.GET_ROLE_ENTITIES_REQUEST, getRoleEntitiesSaga);

  yield takeLatest(adminRoleConstants.UPDATE_ROLE_ENTITIES_REQUEST, updateRoleEntitiesSaga);
  yield takeLatest(adminRoleConstants.GET_ROLE_ACTION_ACCESS_REQUEST, getRoleActionAccessSaga);
  yield takeLatest(adminRoleConstants.UPDATE_ROLE_ACTION_ACCESS_REQUEST, updateRoleActionAccessSaga);
  yield takeLatest(adminRoleConstants.GET_ROLE_STATUS_ACCESS_REQUEST, getRoleStatusAccessSaga);
  yield takeLatest(adminRoleConstants.UPDATE_ROLE_STATUS_ACCESS_REQUEST, updateRoleStatusAccessSaga);
  yield takeLatest(adminRoleConstants.ADMIN_ROLES_NOTIFICATIONS_REQUEST, getRoleNotificationSaga);
  yield takeLatest(adminRoleConstants.ADMIN_ROLES_UPDATE_NOTIFICATIONS_REQUEST, updateRoleNotificationSaga);
  yield takeLatest(adminRoleConstants.ADMIN_ROLES_DELETE_NOTIFICATIONS_REQUEST, deleteRoleNotificationSaga);
}

export function* watchAdminPowerBiRoles() {
  yield takeLatest(adminPowerBiRoleConstants.GET_ALL_POWERBI_ROLES_REQUEST, getPowerBiRolesSaga);

  yield takeLatest(adminPowerBiRoleConstants.CREATE_POWERBI_ROLE_REQUEST, createPowerBiRoleSaga);

  yield takeLatest(adminPowerBiRoleConstants.UPDATE_POWERBI_ROLE_REQUEST, updatePowerBiRoleSaga);
}

export function* watchAdminSchema() {
  yield takeLatest(adminSchemaConstants.GET_ADMIN_ACCESS_TYPES_SCHEMA_REQUEST, getPermissionAccessTypesSaga);

  yield takeLatest(adminSchemaConstants.GET_ADMIN_CONDITIONAL_TYPES_SCHEMA_REQUEST, getConditionTypesSaga);

  yield takeLatest(adminSchemaConstants.GET_ADMIN_NOTIFICATION_TYPES_SCHEMA_REQUEST, getNotificationTypesSaga);

  yield takeLatest(
    adminSchemaConstants.GET_ADMIN_NOTIFICATION_FORM_FIELDS_SCHEMA_REQUEST,
    getNotificationFormFieldsSaga
  );

  yield takeLatest(
    adminSchemaConstants.GET_ADMIN_NOTIFICATION_RECIPIENT_TYPES_SCHEMA_REQUEST,
    getNotificationRecipientTypesSaga
  );
}

export function* watchAdminNotifications() {
  yield takeLatest(adminNotificationsConstants.ADMIN_NOTIFICATIONS_GET_ALL_REQUEST, getAllNotificationsAdminSaga);

  yield takeLatest(adminNotificationsConstants.ADMIN_NOTIFICATIONS_UPDATE_REQUEST, updateNotificationsAdminSaga);
}

export function* watchAdminStates() {
  yield takeLatest(adminStatesConstants.GET_ALL_STATES_REQUEST, getAllStatesSaga);
}

export function* watchAdminFormControl() {
  yield takeLatest(adminFormControlConstants.GET_FIELDS_REQUEST, getFieldsSaga);
  yield takeLatest(adminFormControlConstants.GET_ENTITY_RULES_REQUEST, getEntityRulesSaga);
  yield takeLatest(adminFormControlConstants.GET_ROLE_RULES_REQUEST, getRoleRulesSaga);

  yield takeLatest(adminFormControlConstants.CREATE_AND_UPDATE_FIELD_RULES_REQUEST, createAndUpdateFieldRulesSaga);

  yield takeLatest(adminFormControlConstants.DELETE_ENTITY_RULES_REQUEST, deleteEntityRulesSaga);
  yield takeLatest(adminFormControlConstants.DELETE_ROLE_RULES_REQUEST, deleteRoleRulesSaga);
}

export function* watchAdminJobCosting() {
  yield takeLatest(adminJobCostingConstants.GET_RATES_REQUEST, getRatesSaga);
  yield takeLatest(adminJobCostingConstants.ADD_RATE_REQUEST, addRateSaga);
  yield takeLatest(adminJobCostingConstants.UPDATE_RATE_REQUEST, updateRateSaga);
  yield takeLatest(adminJobCostingConstants.DELETE_RATE_REQUEST, deleteRateSaga);
}

export function* watchTelecomClaimSaga() {
  yield takeLatest(telecomClaimConstants.GET_ALL_CLAIMS_TELECOM_REQUEST, getAllClaimsTelecomSaga);

  yield takeLatest(telecomClaimConstants.GET_MY_CLAIMS_TELECOM_REQUEST, getMyClaimsTelecomSaga);

  yield takeLatest(telecomClaimConstants.GET_MY_TEAMS_CLAIMS_TELECOM_REQUEST, getMyTeamsClaimsTelecomSaga);

  yield takeLatest(telecomClaimConstants.GET_MY_MOBILE_CLAIMS_TELECOM_REQUEST, getMyMobileClaimsTelecomSaga);

  yield takeLatest(telecomClaimConstants.GET_CLAIM_BY_ID_TELECOM_REQUEST, getClaimByIdTelecomSaga);

  yield takeLatest(telecomClaimConstants.UPDATE_TELECOM_CLAIM_REQUEST, updateTelecomClaimSaga);

  yield takeLatest(telecomClaimConstants.DELETE_TELECOM_CLAIM_REQUEST, deleteTelecomClaimSaga);

  yield takeLatest(telecomClaimConstants.GET_SCHEMA_ENTITY_OPTIONS_TELECOM_REQUEST, getSchemaEntityOptionsTelecomSaga);

  yield takeLatest(telecomClaimConstants.GET_SCHEMA_RDA_OPTIONS_TELECOM_REQUEST, getSchemaRdaOptionsTelecomSaga);

  yield takeLatest(
    telecomClaimConstants.GET_CREATE_CLAIM_FIELD_RULES_TELECOM_REQUEST,
    getCreateClaimFieldRulesTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_SCHEMA_FILTER_OPTIONS_TELECOM_REQUEST,
    getSchemaFilterConditionsTelecomSaga
  );

  yield takeLatest(telecomClaimConstants.GET_FILTER_SETS_TELECOM_REQUEST, getFilterSetsTelecomSaga);

  yield takeLatest(telecomClaimConstants.CREATE_FILTER_SET_TELECOM_REQUEST, createFilterSetTelecomSaga);
  yield takeLatest(telecomClaimConstants.REMOVE_FILTER_SET_TELECOM_REQUEST, removeFilterSetTelecomSaga);
  yield takeLatest(telecomClaimConstants.SET_FILTER_SET_ACTIVE_TELECOM_REQUEST, setFilterSetActiveTelecomSaga);

  yield takeLatest(telecomClaimConstants.UPDATE_FILTER_SET_NAME_TELECOM_REQUEST, updateFilterSetNameTelecomSaga);

  yield takeLatest(telecomClaimConstants.DUPLICATE_FILTER_SET_TELECOM_REQUEST, duplicateFilterSetTelecomSaga);

  yield takeLatest(telecomClaimConstants.UPDATE_FILTER_SET_TELECOM_REQUEST, updateFilterSetTelecomSaga);

  yield takeLatest(telecomClaimConstants.UPLOAD_CLAIM_ATTACHMENT_TELECOM_REQUEST, uploadAttachmentTelecomSaga);

  yield takeLatest(telecomClaimConstants.SAVE_ROTATION_TELECOM_REQUEST, saveRotationAttachmentTelecomSaga);

  yield takeLatest(telecomClaimConstants.GET_CLAIM_ATTACHMENTS_TELECOM_REQUEST, getAttachmentsTelecomSaga);

  yield takeLatest(telecomClaimConstants.GET_SIGNED_ATTACHMENT_LINK_TELECOM_REQUEST, getSignedUrlTelecomSaga);

  yield takeLatest(telecomClaimConstants.POST_PAYMENT_TELECOM_REQUEST, postPaymentTelecomSaga);

  yield takeLatest(telecomClaimConstants.GET_CLAIM_HISTORY_TELECOM_REQUEST, getClaimHistoryTelecomSaga);

  yield takeLatest(telecomClaimConstants.GET_JOB_COSTING_INVOICE_TELECOM_REQUEST, getClaimJobCostingInvoiceTelecomSaga);

  yield takeLatest(
    telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_TELECOM_REQUEST,
    getClaimRateCardContractorTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_TELECOM_REQUEST,
    getClaimRateCardMaterialTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_TELECOM_REQUEST,
    getClaimRateCardLaborTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_JOB_COSTING_LABOR_RATE_CARD_USIC_TELECOM_REQUEST,
    getClaimRateCardUsicTelecomSaga
  );

  yield takeLatest(telecomClaimConstants.CLAIM_INVOICE_TIMESTAMP_TELECOM_REQUEST, claimInvoiceTimeStampTelecomSaga);

  yield takeLatest(
    telecomClaimConstants.POST_CLAIM_IN_HOUSE_LABOR_ITEM_TELECOM_REQUEST,
    claimPostInHouseLaborTelecomSaga
  );

  yield takeLatest(telecomClaimConstants.POST_CLAIM_MATERIAL_ITEM_TELECOM_REQUEST, claimPostMaterialTelecomSaga);

  yield takeLatest(telecomClaimConstants.POST_CLAIM_OTHER_ITEM_TELECOM_REQUEST, claimPostOtherItemTelecomSaga);

  yield takeLatest(telecomClaimConstants.POST_CLAIM_UNLISTED_ITEM_TELECOM_REQUEST, claimPostUnlistedItemTelecomSaga);

  yield takeLatest(
    telecomClaimConstants.POST_CLAIM_CONTRACTOR_ITEM_TELECOM_REQUEST,
    claimPostContractorItemTelecomSaga
  );

  yield takeLatest(telecomClaimConstants.POST_CLAIM_LO_ITEM_TELECOM_REQUEST, claimPostLOItemTelecomSaga);

  yield takeLatest(
    telecomClaimConstants.DELETE_CLAIM_JOB_COSTING_OTHER_ITEM_REQUEST,
    claimJobCostingRemoveOtherItemTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.DELETE_CLAIM_JOB_COSTING_LABOR_ITEM_REQUEST,
    claimJobCostingRemoveLaborItemTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_REQUEST,
    claimJobCostingRemoveMaterialItemTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_REQUEST,
    claimJobCostingRemoveContractorItemTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.DELETE_CLAIM_JOB_COSTING_LO_ITEM_REQUEST,
    claimJobCostingRemoveLOItemTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.UPLOAD_CLAIM_JOB_COSTING_SHEET_TELECOM_REQUEST,
    claimJobCostingSheetUploadTelecomSaga
  );

  yield takeLatest(telecomClaimConstants.GET_CLAIM_INVOICE_DOCUMENT_TELECOM_REQUEST, claimInvoiceDocTelecomSaga);

  yield takeLatest(telecomClaimConstants.UPLOAD_CLAIM_DOCUMENT_TELECOM_REQUEST, claimDocumentUploadTelecomSaga);

  yield takeLatest(telecomClaimConstants.GET_CLAIM_DOCUMENT_TELECOM_REQUEST, claimDocTelecomSaga);

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST,
    claimDocTelecomSchemaReasonSaga
  );
  yield takeLatest(
    telecomClaimConstants.ADD_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST,
    addClaimDocTelecomSchemaReasonSaga
  );
  yield takeLatest(
    telecomClaimConstants.EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST,
    editClaimDocTelecomSchemaReasonSaga
  );
  yield takeLatest(
    telecomClaimConstants.REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_TELECOM_REQUEST,
    removeClaimDocTelecomSchemaReasonSaga
  );
  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_TELECOM_REQUEST,
    claimDocTelecomSchemaContactsSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_TELECOM_REQUEST,
    claimDocTelecomSchemaLastSentenceSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_TELECOM_REQUEST,
    claimAttachmentSchemaImageCategories
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_TELECOM_REQUEST,
    claimAttachmentSchemaVideoCategories
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_TELECOM_REQUEST,
    claimAttachmentSchemaDocumentCategories
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_DIG_LAWS_TELECOM_REQUEST,
    claimDocTelecomSchemaDigLawsSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_TELECOM_REQUEST,
    getClaimNarrativeCreatorDocTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_INTENT_TO_BILL_DOCUMENT_TELECOM_REQUEST,
    getClaimIntentToBillDocTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_PROMISSORY_DOCUMENT_TELECOM_REQUEST,
    getClaimPromissoryNoteDocTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_TELECOM_REQUEST,
    getClaimLegalFinalNoticeDocTelecomSaga
  );
  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_FINAL_NOTICE_DOCUMENT_TELECOM_REQUEST,
    getClaimFinalNoticeDocTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_RELEASE_LETTER_DOCUMENT_TELECOM_REQUEST,
    getClaimReleaseLetterDocTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_SECOND_NOTICE_DOCUMENT_TELECOM_REQUEST,
    getClaimSecondNoticeDocTelecomSaga
  );

  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_TELECOM_REQUEST,
    getClaimPaymentReceiptTelecomSaga
  );
  yield takeLatest(
    telecomClaimConstants.GET_CLAIM_BALANCE_OWED_DOCUMENT_TELECOM_REQUEST,
    getClaimBalanceOwedTelecomSaga
  );
  yield takeLatest(telecomClaimConstants.SORT_ATTACHMENTS_TELECOM_CLAIM_REQUEST, getSortOrderAttachmentsTelecomSaga);
  yield takeLatest(
    telecomClaimConstants.UPDATE_CATEGORY_ATTACHMENTS_TELECOM_CLAIM_REQUEST,
    updateCategoryAttachmentsTelecomSaga
  );
  yield takeLatest(telecomClaimConstants.GET_LOGO_TELECOM_REQUEST, getLogoTelecomSaga);
  yield takeLatest(telecomClaimConstants.REMOVE_LEDGER_TELECOM_REQUEST, deleteTelecomLedgerSaga);
  yield takeLatest(telecomClaimConstants.UPDATE_LEDGER_TELECOM_REQUEST, updateTelecomLedgerSaga);
  yield takeLatest(telecomClaimConstants.REMOVE_STATUS_NOTE_TELECOM_REQUEST, deleteTelecomStatusNoteSaga);
  yield takeLatest(telecomClaimConstants.DELETE_ACTION_NOTE_TELECOM_REQUEST, deleteTelecomActionNoteSaga);
  yield takeLatest(telecomClaimConstants.SAVE_TELECOM_COLUMN_FILTER_REQUEST, saveTelecomColumnFiltersSaga);
}

export function* watchDispatchTelecomSaga() {
  yield takeLatest(telecomDispatchConstants.GET_ONE_DISPATCH_TELECOM_REQUEST, getOneDispatchTelecomSaga);

  yield takeLatest(telecomDispatchConstants.UPDATE_ONE_DISPATCH_TELECOM_REQUEST, updateOneDispatchTelecomSaga);

  yield takeLatest(
    telecomDispatchConstants.UPDATE_TEN_EIGHT_DISPATCH_TELECOM_REQUEST,
    updateTenEightDispatchTelecomSaga
  );

  yield takeLatest(telecomDispatchConstants.UPDATE_DISPATCH_TELECOM_TIMER_REQUEST, getDispatchTimer);

  yield takeLatest(telecomDispatchConstants.UPDATE_DISPATCH_TELECOM_TIMER_PAUSE_REQUEST, updateDispatchTimerPause);

  yield takeLatest(telecomDispatchConstants.UPDATE_DISPATCH_TELECOM_TIMER_COLOR_REQUEST, getDispatchTimerColor);
}

export function* watchReportTelecomSaga() {
  yield takeLatest(telecomReportConstants.GET_ALL_REPORTS_TELECOM_REQUEST, getAllReportsTelecomSaga);
  yield takeLatest(telecomReportConstants.GET_ONE_REPORT_TELECOM_REQUEST, getOneReportTelecomSaga);

  yield takeLatest(telecomReportConstants.POST_RUN_REPORT_TELECOM_REQUEST, runOneReportTelecomSaga);
}

export function* watchPowerGasClaimsSaga() {
  yield takeLatest(powerGasClaimConstants.GET_ALL_CLAIMS_POWER_GAS_REQUEST, getAllClaimsPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.GET_MY_CLAIMS_POWER_GAS_REQUEST, getMyClaimsPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.GET_MY_TEAMS_CLAIMS_POWER_GAS_REQUEST, getMyTeamsClaimsPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_REQUEST, getClaimByIdPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.UPDATE_POWER_GAS_CLAIM_REQUEST, updatePowerGasClaimSaga);

  yield takeLatest(powerGasClaimConstants.DELETE_POWER_GAS_CLAIM_REQUEST, deletePowerGasClaimSaga);

  yield takeLatest(
    powerGasClaimConstants.GET_SCHEMA_ENTITY_OPTIONS_POWER_GAS_REQUEST,
    getSchemaEntityOptionsPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.GET_CREATE_CLAIM_FIELD_RULES_POWER_GAS_REQUEST,
    getCreateClaimFieldRulesPowGasSaga
  );

  yield takeLatest(powerGasClaimConstants.GET_SCHEMA_RDA_OPTIONS_POWER_GAS_REQUEST, getSchemaRdaOptionsPowerGasSaga);

  yield takeLatest(
    powerGasClaimConstants.GET_SCHEMA_FILTER_OPTIONS_POWER_GAS_REQUEST,
    getSchemaFilterConditionsPowerGasSaga
  );

  yield takeLatest(powerGasClaimConstants.GET_FILTER_SETS_POWER_GAS_REQUEST, getFilterSetsPowerGasSaga);
  yield takeLatest(powerGasClaimConstants.CREATE_FILTER_SET_POWER_GAS_REQUEST, createFilterSetPowerGasSaga);
  yield takeLatest(powerGasClaimConstants.UPDATE_FILTER_SET_POWER_GAS_REQUEST, updateFilterSetPowerGasSaga);
  yield takeLatest(powerGasClaimConstants.REMOVE_FILTER_SET_POWER_GAS_REQUEST, removeFilterSetPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.DUPLICATE_FILTER_SET_POWER_GAS_REQUEST, duplicateFilterSetPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.UPLOAD_CLAIM_ATTACHMENT_POWER_GAS_REQUEST, uploadAttachmentPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.SAVE_ROTATION_POWER_GAS_REQUEST, saveRotationAttachmentPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.UPLOAD_CLAIM_DOCUMENT_POWER_GAS_REQUEST, claimDocumentUploadPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.GET_CLAIM_ATTACHMENTS_POWER_GAS_REQUEST, getAttachmentsPowerGasSaga);
  yield takeLatest(powerGasClaimConstants.POST_PAYMENT_POWER_GAS_REQUEST, postPaymentPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.GET_CLAIM_HISTORY_POWER_GAS_REQUEST, getClaimHistoryPowerGasSaga);

  yield takeLatest(
    powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_REQUEST,
    getClaimJobCostingInvoicePowerGasSaga
  );

  yield takeLatest(powerGasClaimConstants.CLAIM_INVOICE_TIMESTAMP_POWER_GAS_REQUEST, claimInvoiceTimeStampPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.POST_CLAIM_MATERIAL_ITEM_POWER_GAS_REQUEST, claimPostMaterialPowerGasSaga);

  yield takeLatest(
    powerGasClaimConstants.POST_CLAIM_IN_HOUSE_LABOR_ITEM_POWER_GAS_REQUEST,
    claimPostInHouseLaborPowerGasSaga
  );

  yield takeLatest(powerGasClaimConstants.POST_CLAIM_VEHICLE_ITEM_POWER_GAS_REQUEST, claimPostVehicleItemPowerGasSaga);

  yield takeLatest(
    powerGasClaimConstants.POST_CLAIM_CONTRACTOR_ITEM_POWER_GAS_REQUEST,
    claimPostContractorItemPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.POST_CLAIM_LOSS_OF_GAS_ITEM_POWER_GAS_REQUEST,
    claimPostLossOfGasItemPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_POWER_GAS_REQUEST,
    claimJobCostingRemoveMaterialItemPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.DELETE_CLAIM_JOB_COSTING_IN_HOUSE_LABOR_ITEM_POWER_GAS_REQUEST,
    claimJobCostingRemoveInHouseLaborItemPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.DELETE_CLAIM_JOB_COSTING_VEHICLE_ITEM_POWER_GAS_REQUEST,
    claimJobCostingRemoveVehicleItemPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_POWER_GAS_REQUEST,
    claimJobCostingRemoveContractorItemPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.DELETE_CLAIM_JOB_COSTING_LOSS_OF_GAS_ITEM_POWER_GAS_REQUEST,
    claimJobCostingRemoveLossOfGasItemPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.UPLOAD_CLAIM_JOB_COSTING_SHEET_POWER_GAS_REQUEST,
    claimJobCostingSheetUploadPowerGasSaga
  );

  yield takeLatest(powerGasClaimConstants.GET_CLAIM_INVOICE_DOCUMENT_POWER_GAS_REQUEST, claimInvoiceDocPowerGasSaga);

  yield takeLatest(powerGasClaimConstants.GET_CLAIM_DOCUMENT_POWER_GAS_REQUEST, claimDocPowerGasSaga);

  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST,
    claimDocPowerGasSchemaReasonSaga
  );
  yield takeLatest(
    powerGasClaimConstants.ADD_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST,
    addClaimDocPowerGasSchemaReasonSaga
  );
  yield takeLatest(
    powerGasClaimConstants.EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST,
    editClaimDocPowerGasSchemaReasonSaga
  );
  yield takeLatest(
    powerGasClaimConstants.REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST,
    removeClaimDocPowerGasSchemaReasonSaga
  );

  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_POWER_GAS_REQUEST,
    claimDocPowerGasSchemaContactsSaga
  );

  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_POWER_GAS_REQUEST,
    claimDocPowerGasSchemaLastSentenceSaga
  );

  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_POWER_GAS_REQUEST,
    claimAttachmentSchemaImageCategoriesPowerGas
  );

  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_POWER_GAS_REQUEST,
    claimAttachmentSchemaVideoCategoriesPowerGas
  );

  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_POWER_GAS_REQUEST,
    claimAttachmentSchemaDocumentCategoriesPowerGas
  );

  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_POWER_GAS_REQUEST,
    getClaimNarrativeCreatorDocPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_INTENT_TO_BILL_DOCUMENT_POWER_GAS_REQUEST,
    getClaimIntentToBillDocPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_PROMISSORY_DOCUMENT_POWER_GAS_REQUEST,
    getClaimPromissoryNoteDocPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_POWER_GAS_REQUEST,
    getClaimLegalFinalNoticeDocPowerGasSaga
  );
  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_FINAL_NOTICE_DOCUMENT_POWER_GAS_REQUEST,
    getClaimFinalNoticeDocPowerGasSaga
  );

  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_RELEASE_LETTER_DOCUMENT_POWER_GAS_REQUEST,
    getClaimReleaseLetterDocPowerGasSaga
  );
  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_SECOND_NOTICE_DOCUMENT_POWER_GAS_REQUEST,
    getClaimSecondNoticeDocPowerGasSaga
  );
  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_POWER_GAS_REQUEST,
    getClaimPaymentReceiptPowerGasSaga
  );
  yield takeLatest(
    powerGasClaimConstants.GET_CLAIM_BALANCE_OWED_DOCUMENT_POWER_GAS_REQUEST,
    getClaimBalanceOwedPowerGasSaga
  );
  yield takeLatest(
    powerGasClaimConstants.SORT_ATTACHMENTS_POWER_GAS_CLAIM_REQUEST,
    getSortOrderAttachmentsPowerGasSaga
  );
  yield takeLatest(
    powerGasClaimConstants.UPDATE_CATEGORY_ATTACHMENTS_POWER_GAS_CLAIM_REQUEST,
    updateCategoryAttachmentsPowerGasSaga
  );
  yield takeLatest(powerGasClaimConstants.REMOVE_LEDGER_POWER_GAS_REQUEST, deletePowerGasLedgerSaga);
  yield takeLatest(powerGasClaimConstants.UPDATE_LEDGER_POWER_GAS_REQUEST, updatePowerGasLedgerSaga);
  yield takeLatest(powerGasClaimConstants.REMOVE_STATUS_NOTE_POWER_GAS_REQUEST, deletePowerGasStatusNoteSaga);
  yield takeLatest(powerGasClaimConstants.DELETE_ACTION_NOTE_POWER_GAS_REQUEST, deletePowerGasActionNoteSaga);

  yield takeLatest(powerGasClaimConstants.SAVE_POWER_GAS_COLUMN_FILTER_REQUEST, savePowGasColumnFiltersSaga);
}

export function* watchPowerGasDispatchSaga() {

  yield takeLatest(powerGasDispatchConstants.GET_ONE_DISPATCH_POWER_GAS_REQUEST, getOneDispatchPowerGasSaga);

  yield takeLatest(powerGasDispatchConstants.UPDATE_ONE_DISPATCH_POWER_GAS_REQUEST, updateOneDispatchPowerGasSaga);

  yield takeLatest(
    powerGasDispatchConstants.UPDATE_TEN_EIGHT_DISPATCH_POWER_GAS_REQUEST,
    updateTenEightDispatchPowerGasSaga
  );

  yield takeLatest(powerGasDispatchConstants.UPDATE_DISPATCH_POWER_GAS_TIMER_REQUEST, getDispatchTimerPowerGasSaga);

  yield takeLatest(
    powerGasDispatchConstants.UPDATE_DISPATCH_POWER_GAS_TIMER_PAUSE_REQUEST,
    updateDispatchTimerPausePowerGasSaga
  );

  yield takeLatest(
    powerGasDispatchConstants.UPDATE_DISPATCH_POWER_GAS_TIMER_COLOR_REQUEST,
    getDispatchTimerColorPowerGasSaga
  );
}

export function* watchReportPowerGasSaga() {
  yield takeLatest(powerGasReportConstants.GET_ALL_REPORTS_POWER_GAS_REQUEST, getAllReportsPowerGasSaga);

  yield takeLatest(powerGasReportConstants.GET_ONE_REPORT_POWER_GAS_REQUEST, getOneReportPowerGasSaga);

  yield takeLatest(powerGasReportConstants.POST_RUN_REPORT_POWER_GAS_REQUEST, runOneReportPowerGasSaga);
}

export function* watchFreloProjectSaga() {
  yield takeLatest(freloProjectConstants.GET_ALL_PROJECTS_FRELO_REQUEST, getAllProjectsFreloSaga);

  yield takeLatest(freloProjectConstants.GET_MY_PROJECTS_FRELO_REQUEST, getMyProjectsFreloSaga);

  yield takeLatest(freloProjectConstants.GET_PROJECT_BY_ID_FRELO_REQUEST, getProjectByIdFreloSaga);

  yield takeLatest(freloProjectConstants.UPDATE_FRELO_PROJECT_REQUEST, updateFreloProjectSaga);

  yield takeLatest(freloProjectConstants.GET_SCHEMA_ENTITY_OPTIONS_FRELO_REQUEST, getSchemaEntityOptionsFreloSaga);

  yield takeLatest(
    freloProjectConstants.GET_CREATE_PROJECT_FIELD_RULES_FRELO_REQUEST,
    getCreateProjectFieldRulesFreloSaga
  );

  yield takeLatest(freloProjectConstants.GET_SCHEMA_RDA_OPTIONS_FRELO_REQUEST, getSchemaRdaOptionsFreloSaga);

  yield takeLatest(freloProjectConstants.GET_SCHEMA_FILTER_OPTIONS_FRELO_REQUEST, getSchemaFilterConditionsFreloSaga);

  yield takeLatest(freloProjectConstants.GET_FILTER_SETS_FRELO_REQUEST, getFilterSetsFreloSaga);

  yield takeLatest(freloProjectConstants.UPDATE_FILTER_SET_FRELO_REQUEST, updateFilterSetFreloSaga);

  yield takeLatest(freloProjectConstants.REMOVE_FILTER_SET_FRELO_REQUEST, removeFilterSetFreloSaga);

  yield takeLatest(freloProjectConstants.DUPLICATE_FILTER_SET_FRELO_REQUEST, duplicateFilterSetFreloSaga);
  yield takeLatest(freloProjectConstants.CREATE_FILTER_SET_FRELO_REQUEST, createFilterSetFreloSaga);

  yield takeLatest(freloProjectConstants.UPLOAD_CLAIM_ATTACHMENT_FRELO_REQUEST, uploadAttachmentFreloSaga);

  yield takeLatest(freloProjectConstants.GET_CLAIM_ATTACHMENTS_FRELO_REQUEST, getAttachmentsFreloSaga);

  yield takeLatest(freloProjectConstants.POST_PAYMENT_FRELO_REQUEST, postPaymentFreloSaga);
  yield takeLatest(freloProjectConstants.GET_PROJECT_HISTORY_FRELO_REQUEST, getProjectHistoryFreloSaga);
  yield takeLatest(freloProjectConstants.REMOVE_LEDGER_FRELO_REQUEST, deleteFreloLedgerSaga);
  yield takeLatest(freloProjectConstants.REMOVE_STATUS_NOTE_FRELO_REQUEST, deleteFreloStatusNoteSaga);
}

export function* watchFreloReportsSaga() {
  yield takeLatest(freloReportConstants.GET_ALL_REPORTS_FRELO_REQUEST, getAllReportsFreloSaga);

  yield takeLatest(freloReportConstants.GET_ONE_REPORT_FRELO_REQUEST, getOneReportFreloSaga);

  yield takeLatest(freloReportConstants.POST_RUN_REPORT_FRELO_REQUEST, runOneReportFreloSaga);
}

export function* watchJointReportsSaga() {
  yield takeLatest(jointReportConstants.GET_USER_REPORTS_REQUEST, getUserReportsSaga);

  yield takeLatest(jointReportConstants.GET_REPORT_REQUEST, getReportSaga);

  yield takeLatest(jointReportConstants.GET_REPORT_PROCS_REQUEST, getReportsProcsSaga);

  yield takeLatest(jointReportConstants.QUERY_REPORT_PROC_REQUEST, queryReportsProcSaga);

  yield takeLatest(jointReportConstants.GET_SCORE_BOARD_REQUEST, getScoreboardSaga);

  yield takeLatest(jointReportConstants.GET_A_LIST_REPORT_REQUEST, getAListReportSaga);

  yield takeLatest(jointReportConstants.GET_REMITTANCE_REQUEST, getRemittanceSaga);
}

export function* watchMobileSaga() {
  yield takeLatest(mobileConstants.LOGIN_REQUEST, loginMobileSaga);

  yield takeLatest(mobileConstants.MOBILE_CLEAR, logoutMobileSaga);

  yield takeLatest(mobileConstants.GET_PRECLAIMS_MOBILE_REQUEST, getPreClaimsMobileSaga);

  yield takeLatest(mobileConstants.GET_ALL_MOBILE_CLAIMS_REQUEST, getAllMobileClaimsSaga);

  // yield takeLatest(mobileConstants.GET_RDA_MOBILE_REQUEST, getMobileRdaOptionsTelecomSaga);

  yield takeLatest(mobileConstants.GET_INITIAL_MOBILE_REQUEST, getInitialMobileSaga);

  yield takeLatest(mobileConstants.CREATE_INITIAL_MOBILE_REQUEST, createInitialMobileSaga);

  yield takeLatest(mobileConstants.GET_FULL_MOBILE_REQUEST, getFullMobileSaga);

  yield takeLatest(mobileConstants.UPDATE_FULL_MOBILE_REQUEST, updateFullMobileSaga);

  yield takeLatest(mobileConstants.CREATE_MOVE_TO_PRG_MOBILE_REQUEST, createMoveToPrgMobileSaga);

  yield takeLatest(mobileConstants.GET_MOBILE_ATTACHMENTS_REQUEST, getAttachmentsMobileSaga);

  yield takeLatest(mobileConstants.UPLOAD_CLAIM_ATTACHMENT_MOBILE_REQUEST, uploadAttachmentMobileSaga);

  yield takeLatest(mobileConstants.DELETE_MOBILE_CLAIM_REQUEST, deleteMobileClaimSaga);
}

export function* watchPowerBiSaga() {
  yield takeLatest(powerBiConstants.GET_REPORTS_REQUEST, getPowerBiReportsSaga);
}

export function* watchPaymentSaga() {
  yield takeLatest(paymentConstants.GET_PAYMENT_REQUEST, getPaymentSaga);
}

export function* watchErrorSaga() {
  yield takeLatest(errorConstants.POST_ERROR_REQUEST, postErrorSaga);
}

export function* watchJointLedgerSaga() {
  yield takeLatest(jointLedgerConstants.GET_LEDGERS_REQUEST, getLedgers);
}
export const lobOptions = {
  telecom: "TEL",
  frelo: "FRELO",
  "power-gas": "POWGAS",
};

export const lobUrlMask = {
  TEL: "telecom",
  FRELO: "frelo",
  POWGAS: "power-gas",
};

export const lobUrlMaskRedux = {
  TEL: "telecom",
  FRELO: "frelo",
  POWGAS: "powerGas"
}

export const lobUrlToUserMask = {
  telecom: "Telecom",
  frelo: "Frelo",
  "power-gas": "P&G",
};

export const lobUserMask = {
  TEL: "Telecom",
  POWGAS: "P&G",
  FRELO: "FRELO",
};

export const lobUrlToReducer = {
  telecom: "telecom",
  "power-gas": "powerGas",
  frelo: "frelo",
};


export const integerToLOB = {
  1: "telecom",
  2: "power-gas",
  3: "frelo",
};

export const lobToInteger = {
  "telecom": 1,
  "power-gas": 2,
  "frelo": 3
}

export const integerToLobFormatted = {
  1: "Telecom",
  2: "Power & Gas",
  3: "Frelo"
}

export const lobUrlMaskInteger = {
  TEL: 1,
  POWGAS: 2,
  FRELO: 3
}
import { lobConstants } from "../_constants";
import { produce } from "immer";
import { REHYDRATE } from "redux-persist/lib/constants";

let initState = {
  current: "",
};
export function lob(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case lobConstants.CURRENT_LOB_SET:
      return produce(state, (draft) => {
        draft["current"] = payload;
      });
     
    case REHYDRATE: {
      if (payload && payload.lob) {
        return { ...payload.lob };
      } else {
        return { ...initState };
      }
    }
    case lobConstants.LOB_CLEAR:
      return { ...initState };
    default:
      return state;
  }
}

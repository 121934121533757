import { put, call,all} from "redux-saga/effects";
import { alertConstants, authConstants, adminClaimStatusConstants_ } from "_constants";
import { adminClaimStatusService } from "_services";
import { isEmpty } from "ramda";
// import { buildAlertMessage } from "_utility";


export function* getAllClaimStatusAdminSaga({lob}) {
  try {
    const response = yield call(adminClaimStatusService.getAll,lob);

    yield put({
      type: adminClaimStatusConstants_.GET_ALL_STATUS_SUCCESS,
      payload: [...response],

    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* addStatusAdminSaga({ payload,lob} ) {
  try {
    const responsePost = yield call(adminClaimStatusService.addStatus, payload,lob);
   

     if (isEmpty(responsePost)) {
      return;
    }

    const response = yield call(adminClaimStatusService.getAll,lob);

    yield put({
      type: adminClaimStatusConstants_.GET_ALL_STATUS_SUCCESS,
      payload: [...response],

    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Status Added" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}


export function* getOneClaimStatusAdminSaga({lob,id}) {
  try {
    const response = yield call(adminClaimStatusService.getOne,lob,id);

    yield put({
      type: adminClaimStatusConstants_.GET_ONE_STATUS_SUCCESS,
      payload: {id, data: [ ...response ] },

    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getUsersAdminSaga() {
  try {
    const response = yield call(adminClaimStatusService.getUsers);

    yield put({
      type: adminClaimStatusConstants_.GET_USERS_SUCCESS,
      payload: [...response],

    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getStatusAccessAdminSaga({id}) {
  try {
    const response = yield call(adminClaimStatusService.getStatusAccess,id);

    yield put({
      type: adminClaimStatusConstants_.GET_STATUS_ACCESS_SUCCESS,
      payload: [...response],

    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}


export function* addUsersAdminSaga({payload,id}) {
  try {
    const responsePost = yield call(adminClaimStatusService.addUser, payload);

     if (isEmpty(responsePost)) {
      return;
    }

     const response = yield call(adminClaimStatusService.getStatusAccess,id);

     yield put({
       type: adminClaimStatusConstants_.GET_STATUS_ACCESS_SUCCESS,
       payload: [...response],
 
     });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "User Added" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* removeUserSaga( {payload,statusId}) {
  try {
    const responsePost = yield all(
     payload.map((userId) => call(adminClaimStatusService.removeUser, userId,statusId))
    );

    if (isEmpty(responsePost)) {
      throw new Error("Error removing User");
    }

    const response  = yield call(adminClaimStatusService.getStatusAccess,statusId);

    yield put({
      type: adminClaimStatusConstants_.GET_STATUS_ACCESS_SUCCESS,
      payload: [...response],

    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "User Removed" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}


export function* updateOneClaimStatusAdminSaga({payload,lob,id}) {
  try {
   const responsePost =  yield call(adminClaimStatusService.updateOneStatus,payload,lob,id);
    if (isEmpty(responsePost)) {
      return;
    }
    const response =  yield call(adminClaimStatusService.getOne,lob,id);

    yield put({
      type: adminClaimStatusConstants_.GET_ONE_STATUS_SUCCESS,
      payload: {id, data: [ ...response ] },

    });

    const responseAll = yield call(adminClaimStatusService.getAll,lob);

    yield put({
      type: adminClaimStatusConstants_.GET_ALL_STATUS_SUCCESS,
      payload: [...responseAll],

    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Status Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
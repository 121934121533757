import { put, call } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  powerGasDispatchConstants,
} from "_constants";

import { powerGasDispatchService } from "_services/lob/powerGas/dispatch.service";

export function* getOneDispatchPowerGasSaga({ claimId }) {
  try {
    const response = yield call(
      powerGasDispatchService.getOneDispatch,
      claimId
    );

    yield put({
      type: powerGasDispatchConstants.GET_ONE_DISPATCH_POWER_GAS_SUCCESS,
      payload: { claimId, response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateOneDispatchPowerGasSaga({ claimId, data, callback }) {
  try {
    yield call(powerGasDispatchService.updateOneDispatch, data, claimId, callback);

    const response = yield call(
      powerGasDispatchService.getOneDispatch,
      claimId
    );

    yield put({
      type: powerGasDispatchConstants.GET_ONE_DISPATCH_POWER_GAS_SUCCESS,
      payload: { claimId, response },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Dispatch updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateTenEightDispatchPowerGasSaga({ claimId }) {
  try {
    yield call(powerGasDispatchService.updateTenEightDispatch, claimId);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Dispatch updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

//Dispatch Stopwatch
export function* getDispatchTimerPowerGasSaga({ claims}) {
  try {
   yield put({
      type: powerGasDispatchConstants.UPDATE_DISPATCH_POWER_GAS_TIMER_SUCCESS,
      payload: claims,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateDispatchTimerPausePowerGasSaga({ payload}) {
    try {
       yield call(powerGasDispatchService.updateDispatchTimerPause, payload);

      yield put({
        type: alertConstants.SUCCESS,
        payload: { message: "Timer Stopped" },
      });
    } 
    catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
}

export function* getDispatchTimerColorPowerGasSaga( {payload}) {
  try {
    yield call(powerGasDispatchService.updateDispatchTimerColor, payload);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Timer Updated" },
    });
    
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
import { getApiBase } from "_helpers";
import { cookieService } from "_services";
import { lobOptions } from "_helpers";
import { toSqlDateString } from "_helpers";
import { stringParsers } from "_utility";
import _uniqueId from "lodash/uniqueId";


const getAllEntity = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/entity${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching entity");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const createClientInformation = (  files,payload,lob ) => {
 let entries = Object.entries(payload)
 const dataFile = new FormData();
if(files !== null){
 const fileNameWithType = files[0].name;
 const originalFileName = files[0].name.substr(0, files[0].name.lastIndexOf("."));

 const fileTypeNative = files[0].name.substr(files[0].name.lastIndexOf("."));

 dataFile.append("file", files[0], fileNameWithType);
 dataFile.append("fileName", `${lob} - ${_uniqueId()}`);
 dataFile.append("originalFileName", originalFileName);
 dataFile.append("fileNameWithType", fileNameWithType);
 dataFile.append("fileTypeNative", fileTypeNative);
}
 entries.map( ([key, val] = entries) => dataFile.append(`${key}`, `${val}`))
 if (process.env.NODE_ENV !== "production") {
   dataFile.append("jwtCookie", cookieService.get("token"));
 }

 const parameters = {
   method: "POST",
   credentials: "include",
   body: dataFile,
 };
 let apiBase = getApiBase();

 return fetch(
   `${apiBase}/admin/entity/general?lob=${lob}`,
 parameters
 )
   .then((response) => {
     if (response.status === 403) {
       throw new Error("Invalid Auth Token");
     }
     if (response.status !== 200) {
       throw new Error("Error updating client information");
     }

     return response.json();
   })
   .catch((err) => {
     throw err;
   });
};



const getSchemaLob = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/schema/loboptions${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching Line Of Business Options");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getAllStatus = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/status?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting status");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateStatus = (lob, { id, ...payload }) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/status?&lob=${lobOptions[lob]}&statusid=${id}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id };
    })
    .catch((err) => {
      throw err;
    });
};

const getAllSowContracts = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/sowcontracts?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Sow Contracts");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateSowContract = (entityId, lob, { id, ...payload }) => {
  let body_serialized = Object.keys(payload).reduce((prev, el) => {
    if (el === "startDate" || el === "endDate") {
      return { ...prev, [el]: toSqlDateString(payload[el]) };
    }
    return { ...prev, [el]: payload[el] };
  }, {});

  let body = {
    ...body_serialized,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  // let apiBase = "http://localhost:3000/api"; // local host testing
  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/sowcontracts?entityid=${entityId}&lob=${lobOptions[lob]}&sowid=${id}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id };
    })
    .catch((err) => {
      throw err;
    });
};

const addSowContract = (
  { rdaId, startDate, endDate, sowName, billingType },
  entityId,
  lob
) => {
  let body = {
    rdaId,
    startDate: toSqlDateString(startDate),
    endDate: toSqlDateString(endDate),
    sowName,
    billingType,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };
  // let apiBase = "http://localhost:3000/api"; // local host testing
  let apiBase = getApiBase();
  return fetch(
    `${apiBase}/admin/entity/sowcontracts?entityid=${entityId}&lob=${lobOptions[lob]}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating sow contract");
      }
      return response.json();
    })
    .catch((err) => {
      console.log("err", err);
      throw err;
    });
};

const getBillingTypesSchema = (entityId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/schema/billingtypeids?entityid=${entityId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Billing Type Options");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getRdaSchema = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/schema/rdaids?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Rda Id Options");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getSubaccountSchema = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/schema/subaccountoptions?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Subaccount Options");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getRdaControl = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/rdacontrol?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Rda Control");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getRdaOptionsSchema = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/schema/rdaoptions?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Rda Options");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateRdaControl = (entityId, lob, { id, ...payload }) => {
  let body = {
    rdaId: id,
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/rdacontrol?entityid=${entityId}&lob=${lobOptions[lob]}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id };
    })
    .catch((err) => {
      throw err;
    });
};

const addRdaControl = (
  { active, area, billingTypeId, division, region, subaccountId },
  entityId,
  lob
) => {
  let { binaryBool } = stringParsers;

  let body = {
    region,
    division,
    area,
    active: binaryBool(active),
    subaccountId,
    billingTypeId,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/rdacontrol?entityid=${entityId}&lob=${lobOptions[lob]}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating new Rda");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getBillingTypes = (entityId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/billingtypes?entityid=${entityId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting billing types");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const addBillingType = (
  { billingTypeName, description, startDate, endDate, rdaId },
  entityId,
) => {
  let body = {
    billingTypeName,
    description,
    startDate: toSqlDateString(startDate),
    endDate: toSqlDateString(endDate),
    rdaId,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  return fetch(
    `${apiBase}/admin/entity/billingtypes?entityid=${entityId}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating billing type");
      }
      return response.json();
    })
    .catch((err) => {
      console.log("err", err);
      throw err;
    });
};

const updateBillingType = (
  { billingTypeId, billingTypeName, description, startDate, endDate, active },
  entityId,
) => {
  let body = {
    billingTypeId,
    billingTypeName,
    description,
    startDate: toSqlDateString(startDate),
    endDate: toSqlDateString(endDate),
    active
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  return fetch(
    `${apiBase}/admin/entity/billingtypes?entityid=${entityId}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating billing type");
      }
      return response.json();
    })
    .catch((err) => {
      console.log("err", err);
      throw err;
    });
};

const getSubAccounts = (entityId, lob) => {

  let lobs = {
    "telecom" :1,
    "power-gas" : 2,
    "frelo" : 3
  }

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/subaccounts?entityid=${entityId}&lobid=${lobs[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Sub Accounts");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getSubaccountRda = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/schema/subaccountdropdown${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Subaccount Rda");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateSubAccount = (entityId, lob, { id, ...payload }) => {
  let lobs = {
    "telecom" :1,
    "power-gas" : 2,
    "frelo" : 3
  }

  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/subaccounts?entityid=${entityId}&lobid=${lobs[lob]}&subaccountid=${id}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id };
    })
    .catch((err) => {
      throw err;
    });
};

const addSubAccount = (entityId, lob, payload) => {
  let lobs = {
    "telecom" :1,
    "power-gas" : 2,
    "frelo" : 3
  }
  let body = { ...payload };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/subaccounts?entityid=${entityId}&lobid=${lobs[lob]}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error adding Sub Account");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getContacts = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/contacts?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting entity contacts");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateContact = (entityId, lob, { id, ...payload }) => {
  let body = {
    contactId: id,
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/contacts?entityid=${entityId}&lob=${lobOptions[lob]}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id };
    })
    .catch((err) => {
      throw err;
    });
};

const addContact = (contact, entityId, lob) => {
  let body = {
    ...contact,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/entity/contacts?entityid=${entityId}&lob=${lobOptions[lob]}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      if (response.status !== 200) {
        throw new Error("Error creating contact");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getDocTemplateInvoice = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/docmanagement/paymenttemplate?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting invoice template");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateDocTemplateInvoice = (entityId, lob, payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  return fetch(
    `${apiBase}/admin/entity/docmanagement/paymenttemplate?entityid=${entityId}&lob=${lobOptions[lob]}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

let getDocFieldMentions = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/docmanagement/fields?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting document field callouts");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

let getDocClaimReasonSchema = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/docmanagement/reason?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting document field callouts");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

let getDocClaimLastSentenceSchema = (entityId, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/docmanagement/lastsentence?entityid=${entityId}&lob=${lobOptions[lob]}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting document field callouts");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

//claim Letter Contacts

let getDocClaimContactsSchema = (lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/joint/docmanagement/contacts?lobid=${lob}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting document field callouts");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};
const updateDocClaimContactsSchema = (payload ,id) => {
  let body = {
    ...payload,
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/joint/docmanagement/contacts?lcid=${id}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      return { status: response.status, id };
    })
    .catch((err) => {
      throw err;
    });
};

const addDocClaimContactsSchema = (payload , id) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/joint/docmanagement/contacts?lobid=${id}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      if (response.status !== 200) {
        throw new Error("Error creating contact");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

///////End



const updateDocTemplateClaim = (entityId, lob, letterType, payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  return fetch(
    `${apiBase}/admin/entity/docmanagement/claimlettertemplate?entityid=${entityId}&lob=${lobOptions[lob]}&lettertype=${letterType}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getDocTemplateClaim = (entityId, lob, letterType) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/entity/docmanagement/claimlettertemplate?entityid=${entityId}&lob=${
      lobOptions[lob]
    }&lettertype=${encodeURIComponent(letterType)}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting claim template");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getRdaControlRegions = (entityid, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/region?lob=${lobOptions[lob]}&entityid=${entityid}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Regions");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const addRdaControlRegions = (payload) => {
  let body = {
    ...payload,
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };
  let apiBase = getApiBase();
  return fetch(`${apiBase}/admin/region`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error Adding Region");
      }
      return response.json();
    })
    .catch((err) => {
      console.log("err", err);
      throw err;
    });
};

const updateRdaControlRegion = (payload, lob) => {
  let id = payload["regionid"];

  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/region?lob=${lobOptions[lob]}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      return { status: response.status, id };
    })
    .catch((err) => {
      throw err;
    });
};

const getRdaControlDivisions = (entityid, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/division?lob=${lobOptions[lob]}&entityid=${entityid}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Regions");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const addRdaControlDivisions = (payload) => {
  let body = {
    ...payload,
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };
  let apiBase = getApiBase();
  return fetch(`${apiBase}/admin/division`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error Adding Division");
      }
      return response.json();
    })
    .catch((err) => {
      console.log("err", err);
      throw err;
    });
};

const updateRdaControlDivision = (payload, lob) => {
  let id = payload["divisionid"];

  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/division?lob=${lobOptions[lob]}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      return { status: response.status, id };
    })
    .catch((err) => {
      throw err;
    });
};

const getRdaControlAreas = (entityid, lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/area?lob=${lobOptions[lob]}&entityid=${entityid}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured getting Regions");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const addRdaControlAreas = (payload) => {
  let body = {
    ...payload,
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };
  let apiBase = getApiBase();
  return fetch(`${apiBase}/admin/area`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error Adding Area");
      }
      return response.json();
    })
    .catch((err) => {
      console.log("err", err);
      throw err;
    });
};

const updateRdaControlArea = (payload, lob) => {
  let id = payload["areaid"];

  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/area?lob=${lobOptions[lob]}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      return { status: response.status, id };
    })
    .catch((err) => {
      throw err;
    });
};

const getInvoicing = (entityId, lobId) => {


  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }


  return fetch(`${apiBase}/admin/entity/invoicing?lobid=${lobId}&entityid=${entityId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      return response.json()
    })
    .catch((err) => {
      throw err;
    });
};

const updateInvoicing = (payload, entityId, lobId) => {

  let body = {
    ...payload,
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/entity/invoicing?lobid=${lobId}&entityid=${entityId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      return response.json()
    })
    .catch((err) => {
      throw err;
    });
};

const getLineItems = (entityId, lobId) => {

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/entity/invoicing/lineitem?lobid=${lobId}&entityid=${entityId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      return response.json()
    })
    .catch((err) => {
      throw err;
    });
};

const postLineItem = (payload, entityId, lobId) => {

  let body = {
    ...payload,
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/entity/invoicing/lineitem?lobid=${lobId}&entityid=${entityId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      return response.json()
    })
    .catch((err) => {
      throw err;
    });
};

const putLineItem = (payload, entityId, lobId) => {

  let body = {
    ...payload,
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/entity/invoicing/lineitem?lineitemid=${payload.lineItemId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      return response.json()
    })
    .catch((err) => {
      throw err;
    });
};

const deleteLineItem = (lineItemId) => {

  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include"
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/entity/invoicing/lineitem?lineitemid=${lineItemId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      return response.json()
    })
    .catch((err) => {
      throw err;
    });
};

export const adminEntityService_ = {
  getAllEntity,
  createClientInformation,
  getSchemaLob,
  getAllStatus,
  updateStatus,
  getAllSowContracts,
  updateSowContract,
  addSowContract,
  getBillingTypesSchema,
  getRdaSchema,
  getSubaccountSchema,
  getRdaControl,
  getRdaOptionsSchema,
  updateRdaControl,
  addRdaControl,
  getBillingTypes,
  addBillingType,
  updateBillingType,
  getSubAccounts,
  getSubaccountRda,
  updateSubAccount,
  addSubAccount,
  getContacts,
  updateContact,
  addContact,
  getDocTemplateInvoice,
  updateDocTemplateInvoice,
  getDocFieldMentions,
  getDocClaimReasonSchema,
  getDocClaimLastSentenceSchema,
  getDocClaimContactsSchema,
  updateDocClaimContactsSchema,
  addDocClaimContactsSchema,
  updateDocTemplateClaim,
  getDocTemplateClaim,
  getRdaControlRegions,
  addRdaControlRegions,
  updateRdaControlRegion,
  getRdaControlDivisions,
  addRdaControlDivisions,
  updateRdaControlDivision,
  getRdaControlAreas,
  addRdaControlAreas,
  updateRdaControlArea,
  getInvoicing,
  updateInvoicing,
  getLineItems,
  postLineItem,
  putLineItem,
  deleteLineItem
};

import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const getAuthToken = () => {

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    };

    let apiBase = getApiBase();

    let jwtCookie = "";
    if (process.env.NODE_ENV !== "production") {
        jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
    }

    return fetch(`${apiBase}/auth/powerBI/getAuthToken${jwtCookie}`, parameters)
        .then((response) => {
            if (response.status === 403) {
                throw new Error("Invalid Auth Token");
            }
            if (response.status !== 200) {
                throw new Error("error fetching auth token for powerbi");
            }
            return response.json();
        })
        .catch((err) => {
            throw err;
        });
};

const getReports = (roleId) => {
    let body = { 
        roleId: roleId
    };

    if (process.env.NODE_ENV !== "production") {
        body = {
            ...body,
            jwtCookie: cookieService.get("token"),
        };
    }

    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body),
    };

    let apiBase = getApiBase();

    return fetch(`${apiBase}/auth/powerBI/getReports`, parameters)
        .then((response) => {
            if (response.status === 403) {
                throw new Error("Invalid Auth Token");
            }
            if (response.status !== 200 && response.status !== 201) {
                throw new Error("Error fetching powerbi reports");
            }

            return response.json();
        })
        .catch((err) => {
            throw err;
        });
};

const getEmbedToken = (authToken, workspaceId, reportId, username, roles, datasets) => {

    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify({
            "accessLevel": "View",
            "identities": [
                {
                    "username": username.toString(),
                    "roles": roles.map(e => e.toString()),
                    "datasets": datasets.map(e => e.toString())
                }
            ]
          })
    };

    return fetch(`https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports/${reportId}/GenerateToken`, parameters)
        .then((response) => {
            if (response.status === 403) {
                throw new Error("Invalid Auth Token");
            }
            if (response.status !== 200) {
                throw new Error("error fetching powerbi report token");
            }
            return response.json();
        })
        .catch((err) => {
            throw err;
        });
};

export const powerBiService = {
    getAuthToken,
    getReports,
    getEmbedToken
};

import { denormalize as denormalizer } from "normalizr";

export const denormalize = (payload, schema) => {
  let denormalizeRes = denormalizer(
    payload["result"],
    schema,
    payload["entities"]
  );
  return denormalizeRes;
};

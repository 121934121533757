export const getStoragePath = () => {
    let path = '';
    switch (process.env.REACT_APP_API_ENV) {
        case 'production':
        case 'prod':
            path = 'opd-storage'
            break;
        case 'apptest':
        default:
            path = 'opd-testing'
            break;
    }
    return path;
}
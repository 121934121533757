import { powerGasReportConstants } from "_constants";

const getAll = () => {
  return {
    type: powerGasReportConstants.GET_ALL_REPORTS_POWER_GAS_REQUEST,
  };
};

const getOne = (reportId) => {
  return {
    type: powerGasReportConstants.GET_ONE_REPORT_POWER_GAS_REQUEST,
    reportId,
  };
};

const runReport = (reportId, payload, fields) => {
  return {
    type: powerGasReportConstants.POST_RUN_REPORT_POWER_GAS_REQUEST,
    reportId,
    payload,
    fields,
  };
};


export const powerGasReportActions = {
  getAll,
  getOne,
  runReport,
};

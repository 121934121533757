import { put, call } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  telecomReportConstants,
} from "_constants";

import { telecomReportsService } from "_services";

import { formatDate } from "_helpers";

export function* getAllReportsTelecomSaga() {
  try {
    const response = yield call(telecomReportsService.getAllReports);

    yield put({
      type: telecomReportConstants.GET_ALL_REPORTS_TELECOM_SUCCESS,
      payload: response,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getOneReportTelecomSaga({ reportId }) {
  try {
    const response = yield call(telecomReportsService.getOneReport, reportId);
    yield put({
      type: telecomReportConstants.GET_ONE_REPORT_TELECOM_SUCCESS,
      payload: {
        reportId,
        data: response,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* runOneReportTelecomSaga({ reportId, payload, fields }) {
  try {
    let data = Object.keys(payload).reduce((prev, el) => {
      return [...prev, { ReportParamID: el, value: payload[el] }];
    }, []);

    const result = yield call(telecomReportsService.runReport, reportId, {
      params: data,
    });

    let { response } = result;

    yield put({
      type: telecomReportConstants.POST_RUN_REPORT_TELECOM_SUCCESS,
      payload: {
        reportId,
        data: response,
        fields,
      },
    });
    if (response.length === 0)
      yield put({type: alertConstants.SUCCESS, payload: {message: "No results found for given parameters"}});
    
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: telecomReportConstants.POST_RUN_REPORT_TELECOM_ERROR
    });
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}


const migrations = {
  0: (state) => {
    return { ...state, auth: { loggedIn: false, loggingIn: false } };
  },
  1: (state) => {
    return { ...state, auth: { loggedIn: false, loggingIn: false } };
  },
  2: (state) => {
    return { ...state, auth: { loggedIn: false, loggingIn: false } };
  },
  3: (state) => {
    return { ...state, auth: { loggedIn: false, loggingIn: false } };
  },
  4: (state) => {
    return { ...state, auth: { loggedIn: false, loggingIn: false } };
  },
  5: (state) => {
    return { ...state, auth: { loggedIn: false, loggingIn: false } };
  },
  6: (state) => {
    return { ...state, auth: { loggedIn: false, loggingIn: false } };
  },
  7: (state) => {
    // 1.) state.claim.telecom: { rename: claims => claim: {}, new property => allClaims: {} },
    // 2.) state.claim.power-gas: { rename: claims => claim: {} },

    let telecomInit = { ...state["claim"]["telecom"] };
    let { claims: telClaims, ...telecomClaimsRemoved } = telecomInit;

    let powGasInit = { ...state["claim"]["power-gas"] };
    let { claims: powGasClaims, ...powGasClaimsRemoved } = powGasInit;

    return {
      ...state,
      claim: {
        ...state["claim"],
        telecom: {
          ...telecomClaimsRemoved,
          claim: {
            ...state["claim"]["telecom"]["claims"],
          },
          allClaims: {},
        },
        "power-gas": {
          ...powGasClaimsRemoved,
          claim: { ...state["claim"]["power-gas"]["claims"] },
        },
      },
    };
  },
  8: (state) => {
    // 1.) claim reducer
    // Remove from root => frelo: { myProjects: {}, projects: {} },

    let claimInit = { ...state["claim"] };
    let { frelo, ...claimsFreloRemoved } = claimInit;

    // 2.) create: frelo reducer
    // Add to root => frelo: { project: { all: {}, my: {}, individual: {}}}

    return {
      ...state,
      claim: {
        ...claimsFreloRemoved,
      },
      frelo: {
        project: { all: {}, my: {}, individual: {} },
      },
    };
  },
  9: (state) => {
    // 1.) claim reducer
    // Remove from root => telecom: { myClaims: {}, claim: {}, allClaims: {} }

    // 2.) create: telecom reducer
    // Add to root => telecom: {claim: { all: {}, my: {}, individual: {}}}

    // 3.) migrate data from depreciated claim reducer > telecom
    // Into new telecom root reducer

    let claimInit = { ...state["claim"] };
    let { telecom, ...claimsTelecomRemoved } = claimInit;

    return {
      ...state,
      claim: { ...claimsTelecomRemoved },
      telecom: {
        claim: {
          all: {
            ...state["claim"]["telecom"]["allClaims"],
          },
          my: {
            ...state["claim"]["telecom"]["myClaims"],
          },
          individual: {
            ...state["claim"]["telecom"]["claim"],
          },
        },
      },
    };
  },
  10: (state) => {
    // 1.) claim reducer
    // Remove from root
    let stateInit = { ...state };
    let { claim, ...stateClaimRemoved } = stateInit;
    return { ...stateClaimRemoved };
  },
  11: (state) => {
    // 1.) add root reducer
    // powerGas
    return {
      ...state,
      powerGas: {
        claim: {
          all: {},
          my: {},
          individual: {},
        },
      },
    };
  },
  12: (state) => {
    // 1.) telecom/claim
    // Add -> schema: {entityOptions: []}
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          schema: { entityOptions: [] },
        },
      },
    };
  },
  13: (state) => {
    // 1.) frelo/project
    // Add -> schema: {entityOptions: []}
    return {
      ...state,
      frelo: {
        ...state.frelo,
        project: {
          ...state.frelo.project,
          schema: { entityOptions: [] },
        },
      },
    };
  },
  14: (state) => {
    // 1.) powerGas/claim
    // Add -> schema: {entityOptions: []}
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          schema: { entityOptions: [] },
        },
      },
    };
  },
  15: (state) => {
    // 1.) telecom/claim/schema
    // Add -> rdaOptions: {}
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          schema: { ...state.telecom.claim.schema, rdaOptions: {} },
        },
      },
    };
  },
  16: (state) => {
    // 1.) telecom/claim
    // Add -> create: {}
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          create: {},
        },
      },
    };
  },
  17: (state) => {
    // 1.) powerGas/claim
    // Add -> create: {}
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          create: {},
        },
      },
    };
  },
  18: (state) => {
    // 1.) powerGas/claim/schema
    // Add -> rdaOptions: {}
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          schema: { ...state.powerGas.claim.schema, rdaOptions: {} },
        },
      },
    };
  },
  19: (state) => {
    // 1.) frelo/project
    // Add -> create: {}
    return {
      ...state,
      frelo: {
        ...state.frelo,
        project: {
          ...state.frelo.project,
          create: {},
        },
      },
    };
  },
  20: (state) => {
    // 1.) frelo/project/schema
    // Add -> rdaOptions: {}
    return {
      ...state,
      frelo: {
        ...state.frelo,
        project: {
          ...state.frelo.project,
          schema: { ...state.frelo.project.schema, rdaOptions: {} },
        },
      },
    };
  },
  21: (state) => {
    // 1.) telecom/claim/schema
    // Add -> filterOptions: []
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          schema: { ...state.telecom.claim.schema, filterOptions: [] },
        },
      },
    };
  },
  22: (state) => {
    // 1.) powerGas/claim/schema
    // Add -> filterOptions: []
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          schema: { ...state.powerGas.claim.schema, filterOptions: [] },
        },
      },
    };
  },
  23: (state) => {
    // 1.) frelo/project/schema
    // Add -> filterOptions: []
    return {
      ...state,
      frelo: {
        ...state.frelo,
        project: {
          ...state.frelo.project,
          schema: { ...state.frelo.project.schema, filterOptions: [] },
        },
      },
    };
  },
  24: (state) => {
    // 1.) telecom/claim/schema
    // Add -> filterSets: []
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          schema: { ...state.telecom.claim.schema, filterSets: [] },
        },
      },
    };
  },
  25: (state) => {
    // 1.) powerGas/claim/schema
    // Add -> filterSets: []
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          schema: { ...state.powerGas.claim.schema, filterSets: [] },
        },
      },
    };
  },
  26: (state) => {
    // 1.) frelo/project/schema
    // Add -> filterSets: []
    return {
      ...state,
      frelo: {
        ...state.frelo,
        project: {
          ...state.frelo.project,
          schema: { ...state.frelo.project.schema, filterSets: [] },
        },
      },
    };
  },
  27: (state) => {
    // 1.) telecom/claim
    // Add -> attachments: {}
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          attachments: {},
        },
      },
    };
  },
  28: (state) => {
    // 1.) powerGas/claim
    // Add -> attachments: {}
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          attachments: {},
        },
      },
    };
  },
  29: (state) => {
    // 1.) frelo/project
    // Add -> attachments: {}
    return {
      ...state,
      frelo: {
        ...state.frelo,
        project: {
          ...state.frelo.project,
          attachments: {},
        },
      },
    };
  },
  30: (state) => {
    // 1.) adminEntityManagement/telecom
    // Add -> paymentForm: {}
    return {
      ...state,
      adminEntityManagement: {
        ...state.adminEntityManagement,
        telecom: {
          ...state.adminEntityManagement.telecom,
          paymentForm: {},
        },
      },
    };
  },
  31: (state) => {
    // 1.) adminEntityManagement/power-gas
    // Add -> paymentForm: {}
    return {
      ...state,
      adminEntityManagement: {
        ...state.adminEntityManagement,
        "power-gas": {
          ...state.adminEntityManagement["power-gas"],
          paymentForm: {},
        },
      },
    };
  },
  32: (state) => {
    // 1.) adminEntityManagement/frelo
    // Add -> paymentForm: {}
    return {
      ...state,
      adminEntityManagement: {
        ...state.adminEntityManagement,
        frelo: {
          ...state.adminEntityManagement["frelo"],
          paymentForm: {},
        },
      },
    };
  },
  33: (state) => {
    // 1.) telecom/claim
    // Add -> history: {}
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          history: {},
        },
      },
    };
  },
  34: (state) => {
    // 1.) frelo/project
    // Add -> history: {}
    return {
      ...state,
      frelo: {
        ...state.frelo,
        project: {
          ...state.frelo.project,
          history: {},
        },
      },
    };
  },
  35: (state) => {
    // 1.) powerGas/claim
    // Add -> history: {}
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          history: {},
        },
      },
    };
  },
  36: (state) => {
    // 1.) adminRoles
    // Add -> dashboard: {}
    return {
      ...state,
      adminRoles: {
        ...state.adminRoles,
        dashboard: {},
      },
    };
  },
  37: (state) => {
    // 1.) admin
    // Add -> users: { all: []}
    return {
      ...state,
      admin: {
        ...state.admin,
        users: {
          all: [],
        },
      },
    };
  },
  38: (state) => {
    // 1.) admin/users
    // Add -> roles: []
    return {
      ...state,
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          roles: [],
        },
      },
    };
  },
  39: (state) => {
    // 1.) admin/users
    // Add -> general: {}
    return {
      ...state,
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          general: {},
        },
      },
    };
  },
  40: (state) => {
    // 1.) admin/users
    // Add -> dbAccess: {}
    return {
      ...state,
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          dbAccess: {},
        },
      },
    };
  },
  41: (state) => {
    // 1.) admin
    // Add -> entity: { entities: [], lob: [] }
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          entities: [],
          lob: [],
        },
      },
    };
  },
  42: (state) => {
    // 1.) adminEntityManagement
    // remove ->  entities: [], lob: [],
    let {
      entities,
      lob,
      ...adminEntityManagementItemsRm
    } = state.adminEntityManagement;

    return {
      ...state,
      adminEntityManagement: { ...adminEntityManagementItemsRm },
    };
  },
  43: (state) => {
    // 1.) telecom/claim/individual
    // add ->  loading: false

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          individual: {
            ...state.telecom.claim.individual,
            loading: false,
          },
        },
      },
    };
  },
  44: (state) => {
    // 1.) powerGas/claim/individual
    // add ->  loading: false

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          individual: {
            ...state.powerGas.claim.individual,
            loading: false,
          },
        },
      },
    };
  },
  45: (state) => {
    // 1.) frelo/claim/individual
    // add ->  loading: false

    return {
      ...state,
      frelo: {
        ...state.frelo,
        project: {
          ...state.frelo.project,
          individual: {
            ...state.frelo.project.individual,
            loading: false,
          },
        },
      },
    };
  },
  46: (state) => {
    // 1.) admin/entity add ->
    // contacts: {
    //   loading: false,
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          contacts: {
            loading: false,
            telecom: {},
            frelo: {},
            "power-gas": {},
          },
        },
      },
    };
  },
  47: (state) => {
    // 1.) admin/entity add ->
    // status: {
    //   loading: false,
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          status: {
            loading: false,
            telecom: {},
            frelo: {},
            "power-gas": {},
          },
        },
      },
    };
  },
  48: (state) => {
    // 1.) adminEntityManagement
    // telecom -> remove status
    let { status: telStatus, ...telRest } = state["adminEntityManagement"][
      "telecom"
    ];

    // 2.) adminEntityManagement
    // frelo -> remove status
    let { status: freloStatus, ...freloRest } = state["adminEntityManagement"][
      "frelo"
    ];

    // 3.) adminEntityManagement
    // power-gas -> remove status
    let { status: pgStatus, ...pgRest } = state["adminEntityManagement"][
      "power-gas"
    ];

    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...telRest,
        },
        frelo: {
          ...freloRest,
        },
        "power-gas": {
          ...pgRest,
        },
      },
    };
  },
  49: (state) => {
    // 1.) admin/entity add ->
    // sowContracts: {
    //   loading: false,
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          sowContracts: {
            loading: false,
            telecom: {},
            frelo: {},
            "power-gas": {},
          },
        },
      },
    };
  },
  50: (state) => {
    // 1.) admin/entity add ->
    // schema: {
    //   billingTypes: {
    //     telecom: {},
    //     frelo: {},
    //     "power-gas": {},
    //   },
    // },
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            billingTypes: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  51: (state) => {
    // 1.) admin/entity/schema add ->
    //   rda: {
    //     telecom: {},
    //     frelo: {},
    //     "power-gas": {},
    //   },
    //
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            ...state.admin.entity.schema,
            rda: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  52: (state) => {
    // 1.) adminEntityManagement
    // telecom -> remove sowContracts
    let { sowContracts: telSowContracts, ...telRest } = state[
      "adminEntityManagement"
    ]["telecom"];

    // 2.) adminEntityManagement
    // frelo -> remove sowContracts
    let { sowContracts: freloSowContracts, ...freloRest } = state[
      "adminEntityManagement"
    ]["frelo"];

    // 3.) adminEntityManagement
    // power-gas -> remove sowContracts
    let { sowContracts: pgSowContracts, ...pgRest } = state[
      "adminEntityManagement"
    ]["power-gas"];

    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...telRest,
        },
        frelo: {
          ...freloRest,
        },
        "power-gas": {
          ...pgRest,
        },
      },
    };
  },
  53: (state) => {
    // 1.) adminEntityManagement
    // telecom -> remove powerBi
    let { powerBi: telPowerBi, ...telRest } = state["adminEntityManagement"][
      "telecom"
    ];

    // 2.) adminEntityManagement
    // frelo -> remove PowerBi
    let { powerBi: frelopowerBi, ...freloRest } = state[
      "adminEntityManagement"
    ]["frelo"];

    // 3.) adminEntityManagement
    // power-gas -> remove powerBi
    let { powerBi: pgpowerBi, ...pgRest } = state["adminEntityManagement"][
      "power-gas"
    ];

    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...telRest,
        },
        frelo: {
          ...freloRest,
        },
        "power-gas": {
          ...pgRest,
        },
      },
    };
  },
  54: (state) => {
    // Add state/adminRoles -> entities
    return {
      ...state,
      adminRoles: {
        ...state["adminRoles"],
        entities: {},
      },
    };
  },
  55: (state) => {
    // Add state/users -> entities: {}
    return {
      ...state,
      admin: {
        ...state["admin"],
        users: {
          ...state["admin"]["users"],
          entities: {},
        },
      },
    };
  },
  56: (state) => {
    // Add state/admin -> notifications: {
    // all: []
    // }
    return {
      ...state,
      admin: {
        ...state["admin"],
        notifications: {
          all: [],
        },
      },
    };
  },
  57: (state) => {
    // Add state/adminSchema ->
    // notificationTypes: {
    //      FRELO: [],
    //      POWGAS: [],
    //      TEL: [],
    // }
    return {
      ...state,
      adminSchema: {
        ...state.adminSchema,
        notificationTypes: {
          FRELO: [],
          POWGAS: [],
          TEL: [],
        },
      },
    };
  },
  58: (state) => {
    // Add state/adminSchema ->
    // notificationFormFields: {
    //      FRELO: {},
    //      POWGAS: {},
    //      TEL: {},
    // }
    return {
      ...state,
      adminSchema: {
        ...state.adminSchema,
        notificationFormFields: {
          FRELO: {},
          POWGAS: {},
          TEL: {},
        },
      },
    };
  },
  59: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        notifications: {
          notifications: [],
          notificationsLoading: {
            GET: false,
            PUT: false,
          },
        },
      },
    };
  },
  60: (state) => {
    return {
      ...state,
      adminSchema: {
        ...state.adminSchema,
        recipientTypes: {
          FRELO: {},
          POWGAS: {},
          TEL: {},
        },
      },
    };
  },
  61: (state) => {
    return {
      ...state,
      adminSchema: {
        ...state.adminSchema,
        recipientTypes: {
          FRELO: [],
          POWGAS: [],
          TEL: [],
        },
      },
    };
  },
  62: (state) => {
    return {
      ...state,
      adminSchema: {
        ...state.adminSchema,
        notificationFormFields: {
          FRELO: [],
          POWGAS: [],
          TEL: [],
        },
      },
    };
  },
  63: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            ...state.admin.entity.schema,
            subAccounts: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  64: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          rdaControl: {
            loading: false,
            telecom: {},
            frelo: {},
            "power-gas": {},
          },
        },
      },
    };
  },
  65: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            ...state.admin.entity.schema,
            rdaOptions: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  66: (state) => {
    // 1.) adminEntityManagement
    // telecom -> remove rdaControl
    let { rdaControl: telRdaControl, ...telRest } = state[
      "adminEntityManagement"
    ]["telecom"];

    // 2.) adminEntityManagement
    // frelo -> remove rdaControl
    let { rdaControl: freloRdaControl, ...freloRest } = state[
      "adminEntityManagement"
    ]["frelo"];

    // 3.) adminEntityManagement
    // power-gas -> remove rdaControl
    let { rdaControl: pgRdaControl, ...pgRest } = state[
      "adminEntityManagement"
    ]["power-gas"];

    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...telRest,
        },
        frelo: {
          ...freloRest,
        },
        "power-gas": {
          ...pgRest,
        },
      },
    };
  },
  67: (state) => {
    // 1.) adminEntityManagement
    // telecom/schema -> remove rdaOptions
    let { rdaOptions: telRdaOptions, ...telSchemaRest } = state[
      "adminEntityManagement"
    ]["telecom"]["schema"];
    // 2.) adminEntityManagement
    // power-gas/schema -> remove rdaOptions
    let { rdaOptions: pgRdaOptions, ...pgSchemaRest } = state[
      "adminEntityManagement"
    ]["power-gas"]["schema"];
    // 3.) adminEntityManagement
    // frelo-gas/schema -> remove rdaOptions
    let { rdaOptions: freloRdaOptions, ...freloSchemaRest } = state[
      "adminEntityManagement"
    ]["frelo"]["schema"];

    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...state.adminEntityManagement.telecom,
          schema: {
            ...telSchemaRest,
          },
        },
        frelo: {
          ...state.adminEntityManagement.frelo,
          schema: {
            ...freloSchemaRest,
          },
        },
        "power-gas": {
          ...state.adminEntityManagement["power-gas"],
          schema: {
            ...pgSchemaRest,
          },
        },
      },
    };
  },
  68: (state) => {
    // 1.) adminEntityManagement
    // telecom/schema -> remove subaccountOptions
    let { subaccountOptions: telsubaccountOptions, ...telSchemaRest } = state[
      "adminEntityManagement"
    ]["telecom"]["schema"];
    // 2.) adminEntityManagement
    // power-gas/schema -> remove subaccountOptions
    let { subaccountOptions: pgsubaccountOptions, ...pgSchemaRest } = state[
      "adminEntityManagement"
    ]["power-gas"]["schema"];
    // 3.) adminEntityManagement
    // frelo-gas/schema -> remove subaccountOptions
    let {
      subaccountOptions: frelosubaccountOptions,
      ...freloSchemaRest
    } = state["adminEntityManagement"]["frelo"]["schema"];

    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...state.adminEntityManagement.telecom,
          schema: {
            ...telSchemaRest,
          },
        },
        frelo: {
          ...state.adminEntityManagement.frelo,
          schema: {
            ...freloSchemaRest,
          },
        },
        "power-gas": {
          ...state.adminEntityManagement["power-gas"],
          schema: {
            ...pgSchemaRest,
          },
        },
      },
    };
  },
  69: (state) => {
    // 1.) admin/entity add ->
    // billingTypes: {
    //   loading: false,
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },

    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          billingTypes: {
            loading: false,
            telecom: {},
            frelo: {},
            "power-gas": {},
          },
        },
      },
    };
  },
  70: (state) => {
    // 1.) adminEntityManagement
    // telecom -> remove billingTypes
    let { billingTypes: telbillingTypes, ...telRest } = state[
      "adminEntityManagement"
    ]["telecom"];

    // // 2.) adminEntityManagement
    // // frelo -> remove billingTypes
    let { billingTypes: frelobillingTypes, ...freloRest } = state[
      "adminEntityManagement"
    ]["frelo"];

    // // 3.) adminEntityManagement
    // // power-gas -> remove billingTypes
    let { billingTypes: pgbillingTypes, ...pgRest } = state[
      "adminEntityManagement"
    ]["power-gas"];

    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...telRest,
        },
        frelo: {
          ...freloRest,
        },
        "power-gas": {
          ...pgRest,
        },
      },
    };
  },
  71: (state) => {
    // 1.) adminEntityManagement
    // telecom/schema -> remove rdaIdsOptions
    let { rdaIdsOptions: telrdaIdsOptions, ...telSchemaRest } = state[
      "adminEntityManagement"
    ]["telecom"]["schema"];
    // // 2.) adminEntityManagement
    // // power-gas/schema -> remove rdaIdsOptions
    let { rdaIdsOptions: pgrdaIdsOptions, ...pgSchemaRest } = state[
      "adminEntityManagement"
    ]["power-gas"]["schema"];
    // // 3.) adminEntityManagement
    // // frelo-gas/schema -> remove rdaIdsOptions
    let { rdaIdsOptions: frelordaIdsOptions, ...freloSchemaRest } = state[
      "adminEntityManagement"
    ]["frelo"]["schema"];
    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...state.adminEntityManagement.telecom,
          schema: {
            ...telSchemaRest,
          },
        },
        frelo: {
          ...state.adminEntityManagement.frelo,
          schema: {
            ...freloSchemaRest,
          },
        },
        "power-gas": {
          ...state.adminEntityManagement["power-gas"],
          schema: {
            ...pgSchemaRest,
          },
        },
      },
    };
  },
  72: (state) => {
    // 1.) adminEntityManagement
    // telecom/schema -> remove billingTypeOptions
    let { billingTypeOptions: telbillingTypeOptions, ...telSchemaRest } = state[
      "adminEntityManagement"
    ]["telecom"]["schema"];
    // // 2.) adminEntityManagement
    // // power-gas/schema -> remove billingTypeOptions
    let { billingTypeOptions: pgbillingTypeOptions, ...pgSchemaRest } = state[
      "adminEntityManagement"
    ]["power-gas"]["schema"];
    // // 3.) adminEntityManagement
    // // frelo-gas/schema -> remove billingTypeOptions
    let {
      billingTypeOptions: frelobillingTypeOptions,
      ...freloSchemaRest
    } = state["adminEntityManagement"]["frelo"]["schema"];

    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...state.adminEntityManagement.telecom,
          schema: {
            ...telSchemaRest,
          },
        },
        frelo: {
          ...state.adminEntityManagement.frelo,
          schema: {
            ...freloSchemaRest,
          },
        },
        "power-gas": {
          ...state.adminEntityManagement["power-gas"],
          schema: {
            ...pgSchemaRest,
          },
        },
      },
    };
  },
  73: (state) => {
    // 1.) admin/entity add ->
    // subAccounts: {
    //   loading: false,
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },

    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          subAccounts: {
            loading: false,
            telecom: {},
            frelo: {},
            "power-gas": {},
          },
        },
      },
    };
  },
  74: (state) => {
    // 1.) adminEntityManagement
    // telecom -> remove subAccounts
    let { subAccounts: telsubAccounts, ...telRest } = state[
      "adminEntityManagement"
    ]["telecom"];

    // // 2.) adminEntityManagement
    // // frelo -> remove subAccounts
    let { subAccounts: frelosubAccounts, ...freloRest } = state[
      "adminEntityManagement"
    ]["frelo"];

    // // 3.) adminEntityManagement
    // // power-gas -> remove subAccounts
    let { subAccounts: pgsubAccounts, ...pgRest } = state[
      "adminEntityManagement"
    ]["power-gas"];

    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...telRest,
        },
        frelo: {
          ...freloRest,
        },
        "power-gas": {
          ...pgRest,
        },
      },
    };
  },
  75: (state) => {
    // 1.) admin/entity/schema add ->
    // subAccountsRda: {
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },

    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            ...state.admin.entity.schema,
            subAccountsRda: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  76: (state) => {
    // 1.) adminEntityManagement
    // telecom -> remove schema

    let { schema: telschema, ...telRest } = state["adminEntityManagement"][
      "telecom"
    ];

    // 2.) adminEntityManagement
    // power-gas -> remove schema
    let { schema: pgschema, ...pgRest } = state["adminEntityManagement"][
      "power-gas"
    ];
    // 3.) adminEntityManagement
    // frelo-gas -> remove schema
    let { schema: freloschema, ...freloRest } = state["adminEntityManagement"][
      "frelo"
    ];

    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...telRest,
        },
        frelo: {
          ...freloRest,
        },
        "power-gas": {
          ...pgRest,
        },
      },
    };
  },
  77: (state) => {
    // 1.) adminEntityManagement
    // telecom -> remove contacts

    let { contacts: telcontacts, ...telRest } = state["adminEntityManagement"][
      "telecom"
    ];

    // 2.) adminEntityManagement
    // power-gas -> remove contacts
    let { contacts: pgcontacts, ...pgRest } = state["adminEntityManagement"][
      "power-gas"
    ];
    // 3.) adminEntityManagement
    // frelo-gas -> remove contacts
    let { contacts: frelocontacts, ...freloRest } = state[
      "adminEntityManagement"
    ]["frelo"];

    return {
      ...state,
      adminEntityManagement: {
        telecom: {
          ...telRest,
        },
        frelo: {
          ...freloRest,
        },
        "power-gas": {
          ...pgRest,
        },
      },
    };
  },
  78: (state) => {
    // 1.) add -> telecom/claim
    // jobCosting: {
    //   laborRateCardContractor: {},
    //   laborRateCardMaterial: {},
    //   laborRateCardUsic: {},
    //   laborRateCard: {},
    //   invoice: {},
    // },
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          jobCosting: {
            laborRateCardContractor: {},
            laborRateCardMaterial: {},
            laborRateCardUsic: {},
            laborRateCard: {},
            invoice: {},
          },
        },
      },
    };
  },
  79: (state) => {
    // 1.) add -> powerGas/claim
    // jobCosting: {
    //   invoice: {},
    // },
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          jobCosting: {
            invoice: {},
          },
        },
      },
    };
  },
  80: (state) => {
    // 1.) add -> admin/entity
    // docPaymentTemplate: {
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          docPaymentTemplate: {
            telecom: {},
            frelo: {},
            "power-gas": {},
          },
        },
      },
    };
  },
  81: (state) => {
    // 1.) add -> telecom/claim
    // paymentDoc: {
    // },
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          paymentDoc: {},
        },
      },
    };
  },
  82: (state) => {
    // 1.) add -> powerGas/claim
    // paymentDoc: {
    // },
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          paymentDoc: {},
        },
      },
    };
  },
  83: (state) => {
    // 1.) add -> telecom/
    // dispatch: {
    // all: {
    //  }
    // }

    return {
      ...state,
      telecom: {
        ...state.telecom,
        dispatch: {
          all: {},
        },
      },
    };
  },
  84: (state) => {
    // 1.) add -> powerGas/
    // dispatch: {
    // all: {
    //  }
    // }

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        dispatch: {
          all: {},
        },
      },
    };
  },
  85: (state) => {
    // 1.) add -> telecom/dispatch/
    // individual: {
    //  }

    return {
      ...state,
      telecom: {
        ...state.telecom,
        dispatch: {
          ...state.telecom.dispatch,
          individual: {},
        },
      },
    };
  },
  86: (state) => {
    // 1.) add -> powerGas/dispatch/
    // individual: {
    //  }

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        dispatch: {
          ...state.powerGas.dispatch,
          individual: {},
        },
      },
    };
  },
  87: (state) => {
    // 1.) add -> auth
    // username: {
    //  }
    return {
      ...state,
      auth: {
        ...state.auth,
        username: "",
      },
    };
  },
  88: (state) => {
    // 1.) add -> telecom/
    // reports: {
    // all: []
    // }

    return {
      ...state,
      telecom: {
        ...state.telecom,
        reports: {
          all: [],
          loading: false,
        },
      },
    };
  },
  89: (state) => {
    // 1.) add -> powerGas/
    // reports: {
    // all: []
    // }

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        reports: {
          all: [],
          loading: false,
        },
      },
    };
  },
  90: (state) => {
    // 1.) add -> telecom/reports/
    // individual: {
    //  }

    return {
      ...state,
      telecom: {
        ...state.telecom,
        reports: {
          ...state.telecom.reports,
          individual: {},
        },
      },
    };
  },
  91: (state) => {
    // 1.) add -> powerGas/reports/
    // individual: {
    //  }

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        reports: {
          ...state.powerGas.reports,
          individual: {},
        },
      },
    };
  },
  92: (state) => {
    // 1.) add -> telecom/reports/
    // fields: {
    //  }

    return {
      ...state,
      telecom: {
        ...state.telecom,
        reports: {
          ...state.telecom.reports,
          fields: {},
        },
      },
    };
  },
  93: (state) => {
    // 1.) add -> powerGas/reports/
    // fields: {
    //  }

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        reports: {
          ...state.powerGas.reports,
          fields: {},
        },
      },
    };
  },
  94: (state) => {
    // 1.) add -> telecom/reports/
    // scoreboard: {
    //  }

    return {
      ...state,
      telecom: {
        ...state.telecom,
        reports: {
          ...state.telecom.reports,
          scoreboard: {},
        },
      },
    };
  },
  95: (state) => {
    // 1.) add -> powerGas/reports/
    // scoreboard: {
    //  }

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        reports: {
          ...state.powerGas.reports,
          scoreboard: {},
        },
      },
    };
  },
  96: (state) => {
    // 1.) add -> telecom/reports/
    // aList: {
    //  }

    return {
      ...state,
      telecom: {
        ...state.telecom,
        reports: {
          ...state.telecom.reports,
          aList: {},
        },
      },
    };
  },
  97: (state) => {
    // 1.) add -> telecom/reports/
    // aList: {
    //  loading: false
    //  }

    return {
      ...state,
      telecom: {
        ...state.telecom,
        reports: {
          ...state.telecom.reports,
          aList: {
            loading: false,
          },
        },
      },
    };
  },
  98: (state) => {
    // 1.) add -> powerGas/reports/
    // aList: {
    //  loading: false
    //  }

    return {
      ...state,
      telecom: {
        ...state.powerGas,
        reports: {
          ...state.powerGas.reports,
          aList: {
            loading: false,
          },
        },
      },
    };
  },
  99: (state) => {
    // 1.) add -> frelo/
    // reports: {
    // all: []
    // }

    return {
      ...state,
      frelo: {
        ...state.frelo,
        reports: {
          all: [],
          loading: false,
        },
      },
    };
  },
  100: (state) => {
    // 1.) add -> frelo/reports/
    // fields: {
    //  }

    return {
      ...state,
      frelo: {
        ...state.frelo,
        reports: {
          ...state.frelo.reports,
          fields: {},
        },
      },
    };
  },
  101: (state) => {
    // 1.) add -> frelo/reports/
    // individual: {
    //  }

    return {
      ...state,
      frelo: {
        ...state.frelo,
        reports: {
          ...state.frelo.reports,
          individual: {},
        },
      },
    };
  },
  102: (state) => {
    // 1.) add -> admin/
    // roundRobin: {
    // all: []
    //  }

    return {
      ...state,
      admin: {
        ...state.admin,
        roundRobin: {
          all: [],
        },
      },
    };
  },
  103: (state) => {
    // 1.) add -> admin/roundRobin
    // individual: {}

    return {
      ...state,
      admin: {
        ...state.admin,
        roundRobin: {
          ...state.admin.roundRobin,
          individual: {},
        },
      },
    };
  },
  104: (state) => {
    // 1.) add -> admin/roundRobin
    // locateSchema: []

    return {
      ...state,
      admin: {
        ...state.admin,
        roundRobin: {
          ...state.admin.roundRobin,
          locateSchema: [],
        },
      },
    };
  },
  105: (state) => {
    // 1.) add -> admin/roundRobin
    // entitySchema: []

    return {
      ...state,
      admin: {
        ...state.admin,
        roundRobin: {
          ...state.admin.roundRobin,
          entitySchema: [],
        },
      },
    };
  },
  106: (state) => {
    // 1.) add -> admin/roundRobin
    // individual: {
    //  loading: false
    // }

    return {
      ...state,
      admin: {
        ...state.admin,
        roundRobin: {
          ...state.admin.roundRobin,
          individual: {
            ...state.admin.roundRobin.individual,
            loading: false,
          },
        },
      },
    };
  },
  107: (state) => {
    // 1.) add -> admin/roundRobin
    // inactive: {
    //   loading: false,
    // },

    return {
      ...state,
      admin: {
        ...state.admin,
        roundRobin: {
          ...state.admin.roundRobin,
          inactive: {
            loading: false,
          },
        },
      },
    };
  },
  108: (state) => {
    // 1.) remove -> admin/entity
    // docPaymentTemplate: {
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },

    let { docPaymentTemplate, ...rest } = state.admin.entity;
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...rest,
        },
      },
    };
  },
  109: (state) => {
    // 1.) add -> admin/entity
    // docManagement: {
    //   loading: false,
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },

    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          docManagement: {
            loading: false,
            telecom: {},
            frelo: {},
            "power-gas": {},
          },
        },
      },
    };
  },
  110: (state) => {
    // 1.) add -> admin/entity/schema
    // docFieldMentions: {
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // }

    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            ...state.admin.entity.schema,
            docFieldMentions: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  111: (state) => {
    // 1.) remove -> telecom/claim
    // paymentDoc: {
    // },

    let { paymentDoc, ...rest } = state.telecom.claim;
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...rest,
        },
      },
    };
  },
  112: (state) => {
    // 1.) add -> telecom/claim
    // docs: {
    //   invoice: {},
    //   claimLetter: {},
    //   intentToBill: {},
    //   secondNotice: {},
    // },

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            invoice: {},
            claimLetter: {},
            intentToBill: {},
            secondNotice: {},
          },
        },
      },
    };
  },
  113: (state) => {
    // 1.) remove -> powerGas/claim
    // paymentDoc: {
    // },

    let { paymentDoc, ...rest } = state.powerGas.claim;
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...rest,
        },
      },
    };
  },
  114: (state) => {
    // 1.) add -> powerGas/claim
    // docs: {
    //   invoice: {},
    //   claimLetter: {},
    //   intentToBill: {},
    //   secondNotice: {},
    // },

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            invoice: {},
            claimLetter: {},
            intentToBill: {},
            secondNotice: {},
          },
        },
      },
    };
  },
  115: (state) => {
    // 1.) add -> admin/entity/schema
    // docClaimReasons: {
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },

    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            ...state.admin.entity.schema,
            docClaimReasons: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  116: (state) => {
    // 1.) add -> admin/entity/schema
    // docClaimLastSentences: {
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // }

    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            ...state.admin.entity.schema,
            docClaimLastSentences: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  117: (state) => {

    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            ...state.admin.entity.schema,
            docClaimContacts: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  118: (state) => {
    // 1.) add -> telecom/claim/docs/
    // schemaClaimLetterReasons: {}

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            schemaClaimLetterReasons: {},
          },
        },
      },
    };
  },
  119: (state) => {
    // 1.) add -> telecom/claim/docs/
    // schemaClaimLetterContacts: []

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            schemaClaimLetterContacts: [],
          },
        },
      },
    };
  },
  120: (state) => {
    // 1.) add -> telecom/claim/docs/
    // schemaClaimLetterLastSentence: {}

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            schemaClaimLetterLastSentence: {},
          },
        },
      },
    };
  },
  121: (state) => {
    // 1.) add -> powerGas/claim/docs/
    // schemaClaimLetterReasons: {}

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            ...state.powerGas.claim.docs,
            schemaClaimLetterReasons: {},
          },
        },
      },
    };
  },
  122: (state) => {
    // 1.) add -> powerGas/claim/docs/
    // schemaClaimLetterContacts: []

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            ...state.powerGas.claim.docs,
            schemaClaimLetterContacts: [],
          },
        },
      },
    };
  },
  123: (state) => {
    // 1.) add -> powerGas/claim/docs/
    // schemaClaimLetterLastSentence: {}

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            ...state.powerGas.claim.docs,
            schemaClaimLetterLastSentence: {},
          },
        },
      },
    };
  },
  124: (state) => {
    // 1.) add -> admin/entity/schema/
    // rdaOptionsRegions: {
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            ...state.admin.entity.schema,
            rdaOptionsRegions: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  125: (state) => {
    // 1.) add -> admin/entity/schema/
    // rdaOptionsDivisions: {
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            ...state.admin.entity.schema,
            rdaOptionsDivisions: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  126: (state) => {
    // 1.) add -> admin/entity/schema/
    // rdaOptionsAreas: {
    //   telecom: {},
    //   frelo: {},
    //   "power-gas": {},
    // },
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          schema: {
            ...state.admin.entity.schema,
            rdaOptionsAreas: {
              telecom: {},
              frelo: {},
              "power-gas": {},
            },
          },
        },
      },
    };
  },
  127: (state) => {
    // 1.) add -> telecom/claim/schema
    // attachmentImageCategories: []

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          schema: {
            ...state.telecom.claim.schema,
            attachmentImageCategories: [],
          },
        },
      },
    };
  },
  128: (state) => {
    // 1.) add -> telecom/claim/schema
    // attachmentVideoCategories: []

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          schema: {
            ...state.telecom.claim.schema,
            attachmentVideoCategories: [],
          },
        },
      },
    };
  },
  129: (state) => {
    // 1.) add -> telecom/claim/schema
    // attachmentDocumentCategories: {}

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          schema: {
            ...state.telecom.claim.schema,
            attachmentDocumentCategories: {},
          },
        },
      },
    };
  },
  130: (state) => {
    // 1.) add -> powerGas/claim/schema
    // attachmentImageCategories: []

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          schema: {
            ...state.powerGas.claim.schema,
            attachmentImageCategories: [],
          },
        },
      },
    };
  },
  131: (state) => {
    // 1.) add -> powerGas/claim/schema
    // attachmentVideoCategories: []

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          schema: {
            ...state.powerGas.claim.schema,
            attachmentVideoCategories: [],
          },
        },
      },
    };
  },
  132: (state) => {
    // 1.) add -> powerGas/claim/schema
    // attachmentDocumentCategories: {}

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          schema: {
            ...state.powerGas.claim.schema,
            attachmentDocumentCategories: {},
          },
        },
      },
    };
  },
  133: (state) => {
    // 1.) add -> powerGas/claim/docs
    // narrativeCreator: {}
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            ...state.powerGas.claim.docs,
            narrativeCreator: {},
          },
        },
      },
    };
  },
  134: (state) => {
    // 1.) add -> telecom/claim/docs
    // schemaDigLaws: {}
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            schemaDigLaws: {},
          },
        },
      },
    };
  },
  135: (state) => {
    // 1.) add -> telecom/claim/docs
    // narrativeCreator: {}
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            narrativeCreator: {},
          },
        },
      },
    };
  },
  136: (state) => {
    // 1.) add -> telecom/claim/docs
    // promissoryNote: {}
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            promissoryNote: {},
          },
        },
      },
    };
  },
  137: (state) => {
    // 1.) add -> powerGas/claim/docs
    // promissoryNote: {}
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            ...state.powerGas.claim.docs,
            promissoryNote: {},
          },
        },
      },
    };
  },
  138: (state) => {
    // 1.) add -> telecom/claim/docs
    // secondNotice: {}
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            secondNotice: {},
          },
        },
      },
    };
  },
  139: (state) => {
    // 1.) add -> powerGas/claim/docs
    // secondNotice: {}
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            ...state.powerGas.claim.docs,
            secondNotice: {},
          },
        },
      },
    };
  },
  140: (state) => {
    // 1.) add -> powerGas/claim/docs
    // releaseLetter: {}
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            ...state.powerGas.claim.docs,
            releaseLetter: {},
          },
        },
      },
    };
  },
  141: (state) => {
    // 1.) add -> telecom/claim/docs
    // releaseLetter: {}
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            releaseLetter: {},
          },
        },
      },
    };
  },
  142: (state) => {
    //   // 1.) add -> telecom/claim/docs
    //   // finalNotice: {}

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            finalNotice: {},
          },
        },
      },
    };
  },
  143: (state) => {
    //   // 1.) add -> powerGas/claim/docs
    //   // finalNotice: {}

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            ...state.powerGas.claim.docs,
            finalNotice: {},
          },
        },
      },
    };
  },
  144: (state) => {
    // 144: (state) => {
    //    1.) add -> powerGas/claim/docs
    //    legalFinalNotice: {}

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            ...state.powerGas.claim.docs,
            legalFinalNotice: {},
          },
        },
      },
    };
  },
  145: (state) => {
    // 144: (state) => {
    //    1.) add -> telecom/claim/docs
    //    legalFinalNotice: {}

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            legalFinalNotice: {},
          },
        },
      },
    };
  },
  146: (state) => {
    // 146: (state) => {
    //    1.) add -> powerGas/claim/docs
    //    paymentReceipt: {}

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            ...state.powerGas.claim.docs,
            paymentReceipt: {},
          },
        },
      },
    };
  },
  147: (state) => {
    // 147: (state) => {
    //    1.) add -> telecom/claim/docs
    //    paymentReceipt: {}

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            paymentReceipt: {},
          },
        },
      },
    };
  },
  148: (state) => {
    // 148: (state) => {
    //    1.) add -> telecom/claim/docs
    //    balanceOwed: {}

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            balanceOwed: {},
          },
        },
      },
    };
  },
  149: (state) => {
    // 149: (state) => {
    //    1.) add -> powerGas/claim/docs
    //    balanceOwed: {}

    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          docs: {
            ...state.powerGas.claim.docs,
            balanceOwed: {},
          },
        },
      },
    };
  },
  150: (state) => {
    // 1.) admin/users
    // Add -> userTypes: []
    return {
      ...state,
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          userTypes: [],
        },
      },
    };
  },
  151: (state) => {
    // 1.) admin/claimStatus
    // Add -> all: []
    return {
      ...state,
      admin: {
        ...state.admin,
        claimStatus: {
          ...state.admin.claimStatus,
          all: [],
        },
      },
    };
  },
  152: (state) => {
    // 1.) admin/claimStatus
    // Add -> one: []
    return {
      ...state,
      admin: {
        ...state.admin,
        claimStatus: {
          ...state.admin.claimStatus,
          one: [],
        },
      },
    };
  },
  153: (state) => {
    // 1.) admin/claimStatus
    // Add -> users: []
    return {
      ...state,
      admin: {
        ...state.admin,
        claimStatus: {
          ...state.admin.claimStatus,
          users: [],
        },
      },
    };
  },
  154: (state) => {
    // 1.) admin/claimStatus
    // Add -> statusAccess: []
    return {
      ...state,
      admin: {
        ...state.admin,
        claimStatus: {
          ...state.admin.claimStatus,
          statusAccess: [],
        },
      },
    };
  },
  155: (state) => {
    return { ...state, mobile: { loggedIn: false, loggingIn: false } };
  },
  156: (state) => {
    // 1.) add -> mobile
    // userId: ""
    return {
      ...state,
      mobile: {
        ...state.mobile,
        userId: "",
      },
    };
  },
  157: (state) => {
    // 1.) add -> mobile
    // myClaims: {
    //  }
    return {
      ...state,
      mobile: {
        ...state.mobile,
        myClaims: {},
      },
    };
  },
  158: (state) => {
    // 1.) add -> mobile
    // rdaOptions: {
    //  }
    return {
      ...state,
      mobile: {
        ...state.mobile,
        rdaOptions: {},
      },
    };
  },
  159: (state) => {
    // 1.) add root reducer
    // joint reports
    return {
      ...state,
      joint: {
        reports: {
          current: {},
          my: [],
          shared: [],
          procs: [],
          data: {
            preview: {},
            all: {},
          },
        },
        claimsQueue: {
          my: [],
          team: [],
          roleId: null,
          loading: true
        }
      },
    };
  },
  160: (state) => {
    // 1.) add -> claim
    // logo: {}
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          individual: {
            ...state.telecom.claim.individual,
            logo: {},
          },
        },
      },
    };
  },
  161: (state) => {
    // 1.) add -> telecom/claim
    // jobCosting: {
    //   laborRateCardContractor: {},
    //   laborRateCardMaterial: {},
    //   laborRateCardUsic: {},
    //   laborRateCard: {},
    // },
    return {
      ...state,
      mobile: {
        ...state.mobile,
        jobCosting: {
          laborRateCardContractor: {},
          laborRateCardMaterial: {},
          laborRateCardUsic: {},
          laborRateCard: {},
        },
      },
    };
  },
  162: (state) => {
    // 1.) claim > telecom: { mobile: {}, }

    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          mobile: {
            ...state["claim"]["telecom"]["myMobileClaims"],
          },
        },
      },
    };
  },
  163: (state) => {
    // 1.) add -> joint/reports/data/count
    // count: 0
    return {
      ...state,
      joint: {
        ...state.joint,
        reports: {
          ...state.reports,
          data: {
            ...state.data,
            count: 0,
          },
        },
      },
    };
  },

  164: (state) => {
    // 1.) admin/users
    // Add -> users: []
    return {
      ...state,
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          users: [],
        },
      },
    };
  },

  165: (state) => {
    // Add state/admin -> powerBiRoles
    return {
      ...state,
      admin: {
        ...state.admin,
        powerBiRoles: {
          all: [],
          general: {},
        },
      },
    };
  },

  166: (state) => {
    // add state/powerbi
    return {
      ...state,
      powerbi: {
        reports: {
          all: [],
          default: "",
          loading: false,
          individual: {},
        },
      },
    };
  },

  167: (state) => {
    // add state/admin/powerBiRoles -> rolesLoading
    // add state/admin/powerBiRoles -> saveLoading
    return {
      ...state,
      admin: {
        ...state.admin,
        powerBiRoles: {
          ...state.powerBiRoles,
          rolesLoading: false,
          saveLoading: false,
        },
      },
    };
  },

  168: (state) => {
    // add state/auth/powerBiRoleId
    return {
      ...state,
      auth: {
        ...state.auth,
        powerBiRoleId: null,
      },
    };
  },

  169: (state) => {
    // add lob > Entities
    return {
      ...state,
      adminEntityManagement: {
        ...state.adminEntityManagement,
        entities: {},
      },
    };
  },

  170: (state) => {
    // 1.) telecom/claim/myTeams
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          myTeams: {},
        },
      },
    };
  },

  171: (state) => {
    // adminRoles / actionAccess
    return {
      ...state,
      adminRoles: {
        ...state.adminRoles,
        actionAccess: {},
        actionAccessLoading: false,
      },
    };
  },

  172: (state) => {
    // adminRoles / actionAccessLoading / GET and PUT
    return {
      ...state,
      adminRoles: {
        ...state.adminRoles,
        actionAccessLoading: {
          GET: false,
          PUT: false,
        },
      },
    };
  },

  173: (state) => {
    // permission / actionAccess
    return {
      ...state,
      permission: {
        ...state.permission,
        actionAccess: {},
      },
    };
  },

  174: (state) => {
    // admin / users / actionAccess
    return {
      ...state,
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          actionAccess: {},
          actionAccessLoading: {
            GET: false,
            PUT: false,
            DELETE: false,
          },
        },
      },
    };
  },

  175: (state) => {
    // 1.) powerGas/claim/myTeams
    return {
      ...state,
      powerGas: {
        ...state.powerGas,
        claim: {
          ...state.powerGas.claim,
          myTeams: {},
        },
      },
    };
  },

  176: (state) => {
    // adminRoles / statusAccess
    // admin / users / statusAccess
    // permission / statusAccess
    return {
      ...state,
      adminRoles: {
        ...state.adminRoles,
        statusAccess: {},
        statusAccessLoading: {
          GET: false,
          PUT: false,
        },
      },
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          statusAccess: {},
          statusAccessLoading: {
            GET: false,
            PUT: false,
            DELETE: false,
          },
        },
      },
      permission: {
        ...state.permission,
        statusAccess: {},
      },
    };
  },

  177: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          damageInvestigator: {},
          damageInvestigatorLoading: {
            GET: false,
            PUT: false,
            DELETE: false,
          },
        },
      },
    };
  },

  178: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        users: {
          ...state.admin.users,
          notifications: {},
          notificationsLoading: {
            GET: false,
            PUT: false,
            DELETE: false,
          },
        },
      },
      adminRoles: {
        ...state.adminRoles,
        notifications: {},
        notificationsLoading: {
          GET: false,
          PUT: false,
          DELETE: false,
        },
      },
    };
  },

  179: (state) => {
    // 1.) adminEntityManagement/telecom
    // Add -> paymentFormFees: {}
    return {
      ...state,
      adminEntityManagement: {
        ...state.adminEntityManagement,
        telecom: {
          ...state.adminEntityManagement.telecom,
          paymentFormFees: {},
        },
      },
    };
  },
  180: (state) => {
    // 1.) adminEntityManagement/power-gas
    // Add -> paymentFormFees: {}
    return {
      ...state,
      adminEntityManagement: {
        ...state.adminEntityManagement,
        "power-gas": {
          ...state.adminEntityManagement["power-gas"],
          paymentFormFees: {},
        },
      },
    };
  },
  181: (state) => {
    // 1.) adminEntityManagement/frelo
    // Add -> paymentFormFees: {}
    return {
      ...state,
      adminEntityManagement: {
        ...state.adminEntityManagement,
        frelo: {
          ...state.adminEntityManagement["frelo"],
          paymentFormFees: {},
        },
      },
    };
  },
  182: (state) => {
    return {
      ...state,
      permission: {
        ...state.permission,
        activeLinks: {},
      },
    };
  },
  183: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        states: {},
      },
    };
  },
  184: (state) => {
    return {
      ...state,
      permission: {
        ...state.permission,
        formControl: {},
      },
    };
  },
  185: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        formControl: {},
      },
    };
  },
  186: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        formControl: {
          ...state.admin.formControl,
          fields: {
            1: {},
            2: {},
            3: {},
          },
        },
      },
    };
  },
  // add "loading" to fields obj
  // add rules obj to formControl obj
  187: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        formControl: {
          ...state.admin.formControl,
          fields: {
            ...state.admin.formControl.fields,
            loading: false,
          },
          rules: {
            loading: false,
            1: {
              entityRules: {},
              rolerules: {},
            }, // Tel
            2: {
              entityRules: {},
              rolerules: {},
            }, // PowGas
            3: {
              entityRules: {},
              rolerules: {},
            }, // frelo
          },
        },
      },
    };
  },
  // fix typo rolerules -> roleRules
  188: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        formControl: {
          ...state.admin.formControl,
          rules: {
            1: {
              entityRules: {},
              roleRules: {},
            }, // Tel
            2: {
              entityRules: {},
              roleRules: {},
            }, // PowGas
            3: {
              entityRules: {},
              roleRules: {},
            }, // frelo
          },
        },
      },
    };
  },
   // change formatting
   189: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        formControl: {
          ...state.admin.formControl,
          rules: {
            1: {}, // Tel
            2: {}, // PowGas
            3: {}, // frelo
          },
        },
      },
    };
  },
   // entity management > invoicing
   190: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        entity: {
          ...state.admin.entity,
          invoicing: {
            loading: false,
            telecom: {},
            frelo: {},
            "power-gas": {},
          }
        },
      },
    };
  },
  191: (state) => {
    return {
      ...state,
      telecom: {
        ...state.telecom,
        claim: {
          ...state.telecom.claim,
          docs: {
            ...state.telecom.claim.docs,
            schemaClaimLetterReasons: []
          },
        },
      },
    };
  },
  192: (state) => {
    return {
      ...state,
      admin: {
        ...state.admin,
        jobCosting: {
          loading: false,
          rates: [],
          rdaOptions: []
        }
      }
    }
  },
  193: (state) => {
    return {
      ...state,
      payment: {
        loading: false,
        info: {}
      }
    }
  },
  194: (state) => {
    return {
      ...state,
      mobile: {
        loggedIn: false,
        loggingIn: false,
        userId: "",
        entityID: "",
        entityLogo: "",
        myClaims: {},
        form: {},
        formLoading: false,
        meta: {},
        convertLoading: false,
        convertClaimID: null,
        attachments: [],
        allClaims: [],
        allClaimsLoading: false,
        deleteLoading: false
      }
    }
  },
  195: (state) => {
    return {
      ...state,
      joint: {
      ...state.joint,
      reports: {
        ...state.joint.reports,
        remittance: {
          loading: false,
          all: {}
        }
      }
    }
    }
  }
};
export default migrations;
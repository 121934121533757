import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import styled from "styled-components";
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BugReportIcon from "@material-ui/icons/BugReport";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { userActions } from "_actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ObjectTools } from "_utility";

import Assume from "./Assume";

const StyledMenu = withStyles({
  paper: {
    borderTop: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.GrayPrg.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.DarkGrayPrg.main,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  profile: {
    color: theme.palette.DarkGrayPrg.main,
    height: "100%",
    width: "150px",
    cursor: "pointer",
  },
  IconWrap: {
    marginTop: "5px",
    paddingRight: "10px",
  },
  dropdown: {
    "& ul": {
      padding: 0,
    },
    borderRadius: 0,
    width: 160,
    marginLeft: 15,
  },
  buttonWrap: {
    height: "100%",
    padding: "0 5px",
    backgroundColor: theme.palette.GrayPrg.main,
    position: "absolute",
    right: 0,
    top: 0,
  },
}));

export default function Profile() {
  const classes = useStyles();
  const history = useHistory();
  const { getNestedPropertySafe } = ObjectTools;

  const [anchorEl, setAnchorEl] = useState(null);
  const [openAssume, setOpenAssume] = useState(false);

  const dispatch = useDispatch();

  const { username } = useSelector((state) =>
    getNestedPropertySafe(state, ["auth"])
  );

  const assumeRoles = useSelector((state) => getNestedPropertySafe(state, ["auth", "role", "assumeRoles"]));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let handleLogout = () => {
    dispatch(userActions.logout());
    //@ todo transfer history push into saga
    history.push("/login");
  };
  let handleProfile = () => {
    history.push("/dashboard/profile");
  };

  const handleAssume = () => {
    setOpenAssume(!openAssume)
  }
  return (
    <ProfileWrapper>
      <div className={classes.buttonWrap}>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          color="primary"
          className={classes.profile}
          onClick={handleClick}
        >
          {username}
        </Button>
      </div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classes.dropdown,
        }}
      >
        <StyledMenuItem onClick={() => handleProfile()}>
          <div className={classes.IconWrap}>
            <PersonOutlineIcon className={classes.Icon} />
          </div>
          <ListItemText primary="Profile" />
        </StyledMenuItem>

        {assumeRoles &&
          <StyledMenuItem onClick={handleAssume}>
            <div className={classes.IconWrap}>
              <SocialDistanceIcon className={classes.Icon} />
            </div>
            <ListItemText primary="Role/User" />
          </StyledMenuItem>
        }

        <StyledMenuItem onClick={() => handleLogout()}>
          <div className={classes.IconWrap}>
            <ExitToAppIcon className={classes.Icon} />
          </div>
          <ListItemText primary="Logout" />
        </StyledMenuItem>
      </StyledMenu>


      <Assume
        openModal={openAssume}
        setOpenModal={setOpenAssume}
      />
    </ProfileWrapper>
  );
}

export const ProfileWrapper = styled.div`
  margin: 0 auto;
  padding: 0 0 0 15px;
`;

import { adminStatesConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";


let initState = {
  all: []
};

export function states(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminStatesConstants.GET_ALL_STATES_SUCCESS:
      return produce(state, (draft) => {
        draft["all"] = payload;
      });
    
    case REHYDRATE: {
      if (payload && payload.admin && payload.admin.states) {
        return { ...payload.admin.states };
      } else {
        return { ...initState };
      }
    }
    case adminStatesConstants.ADMIN_STATES_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

import { put, call, all } from "redux-saga/effects";
import { alertConstants, authConstants, mobileConstants } from "_constants";
import { isEmpty, hasIn } from "ramda";
import { mobileService, cookieService } from "_services";
import { buildAlertMessage } from "_utility";

export function* loginMobileSaga(payload) {
  try {
    const response = yield call(mobileService.loginMobile, payload);
    if (isEmpty(response)) {
      throw new Error("Error authenticating user");
    }

    // ----- Note ------
    // Due to Cors, we set the cookie manually on localhost,
    // in production the node api sets the cookie
    if (
      (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
      (!process.env.REACT_APP_TEST_API_CALLS || process.env.REACT_APP_TEST_API_CALLS === "false")
    ) {
      if (!hasIn("Token", response)) {
        throw new Error("Error authenticating user");
      }
      yield call(cookieService.setMobile, "token", `${response.Token}`);
    }

    yield put({
      type: mobileConstants.LOGIN_SUCCESS,
      payload: {
        userId: payload.payload,
        entityID: response.entityID,
        entityLogo: response.entityLogo,
      },
    });
  } catch (error) {
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
    yield put({ type: mobileConstants.LOGIN_FAILURE });
  }
}

export function* logoutMobileSaga() {
  try {
    yield call(cookieService.removeMobile, "token");

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Logged out" },
    });
    localStorage.clear();
  } catch (error) {
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getPreClaimsMobileSaga(payload) {
  try {
    // GET Mobile pre-claims
    const response = yield call(mobileService.getMyclaims, encodeURI(cookieService.getMobile("token")));
    let email = payload.payload;

    yield put({
      type: mobileConstants.GET_PRECLAIMS_MOBILE_SUCCESS,
      payload: { email, data: { ...response } },
    });
  } catch (error) {
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
    yield put({ type: mobileConstants.LOGIN_FAILURE });
  }
}

export function* getAllMobileClaimsSaga() {
  try {
    const response = yield call(mobileService.getAllMobileClaims);

    yield put({
      type: mobileConstants.GET_ALL_MOBILE_CLAIMS_SUCCESS,
      payload: { data: [...response] },
    });
  } catch (error) {
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
    yield put({ type: mobileConstants.LOGIN_FAILURE });
  }
}

// export function* getMobileRdaOptionsTelecomSaga({ entityID }) {
//   try {
//     const response = yield call(mobileService.getSchemaRdaOptions, entityID);

//     if (!Array.isArray(response)) {
//       throw new Error("Error fetching rda options");
//     }

//     yield put({
//       type: mobileConstants.GET_RDA_MOBILE_SUCCESS,
//       payload: { entityID, options: [...response] },
//     });
//   } catch (error) {
//     if (error.message === "Invalid Auth Token") {
//       yield put({
//         type: authConstants.LOGOUT,
//       });
//     }
//     yield put({
//       type: alertConstants.ERROR,
//       payload: { message: error.message },
//     });
//   }
// }

export function* getInitialMobileSaga() {
  try {
    const response = yield call(mobileService.getInitial);

    const Basic = response.response;

    yield put({
      type: mobileConstants.GET_INITIAL_MOBILE_SUCCESS,
      payload: { data: { ...Basic } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* createInitialMobileSaga({ payload, entityID }) {
  try {
    yield call(mobileService.createInitial, payload, entityID);
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "New Mobile Claim Created" },
    });

    // GET Mobile pre-claims
    const response = yield call(mobileService.getMyclaims, encodeURI(cookieService.getMobile("token")));
    let email = payload.email;
    yield put({
      type: mobileConstants.GET_PRECLAIMS_MOBILE_SUCCESS,
      payload: { email, data: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getFullMobileSaga({ mobileclaimid, internal }) {
  try {
    const response = yield call(mobileService.getFull, mobileclaimid, internal);

    const form = response.response;
    const claimID = response.claimID;

    yield put({
      type: mobileConstants.GET_FULL_MOBILE_SUCCESS,
      payload: { data: { ...form }, claimID },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateFullMobileSaga({ payload, mobileclaimid, internal }) {
  try {
    yield call(mobileService.updateFull, payload, mobileclaimid, internal);

    // refresh form data
    const response = yield call(mobileService.getFull, mobileclaimid, internal);

    const form = response.response;

    yield put({
      type: mobileConstants.GET_FULL_MOBILE_SUCCESS,
      payload: { mobileclaimid, data: { ...form } },
    });

    if (internal) {
      yield put({
        type: alertConstants.SUCCESS,
        payload: { message: "Mobile claim updated!" },
      });
    }
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* createMoveToPrgMobileSaga({ mobileclaimid, lobid }) {
  try {
    const responseConvert = yield call(mobileService.createMoveToPrg, mobileclaimid, lobid);

    yield put({
      type: mobileConstants.CREATE_MOVE_TO_PRG_MOBILE_SUCCESS,
      payload: { claimID: responseConvert.claimId },
    });

    // // GET mobile claims
    // const response = yield call(mobileService.getMyclaims, encodeURI(cookieService.getMobile("token")));
    // yield put({
    //   type: mobileConstants.GET_PRECLAIMS_MOBILE_SUCCESS,
    //   payload: { email, data: { ...response } },
    // });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Claim completed!" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getAttachmentsMobileSaga({ mobileclaimid, internal }) {
  try {
    const response = yield call(mobileService.getFiles, mobileclaimid, internal);

    yield put({
      type: mobileConstants.GET_MOBILE_ATTACHMENTS_SUCCESS,
      payload: { files: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* uploadAttachmentMobileSaga({ payload }) {
  const { files, mobileClaimID, claimID, categoryID, userEmail, internal } = payload;
  try {
    const responses = yield all(
      files.map((image) => call(mobileService.uploadFile, image, mobileClaimID, claimID, categoryID, userEmail, internal))
    );

    if (isEmpty(responses)) {
      throw new Error("Error uploading files");
    }

    let statusMessage = buildAlertMessage("Uploads", responses);

    const response = yield call(mobileService.getFiles, mobileClaimID, internal);

    yield put({
      type: mobileConstants.GET_MOBILE_ATTACHMENTS_SUCCESS,
      payload: { files: [...response] },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: "File(s) uploaded successfully",
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deleteMobileClaimSaga({ payload }) {
  const mobileclaimid = payload;

  try {
    yield call(mobileService.deleteMobileClaim, mobileclaimid);

    const response = yield call(mobileService.getAllMobileClaims);

    yield put({
      type: mobileConstants.GET_ALL_MOBILE_CLAIMS_SUCCESS,
      payload: { data: [...response] },
    });

    yield put({
      type: mobileConstants.DELETE_MOBILE_CLAIM_SUCCESS,
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Mobile claim deleted!" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

// Rate Card
// export function* getClaimRateCardContractorMobileSaga({ claimId }) {
//   try {
//     const response = yield call(mobileService.getClaimJobCostingRateCardContractor, claimId);

//     yield put({
//       type: mobileConstants.GET_JOB_COSTING_LABOR_RATE_CARD_CONTRACTOR_MOBILE_SUCCESS,
//       payload: { claimId, data: [...response] },
//     });
//   } catch (error) {
//     if (error.message === "Invalid Auth Token") {
//       yield put({
//         type: authConstants.LOGOUT,
//       });
//     }
//     yield put({
//       type: alertConstants.ERROR,
//       payload: { message: error.message },
//     });
//   }
// }

// export function* getClaimRateCardMaterialMobileSaga({ claimId }) {
//   try {
//     const responseData = yield call(mobileService.getClaimJobCostingRateCardMaterial, claimId);

//     let { response } = responseData;

//     yield put({
//       type: mobileConstants.GET_JOB_COSTING_LABOR_RATE_CARD_MATERIAL_MOBILE_SUCCESS,
//       payload: { claimId, data: [...response] },
//     });
//   } catch (error) {
//     if (error.message === "Invalid Auth Token") {
//       yield put({
//         type: authConstants.LOGOUT,
//       });
//     }
//     yield put({
//       type: alertConstants.ERROR,
//       payload: { message: error.message },
//     });
//   }
// }

// export function* getClaimRateCardLaborMobileSaga({ claimId }) {
//   try {
//     const responseData = yield call(mobileService.getClaimJobCostingRateCardLabor, claimId);

//     let { response } = responseData;

//     yield put({
//       type: mobileConstants.GET_JOB_COSTING_LABOR_RATE_CARD_LABOR_MOBILE_SUCCESS,
//       payload: { claimId, data: [...response] },
//     });
//   } catch (error) {
//     if (error.message === "Invalid Auth Token") {
//       yield put({
//         type: authConstants.LOGOUT,
//       });
//     }
//     yield put({
//       type: alertConstants.ERROR,
//       payload: { message: error.message },
//     });
//   }
// }

import { adminStatesConstants } from "_constants";

const getAllStates = () => {
  return {
    type: adminStatesConstants.GET_ALL_STATES_REQUEST,
  };
};


export const adminStatesActions = {
  getAllStates
};

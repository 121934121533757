import { schema } from "normalizr";

// My Claims
const myClaim = new schema.Entity("claims", {}, { idAttribute: "claimId" });
const myClaimsSchema = new schema.Entity(
  "page",
  {
    claims: [myClaim],
  },
  { idAttribute: "page" }
);

// All Claims
const allClaim = new schema.Entity("claims", {}, { idAttribute: "claimId" });
const allClaimsSchema = new schema.Entity(
  "page",
  {
    claims: [allClaim],
  },
  { idAttribute: "page" }
);

export const powerGasClaimSchema = {
  myClaimsSchema,
  allClaimsSchema,
};

import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import BackupIcon from "@material-ui/icons/Backup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DropzoneDialog } from "material-ui-dropzone";
import AttachmentTable from "./AttachmentTable";
import { mobileActions } from "_actions";
import { useDispatch, useSelector } from "react-redux";
import { ObjectTools } from "_utility";
const { getNestedPropertySafe } = ObjectTools;

const useStyles = makeStyles((theme) => ({
  upload: {
    marginTop: "30px",
  },
  Icon: {
    marginLeft: 10,
  },
}));

export default function Documents({ mobileClaimID, claimID }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const email = useSelector((state) => getNestedPropertySafe(state, ["mobile", "userId"]));

  const handleUploadSave = (files) => {
    const payload = {
      mobileClaimID: mobileClaimID,
      claimID: claimID,
      files: files,
      categoryID: 16,
      userEmail: email,
    };
    dispatch(mobileActions.uploadFile(payload));
    setOpen(false);
  };
  const [highlightRequiredFields, setHighlightRequiredFields] = useState();
  const openDropZoneModal = () => {
    // const values = getValues();

    // let checkIsEmpty = Object.keys(values).reduce((prev, el) => {
    //   if (values[el]) {
    //     return { ...prev, [el]: false };
    //   }
    //   return { ...prev, [el]: true };
    // }, {});

    // if (Object.keys(checkIsEmpty).find((el) => checkIsEmpty[el] === true)) {
    //   setHighlightRequiredFields(checkIsEmpty);
    //   setOpen(false);
    // }

    setHighlightRequiredFields();
    setOpen(true);
    return;
  };

  useEffect(() => {
    if (mobileClaimID) {
      dispatch(mobileActions.getFiles(mobileClaimID, false));
    }
  }, [dispatch, mobileClaimID]);

  const attachments = useSelector((state) => getNestedPropertySafe(state, ["mobile", "attachments"]));

  return (
    <Grid container item spacing={3} className={classes.upload}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" style={{ textAlign: "center" }}>
          Documents
        </Typography>
        <Typography variant="subtitle1" component="div" gutterBottom style={{ textAlign: "center" }}>
          Please upload damage photos
        </Typography>
      </Grid>
      {/* <Grid item xs={6}>
                <Autocomplete
                    options={fileCategory}
                    label="Select a File Category"
                    name="category"
                    control={control}
                    defaultValue=""
                    error={
                      highlightRequiredFields &&
                      highlightRequiredFields["category"]
                        ? true
                        : false
                    }
                    helperText={
                      highlightRequiredFields &&
                      highlightRequiredFields["category"]
                        ? "Required"
                        : null
                    }
                  />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
                  <Autocomplete
                    options={fileType}
                    label="Select a File Type"
                    name="type"
                    control={control}
                    defaultValue=""
                    error={
                      highlightRequiredFields && highlightRequiredFields["type"]
                        ? true
                        : false
                    }
                    helperText={
                      highlightRequiredFields && highlightRequiredFields["type"]
                        ? "Required"
                        : null
                    }
                  />
                </Grid> */}
      {/* <Grid item xs={3}></Grid> */}
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            component="label"
            fullWidth
            size="large"
            onClick={() => openDropZoneModal()}
            sx={{ width: "min-content", whiteSpace: "nowrap", margin: "auto" }}
          >
            Upload Files
            <BackupIcon fontSize="small" className={classes.Icon} />
          </Button>
          <DropzoneDialog
            open={open}
            onSave={handleUploadSave}
            acceptedFiles={[".doc", ".pdf", ".png", ".jpg", ".jpeg"]}
            showPreviews={true}
            maxFileSize={5000000}
            onClose={() => setOpen(false)}
            showAlerts={false}
          />
        </Box>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <AttachmentTable files={attachments ? attachments : []} />
      </Grid>
      {/* <Grid container spacing={3}>
        {openFileOrder ? (
          <Grid item xs={12}>
            <AttachmentGrid files={attachments ? attachments : []} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <AttachmentTable files={attachments ? attachments : []} />
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="flex-end">
        {attachments && attachments.length > 0 ? (
          <Button onClick={() => setOpenFileOrder(!openFileOrder)}>
            {openFileOrder ? "Go back" : "Edit File Order"}
          </Button>
        ) : null}
      </Grid> */}
    </Grid>
  );
}

export const adminRoleConstants = {
  GET_ALL_ROLES_REQUEST: "GET_ALL_ROLES_REQUEST",
  GET_ALL_ROLES_SUCCESS: "GET_ALL_ROLES_SUCCESS",
  CREATE_NEW_ROLE_REQUEST: "CREATE_NEW_ROLE_REQUEST",
  CREATE_NEW_ROLE_SUCCESS: "CREATE_NEW_ROLE_SUCCESS",
  CLONE_ROLE_REQUEST: "CLONE_ROLE_REQUEST",
  CLONE_ROLE_SUCCESS: "CLONE_ROLE_SUCCESS",
  GET_ROLE_GENERAL_REQUEST: "GET_ROLE_GENERAL_REQUEST",
  GET_ROLE_GENERAL_SUCCESS: "GET_ROLE_GENERAL_SUCCESS",
  GET_ROLE_RDA_REQUEST: "GET_ROLE_RDA_REQUEST",
  GET_ROLE_RDA_SUCCESS: "GET_ROLE_RDA_SUCCESS",
  UPDATE_ROLE_GENERAL_REQUEST: "UPDATE_ROLE_GENERAL_REQUEST",
  UPDATE_ROLE_GENERAL_SUCCESS: "UPDATE_ROLE_GENERAL_SUCCESS",
  GET_ROLE_DB_ACCESS_REQUEST: "GET_ROLE_DB_ACCESS_REQUEST",
  GET_ROLE_DB_ACCESS_SUCCESS: "GET_ROLE_DB_ACCESS_SUCCESS",
  UPDATE_ROLE_DB_ACCESS_REQUEST: "UPDATE_ROLE_DB_ACCESS_REQUEST",
  GET_ROLE_DASHBOARD_REQUEST: "GET_ROLE_DASHBOARD_REQUEST",
  GET_ROLE_DASHBOARD_SUCCESS: "GET_ROLE_DASHBOARD_SUCCESS",
  ADD_ROLE_DASHBOARD_REQUEST: "ADD_ROLE_DASHBOARD_REQUEST",
  ADD_ROLE_DASHBOARD_SUCCESS: "ADD_ROLE_DASHBOARD_SUCCESS",
  REMOVE_ROLE_DASHBOARD_REQUEST: " REMOVE_ROLE_DASHBOARD_REQUEST",
  REMOVE_ROLE_DASHBOARD_SUCCESS: " REMOVE_ROLE_DASHBOARD_SUCCESS",
  UPDATE_ROLE_DASHBOARD_REQUEST: "UPDATE_ROLE_DASHBOARD_REQUEST",
  UPDATE_ROLE_DASHBOARD_SUCCESS: "UPDATE_ROLE_DASHBOARD_SUCCESS",
  UPDATE_ROLE_DASHBOARD_POSITION_REQUEST: "UPDATE_ROLE_DASHBOARD_POSITION_REQUEST",
  UPDATE_ROLE_DASHBOARD_POSITION_SUCCESS: "UPDATE_ROLE_DASHBOARD_POSITION_SUCCESS",
  GET_ROLE_ENTITIES_REQUEST: "GET_ROLE_ENTITIES_REQUEST",
  GET_ROLE_ENTITIES_SUCCESS: "GET_ROLE_ENTITIES_SUCCESS",
  UPDATE_ROLE_ENTITIES_REQUEST: "UPDATE_ROLE_ENTITIES_REQUEST",
  UPDATE_ROLE_ENTITIES_SUCCESS: "UPDATE_ROLE_ENTITIES_SUCCESS",
  GET_ROLE_ACTION_ACCESS_REQUEST: "GET_ROLE_ACTION_ACCESS_REQUEST",
  GET_ROLE_ACTION_ACCESS_SUCCESS: "GET_ROLE_ACTION_ACCESS_SUCCESS",
  GET_ROLE_ACTION_ACCESS_LOADING: "GET_ROLE_ACTION_ACCESS_LOADING",
  UPDATE_ROLE_ACTION_ACCESS_REQUEST: "UPDATE_ROLE_ACTION_ACCESS_REQUEST",
  UPDATE_ROLE_ACTION_ACCESS_SUCCESS: "UPDATE_ROLE_ACTION_ACCESS_SUCCESS",
  UPDATE_ROLE_ACTION_ACCESS_LOADING: "UPDATE_ROLE_ACTION_ACCESS_LOADING",
  GET_ROLE_STATUS_ACCESS_REQUEST: "GET_ROLE_STATUS_ACCESS_REQUEST",
  GET_ROLE_STATUS_ACCESS_SUCCESS: "GET_ROLE_STATUS_ACCESS_SUCCESS",
  GET_ROLE_STATUS_ACCESS_LOADING: "GET_ROLE_STATUS_ACCESS_LOADING",
  UPDATE_ROLE_STATUS_ACCESS_REQUEST: "UPDATE_ROLE_STATUS_ACCESS_REQUEST",
  UPDATE_ROLE_STATUS_ACCESS_SUCCESS: "UPDATE_ROLE_STATUS_ACCESS_SUCCESS",
  UPDATE_ROLE_STATUS_ACCESS_LOADING: "UPDATE_ROLE_STATUS_ACCESS_LOADING",
  ADMIN_ROLES_CLEAR: "ADMIN_ROLES_CLEAR",
  ADMIN_ROLES_NOTIFICATIONS_REQUEST: "ADMIN_ROLES_NOTIFICATIONS_REQUEST",
  ADMIN_ROLES_NOTIFICATIONS_SUCCESS: "ADMIN_ROLES_NOTIFICATIONS_SUCCESS",
  ADMIN_ROLES_NOTIFICATIONS_LOADING: "ADMIN_ROLES_NOTIFICATIONS_LOADING",
  ADMIN_ROLES_UPDATE_NOTIFICATIONS_REQUEST: "ADMIN_ROLES_UPDATE_NOTIFICATIONS_REQUEST",
  ADMIN_ROLES_UPDATE_NOTIFICATIONS_SUCCESS: "ADMIN_ROLES_UPDATE_NOTIFICATIONS_SUCCESS",
  ADMIN_ROLES_UPDATE_NOTIFICATIONS_LOADING: "ADMIN_ROLES_UPDATE_NOTIFICATIONS_LOADING",
  ADMIN_ROLES_DELETE_NOTIFICATIONS_REQUEST: "ADMIN_ROLES_DELETE_NOTIFICATIONS_REQUEST",
  ADMIN_ROLES_DELETE_NOTIFICATIONS_SUCCESS: "ADMIN_ROLES_DELETE_NOTIFICATIONS_SUCCESS",
  ADMIN_ROLES_DELETE_NOTIFICATIONS_LOADING: "ADMIN_ROLES_DELETE_NOTIFICATIONS_LOADING"
};

import { alertConstants } from "../_constants";
import { produce } from "immer";
import { REHYDRATE } from "redux-persist/lib/constants";
import moment from "moment";

let initState = {
  type: "",
  message: "",
  timestamp: "",
  duration: 5000, // default 5 seconds
  isVersionCheck: false,
};

export function alert(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case alertConstants.SUCCESS:
      return produce(state, (draft) => {
        draft["type"] = "success";
        draft["message"] = payload.message;
        draft["timestamp"] = moment().format();
        draft["isVersionCheck"] = false;
        if (payload.duration === null) draft["duration"] = payload.duration; // null passed means do not auto hide
        if (typeof payload.duration === "undefined") draft["duration"] = 5000; // default (no argument passed) set auto hide to 5 
      });
      case alertConstants.INFO:
        return produce(state, (draft) => {
          draft["type"] = "info";
          draft["message"] = payload.message;
          draft["timestamp"] = moment().format();
          draft["isVersionCheck"] = false;
          if (payload.duration === null) draft["duration"] = payload.duration; // null passed means do not auto hide
          if (typeof payload.duration === "undefined") draft["duration"] = 5000; // default (no argument passed) set auto hide to 5 seconds
        });
      case alertConstants.VERSION_CHECK:
        return produce(state, (draft) => {
          draft["type"] = "info";
          draft["message"] = payload.message;
          draft["timestamp"] = moment().format();
          draft["isVersionCheck"] = true;
          if (payload.duration === null) draft["duration"] = payload.duration; // null passed means do not auto hide
          if (typeof payload.duration === "undefined") draft["duration"] = 5000; // default (no argument passed) set auto hide to 5 seconds
        });
      case alertConstants.ERROR:
        return produce(state, (draft) => {
          draft["type"] = "error";
          draft["message"] = payload.message;
          draft["timestamp"] = moment().format();
          draft["isVersionCheck"] = false;
          if (payload.duration === null) draft["duration"] = payload.duration; // null passed means do not auto hide
          if (typeof payload.duration === "undefined") draft["duration"] = 5000; // default (no argument passed) set auto hide to 5 
        });
      case alertConstants.CLEAR:
        return { ...initState };
      case REHYDRATE:
        return { ...initState };
      default:
        return state;
  }
}

import { adminClaimStatusConstants_ } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";

let initState = {
  all: [],
  one:[],
  users:[],
  statusAccess:[]
};

export function claimStatus(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminClaimStatusConstants_.GET_ALL_STATUS_SUCCESS:
      return produce(state, (draft) => {
        draft["all"] = [...payload];

      });
      case adminClaimStatusConstants_.GET_ONE_STATUS_SUCCESS:
      return produce(state, (draft) => {
        let { id,data } = payload;
        draft["one"][id] = [...data];

      });
      case adminClaimStatusConstants_.GET_USERS_SUCCESS:
        return produce(state, (draft) => {
          draft["users"] = [...payload];
  
        });
        case adminClaimStatusConstants_.GET_STATUS_ACCESS_SUCCESS:
        return produce(state, (draft) => {
          draft["statusAccess"] = [...payload];
        });
    case REHYDRATE: {
      if (payload && payload.admin && payload.admin.claimStatus) {
        return { ...payload.admin.claimStatus };
      } else {
        return { ...initState };
      }
    }
    case adminClaimStatusConstants_.ADMIN_CLAIM_STATUS_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

import { schema } from "normalizr";

// All Projects
const allProject = new schema.Entity(
  "projects",
  {},
  { idAttribute: "claimId" }
);
const allProjectsSchema = new schema.Entity(
  "page",
  {
    projects: [allProject],
  },
  { idAttribute: "page" }
);

// My Projects
const myProject = new schema.Entity("projects", {}, { idAttribute: "claimId" });
const myProjectsSchema = new schema.Entity(
  "page",
  {
    projects: [myProject],
  },
  { idAttribute: "page" }
);

export const freloProjectSchema = {
  allProjectsSchema,
  myProjectsSchema,
};

export const authConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  ASSUME_USER_REQUEST: "ASSUME_USER_REQUEST",
  ASSUME_USER_SUCCESS: "ASSUME_USER_SUCCESS",
  ASSUME_ROLE_REQUEST: "ASSUME_ROLE_REQUEST",
  ASSUME_ROLE_SUCCESS: "ASSUME_ROLE_SUCCESS",
  IMPERSONATION_END_REQUEST:"IMPERSONATION_END_REQUEST",
  IMPERSONATION_END_SUCCESS:"IMPERSONATION_END_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  LOGOUT: "USERS_LOGOUT",
};

export const powerGasReportConstants = {
  GET_ALL_REPORTS_POWER_GAS_REQUEST: "GET_ALL_REPORTS_POWER_GAS_REQUEST",
  GET_ALL_REPORTS_POWER_GAS_SUCCESS: "GET_ALL_REPORTS_POWER_GAS_SUCCESS",
  GET_ONE_REPORT_POWER_GAS_REQUEST: "GET_ONE_REPORT_POWER_GAS_REQUEST",
  GET_ONE_REPORT_POWER_GAS_SUCCESS: "GET_ONE_REPORT_POWER_GAS_SUCCESS",
  POST_RUN_REPORT_POWER_GAS_REQUEST: "POST_RUN_REPORT_POWER_GAS_REQUEST",
  POST_RUN_REPORT_POWER_GAS_SUCCESS: "POST_RUN_REPORT_POWER_GAS_SUCCESS",
  POST_RUN_REPORT_POWER_GAS_ERROR: "POST_RUN_REPORT_POWER_GAS_ERROR",

  POWER_GAS_REPORTS_CLEAR: "POWER_GAS_REPORTS_CLEAR",
};

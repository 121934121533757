import { adminRoleConstants } from "../_constants";
import { produce } from "immer";
import { REHYDRATE } from "redux-persist/lib/constants";

let initState = {
  roles: [],
  general: {},
  dbAccess: {},
  dashboard: {},
  entities: {},
  actionAccess: {},
  actionAccessLoading: {
    GET: false,
    PUT: false
  },
  statusAccess: {},
  statusAccessLoading: {
    GET: false,
    PUT: false
  },
  notifications: {},
  notificationsLoading: {
    GET: false,
    PUT: false,
    DELETE: false
  }
};

export function adminRoles(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminRoleConstants.GET_ALL_ROLES_SUCCESS:
      return produce(state, (draft) => {
        draft["roles"] = [...payload];
      });
    case adminRoleConstants.GET_ROLE_GENERAL_SUCCESS:
      return produce(state, (draft) => {
        let { roleId, data } = payload;
        draft["general"][roleId] = { ...data };
      });
    case adminRoleConstants.GET_ROLE_DB_ACCESS_SUCCESS:
      return produce(state, (draft) => {
        let { roleId, data } = payload;
        draft["dbAccess"][roleId] = { ...data };
      });
    case adminRoleConstants.GET_ROLE_DASHBOARD_SUCCESS:
      return produce(state, (draft) => {
        let { roleId, data } = payload;
        draft["dashboard"][roleId] = { ...data };
      });
    case adminRoleConstants.GET_ROLE_ENTITIES_SUCCESS:
      return produce(state, (draft) => {
        let { roleId, data } = payload;
        draft["entities"][roleId] = [...data];
      });
    case adminRoleConstants.GET_ROLE_ACTION_ACCESS_SUCCESS:
      return produce(state, (draft) => {
        let { roleId, data } = payload;
        const obj = {};
        data.actionAccessRoleData.forEach(e => {
          obj[e.accessTypeLOB] = e
        })
        draft["actionAccess"][roleId] = { ...obj };
        draft["actionAccessLoading"]["GET"] = false;
        draft["actionAccessLoading"]["PUT"] = false;
      });
    case adminRoleConstants.GET_ROLE_ACTION_ACCESS_LOADING:
      return produce(state, (draft) => {
        draft["actionAccessLoading"]["GET"] = payload;
      });
    case adminRoleConstants.UPDATE_ROLE_ACTION_ACCESS_LOADING:
      return produce(state, (draft) => {
        draft["actionAccessLoading"]["PUT"] = payload;
      });
    case adminRoleConstants.GET_ROLE_STATUS_ACCESS_SUCCESS:
      return produce(state, (draft) => {
        let { roleId, data } = payload;

        const statusAccessDataFormatted = data.statusAccessRoleData.reduce((prev, el) => {
          return {
            ...prev,
            [el.LOBID]: {
              ...prev[el.LOBID],
              [el.statusType]: {
                ...prev[el.LOBID][el.statusType],
                [el.statusId]: el
              }
            }
          }
        }, {
          // designate default values here where 1/2/3 are LOBIDs (TEL, P&G, FRELO)
          // within each LOBID are statusTypes (1 claimStatus or 2 paymentStatus)
          1: { 1: {}, 2: {} },
          2: { 1: {}, 2: {} },
          3: { 1: {}, 2: {} }
        })

        draft["statusAccess"][roleId] = { ...statusAccessDataFormatted };
        draft["statusAccessLoading"]["GET"] = false;
        draft["statusAccessLoading"]["PUT"] = false;
      });
    case adminRoleConstants.GET_ROLE_STATUS_ACCESS_LOADING:
      return produce(state, (draft) => {
        draft["statusAccessLoading"]["GET"] = payload;
      });
    case adminRoleConstants.UPDATE_ROLE_STATUS_ACCESS_LOADING:
      return produce(state, (draft) => {
        draft["statusAccessLoading"]["PUT"] = payload;
      });

    case adminRoleConstants.ADMIN_ROLES_NOTIFICATIONS_LOADING:
      return produce(state, (draft) => {
        draft["notificationsLoading"]["GET"] = true;
      });
    case adminRoleConstants.ADMIN_ROLES_NOTIFICATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft["notifications"] = payload;
        draft["notificationsLoading"]["GET"] = false;
        draft["notificationsLoading"]["PUT"] = false;
        draft["notificationsLoading"]["DELETE"] = false;
      });
    case adminRoleConstants.ADMIN_ROLES_DELETE_NOTIFICATIONS_LOADING:
      return produce(state, (draft) => {
        draft["notificationsLoading"]["DELETE"] = true;
      });
    case adminRoleConstants.ADMIN_ROLES_DELETE_NOTIFICATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft["notifications"] = {...state.notifications, notifications: state.notifications.notifications.map(n => {return {...n, access: false}})};
        draft["notificationsLoading"]["DELETE"] = false;
      });
    case adminRoleConstants.ADMIN_ROLES_UPDATE_NOTIFICATIONS_LOADING:
      return produce(state, (draft) => {
        draft["notificationsLoading"]["PUT"] = true;
      });
    case adminRoleConstants.ADMIN_ROLES_UPDATE_NOTIFICATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft["notificationsLoading"]["PUT"] = false;
      });


    case adminRoleConstants.ADMIN_ROLES_CLEAR:
      return { ...initState };
    case REHYDRATE: {
      if (payload && payload.adminRoles) {
        return { ...payload.adminRoles };
      } else {
        return { ...initState };
      }
    }
    default:
      return state;
  }
}

import { adminknowledgeBaseConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";

let initState = {
  faq: [],
  kb: [],
  topics: [],
  markets: [],
  resourcetypes: [],
};

export function knowledgeBase(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminknowledgeBaseConstants.GET_ALL_FAQ_SUCCESS:
      return produce(state, (draft) => {
        draft["faq"] = [...payload];
      });
    case adminknowledgeBaseConstants.GET_ALL_KB_SUCCESS:
      return produce(state, (draft) => {
        draft["kb"] = [...payload];
      });
    case adminknowledgeBaseConstants.GET_ALL_KBTOPICS_SUCCESS:
      return produce(state, (draft) => {
        draft["topics"] = [...payload];
      });
    case adminknowledgeBaseConstants.GET_ALL_KBMARKETS_SUCCESS:
      return produce(state, (draft) => {
        draft["markets"] = [...payload];
      });
    case adminknowledgeBaseConstants.GET_ALL_KBRESOURCETYPES_SUCCESS:
      return produce(state, (draft) => {
        draft["resourcetypes"] = [...payload];
      });
    case REHYDRATE: {
      if (payload && payload.admin && payload.admin.knowledgeBase) {
        return { ...payload.admin.knowledgeBase };
      } else {
        return { ...initState };
      }
    }
    case adminknowledgeBaseConstants.ADMIN_KNOWLEDGE_BASE_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

// import { createMuiTheme } from "@material-ui/core/styles";
import {  createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8bc34a",
    },
    secondary: {
      main: "#ffcc00",
    },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#737373",
    },
    WhitePrg: {
      main: "#ffffff",
    },
    OffWhitePrg: {
      main: "#f5f5f5",
    },
    BodyWhitePrg: {
      main: "#fafafa",
    },
    BlackPrg: {
      main: "#000000",
    },
    OverlayPrg: {
      main: "#0000003b",
    },
    GrayPrg: {
      main: "#eae9e9",
    },
    HeaderGrayPrg: {
      main: "#f8f9fa",
    },
    LightGrayPrg: {
      main: "#737373",
      secondary: "#eeeded",
    },
    DarkGrayPrg: {
      main: "#636363",
    },
    GreenPrg: {
      main: "#8bc34a",
    },
    HoverGreen: {
      main: "#e5efda",
    },
    LightGreenPrg: {
      main: "#92bb6a",
    },
    DarkGreenPrg: {
      main: "#6f9c3b",
    },
    RedPrg: {
      light: "#E6717C",
      main: "#dc3545",
      dark: "#841F29",
    },
    YellowPrg: {
      main: "#ffcc00",
    },
    Blue: {
      main: "#2f4073",
    },
    Teal: {
      main: "#04bf7d",
    },
  },
  overrides: {
    body:{
      backgroundColor: '#ffffff !important'
    },
    MuiTypography: {
      root: {
        color: "#737373 !important",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none !important",
      },
    },
    MuiCollapse: {
      wrapper: {
        // backgroundColor: '#636363 !important',
      },
    },
    MUIRichTextEditor: {
      root: {
        width: "100%",
      },
      editor: {
        // borderBottom: "1px solid gray",
        borderBottom: "none",
        minHeight: "2rem",
        color: "black",
        fontSize: "14px",
      },
    },
    MuiChip: {
      root: {
        height: "25px",
      },
      deleteIcon: {
        color: "#636363",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#fff!important",
        textShadow: '0 3px 5px #00000082',
        borderEadius: '5px',
        "&.Mui-disabled": {
          color: "rgba(255, 255, 255, 0.5)!important",
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#eae9e9 !important",
      },
    },
    //     MuiButtonBase:{
    //       root:{
    //         backgroundColor: "#737373 !important",
    //         color: "#ffffff !important",
    //   },
    // },
  },
});

export default theme;

import { settingsConstants } from "../_constants";
import { produce } from "immer";
import { REHYDRATE } from "redux-persist/lib/constants";

let initState = {
  theme: "dark",
};
export function settings(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case settingsConstants.SETTINGS_CHANGE_THEME_SUCCESS:
      return produce(state, (draft) => {
        if (state["theme"] === "light") {
          draft["theme"] = "dark";
        } else {
          draft["theme"] = "light";
        }
      });

    case REHYDRATE: {
      if (payload && payload.settings) {
        return { ...payload.settings };
      } else {
        return { ...initState };
      }
    }
    case settingsConstants.SETTINGS_CLEAR:
      return { ...initState };
    default:
      return state;
  }
}

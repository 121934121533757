export const PowerGasLinks = [
  {
    id: "Dashboard",
    name: "Dashboard",
    url: "/dashboard",
    root: true,
    icon: "pie_chart",
    access: false,
  },
  {
    id: "Dispatch",
    name: "Dispatch",
    url: "/dashboard/dispatch/power-gas",
    icon: "headset_mic",
    root: true,
    access: false,
  },
  {
    id: "DispatchAdmin",
    name: "Dispatch Admin",
    url: "/dashboard/dispatch-admin/power-gas",
    icon: "edit_note_icon",
    root: true,
    access: false,
  },
  {
    id: "ClaimsAdmin",
    name: "Claims Admin",
    icon: "edit_note_icon",
    root: true,
    access: false,
  },
  {
    parentId: "ClaimsAdmin",
    id: "LetterReasons",
    name: "Letter Reasons",
    url: "/dashboard/claims-manager/letters",
    icon: "description",
    root: false,
    access: false,
  },
  {
    id: "MyTeam",
    name: "MyTeam",
    url: "/dashboard/my-team",
    icon: "group",
    root: true,
    access: false,
  },
  {
    id: "Claims",
    name: "Claims",
    url: "/dashboard/claim/power-gas",
    icon: "list_alt",
    root: true,
    access: false,
  },
  {
    id: "ClaimsQueue",
    name: "Claims Queue",
    url: "/dashboard/queues",
    icon: "group",
    root: true,
    access: false
  },
  {
    id: "Reports",
    name: "Reports",
    icon: "description",
    root: true,
    access: false,
  },
  {
    parentId: "Reports",
    id: "CustomReports",
    name: "Custom Reports",
    url: "/dashboard/reports/custom",
    icon: "description",
    root: false,
    access: false,
  },
  {
    parentId: "Reports",
    id: "LegacyReports",
    name: "Legacy Reports",
    url: "/dashboard/reports/legacy/power-gas",
    icon: "description",
    root: false,
    access: false,
  },
  {
    id: "ResourceTools",
    name: "Resource Tools",
    icon: "emoji_objects",
    root: true,
    access: false,
  },
  {
    parentId: "ResourceTools",
    id: "KnowledgeBase",
    name: "Knowledge Base",
    icon: "",
    url: "/dashboard/resource-tools/knowledge-base",
    root: false,
    access: false,
  },
  {
    parentId: "ResourceTools",
    id: "FAQS",
    name: "FAQ's",
    icon: "",
    url: "/dashboard/resource-tools/faqs",
    root: false,
    access: false,
  },
  {
    parentId: "Ledger",
    id: "LedgerAdjustment",
    name: "Ledger Adjustments",
    icon: "history_edu",
    url: "/dashboard/ledger/powergas",
    root: true,
    access: false,
  },
];

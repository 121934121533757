import { telecomReportConstants } from "_constants";

const getAll = () => {
  return {
    type: telecomReportConstants.GET_ALL_REPORTS_TELECOM_REQUEST,
  };
};

const getOne = (reportId) => {
  return {
    type: telecomReportConstants.GET_ONE_REPORT_TELECOM_REQUEST,
    reportId,
  };
};

const runReport = (reportId, payload, fields) => {
  return {
    type: telecomReportConstants.POST_RUN_REPORT_TELECOM_REQUEST,
    reportId,
    payload,
    fields,
  };
};


export const telecomReportActions = {
  getAll,
  getOne,
  runReport
};

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
//import TextField from "@material-ui/core/TextField";
import TextField from "@mui/material/TextField";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { cookieService } from "_services";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import IconButton from "@material-ui/core/IconButton";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory } from "react-router-dom";
import { userActions, mobileActions } from "_actions";
import { withRouter, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ObjectTools } from "_utility";

import PRGLogo from "_assets/images/Logo/PRG-Logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.DarkGrayPrg.main,
    borderRadius: "4px",
    "& .MuiFormLabel-root": {
      color: `${theme.palette.WhitePrg.main} !important`,
      position: "relative",
      zIndex: 9,
    },
    "& .MuiInputBase-root": {
      backgroundColor: `#5c5c5c !important`,
    },
    "& .MuiInputBase-input": {
      color: `${theme.palette.WhitePrg.main} !important`,
      padding: 5,
      // border: 'solid 10px white',
      borderRadius: 5,
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "-100px",
    padding: "20px",
  },
  formMobile: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "-50px",
    padding: "20px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: `${theme.palette.GreenPrg.main} !important`,
    "&:hover": {
      backgroundColor: theme.palette.GrayPrg.main,
      color: theme.palette.DarkGrayPrg.main,
    },
  },
  inputs: {
    backgroundColor: `#5c5c5c !important`,
    color: `${theme.palette.DarkGrayPrg.main} !important`,
    borderRadius: "4px",
    padding: "10px !important",

  },
  margin: {
    marginTop: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  imgWrap: {
    position: "relative",
    top: "-100px",
    width: "202px",
    height: "202px",
    backgroundColor: "#3b3b3b",
    borderRadius: "50%",
  },
  imgInnerWrap: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: 0,
    right: 0,
    margin: "auto",
    width: "166px",
    height: "166px",
    backgroundColor: theme.palette.WhitePrg.main,
    borderRadius: "50%",
    borderBottom: `solid 2px ${theme.palette.GreenPrg.main}`,
    boxShadow: `0 0 0px 8px ${theme.palette.GrayPrg.main}`,
  },
  img: {
    top: "50%",
    left: "-6px",
    width: "178px",
    position: "absolute",
    transform: "translateY(-50%)",
    right: 0,
    margin: "auto",
  },
  forgotPass: {
    cursor: "pointer",
    textAlign: "right",
    color: theme.palette.WhitePrg.main,
    "&:hover": {
      opacity: ".5",
    },
  },
}));

function Login() {
  const history = useHistory();
  const classes = useStyles();
  const { getNestedPropertySafe } = ObjectTools;
  const dispatch = useDispatch();
  const { register, watch } = useForm();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const { loggedIn } = useSelector((state) =>
    getNestedPropertySafe(state, ["auth"])
  );
  const mobileLogin = useSelector((state) =>
    getNestedPropertySafe(state, ["mobile", "loggedIn"])
  );

  const loginRedirectPath = useSelector((state) =>
    getNestedPropertySafe(state, ["auth", "role", "loginRedirectPath"])
  );

  let validation = () => {
    let dataErrors = {};
    if (username.length < 1)
      dataErrors = { ...dataErrors, username: "Invalid username" };
    if (password.length < 1)
      dataErrors = { ...dataErrors, password: "Invalid password" };

    if (Object.keys(dataErrors).length > 0) {
      setErrors(dataErrors);
      return false;
    }
    return true;
  };

  let handleSubmit = (e) => {
    e.preventDefault();

    let validForm = validation();
    if (!validForm) return;

    setErrors({});
    setShowPassword(false);

    dispatch(userActions.login({ username, password }));
  };

  let handelForgotPass = () => {
    history.push(`/forgot-password`);
  };

  // Mobile Claim Login

  let watchLogin = watch("userEmail");
  const handleLogin = (e) => {
    e.preventDefault();
    if (watchLogin)    dispatch(mobileActions.login(watchLogin.trim()));
  };

  let url = window.location.href;
  useEffect(() => {
    if (window.location.href.indexOf("mobile-claim/") > -1) return;
    if (window.location.href.indexOf("mobile-claim") > -1) {
      window.location.href = `${url}/`;
      return;
    }
  }, [url]);

  useEffect(() => {
    if (mobileLogin) {
      history.push('/mobile-claim/my-list');
    }
  },[mobileLogin])

  return (
    <Container component="main" maxWidth="xs">
      {loggedIn && cookieService.get("token") && (
        <Redirect to={loginRedirectPath ? loginRedirectPath : "dashboard"} />
      )}
      <CssBaseline />

      {window.location.href.indexOf("mobile-claim/") > -1 ? (
        <div className={classes.root}>
          <div className={classes.imgWrap}>
            <div className={classes.imgInnerWrap}>
              <img
                src={PRGLogo}
                className={classes.img}
                alt="Project Resources Group logo"
              />
            </div>
            <Typography
              variant="subtitle1"
              component="div"
              gutterBottom
              style={{ position: "relative", top: 230, marginBottom: 20, textAlign: "center" }}
            >
              Mobile Claim Login
            </Typography>
          </div>
          <form
            className={classes.formMobile}
            noValidate
            onSubmit={(e) => handleLogin(e)}
          >
            <TextField
              variant="standard"
              className={classes.inputs}
              margin="normal"
              fullWidth
              name="userEmail"
              label={`Enter Email`}
              inputRef={register}
              autoComplete="userEmail"
            />
            <Button
              type="submit"
              size="large"
              variant="outlined"
              //disabled={disabled}
              fullWidth
              className={classes.submit}
            >
              Log In
            </Button>
          </form>
        </div>
      ) : (
        <div className={classes.root}>
          <div className={classes.imgWrap}>
            <div className={classes.imgInnerWrap}>
              <img
                src={PRGLogo}
                className={classes.img}
                alt="Project Resources Group logo"
              />
            </div>
          </div>
          <form
            className={classes.form}
            noValidate
            onSubmit={(e) => handleSubmit(e)}
          >
            <TextField
              variant="standard"
              className={classes.inputs}
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onBlur={(e) => setUsername(e.target.value)}
              helperText={errors["username"] && errors["username"]}
              error={errors["username"] && true}
            />
            <FormControl
              className={clsx(
                classes.margin,
                classes.textField,
                classes.inputs
              )}
              variant="standard"
            >
              <InputLabel htmlFor="filled-adornment-password">
                Password
              </InputLabel>
              <FilledInput
                className={classes.inputs}
                color="primary"
                id="filled-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility style={{ color: "var(--WhitePrg)" }} />
                      ) : (
                        <VisibilityOff style={{ color: "var(--WhitePrg)" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {errors && errors["password"] && (
              <FormHelperText
                error
                id="component-error-text"
                className={classes.errorBg}
              >
                {errors["password"]}
              </FormHelperText>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <p
              onClick={() => handelForgotPass()}
              className={classes.forgotPass}
            >
              Forgot Your Password?
            </p>
          </form>
        </div>
      )}
    </Container>
  );
}

const connectedLoginPage = withRouter(Login);
export { connectedLoginPage as Login };

import React, { useEffect, useState } from "react";

import FindPaymentForm from "./FindPaymentForm";
import MakePaymentForm from "./MakePaymentForm";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { paymentActions } from "_actions";
import { useDispatch, useSelector } from "react-redux";
import { ObjectTools } from "_utility";
import { isEmpty } from "ramda";

import PRGLogo from "_assets/images/Logo/PRG-Logo.svg";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(16),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.DarkGrayPrg.main,
    borderRadius: "4px",
  },
  imgWrap: {
    position: "relative",
    top: "-100px",
    width: "202px",
    height: "202px",
    backgroundColor: "#3b3b3b",
    borderRadius: "50%",
  },
  imgInnerWrap: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: 0,
    right: 0,
    margin: "auto",
    width: "166px",
    height: "166px",
    backgroundColor: theme.palette.WhitePrg.main,
    borderRadius: "50%",
    borderBottom: `solid 2px ${theme.palette.GreenPrg.main}`,
    boxShadow: `0 0 0px 8px ${theme.palette.GrayPrg.main}`,
  },
  img: {
    top: "50%",
    left: "-6px",
    width: "178px",
    position: "absolute",
    transform: "translateY(-50%)",
    right: 0,
    margin: "auto",
  },
}));

export default function Payment() {
  const classes = useStyles();
  const { getNestedPropertySafe } = ObjectTools;
  const dispatch = useDispatch();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const lobidURL = urlParams.get("lobid");

  useEffect(() => {
    dispatch(paymentActions.clearPayment());
  }, []);

  const [formData, setFormData] = useState();
  const setSubmitted = (formData) => {
    setFormData(formData);
  };

  // if form data is provided and all values are present, get payment
  useEffect(() => {
    if (formData) {
      const { invoice, lobid } = formData;
      if (invoice && (lobidURL || lobid)) {
        const payload = {
          claimid: invoice,
          lobid: lobidURL ? lobidURL : lobid.value, //lobidURL comes from url params, while lobid comes from the form (if no lobid exists in url)
        };
        dispatch(paymentActions.getPayment(payload));
      }
    }
  }, [formData]);

  // if all values are supplied via url params on load, get payment automatically
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const invoice = urlParams.get("invoice");
    if (invoice && lobidURL) {
      const payload = {
        claimid: invoice,
        lobid: lobidURL,
      };
      dispatch(paymentActions.getPayment(payload));
    }
  }, []);

  const payment = useSelector((state) => getNestedPropertySafe(state, ["payment", "info"]));
  const roleId = useSelector((state) => getNestedPropertySafe(state, ["auth", "role", "roleId"]));

  // restrict access for recovery agents
  const isRestricted = [19,1048].includes(roleId);

  return (
    <Container component="main" maxWidth="lg">
      <div className={classes.root}>
        <div className={classes.imgWrap}>
          <div className={classes.imgInnerWrap}>
            <img src={PRGLogo} className={classes.img} alt="Project Resources Group logo" />
          </div>
        </div>

        {isRestricted && (
          <Stack alignItems="center" sx={{ padding: "10vh 200px 40vh" }}>
            <Typography variant="h5" sx={{ color: "red" }}>Access Denied</Typography>
            <Typography >You do not have permission to access this page</Typography>
          </Stack>
        )}
        {!isRestricted && isEmpty(payment) && <FindPaymentForm formData={formData} setSubmitted={setSubmitted} />}
        {<MakePaymentForm lobid={lobidURL} isRestricted={isRestricted} isEmpty={isEmpty(payment)}/>}
      </div>
    </Container>
  );
}

import { schema } from "normalizr";

// Contacts
const contact = new schema.Entity("contacts", {}, { idAttribute: "contactId" });
const contactSchema = [contact];
// ----

// RDA Control
const rdaControl = new schema.Entity("rda", {}, { idAttribute: "rdaId" });
const rdaControlSchema = [rdaControl];
// ----

// Status
const status = new schema.Entity("status", {}, { idAttribute: "statusId" });
const statusSchema = [status];
// ----

// Subaccounts
const subAccount = new schema.Entity(
  "subAccounts",
  {},
  { idAttribute: "subaccountId" }
);
const subAccountSchema = [subAccount];
// ----

// Sow Contracts
const sowContract = new schema.Entity(
  "sowContracts",
  {},
  { idAttribute: "sowId" }
);
const sowContractSchema = [sowContract];
// ----

// Billing Types
const billingTypes = new schema.Entity(
  "billingTypes",
  {},
  { idAttribute: "billingTypeId" }
);
const billingTypesSchema = [billingTypes];
//  ----

// Form Control: Forms
const form = new schema.Entity("form", {}, { idAttribute: "formId" });
const formControlFormSchema = [form];
// ----

// Form Control: Collections All
const collection = new schema.Entity(
  "collection",
  {},
  { idAttribute: "collectionId" }
);
const formControlCollectionSchema = [collection];
//

// Form Control:: Collection By Form Id
const collectionById = new schema.Entity(
  "collections",
  {},
  { idAttribute: "collectionId" }
);

const collectionByIdSchema = new schema.Entity(
  "form",
  {
    collections: [collectionById],
  },
  { idAttribute: "formId" }
);
//

// Form Control: RuleSet

const collectionRuleSetSchema = new schema.Entity(
  "forms",
  {},
  { idAttribute: "formId" }
);
//

// Form Control: form fields
const fields = new schema.Entity("fields", {}, { idAttribute: "fieldId" });
const formFieldsSchema = new schema.Entity(
  "form",
  {
    fields: [fields],
  },
  { idAttribute: "formId" }
);
//

export const adminEntityManagementSchema = {
  contactSchema,
  rdaControlSchema,
  statusSchema,
  subAccountSchema,
  sowContractSchema,
  billingTypesSchema,
  formControlFormSchema,
  formControlCollectionSchema,
  collectionByIdSchema,
  collectionRuleSetSchema,
  formFieldsSchema,
};

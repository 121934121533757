import { put, call } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  powerBiConstants
} from "_constants";
import { powerBiService } from "_services";

export function* getPowerBiReportsSaga({ roleId }) {
  try {

    // put: loading true
    yield put({
      type: powerBiConstants.GET_REPORTS_LOADING,
    });

    const response = yield call(
      powerBiService.getReports,
      roleId
    );

    if (!Array.isArray(response.reports)) {
      throw new Error("Error fetching powerbi reports");
    }

    yield put({
      type: powerBiConstants.GET_REPORTS_SUCCESS,
      payload: { reports: [...response["reports"]], defaultReports: response["defaultReports"] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}




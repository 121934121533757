import { adminRoundRobinConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";

let initState = {
  all: [],
  individual: {
    loading: false,
  },
  locateSchema: [],
  entitySchema: [],
  inactive: {
    loading: false,
  },
};

export function roundRobin(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminRoundRobinConstants.ADMIN_GET_ALL_ROUNDROBIN_SUCCESS:
      return produce(state, (draft) => {
        draft["all"] = [...payload];
      });
    case adminRoundRobinConstants.ADMIN_GET_ONE_ROUNDROBIN_SUCCESS:
      return produce(state, (draft) => {
        let { id, data } = payload;

        draft["individual"] = { [id]: { ...data }, loading: false };
      });
    case adminRoundRobinConstants.ADMIN_GET_LOCATE_SCHEMA_ROUNDROBIN_SUCCESS:
      return produce(state, (draft) => {
        draft["locateSchema"] = [...payload];
      });
    case adminRoundRobinConstants.ADMIN_GET_ENTITIES_SCHEMA_ROUNDROBIN_SUCCESS:
      return produce(state, (draft) => {
        draft["entitySchema"] = [...payload];
      });
    case adminRoundRobinConstants.SET_ADMIN_INDIVIDUAL_ROUNDROBIN_LOADING_TRUE:
      return produce(state, (draft) => {
        draft["individual"] = {
          ...state.individual,
          loading: true,
        };
      });
    case adminRoundRobinConstants.SET_ADMIN_INACTIVE_ROUNDROBIN_LOADING_TRUE:
      return produce(state, (draft) => {
        draft["inactive"] = {
          loading: true,
        };
      });
    case adminRoundRobinConstants.SET_ADMIN_INACTIVE_ROUNDROBIN_LOADING_FALSE:
      return produce(state, (draft) => {
        draft["inactive"] = {
          loading: false,
        };
      });
    case REHYDRATE: {
      if (payload && payload.admin && payload.admin.roundRobin) {
        return { ...payload.admin.roundRobin };
      } else {
        return { ...initState };
      }
    }
    case adminRoundRobinConstants.ADMIN_ROUNDROBIN_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

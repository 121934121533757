import { put, call } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  jointReportConstants
} from "_constants";

import { jointReportsService } from "_services";

export function* getUserReportsSaga() {
  try {
      const response = yield call(jointReportsService.getUserReports);
  
      yield put({
        type: jointReportConstants.GET_USER_REPORTS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
}

export function* getReportSaga({ reportID }) {
  try {
      const response = yield call(jointReportsService.getReport, reportID);
  
      yield put({
        type: jointReportConstants.GET_REPORT_SUCCESS,
        payload: {
          reportID,
          data: response
        }
      });
    } catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
}

export function* getReportsProcsSaga() {
    try {
        const response = yield call(jointReportsService.getProcs);
    
        yield put({
          type: jointReportConstants.GET_REPORT_PROCS_SUCCESS,
          payload: response,
        });
      } catch (error) {
        if (error.message === "Invalid Auth Token") {
          yield put({
            type: authConstants.LOGOUT,
          });
        }
        yield put({
          type: alertConstants.ERROR,
          payload: { message: error.message },
        });
      }
}

export function* queryReportsProcSaga({ procedures, filters, fields, inputs, reportName, shared, rowsPerPage, page }) {
    try {
       // put: loading true
    yield put({
      type: jointReportConstants.QUERY_REPORT_PROC_LOADING,
    });
    
      const response = yield call(jointReportsService.queryProc, procedures, filters, fields, inputs, reportName, shared, rowsPerPage, page);
      yield put({
        type: jointReportConstants.QUERY_REPORT_PROC_SUCCESS,
        payload: {
          procedures, 
          filters, 
          fields, 
          inputs, 
          reportName, 
          shared, 
          rowsPerPage, 
          page,
          data: response,
        },
      });
    } catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
  }
  
  export function* getScoreboardSaga({ startDate }) {
    try {
      const result = yield call(
        jointReportsService.getScoreboard,
        startDate
      );

      const { response } = result;
  
      if (response) {
        const [header, targets, stats] = response;
        yield put({
          type: jointReportConstants.GET_SCORE_BOARD_SUCCESS,
          payload: { header, targets, stats },
        });
      }
    } catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
  }
  
  export function* getAListReportSaga({ regionId, marketId }) {
    try {
      yield put({
        type: jointReportConstants.GET_A_LIST_REPORT_LOADING_TRUE,
      });
      const result = yield call(
        jointReportsService.getAList,
        regionId,
        marketId
      );
  
      let { response } = result;
  
      if (response) {
        yield put({
          type: jointReportConstants.GET_A_LIST_REPORT_SUCCESS,
          payload: { data: response, marketId, regionId },
        });
      }
    } catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
  }

  export function* getRemittanceSaga({ payload }) {
    const { entityIDs } = payload;
    try {
     
      const response = yield call( jointReportsService.getRemittance, payload );
      const { data } = response;
  
      if (response) {
        yield put({
          type: jointReportConstants.GET_REMITTANCE_SUCCESS,
          payload: { entityIDs: entityIDs, data: data },
        });
      }
    } catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
  }
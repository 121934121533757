import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";

import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import { telecomClaimService } from "_services";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    backgroundColor: theme.palette.WhitePrg.main,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "4px",

    outline: "none",
  },
  save: {
    backgroundColor: theme.palette.GreenPrg.main,
    color: theme.palette.WhitePrg.main,
  },
  h3: {
    color: "var(--LightGray)",
    margin: "5px",
  },

  close: {
    top: "-15px",
    right: 0,
    position: "absolute",
    backgroundColor: "var(--White)",
    borderRadius: "4px 4px 0 0",
    padding: "2px 5px",
    margin: 0,
  },
  icon: {
    color: theme.palette.DarkGrayPrg.main,
    cursor: "pointer",
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const ViewFileModal = ({ openModal, handleCleanup, path }) => {
  const classes = useStyles();

  const [copySuccess, setCopySuccess] = useState("");

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  // const [link, setLink] = useState();

  // const fetchMyAPI = useCallback(async () => {
  //   if (path) {
  //     let expireDate = moment().add(7, "days").calendar();
  //     let encodePath = encodeURI(path);
  //     let response = await telecomClaimService.getSignedUrl(
  //       encodePath,
  //       expireDate
  //     );
  //     setLink(response["url"]);
  //     return;
  //   }
  //   setLink();
  // }, [path]);

  // useEffect(() => {
  //   fetchMyAPI();
  // }, [fetchMyAPI]);

  useEffect(() => {
    let timer1 = setTimeout(() => setCopySuccess(), 5 * 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, [copySuccess]);

  let copyToClipboard = (path) => {
    navigator.clipboard.writeText(path);
    setCopySuccess("File Link Copied To Clipboard!");
  };

  return (
    <Dialog open={openModal} onClose={handleCleanup} closeAfterTransition fullScreen={isMobile}>
      <DialogTitle sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          aria-label="close"
          onClick={handleCleanup}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ position: "relative" }}>
        <Box
          sx={{
            width: { xs: "100%", md: "500px" },
            height: { xs: "100%", md: "500px" },
          }}
        >
          {path ? (
            <Fade in={!!path}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                src={path}
                alt="Claim/Project"
              />
            </Fade>
          ) : (
            <Skeleton variant="rect" width={500} height={500} />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ViewFileModal;

import { getApiBase } from "_helpers";
import { client } from "_helpers/websocket";

const login = (request) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(request.user),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/auth/login`, parameters)
    .then(async (response) => {
      if (response.status === 401) {
        let data = await response.json();
        throw new Error(data.error);
      }
      if (response.status !== 200 && response.status !== 401) throw new Error("login failed");
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const forgotPassword = (payload) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(payload),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/auth/forgotpassword`, parameters)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const checkPasswordResetJwt = (token) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/auth/passwordjwtcheck?token=${token}`, parameters)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const resetPassword = (payload, token) => {
  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(payload),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/auth/resetpassword?token=${token}`, parameters)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};


const assumeUser = (payload) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(payload),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/auth/assumeUser`, parameters)
    .then((response) => {
      if (response.status !== 200) throw new Error("Assume User Failed");
      client?.close();
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};


const assumeRole = (payload) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(payload),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/auth/assumeRole`, parameters)
    .then((response) => {
      if (response.status !== 200) throw new Error("Assume Role Failed");
      client?.close();
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const endImpersonation = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/auth/assumeRole/endImpersonation`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("An error occured ending Impersonation");
      }
      client?.close();
      return response
    })
    .catch((err) => {
      throw err;
    });
};

export const userService = {
  login,
  forgotPassword,
  resetPassword,
  checkPasswordResetJwt,
  assumeUser,
  assumeRole,
  endImpersonation
};

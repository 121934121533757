import { combineReducers } from "redux";
import { adminRoles } from "./adminRole.reducer";
import { adminSchema } from "./adminSchema.reducer";
import { auth } from "./auth.reducer";
import { alert } from "./alert.reducer";
import { permission } from "./permission.reducer";
import { lob } from "./lob.reducer";
import { settings } from "./settings.reducer";
import { adminEntityManagement } from "./admin_entityManagement.reducer";

// Telecom
import telecom from "./lob/telecom/index";

// Power Gas
import powerGas from "./lob/powerGas/index";

// Frelo
import frelo from "./lob/frelo/index";

// Joint
import joint from "./lob/joint/index";

// Admin
import admin from "./admin/index";

// mobile
import { mobile } from "./mobile/mobile.reducer";

// powerbi
import { powerbi } from "./powerbi/powerbi.reducer";

// payment
import { payment } from "./payment/payment.reducer";

const rootReducer = combineReducers({
  admin,
  adminEntityManagement,
  auth,
  adminRoles,
  adminSchema,
  alert,
  permission,
  lob,
  telecom,
  powerGas,
  frelo,
  joint,
  mobile,
  settings,
  powerbi,
  payment,
});

export default rootReducer;

import { combineReducers } from "redux";
import { knowledgeBase } from "./knowledgeBase.reducer.js";
import { users } from "./users.reducer";
import { entity } from "./entity.reducer";
import { notifications } from "./notifications.reducer";
import { roundRobin } from "./roundRobin.reducer";
import { claimStatus } from "./claimStatus.reducer";
import { powerBiRoles } from "./powerBiRoles.js";
import { recoveryAgent } from "./recoveryAgent.reducer";
import { states } from "./states.reducer";
import { formControl } from "./formControl.reducer";
import { jobCosting } from "./jobCosting.reducer.js";


const adminRootReducer = combineReducers({
  knowledgeBase,
  users,
  entity,
  notifications,
  roundRobin,
  claimStatus,
  powerBiRoles,
  recoveryAgent,
  states,
  formControl,
  jobCosting
});

export default adminRootReducer;

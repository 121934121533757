import { adminPowerBiRoleConstants } from "../../_constants";
import { produce } from "immer";
import { REHYDRATE } from "redux-persist/lib/constants";

let initState = {
  all: [],
  general: {},
  rolesLoading: false,
  saveLoading: false
};

export function powerBiRoles(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminPowerBiRoleConstants.GET_ALL_POWERBI_ROLES_SUCCESS:
      return produce(state, (draft) => {
        draft["all"] = [...payload];
        draft["rolesLoading"] = false;
      });
    case adminPowerBiRoleConstants.GET_ALL_POWERBI_ROLES_LOADING:
      return produce(state, (draft) => {
        draft["rolesLoading"] = true;
      });
    case adminPowerBiRoleConstants.UPDATE_POWERBI_ROLE_SUCCESS:
      return produce(state, (draft) => {
        draft["saveLoading"] = false;
      });
    case adminPowerBiRoleConstants.UPDATE_POWERBI_ROLE_LOADING:
      return produce(state, (draft) => {
        draft["saveLoading"] = true;
      });
    case adminPowerBiRoleConstants.ADMIN_POWERBI_ROLES_CLEAR:
      return { ...initState };
    case REHYDRATE: {
      if (payload && payload.powerBiRoles) {
        return { ...payload.powerBiRoles };
      } else {
        return { ...initState };
      }
    }
    default:
      return state;
  }
}

export const adminClaimStatusConstants_ = {
    ADD_STATUS_REQUEST:"ADD_STATUS_REQUEST",
    ADD_STATUS_SUCCESS:"ADD_STATUS_SUCCESS",
    GET_ALL_STATUS_REQUEST: "GET_ALL_STATUS_REQUEST",
    GET_ALL_STATUS_SUCCESS: "GET_ALL_STATUS_SUCCESS",
    GET_ONE_STATUS_REQUEST:"GET_ONE_STATUS_REQUEST",
    GET_ONE_STATUS_SUCCESS:"GET_ONE_STATUS_SUCCESS",
    GET_USERS_REQUEST:"GET_USERS_REQUEST",
    GET_USERS_SUCCESS:"GET_USERS_SUCCESS",
    GET_STATUS_ACCESS_REQUEST:"GET_STATUS_ACCESS_REQUEST",
    GET_STATUS_ACCESS_SUCCESS:"GET_STATUS_ACCESS_SUCCESS",
    ADD_USERS_REQUEST:"ADD_USERS_REQUEST",
    ADD_USERS_SUCCESS:"ADD_USERS_SUCCESS",
    REMOVE_USER_REQUEST:"REMOVE_USER_REQUEST",
    REMOVE_USER_SUCCESS:"REMOVE_USER_SUCCESS",
    UPDATE_ONE_STATUS_REQUEST:"UPDATE_ONE_STATUS_REQUEST",
    UPDATE_ONE_STATUS_SUCCESS:"UPDATE_ONE_STATUS_SUCCESS",
    ADMIN_CLAIM_STATUS_CLEAR: "ADMIN_CLAIM_STATUS_CLEAR",
  };
  
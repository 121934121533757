import { adminEntityConstants_ } from "_constants";

const getAllEntity = () => {
  return {
    type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_REQUEST,
  };
};
const createEntity = (files, fieldValues,lob) => {
  return {
    type: adminEntityConstants_.CREATE_ENTITY_REQUEST,
    payload: { ...fieldValues, files },
    lob
  };
};

const getSchemaLob = () => {
  return {
    type: adminEntityConstants_.GET_ALL_ADMIN_SCHEMA_LOB_REQUEST,
  };
};

const getAllStatus = (entityId, lob) => {
  return {
    type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_STATUS_REQUEST,
    entityId,
    lob,
  };
};

const updateStatus = (dataEdits, entityId, lob) => {
  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_STATUS_REQUEST,
    dataEdits,
    entityId,
    lob,
  };
};

const getAllSowContracts = (entityId, lob) => {
  return {
    type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_SOW_CONTRACTS_REQUEST,
    entityId,
    lob,
  };
};

const updateSowContracts = (dataEdits, entityId, lob) => {
  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_SOW_CONTRACTS_REQUEST,
    dataEdits,
    entityId,
    lob,
  };
};

const addSowContract = (payload, entityId, lob) => {
  return {
    type: adminEntityConstants_.ADD_ADMIN_ENTITY_SOW_CONTRACT_REQUEST,
    payload,
    entityId,
    lob,
  };
};

const getBillingTypesSchema = (entityId, lob) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_BILLING_TYPES_REQUEST,
    entityId,
    lob,
  };
};

const getRdaSchema = (entityId, lob) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_RDA_REQUEST,
    entityId,
    lob,
  };
};

const getSubAccountSchema = (entityId, lob) => {
  return {
    type: adminEntityConstants_.ADMIN_ENTITY_SCHEMA_SUB_ACCOUNTS_REQUEST,
    entityId,
    lob,
  };
};

const getRdaControl = (entityId, lob) => {
  return {
    type: adminEntityConstants_.GET_ALL_ADMIN_ENTITY_RDA_CONTROL_REQUEST,
    entityId,
    lob,
  };
};
const getRdaOptionsSchema = (entityId, lob) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_OPTIONS_SCHEMA_REQUEST,
    entityId,
    lob,
  };
};

const updateRdaControl = (dataEdits, entityId, lob) => {
  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_RDA_CONTROL_REQUEST,
    dataEdits,
    entityId,
    lob,
  };
};
const addRdaControl = (rda, entityId, lob) => {
  return {
    type: adminEntityConstants_.ADD_ADMIN_ENTITY_RDA_CONTROL_REQUEST,
    rda,
    entityId,
    lob,
  };
};

const getBillingTypes = (entityId, lob) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_BILLING_TYPES_REQUEST,
    entityId,
    lob,
  };
};

const addbillingType = (payload, entityId, lob) => {
  return {
    type: adminEntityConstants_.ADD_ADMIN_ENTITY_BILLING_TYPE_REQUEST,
    payload,
    entityId,
    lob,
  };
};

const updateBillingType = (payload, entityId, lob) => {
  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_BILLING_TYPE_REQUEST,
    payload,
    entityId,
    lob,
  };
};

const getSubAccounts = (entityId, lob) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_SUB_ACCOUNTS_REQUEST,
    entityId,
    lob,
  };
};

const getSubaccountRda = (entityId, lob) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_SUBACCOUNTS_RDA_SCHEMA_REQUEST,
    entityId,
    lob,
  };
};

const updateSubAccounts = (dataEdits, entityId, lob) => {
  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_SUB_ACCOUNTS_REQUEST,
    dataEdits,
    entityId,
    lob,
  };
};

const addSubAccount = (payload, entityId, lob) => {
  return {
    type: adminEntityConstants_.ADD_ADMIN_ENTITY_SUB_ACCOUNT_REQUEST,
    payload,
    entityId,
    lob,
  };
};

const getContacts = (entityId, lob) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_CONTACTS_REQUEST,
    entityId,
    lob,
  };
};

const updateContacts = (dataEdits, entityId, lob) => {
  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_CONTACTS_REQUEST,
    dataEdits,
    entityId,
    lob,
  };
};

const addContact = (contact, entityId, lob) => {
  return {
    type: adminEntityConstants_.ADD_ADMIN_ENTITY_CONTACT_REQUEST,
    contact,
    entityId,
    lob,
  };
};

const getDocInvoiceTemplate = (entityId, lob) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_REQUEST,
    entityId,
    lob,
  };
};

const updateDocInvoiceTemplate = (entityId, lob, payload) => {
  return {
    type:
      adminEntityConstants_.UPDATE_ADMIN_ENTITY_DOC_INVOICE_TEMPLATE_REQUEST,
    entityId,
    lob,
    payload,
  };
};

const getDocFieldMentions = (entityId, lob) => {
  return {
    type:
      adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_FIELD_MENTIONS_REQUEST,
    entityId,
    lob,
  };
};

const getClaimDocReasonSchema = (entityId, lob) => {
  return {
    type:
      adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_REASONS_REQUEST,
    entityId,
    lob,
  };
};

const getClaimDocLastSentenceSchema = (entityId, lob) => {
  return {
    type:
      adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_LAST_SENTENCE_REQUEST,
    entityId,
    lob,
  };
};

//Claim Letter contact

const getClaimDocContactsSchema = (lob) => {
  return {
    type:
      adminEntityConstants_.GET_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST,
    lob,
  };
};
const updateClaimDocContactsSchema = (payload, cb=null) => {
  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST,
    payload,
    cb
  };
};

const addClaimDocContactsSchema = (payload, id, cb=null) => {
  return {
    type: adminEntityConstants_.ADD_ADMIN_ENTITY_SCHEMA_DOCS_CLAIM_CONTACTS_REQUEST,
    payload,
    id,
    cb
  };
};

///end

const updateClaimDocTemplate = (entityId, lob, letterType, payload) => {
  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_DOCS_CLAIM_REQUEST,
    entityId,
    lob,
    letterType,
    payload,
  };
};

const getDocClaimTemplate = (entityId, lob, letterType) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_DOC_CLAIM_TEMPLATE_REQUEST,
    entityId,
    lob,
    letterType,
  };
};

const getRdaControlRegions = (lob, entityId) => {
  return {
    type:
      adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_REGIONS_REQUEST,
    lob,
    entityId,
  };
};

const postRdaControlRegion = (regionName, lob, entityId) => {
  return {
    type: adminEntityConstants_.POST_ADMIN_ENTITY_RDA_CONTROL_REGION_REQUEST,
    regionName,
    lob,
    entityId,
  };
};

const updateRdaControlRegions = (data, entityId, lob) => {
  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_RDA_CONTROL_REGIONS_REQUEST,
    data,
    entityId,
    lob,
  };
};

const getRdaControlDivisions = (lob, entityId) => {
  return {
    type:
      adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_DIVISIONS_REQUEST,
    lob,
    entityId,
  };
};

const postRdaControlDivision = (divisionName, lob, entityId) => {
  return {
    type: adminEntityConstants_.POST_ADMIN_ENTITY_RDA_CONTROL_DIVISION_REQUEST,
    divisionName,
    lob,
    entityId,
  };
};

const updateRdaControlDivisions = (data, entityId, lob) => {
  return {
    type:
      adminEntityConstants_.UPDATE_ADMIN_ENTITY_RDA_CONTROL_DIVISIONS_REQUEST,
    data,
    entityId,
    lob,
  };
};

const getRdaControlAreas = (lob, entityId) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_RDA_CONTROL_ALL_AREAS_REQUEST,
    lob,
    entityId,
  };
};

const postRdaControlArea = (areaName, lob, entityId) => {
  return {
    type: adminEntityConstants_.POST_ADMIN_ENTITY_RDA_CONTROL_AREA_REQUEST,
    areaName,
    lob,
    entityId,
  };
};

const updateRdaControlAreas = (data, entityId, lob) => {
  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_RDA_CONTROL_AREAS_REQUEST,
    data,
    entityId,
    lob,
  };
};

const getInvoicing = (entityId, lobId) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_REQUEST,
    entityId,
    lobId,
  };
};

const updateInvoicing = (entityId, lobId, settings, newLineItems, editLineItems, deleteLineItems) => {

  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_INVOICING_REQUEST,
    entityId,
    lobId,
    settings,
    newLineItems,
    editLineItems,
    deleteLineItems
  };
};

const getInvoicingLineItems = (entityId, lobId) => {
  return {
    type: adminEntityConstants_.GET_ADMIN_ENTITY_INVOICING_LINE_ITEMS_REQUEST,
    entityId,
    lobId,
  };
};

const createInvoicingLineItem = (payload, entityId, lobId) => {
  return {
    type: adminEntityConstants_.CREATE_ADMIN_ENTITY_INVOICING_LINE_ITEM_REQUEST,
    payload,
    entityId,
    lobId,
  };
};

const updateInvoicingLineItem = (payload, entityId, lobId, lineItemId) => {
  return {
    type: adminEntityConstants_.UPDATE_ADMIN_ENTITY_INVOICING_LINE_ITEM_REQUEST,
    payload,
    entityId,
    lobId,
    lineItemId
  };
};

const deleteInvoicingLineItem = (lineItemId) => {
  return {
    type: adminEntityConstants_.DELETE_ADMIN_ENTITY_INVOICING_LINE_ITEM_REQUEST,
    lineItemId
  };
};

export const adminEntityActions_ = {
  getAllEntity,
  createEntity,
  getSchemaLob,
  getAllStatus,
  updateStatus,
  getAllSowContracts,
  updateSowContracts,
  addSowContract,
  getBillingTypesSchema,
  getRdaSchema,
  getSubAccountSchema,
  getRdaControl,
  getRdaOptionsSchema,
  updateRdaControl,
  addRdaControl,
  getBillingTypes,
  addbillingType,
  updateBillingType,
  getSubAccounts,
  getSubaccountRda,
  updateSubAccounts,
  addSubAccount,
  getContacts,
  updateContacts,
  addContact,
  getDocInvoiceTemplate,
  updateDocInvoiceTemplate,
  getDocFieldMentions,
  getClaimDocReasonSchema,
  getClaimDocLastSentenceSchema,
  getClaimDocContactsSchema,
  updateClaimDocContactsSchema,
  addClaimDocContactsSchema,
  updateClaimDocTemplate,
  getDocClaimTemplate,
  getRdaControlRegions,
  postRdaControlRegion,
  updateRdaControlRegions,
  getRdaControlDivisions,
  postRdaControlDivision,
  updateRdaControlDivisions,
  getRdaControlAreas,
  postRdaControlArea,
  updateRdaControlAreas,
  getInvoicing,
  updateInvoicing,
  getInvoicingLineItems,
  createInvoicingLineItem,
  updateInvoicingLineItem,
  deleteInvoicingLineItem
};

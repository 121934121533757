export const telecomDispatchConstants = {
  GET_ALL_DISPATCH_TELECOM_REQUEST: "GET_ALL_DISPATCH_TELECOM_REQUEST",
  GET_ALL_DISPATCH_TELECOM_SUCCESS: "GET_ALL_DISPATCH_TELECOM_SUCCESS",
  GET_ONE_DISPATCH_TELECOM_REQUEST: "GET_ONE_DISPATCH_TELECOM_REQUEST",
  GET_ONE_DISPATCH_TELECOM_SUCCESS: "GET_ONE_DISPATCH_TELECOM_SUCCESS",
  UPDATE_ONE_DISPATCH_TELECOM_REQUEST: "UPDATE_ONE_DISPATCH_TELECOM_REQUEST",
  UPDATE_ONE_DISPATCH_TELECOM_SUCCESS: "UPDATE_ONE_DISPATCH_TELECOM_SUCCESS",
  UPDATE_TEN_EIGHT_DISPATCH_TELECOM_REQUEST:
    "UPDATE_TEN_EIGHT_DISPATCH_TELECOM_REQUEST",
  UPDATE_TEN_EIGHT_DISPATCH_TELECOM_SUCCESS:
    "UPDATE_TEN_EIGHT_DISPATCH_TELECOM_SUCCESS",
  TELECOM_DISPATCH_CLEAR: "TELECOM_DISPATCH_CLEAR",

  UPDATE_DISPATCH_TELECOM_TIMER_REQUEST: "UPDATE_DISPATCH_TELECOM_TIMER_REQUEST",
  UPDATE_DISPATCH_TELECOM_TIMER_SUCCESS:"UPDATE_DISPATCH_TELECOM_TIMER_SUCCESS",
  UPDATE_DISPATCH_TELECOM_TIMER_PAUSE_REQUEST:"UPDATE_DISPATCH_TELECOM_TIMER_PAUSE_REQUEST",
  UPDATE_DISPATCH_TELECOM_TIMER_PAUSE_SUCCESS:"UPDATE_DISPATCH_TELECOM_TIMER_PAUSE_SUCCESS",
  UPDATE_DISPATCH_TELECOM_TIMER_COLOR_REQUEST:"UPDATE_DISPATCH_TELECOM_TIMER_COLOR_REQUEST",
  UPDATE_DISPATCH_TELECOM_TIMER_COLOR_SUCCESS:"UPDATE_DISPATCH_TELECOM_TIMER_COLOR_SUCCESS"
};

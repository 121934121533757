import { put, call } from "redux-saga/effects";
import {
  adminSchemaConstants,
  alertConstants,
  authConstants,
} from "../_constants";
import { adminSchemaService } from "_services";
import { isEmpty } from "ramda";

export function* getPermissionAccessTypesSaga() {
  try {
    const response = yield call(adminSchemaService.getPermissionAccessTypes);

    if (isEmpty(response)) {
      return;
    }

    yield put({
      type: adminSchemaConstants.GET_ADMIN_ACCESS_TYPES_SCHEMA_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getConditionTypesSaga({ lob }) {
  try {
    const response = yield call(adminSchemaService.getConditionTypes, lob);

    if (isEmpty(response)) {
      return;
    }

    yield put({
      type: adminSchemaConstants.GET_ADMIN_CONDITIONAL_TYPES_SCHEMA_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getNotificationTypesSaga({ lob }) {
  try {
    const response = yield call(adminSchemaService.getNotificationTypes, lob);

    yield put({
      type: adminSchemaConstants.GET_ADMIN_NOTIFICATION_TYPES_SCHEMA_SUCCESS,
      payload: {
        lob,
        data: [...response],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getNotificationFormFieldsSaga({ lob, formId }) {
  try {
    const response = yield call(
      adminSchemaService.getNotificationFormFields,
      lob,
      formId
    );

    yield put({
      type:
        adminSchemaConstants.GET_ADMIN_NOTIFICATION_FORM_FIELDS_SCHEMA_SUCCESS,
      payload: {
        lob,
        formId,
        data: [...response],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getNotificationRecipientTypesSaga({ lob }) {
  try {
    const response = yield call(
      adminSchemaService.getNotificationRecipientTypes,
      lob
    );

    let responseTransform = response.map((e) => ({
      ...e,
      sms: false,
      email: false,
    }));

    let cleanEmpty = responseTransform.filter(
      (e) => e["recipientTypeName"] !== ""
    );

    yield put({
      type:
        adminSchemaConstants.GET_ADMIN_NOTIFICATION_RECIPIENT_TYPES_SCHEMA_SUCCESS,
      payload: {
        lob,
        data: [...cleanEmpty],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

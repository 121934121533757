import { getApiBase } from "_helpers";
import { cookieService } from "_services";
import _uniqueId from "lodash/uniqueId";

const getRates = (payload) => {
  const { lobid, entityid, categoryid, regionid, divisionid, areaid } = payload;

  const query = `?lobid=${lobid}&entityid=${entityid}&categoryid=${categoryid}${
    regionid ? `&regionid=${regionid}` : ""
  }${divisionid ? `&divisionid=${divisionid}` : ""}${areaid ? `&areaid=${areaid}` : ""}`;

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/jobcosting${query}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching rates");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const addRate = (payload) => {
  const { lobid, entityid, categoryid, regionid, divisionid, areaid, description, cost } = payload;

  const query = `?lobid=${lobid}&categoryid=${categoryid}`;
  const body = {
    entityid,
    regionid,
    divisionid,
    areaid,
    description,
    cost,
  };

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/jobcosting${query}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error creating rate");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateRate = (payload) => {
  const { lobid, categoryid, itemid, regionid, divisionid, areaid, description, cost } = payload;

  const query = `?lobid=${lobid}&categoryid=${categoryid}&itemid=${itemid}`;
  const body = {
    description,
    cost,
  };

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/jobcosting${query}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 201) {
        throw new Error("error updating rate");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const deleteRate = (payload) => {
  const { lobid, categoryid, itemid } = payload;

  const query = `?lobid=${lobid}&categoryid=${categoryid}&itemid=${itemid}`;

  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/jobcosting${query}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error deleting rate");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

export const adminJobCostingService = {
  getRates,
  addRate,
  updateRate,
  deleteRate,
};

import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const getRoles = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/role${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching roles");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getGeneral = (roleId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/general?roleid=${roleId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching role general");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateRoleGeneral = (payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/role/general`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating role general");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getDbAccess = (roleId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/dbaccess?roleid=${roleId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching role db access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateDbAccess = (payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/admin/role/dbaccess`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating db access");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getRda = (roleId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/rdaaccess?roleid=${roleId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching role rda");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

//Dashoards

const getDashboard = (roleId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/dashboard?roleid=${roleId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching Dashboard");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const addDashboard = (payload, roleId) => {
  let body = {
    ...payload,
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/dashboard?roleid=${roleId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error Adding a Panel");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const removeDashboard = (pannelId, roleId) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/dashboard?roleid=${roleId}&pannelid=${pannelId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error Removing Panel");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateDashboard = (payload, roleId) => {
  let body = {
    ...payload,
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/dashboard?roleid=${roleId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error Updating Panel");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateDashboardPosition = (payload, dashid) => {
  let body = {
    ...payload,
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/role/dashboard/position?dashid=${dashid}&pannelid=${payload["i"]}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      return { status: response.status, id: payload["i"] };
    })
    .catch((err) => {
      throw err;
    });
};

const createRole = (payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/role${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating role");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const cloneRole = (payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/admin/role/clone${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error cloning role");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};


const getEntities = (roleId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/entityaccess?roleid=${roleId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching role Entity Access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateEntities = ({ entityId, lob, ...payload }, roleid) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  // let apiBase = "http://localhost:3000/api"; // local host testing
  let apiBase = getApiBase();

  return fetch(
    `${apiBase}/admin/role/entityaccess?roleid=${roleid}&entityid=${entityId}&lobid=${lob}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id: roleid };
    })
    .catch((err) => {
      throw err;
    });
};

// Action Access
// =================

const getRoleActionAccess = (roleId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/actionaccess?roleId=${roleId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching role action access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateRoleActionAccess = (payload, roleId, accessTypeLOB) => {

  let body = {
    ...payload,
    roleId: Number(roleId),
    accessTypeLOB: Number(accessTypeLOB)
  };

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body)
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/actionaccess?${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error updating role action access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

// =================

// Status Access
// =================

const getRoleStatusAccess = (roleId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/statusaccess?roleId=${roleId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching role status access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateRoleStatusAccess = (roleId, statusId, LOBID, statusType, statusAccessType) => {

  let body = {
    roleId,
    statusId,
    LOBID,
    statusType,
    statusAccessType
  };

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body)
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/statusaccess?${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error updating role status access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const deleteRoleStatusAccess = (statusId,) => {

  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include"
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/admin/role/statusaccess?roleStatusAccessId=${statusId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error updating role status access");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

// =================

/* =================
NOTIFICATIONS
*/
const getRoleNotifications = async (roleId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/admin/role/notifications?roleId=${roleId}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error fetching role notifications");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const updateRoleNotifications = async (roleId, notificationIds) => {
  const body = {
    notificationIds
  }
  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body)
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/admin/role/notifications?roleId=${roleId}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error updating role notifications");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const deleteRoleNotifications = async (roleId) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";

  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/admin/role/notifications?roleId=${roleId}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error deleting role notificaitons");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

 
export const adminRoleService = {
  getRoles,
  createRole,
  cloneRole,
  getGeneral,
  updateRoleGeneral,
  getDbAccess,
  updateDbAccess,
  getDashboard,
  addDashboard,
  removeDashboard,
  updateDashboard,
  updateDashboardPosition,
  getRda,
  getEntities,
  updateEntities,
  getRoleActionAccess,
  updateRoleActionAccess,
  getRoleStatusAccess,
  updateRoleStatusAccess,
  deleteRoleStatusAccess,
  getRoleNotifications,
  updateRoleNotifications,
  deleteRoleNotifications
};

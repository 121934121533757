import { paymentConstants } from "_constants";

const getPayment = (payload) => {
    return {
      type: paymentConstants.GET_PAYMENT_REQUEST,
      payload
    };
  };

  const clearPayment = (payload) => {
    return {
      type: paymentConstants.PAYMENT_CLEAR
    }
  }

export const paymentActions = {
  getPayment,
  clearPayment
};
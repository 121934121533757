import React from "react";
import { Route, Redirect } from "react-router-dom";
import { cookieService } from "_services";

export const PrivateRouteMobile = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return cookieService.getMobile("token") ? (
          <Component {...props} />
        ) : (
          <Redirect
            exact
            // to={{ pathname: "/", state: { from: props.location } }}
            to={`/mobile-claim/my-list/`}
          />
        );
      }}
    />
  );
};

import { adminFormControlConstants } from "_constants";

const getFields = (LOBID, formId) => {
  return {
    type: adminFormControlConstants.GET_FIELDS_REQUEST,
    LOBID,
    formId,
  };
};

const getEntityRules = (LOBID, fieldId) => {
  return {
    type: adminFormControlConstants.GET_ENTITY_RULES_REQUEST,
    LOBID,
    fieldId,
  };
};

const getRoleRules = (LOBID, fieldId) => {
  return {
    type: adminFormControlConstants.GET_ROLE_RULES_REQUEST,
    LOBID,
    fieldId,
  };
};

const createAndUpdateFieldRules = (
  LOBID,
  formId,
  entityRulesPOST,
  entityRulesPUT,
  roleRulesPOST,
  roleRulesPUT
) => {
  return {
    type: adminFormControlConstants.CREATE_AND_UPDATE_FIELD_RULES_REQUEST,
    LOBID,
    formId,
    entityRulesPOST,
    entityRulesPUT,
    roleRulesPOST,
    roleRulesPUT,
  };
};

const deleteEntityRules = (LOBID, formId, fieldId) => {
  return {
    type: adminFormControlConstants.DELETE_ENTITY_RULES_REQUEST,
    LOBID,
    formId,
    fieldId,
  };
};

const deleteRoleRules = (LOBID, formId, fieldId) => {
  return {
    type: adminFormControlConstants.DELETE_ROLE_RULES_REQUEST,
    LOBID,
    formId,
    fieldId,
  };
};

export const adminFormControlActions = {
  getFields,
  getEntityRules,
  getRoleRules,
  createAndUpdateFieldRules,
  deleteEntityRules,
  deleteRoleRules,
};

import { jointClaimsQueueConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";

let initState = {
  my: [],
  team: [],
  roleId: null,
  loading: true
};

export function claimsQueue(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case jointClaimsQueueConstants.GET_CLAIMS_QUEUE_SUCCESS:
      return produce(state, (draft) => {
        draft["my"] = [...payload.queues.MyClaimsQueue];
        draft["team"] = [...payload.queues.MyTeamsQueue];
        draft["roleId"] = payload.roleId;
        draft["loading"] = false;
      })
    case jointClaimsQueueConstants.CLEAR_QUEUE:
      return produce(state, (draft) => {
        draft["my"] = [];
        draft["team"] = [];
        draft["roleId"] = null;
        draft["loading"] = true;
      })
    case REHYDRATE: {
      if (payload && payload.joint && payload.joint.claimsQueue) {
        return { ...payload.joint.claimsQueue };
      } else {
        return { ...initState };
      }
    }
    default:
      return state;
  }
}

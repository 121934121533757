import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _getProperty from "lodash/get";
import { mobileActions } from "_actions";
import Breadcrumbs from "_components/core/Breadcrumbs";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Documents from "../Documents";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Basic from "./components/Basic";
import ClaimDetails from "./components/ClaimDetails";
import PoliceInformation from "./components/PoliceInformation";
import LocateTicket from "./components/LocateTicket";
import DamagingParty from "./components/DamagingParty";
import RepairInformation from "./components/RepairInformation";
import TimeAndMaterials from "./components/TimeAndMaterials";
import Prism from "./components/Prism";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Container from "@material-ui/core/Container";
import LinearProgress from "@mui/material/LinearProgress";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    boxShadow: "none !important",
    padding: 20,
    [theme.breakpoints.up('md')]: {
      padding: 40
    }
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "40px 10px 0",
  },
}));
let times = ["", "", ""];

export default function Index() {
  const classes = useStyles();
  const { entityID, mobileClaimID } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    control,
    formState,
    formState: { errors, dirtyFields, isDirty },
    trigger,
  } = useForm();

  useEffect(() => {
    dispatch(mobileActions.getFull(mobileClaimID));
  }, []);

  const hydration = useSelector((state) => _getProperty(state, ["mobile", "form"]));
  const isLoading = useSelector((state) => _getProperty(state, ["mobile", "formLoading"]));
  const isLoadingConvert = useSelector((state) => _getProperty(state, ["mobile", "convertLoading"]));
  const convertClaimID = useSelector((state) => _getProperty(state, ["mobile", "convertClaimID"]));

  const [pages, setPages] = useState([
    "Basic",
    "ClaimDetails",
    "PoliceInformation",
    "LocateTicket",
    "DamagingParty",
    "Documents",
    "RepairInformation",
    "TimeAndMaterials",
    "Prism",
  ]);
  const [page, setPage] = useState(0);
  const currentPage = pages[page];

  // Set Initial Values for page (refreshes each time page forward/back occurs)
  useEffect(() => {
    if (!hydration) return;
    let hydrationValues_immutable = Object.keys(hydration).reduce((prev, section) => {
      // only load default values for current page set, otherwise isDirty field malfunctions
      if (section === currentPage) {
        let sectionFieldValues = Object.keys(hydration[section]).reduce((prev_inner, key_inner) => {
          let value = hydration[section][key_inner]["value"];
          return {
            ...prev_inner,
            [key_inner]: value,
          };
        }, {});
        return { ...prev, ...sectionFieldValues };
      } else return { ...prev };
    }, {});

    reset({ ...hydrationValues_immutable }, { isDirty: false });
  }, [hydration, reset, currentPage]);

  const onSubmit = (data) => {
    dispatch(mobileActions.updateFull(data, mobileClaimID));
  };

  const [breadCrumbs, setBreadCrumbs] = useState();
  useEffect(() => {
    if (entityID) {
      let breadCrumbs_x = [
        { label: "Invoices", url: `/mobile-claim/my-list` },
        {
          label: <span style={{ color: "#c7c2c2" }}>{entityID && ` Mobile Claim: ${mobileClaimID}`}</span>,
        },
      ];
      setBreadCrumbs(breadCrumbs_x);
    }
  }, [classes, entityID, mobileClaimID]);


  const pageForward = async () => {
    const values = getValues();
    const validate = await trigger();

    if (isDirty && !validate) return; // failed validation, do nothing

    if (isDirty) onSubmit(values); // save page

    // determine what pages should be displayed/hidden in the form
    if (currentPage === "ClaimDetails") {
      let pagesUpdated = [...pages];
      // damageCausedByExcavacation
      if (!values[308]) {
        pagesUpdated = pagesUpdated.filter((page) => page !== "LocateTicket");
      }
      // motorVehicleAccident
      if (!values[309]) {
        pagesUpdated = pagesUpdated.filter((page) => page !== "PoliceInformation");
      }
      // whoCausedDamage
      if (!values[311]) {
        pagesUpdated = pagesUpdated.filter((page) => page !== "DamagingParty");
      }
      // has Photos
      if (!values[312]) {
        pagesUpdated = pagesUpdated.filter((page) => page !== "Documents");
      }
      setPages(pagesUpdated);
    }
    setPage(page + 1);
  };

  const pageBack = () => {
    dispatch(mobileActions.getFull(mobileClaimID));
    if (page > 0) setPage(page - 1);
  };

  const onCancel = () => {
    window.location.href = `/mobile-claim/my-list`;
  };

  const completeClaim = () => {
    const values = getValues();
    if (isDirty) {
      onSubmit(values); // save page
    }
    setTimeout(() => {
      dispatch(mobileActions.createMoveToPrg(mobileClaimID, 1));
    }, 1000);
  };

  const redirect = () => {
    history.push(`/mobile-claim/dashboard`);
  };

  return (
    <Container maxWidth="xl">
      <Paper className={classes.root}>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs mapCrumbs={breadCrumbs || []} />
          </Grid>
        </Grid>
        {!hydration && (
          <Grid container item spacing={3}>
            {times.map((e) => (
              <Grid item xs={4}>
                <Box
                  sx={{
                    p: 8,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Skeleton
                    sx={{ bgcolor: "var(--DarkGray)", marginBottom: "10px", maxWidth: "400px" }}
                    variant="rounded"
                    animation="wave"
                    width={"100%"}
                    height={30}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--DarkGray)", maxWidth: "400px" }}
                    variant="rounded"
                    animation="wave"
                    width={"100%"}
                    height={30}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--DarkGray)", marginTop: "10px", maxWidth: "400px" }}
                    variant="rounded"
                    animation="wave"
                    width={"100%"}
                    height={30}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}

        {(isLoading || isLoadingConvert) && (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "50vh" }}>
            <CircularProgress />
          </Box>
        )}

        {hydration && !isLoading && !isLoadingConvert && !convertClaimID && (
          <>
            {currentPage === "Basic" && hydration.Basic && (
              <Basic
                control={control}
                register={register}
                errors={errors}
                watch={watch}
                hydration={hydration["Basic"]}
              />
            )}

            {currentPage === "ClaimDetails" && hydration.ClaimDetails && (
              <ClaimDetails
                control={control}
                register={register}
                errors={errors}
                hydration={hydration["ClaimDetails"]}
              />
            )}

            {currentPage === "PoliceInformation" && hydration.PoliceInformation && (
              <PoliceInformation
                control={control}
                register={register}
                errors={errors}
                hydration={hydration["PoliceInformation"]}
              />
            )}

            {currentPage === "LocateTicket" && (
              <LocateTicket
                control={control}
                register={register}
                errors={errors}
                hydration={hydration && hydration["LocateTicket"]}
              />
            )}

            {currentPage === "DamagingParty" && (
              <DamagingParty
                control={control}
                register={register}
                errors={errors}
                hydration={hydration && hydration["DamagingParty"]}
              />
            )}

            {currentPage === "Documents" && (
              <Documents
                mobileClaimID={mobileClaimID && mobileClaimID}
              />
            )}

            {currentPage === "RepairInformation" && (
              <RepairInformation
                control={control}
                register={register}
                errors={errors}
                hydration={hydration && hydration["RepairInformation"]}
              />
            )}

            {currentPage === "TimeAndMaterials" && (
              <TimeAndMaterials
                control={control}
                register={register}
                errors={errors}
                hydration={hydration && hydration["TimeAndMaterials"]}
              />
            )}

            {currentPage === "Prism" && (
              <Prism
                control={control}
                register={register}
                errors={errors}
                hydration={hydration && hydration["Prism"]}
              />
            )}

            <Divider sx={{ paddingTop: "40px" }} />

            <Box className={classes.navigation}>
              <Box sx={{ display: "flex", justifyContent: "center", width: "100px" }}>
                {page > 0 ? <Button onClick={pageBack}>Previous</Button> : <Button onClick={onCancel}>Cancel</Button>}
              </Box>
              <LinearProgress
                variant="determinate"
                color="primary"
                value={(page / pages.length) * 100}
                sx={{ width: "100%", margin: "0 40px" }}
              />
              <Box sx={{ display: "flex", justifyContent: "center", width: "100px" }}>
                {page < pages.length - 1 ? (
                  <Button variant="contained" onClick={pageForward}>
                    {page === 0 ? "Continue" : "Next"}
                  </Button>
                ) : (
                  <Button variant="contained" type="submit" onClick={completeClaim}>
                    Submit
                  </Button>
                )}
              </Box>
            </Box>
          </>
        )}

        {(convertClaimID && !isLoadingConvert && !isLoading) && (
          // <Paper>
            <Box
              sx={{
                padding: {xs: "40px 20px", md:"80px 40px"},
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h4" sx={{ marginBottom: "40px" }}>Claim completed</Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: "10px"}}>Please find your Claim ID below:</Typography>
              <Typography variant="h6" sx={{ marginBottom: "40px" }}>
                {convertClaimID}
              </Typography>
              <Button variant="contained" color="primary" onClick={redirect}>
                Return to my claims
              </Button>
            </Box>
          // </Paper>
        )}
      </Paper>
    </Container>
  );
}

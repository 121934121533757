import { adminFormControlConstants } from "_constants";
import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";

let initState = {
  fields: {
    loading: false,
    1: {}, // Tel
    2: {}, // PowGas
    3: {}, // frelo
  },
  rules: {
    loading: false,
    1: {}, // Tel
    2: {}, // PowGas
    3: {}, // frelo
  },
};

export function formControl(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case adminFormControlConstants.GET_FIELDS_REQUEST:
      return produce(state, (draft) => {
        draft["fields"]["loading"] = true;
      });

    case adminFormControlConstants.GET_FIELDS_SUCCESS:
      return produce(state, (draft) => {
        const { LOBID, formId, data } = payload;
        draft["fields"][LOBID][formId] = data;
        draft["fields"]["loading"] = false;
      });

    case adminFormControlConstants.GET_ENTITY_RULES_REQUEST:
    case adminFormControlConstants.GET_ROLE_RULES_REQUEST:
      return produce(state, (draft) => {
        draft["rules"]["loading"] = true;
      });

    case adminFormControlConstants.GET_ENTITY_RULES_SUCCESS:
      return produce(state, (draft) => {
        const { LOBID, fieldId, data } = payload;

        // add fieldId obj if it doesn't already exist
        if (!(fieldId in draft["rules"][LOBID])) {
          draft["rules"][LOBID][fieldId] = { entityRules: {}, roleRules: {} };
        }
        // reformat array of rules to obj
        const newEntityRules = data.reduce((acc, rule) => {
          let { entityId } = rule;
          return { ...acc, [entityId]: { ...rule } };
        }, {});

        draft["rules"][LOBID][fieldId]["entityRules"] = { ...newEntityRules };
        draft["rules"]["loading"] = false;
      });

    case adminFormControlConstants.GET_ROLE_RULES_SUCCESS:
      return produce(state, (draft) => {
        const { LOBID, fieldId, data } = payload;

        // add fieldId obj if it doesn't already exist
        if (!(fieldId in draft["rules"][LOBID])) {
          draft["rules"][LOBID][fieldId] = { entityRules: {}, roleRules: {} };
        }
        // reformat array of rules to obj
        const newRoleRules = data.reduce((acc, rule) => {
          let { roleId } = rule;
          return { ...acc, [roleId]: { ...rule } };
        }, {});

        draft["rules"][LOBID][fieldId]["roleRules"] = { ...newRoleRules };
        draft["rules"]["loading"] = false;
      });

    case REHYDRATE: {
      if (payload && payload.admin && payload.admin.formControl) {
        return { ...payload.admin.formControl };
      } else {
        return { ...initState };
      }
    }
    case adminFormControlConstants.ADMIN_FORMCONTROL_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}

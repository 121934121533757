import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { userService } from "_services";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import PRGLogo from "_assets/images/Logo/PRG-Logo.svg";
import { alertActions } from "_actions";
import { useDispatch } from "react-redux";
import { validation } from "_utility";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";

import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FilledInput from "@material-ui/core/FilledInput";
let { checkPassword } = validation;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.DarkGrayPrg.main,
    borderRadius: "4px",
    "& .MuiFormLabel-root": {
      color: `${theme.palette.DarkGrayPrg.main} !important`,
    },
    "& .MuiInputBase-root": {
      backgroundColor: `${theme.palette.WhitePrg.main} !important`,
    },
    "& .MuiInputBase-input": {
      color: `${theme.palette.DarkGrayPrg.main} !important`,
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "-100px",
    padding: "20px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.GreenPrg.main,
    "&:hover": {
      backgroundColor: theme.palette.GrayPrg.main,
      color: theme.palette.DarkGrayPrg.main,
    },
  },

  inputs: {
    backgroundColor: `${theme.palette.WhitePrg.main} !important`,
    color: `${theme.palette.DarkGrayPrg.main} !important`,
    borderRadius: "4px",
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  imgWrap: {
    position: "relative",
    top: "-100px",
    width: "202px",
    height: "202px",
    backgroundColor: theme.palette.BodyWhitePrg.main,
    borderRadius: "50%",
  },
  imgInnerWrap: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: 0,
    right: 0,
    margin: "auto",
    width: "166px",
    height: "166px",
    backgroundColor: theme.palette.WhitePrg.main,
    borderRadius: "50%",
    borderBottom: `solid 2px ${theme.palette.GreenPrg.main}`,
    boxShadow: `0 0 0px 8px ${theme.palette.GrayPrg.main}`,
  },
  img: {
    top: "50%",
    left: "-6px",
    width: "178px",
    position: "absolute",
    transform: "translateY(-50%)",
    right: 0,
    margin: "auto",
  },
  forgotPass: {
    cursor: "pointer",
    textAlign: "right",
    color: theme.palette.WhitePrg.main,
    "&:hover": {
      opacity: ".5",
    },
  },
  textWhite: {
    color: theme.palette.WhitePrg.main,
    textAlign: "center",
  },
  confirm: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.GreenPrg.main,
    "&:hover": {
      backgroundColor: theme.palette.GrayPrg.main,
      color: theme.palette.DarkGrayPrg.main,
    },
  },
  error: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.YellowPrg.main,
    "&:hover": {
      backgroundColor: theme.palette.GrayPrg.main,
      color: theme.palette.DarkGrayPrg.main,
    },
  },
  errorBg: {
    background: "white",
    padding: ".5rem",
  },
}));

export default function Index() {
  const classes = useStyles();
  const history = useHistory();
  let { token } = useParams();
  const dispatch = useDispatch();

  let goBack = () => {
    history.push("/");
  };

  let goToReset = () => {
    history.push("/forgot-password");
  };

  const { register, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const [errors, setErrors] = useState();
  const [confirmation, setConfirmation] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = async (data) => {
    if (!data["password"]) {
      setErrors({ password: "Required" });
      return false;
    }

    if (!data["confirmPassword"]) {
      setErrors({ confirmPassword: "Required" });
      return false;
    }

    if (data["password"] !== data["confirmPassword"]) {
      // confirm passwords match
      setErrors({ password: "Passwords must match" });
      setErrors({ confirmPassword: "Passwords must match" });
      return false;
    }

    if (checkPassword(data["password"]) === false) {
      setErrors({
        password:
          "Passwords must have min 8 characters, with at least a symbol, upper and lower case letters and a number",
      });
      return false;
    }

    try {
      await userService.resetPassword({ password: data["password"] }, token);
    } catch (error) {
      dispatch(alertActions.error(`${error}`));
    }

    setConfirmation(true);
    setErrors();
  };

  const [renderError, setRenderError] = useState(false);

  useEffect(() => {
    async function fetchMyAPI(token_) {
      let jwtStatus = await userService.checkPasswordResetJwt(token_);
      if (jwtStatus["status"] === false) {
        // render screen for user to try again.
        // add button to go back to request screen
        setRenderError(true);
        dispatch(alertActions.error(`${jwtStatus["message"]}`));
        return false;
      }
      setRenderError(false);
    }

    if (token) {
      try {
        fetchMyAPI(token);
      } catch (error) {
        dispatch(alertActions.error(`${error}`));
      }
    }
  }, [token, dispatch]);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.root}>
        <div className={classes.imgWrap}>
          <div className={classes.imgInnerWrap}>
            <img
              src={PRGLogo}
              className={classes.img}
              alt="Project Resources Group logo"
            />
          </div>
        </div>
        {renderError && !confirmation && (
          <div className={classes.form}>
            <p className={classes.textWhite}>Password reset link expired</p>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.error}
                onClick={() => goToReset()}
              >
                Try again
              </Button>
            </div>
          </div>
        )}
        {!renderError && confirmation && (
          <div className={classes.form}>
            <p className={classes.textWhite}>Password reset success!</p>
            <p className={classes.textWhite}>
              Please login with your new password
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.confirm}
                onClick={() => goBack()}
              >
                Return to login
              </Button>
            </div>
          </div>
        )}
        {!confirmation && !renderError && (
          <form className={classes.form} noValidate>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="filled"
            >
              <InputLabel htmlFor="filled-adornment-password">
                Password
              </InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                inputRef={register}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {errors && errors["password"] && (
              <FormHelperText
                error
                id="component-error-text"
                className={classes.errorBg}
              >
                {errors["password"]}
              </FormHelperText>
            )}

            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="filled"
            >
              <InputLabel htmlFor="filled-adornment-confirm-password">
                Confirm Password
              </InputLabel>
              <FilledInput
                id="filled-adornment-confirm-password"
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                inputRef={register}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {errors && errors["confirmPassword"] && (
              <FormHelperText
                error
                id="component-error-text"
                className={classes.errorBg}
              >
                {errors["confirmPassword"]}
              </FormHelperText>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
            <p className={classes.forgotPass} onClick={() => goBack()}>
              Go Back
            </p>
          </form>
        )}
      </div>
    </Container>
  );
}

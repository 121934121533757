import { put, call } from "redux-saga/effects";
import {
  alertConstants,
  authConstants,
  adminRecoveryAgentConstants,
} from "_constants";
import { adminRecoveryAgentService } from "_services";


export function* getAllRecoveryAgentAdminSaga() {
    try {
      const result = yield call(adminRecoveryAgentService.getAll);

      yield put({
        type: adminRecoveryAgentConstants.ADMIN_GET_ALL_RECOVERY_AGENTS_SUCCESS,
        payload: result,
      });
    } catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
  
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
  }

  export function* getOneRecoveryAgentAdminSaga({ id }) {
    try {
      const result = yield call(adminRecoveryAgentService.getOne, id);
      yield put({
        type: adminRecoveryAgentConstants.ADMIN_GET_ONE_RECOVERY_AGENT_SUCCESS,
        payload: {
          id,
          data: [ ...result ],
        },
      });
    } catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
  
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
  }

  export function* getTeamListRecoveryAgentAdminSaga({ id }) {
    try {
      const result = yield call(adminRecoveryAgentService.getTeamList, id);
  
      yield put({
        type: adminRecoveryAgentConstants.ADMIN_GET_TEAM_LIST_SUCCESS,
        payload: {
          id,
          data: [ ...result ],
        },
      });
    } catch (error) {
      if (error.message === "Invalid Auth Token") {
        yield put({
          type: authConstants.LOGOUT,
        });
      }
  
      yield put({
        type: alertConstants.ERROR,
        payload: { message: error.message },
      });
    }
  }
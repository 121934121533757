export const adminPowerBiRoleConstants = {
  GET_ALL_POWERBI_ROLES_REQUEST: "GET_ALL_POWERBI_ROLES_REQUEST",
  GET_ALL_POWERBI_ROLES_SUCCESS: "GET_ALL_POWERBI_ROLES_SUCCESS",
  GET_ALL_POWERBI_ROLES_LOADING: "GET_ALL_POWERBI_ROLES_LOADING",
  CREATE_POWERBI_ROLE_REQUEST: "CREATE_POWERBI_ROLE_REQUEST",
  CREATE_POWERBI_ROLE_SUCCESS: "CREATE_POWERBI_ROLE_SUCCESS",
  UPDATE_POWERBI_ROLE_REQUEST: "UPDATE_POWERBI_ROLE_REQUEST",
  UPDATE_POWERBI_ROLE_SUCCESS: "UPDATE_POWERBI_ROLE_SUCCESS",
  UPDATE_POWERBI_ROLE_LOADING: "UPDATE_POWERBI_ROLE_LOADING",
  ADMIN_POWERBI_ROLES_CLEAR: "ADMIN_POWERBI_ROLES_CLEAR"
};


